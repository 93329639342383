import './style.scss';

import React, { useEffect, useState } from 'react';

const LoadingIndicator = ({ text = `Loading...`, delay = 0 }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  });

  return show ? (
    <div className="loop-wrapper animated fadeIn">
      <h2>{text}</h2>
      <div className="sun" />
      <div className="mountain" />
      <div className="hill" />
      <div className="tree" />
      <div className="tree" />
      <div className="tree" />
      <div className="rock" />
      <div className="truck" />
      <div className="wheels" />
    </div>
  ) : null;
};

export default LoadingIndicator;
