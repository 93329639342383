import React from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormLink,
  FormSubmit,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import { ROLE } from 'straightline-utils/constants';

export default function Invite() {
  const app = useApp();
  const service = app.service('api/invitations');
  const history = useHistory();
  const initialValues = {
    email: '',
    role_type: ROLE.CARRIER_ADMIN
  };

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then(() => {
        toast.success('Invitation Sent!');
        formik.setSubmitting(false);
        history.push('/settings/users');
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <Container fluid>
      <Row>
        <Col>
          <div className="header pt-md-2">
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <h6 className="header-pretitle">My Team</h6>
                  <h1 className="header-title">Invite New User</h1>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl="5" lg="6">
          <Card>
            <CardBody>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={service.schema}
                render={({ isSubmitting, values }) => {
                  return (
                    <Form>
                      <FormGroup>
                        <Label>Email*</Label>
                        <FormInput
                          name="email"
                          disabled={isSubmitting}
                          placeholder="Enter email"
                        />
                        <FormFeedback name="email" />
                      </FormGroup>
                      <FormSubmit className="mr-3">Invite</FormSubmit>
                      <FormLink to="/settings/users">Cancel</FormLink>
                    </Form>
                  );
                }}
              />
            </CardBody>
          </Card>
        </Col>
        <Col xl="5" lg="6">
          <p className="text-muted mt-4">
            New users will receive an email to create a new account and have
            access to the Strightline app.
          </p>
        </Col>
      </Row>
    </Container>
  );
}
