import { authenticateClient } from 'src/feathers/hooks';
import { validateSchema } from 'straightline-utils/hooks';

export default {
  before: {
    all: [authenticateClient],
    find: [],
    create: [validateSchema]
  },
  after: {
    all: [],
    find: [],
    create: []
  },
  error: {
    all: [],
    find: [],
    create: []
  }
};
