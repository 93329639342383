import React from 'react';
import WithStripe from 'src/components/WithStripe';
import { useAppState } from 'src/lib/hooks';
import {
  Card,
  CardBody,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormReset,
  FormSubmit,
  Label,
  TabContent,
  TabPane,
  toast,
  useApp
} from 'straightline-ui';
import { STATES } from 'straightline-utils/constants';

const stateOptions = STATES.map((state) => {
  return { label: state, value: state };
});

function CompanyForm({ stripe, onComplete }) {
  const app = useApp();
  const {
    state: { carrier }
  } = useAppState();

  const handleCompanySubmit = (data, formik) => {
    formik.setSubmitting(true);
    stripe
      .createToken('account', {
        company: data,
        business_type: 'company',
        tos_shown_and_accepted: true
      })
      .then((stripeAccount) => {
        return app
          .service('api/verification')
          .create({ account_token: stripeAccount.token.id });
      })
      .then((updatedCarrier) => {
        formik.setSubmitting(false);
        if (onComplete) {
          onComplete(updatedCarrier);
        }
      })
      .catch((err) => {
        toast.errors(err);
        formik.setSubmitting(false);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
      });
  };

  // const handleProfileSubmit = (data, formik) => {
  //   // TODO: Call our server directly and update this info
  // };

  // const handleChangeTabs = (activeTab) => {
  //   this.setState({ activeTab });
  // };

  const activeTab = 1;

  return (
    <Card>
      {/* <div className="card-header">
          <Row>
            <Col className="" xl="8" lg="6">
              <Nav tabs className="card-header-tabs">
                <NavTab
                  tab={1}
                  activeTab={activeTab}
                  onClick={handleChangeTabs}
                >
                  Company
                </NavTab>
                <NavTab
                  tab={2}
                  activeTab={activeTab}
                  onClick={handleChangeTabs}
                >
                  Profile
                </NavTab>
              </Nav>
            </Col>
          </Row>
        </div> */}
      <div data-private>
        <CardBody>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={1}>
              <Formik
                initialValues={{
                  name: carrier.name,
                  phone: '',
                  tax_id: '',
                  address: {
                    city: '',
                    line1: '',
                    line2: '',
                    postal_code: '',
                    state: '',
                    country: 'US'
                  }
                }}
                validationSchema={this.service.companySchema}
                onSubmit={handleCompanySubmit}
                render={({ isSubmitting, values }) => {
                  return (
                    <Form>
                      <FormGroup>
                        <Label>Legal Business Name*</Label>
                        <FormInput name="name" disabled={isSubmitting} />
                        <FormFeedback name="name" />
                        <p className="text-muted small mt-2">
                          Your legal business name must exactly match your tax
                          ID—including capitalization. Please make sure it's
                          correct.
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <Label>Phone*</Label>
                        <FormInput
                          name="phone"
                          type="phone"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="phone" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Employer Identification Number (EIN)*</Label>
                        <FormInput
                          name="tax_id"
                          maxLength="9"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="tax_id" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Address 1*</Label>
                        <FormInput
                          name="address.line1"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="address.line1" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Address 2</Label>
                        <FormInput
                          name="address.line2"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="address.line2" />
                      </FormGroup>
                      <FormGroup>
                        <Label>City*</Label>
                        <FormInput
                          name="address.city"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="address.city" />
                      </FormGroup>
                      <FormGroup>
                        <Label>State*</Label>
                        <FormInput
                          name="address.state"
                          type="select"
                          options={stateOptions}
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="address.state" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Zip*</Label>
                        <FormInput
                          name="address.postal_code"
                          maxLength="5"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="address.postal_code" />
                      </FormGroup>
                      <FormSubmit className="mr-3">Submit</FormSubmit>
                      <FormReset>Discard Changes</FormReset>
                      <p className="mt-3 mb-0 text-muted">
                        Please ensure that all values are correct and match your
                        legal records. Your company information cannot be
                        changed after verification.
                      </p>
                    </Form>
                  );
                }}
              />
            </TabPane>
            {/* <TabPane tabId={2}>
                  <Formik
                    initialValues={{
                      name: carrier.name,
                      url: '',
                      support_url: '',
                      support_email: '',
                      support_phone: ''
                    }}
                    onSubmit={this.handleProfileSubmit}
                    render={({ isSubmitting }) => {
                      return (
                        <Form>
                          <FormGroup>
                            <Label>Doing Business As (DBA)</Label>
                            <FormInput name="name" disabled={isSubmitting} />
                            <FormFeedback name="name" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Support URL</Label>
                            <FormInput
                              name="support_url"
                              disabled={isSubmitting}
                            />
                            <FormFeedback name="support_url" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Support Email</Label>
                            <FormInput
                              name="support_email"
                              disabled={isSubmitting}
                            />
                            <FormFeedback name="support_email" />
                          </FormGroup>
                          <FormGroup>
                            <Label>Support Phone</Label>
                            <FormInput
                              type="phone"
                              name="support_phone"
                              disabled={isSubmitting}
                            />
                            <FormFeedback name="support_phone" />
                          </FormGroup>
                          <FormGroup>
                            <Button color="primary">Submit</Button>
                          </FormGroup>
                        </Form>
                      );
                    }}
                  />
                </TabPane> */}
          </TabContent>
        </CardBody>
      </div>
    </Card>
  );
}

export default (props) => {
  return (
    <WithStripe
      render={({ stripe, elements }) => (
        <CompanyForm {...props} stripe={stripe} elements={elements} />
      )}
    />
  );
};
