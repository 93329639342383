import React from 'react';
import { Button, ButtonGroup, Icon } from 'straightline-ui';

export default function PaginationBtns({
  total,
  setSkip,
  max,
  limit = 10,
  skip = 0,
  disabled = false
}) {
  const $skip = Number(skip);
  const $limit = Number(limit);
  const $max = max ? Number(max) : max;
  const $total = $max ? $max : Number(total);
  const lastOfSet = $max ? Math.min($skip + $limit, $max) : $skip + $limit;
  const firstOfSet = $skip + 1;
  return (
    <React.Fragment>
      <div className="pagination-btns">
        <span className="margin-right-15">
          {firstOfSet}-{lastOfSet > $total ? $total : lastOfSet}&nbsp;of&nbsp;
          {$total}
        </span>
        <Button
          className="btn-rounded-circle margin-right-5"
          size="sm"
          disabled={$skip === 0 || disabled}
          onClick={() => setSkip($skip - $limit)}
        >
          <Icon type="chevron-left" />
        </Button>
        <Button
          className="btn-rounded-circle"
          size="sm"
          disabled={lastOfSet >= $total || disabled}
          onClick={() => setSkip($skip + $limit)}
        >
          <Icon type="chevron-right" />
        </Button>
      </div>
    </React.Fragment>
  );
}

export function TablePaginationBtns({
  total,
  setSkip,
  max,
  limit = 10,
  skip = 0,
  disabled = false
}) {
  const $skip = Number(skip);
  const $limit = Number(limit);
  const $max = max ? Number(max) : max;
  const $total = $max ? $max : Number(total);
  const lastOfSet = $max ? Math.min($skip + $limit, $max) : $skip + $limit;
  const firstOfSet = $skip + 1;
  return (
    <div>
      {total > 0 ? (
        <span className="mr-2 smaller">
          {firstOfSet}-{lastOfSet > $total ? $total : lastOfSet}&nbsp;of&nbsp;
          {$total}
        </span>
      ) : (
        <span className="mr-2 smaller">0 results</span>
      )}
      <ButtonGroup disabled={true}>
        <Button
          color="secondary"
          size="sm"
          disabled={$skip === 0 || disabled}
          onClick={() => setSkip($skip - $limit)}
        >
          <Icon type="chevron-left" />
        </Button>
        <Button
          color="secondary"
          size="sm"
          disabled={lastOfSet >= $total || disabled}
          onClick={() => setSkip($skip + $limit)}
        >
          <Icon type="chevron-right" />
        </Button>
      </ButtonGroup>
    </div>
  );
}
