import React, { useState } from 'react';
import { useAppState } from 'src/lib/hooks';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  Label,
  Modal,
  ModalBody,
  toast,
  useApp
} from 'straightline-ui';
import { yup } from 'straightline-utils/validation';

// When given a child function, the component's open state is
// managed internally. This is nice because you basically just
// supply the button and the open state is managed. But, its
// also trying to mount the modal markup right next to your
// button, which can clash when trying to use this in dropdowns,
// etc. So, don't pass children and pass isOpen to have just
// a modal that is controlled externally

const schema = yup.object({
  text: yup.string().trim().required().label('Message')
});

export default function ContactModal({
  children,
  isOpen,
  toggle,
  config = {}
}) {
  const app = useApp();
  const [isInternalOpen, setIsOpen] = useState(false);
  const toggleInternal =
    toggle ||
    function () {
      setIsOpen(!isInternalOpen);
    };

  const {
    state: { user }
  } = useAppState();

  const open = children ? isInternalOpen : isOpen;

  const service = app.service('api/contact');

  const handleSubmit = (data, formik) => {
    service
      .create({
        subject: 'New App Contact',
        ...config,
        emails: [process.env.REACT_APP_SUPPORT_EMAIL],
        text: data.text,
        preTag: JSON.stringify(
          {
            user: { id: user.id, email: user.email, full_name: user.full_name }
          },
          null,
          2
        )
      })
      .then(() => {
        toast.success(`Message Sent!`);
        formik.resetForm();
        toggleInternal();
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {children && children(toggleInternal)}
      <Modal
        isOpen={open}
        toggle={toggleInternal}
        className="mx-auto"
        style={{ maxWidth: 425, width: '95%' }}
      >
        {() => (
          <ModalBody>
            <h2>We're here for you.</h2>
            <p>
              Straightline has a team of logistics professionals ready to help.
              Give us a shout or fill out the form below and we will get back to
              you ASAP.
            </p>
            <p>
              <Icon type="phone" className="mr-3" />
              <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                {process.env.REACT_APP_SUPPORT_PHONE}
              </a>
            </p>
            <p>
              <Icon type="mail" className="mr-3" />
              <a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </p>
            <Formik
              initialValues={{ text: '' }}
              onSubmit={handleSubmit}
              validationSchema={schema}
              render={({ isSubmitting }) => {
                return (
                  <Form>
                    <FormGroup>
                      <Label>Message*</Label>
                      <FormInput
                        name="text"
                        type="textarea"
                        placeholder="How can we help?"
                        rows="6"
                        disabled={isSubmitting}
                      />
                      <FormFeedback name="text" />
                    </FormGroup>
                    <div className="d-flex justify-content-between">
                      <div>
                        <Button
                          color="white"
                          className="mr-3"
                          disabled={isSubmitting}
                          type="button"
                          onClick={toggleInternal}
                        >
                          Cancel
                        </Button>
                      </div>
                      <div>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          className={isSubmitting ? 'is-loading' : ''}
                        >
                          <Icon type="send" className="mr-2" />
                          Submit
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            />
          </ModalBody>
        )}
      </Modal>
    </React.Fragment>
  );
}
