import React, { useState } from 'react';
import VideoModal from 'src/components/VideoModal';
import emailImage from 'src/images/email.svg';
import logo from 'src/images/logo-md.png';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Label,
  Link,
  Row,
  Stripes,
  toast,
  useApp
} from 'straightline-ui';

const initialValues = {
  carrier_name: '',
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  mc_number: '',
  dot_number: ''
};

// There is not really a "code" sent from the
// server indicating which validation rule it
// failed. We just get "This ${label} is not available"
// when something fails a .unique() validation
const hasUniqueError = (errors) => {
  return Object.values(errors).find((message) =>
    message.includes('is not available')
  );
};

export default function Signup() {
  const [success, setSuccess] = useState(false);

  if (success) {
    return (
      <Container fluid>
        <Row className="align-items-center justify-content-center vh-100">
          <Col xs="12" md="5" lg="4">
            <div className="px-3">
              <img
                src={emailImage}
                alt="Check Email"
                className="img-fluid mb-5 animated slideInDown"
              />
            </div>
            <div className="display-4 mb-3 animated slideInUp">
              Almost there!
            </div>
            <p className="text-muted lead mb-3 animated slideInUp">
              Check your email to validate your account. If you do not see an
              email, be sure to check your spam filter. Once validated, you will
              then upload your required documents to complete the sign-up
              process.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col
          xs="12"
          md="6"
          lg="5"
          xl="4"
          className="px-lg-5 border-lg-right d-flex align-items-center min-h-100vh"
        >
          <div className="w-100">
            <div className="text-center my-4">
              <img
                src={logo}
                alt="Straightline Logo"
                className="img-fluid"
                style={{ height: 50 }}
              />
            </div>
            <SignupForm handleSuccess={() => setSuccess(true)} />
          </div>
        </Col>
        <Col lg="7" xl="8" className="d-none d-lg-block px-0">
          <div
            className="d-flex align-items-center"
            style={{
              overflow: 'hidden',
              position: 'relative',
              height: '100vh'
            }}
          >
            <Stripes animation="fadeIn" />
            <Container fluid className="px-4">
              <Row className="d-flex justify-content-center">
                <Col xl="8">
                  <div className="text-white">
                    <h1 style={{ fontSize: '3.5rem' }}>Freight to the Point</h1>
                    <h3 style={{ fontSize: '1.5rem' }}>
                      Improve visibility, reduce waste, lower costs
                    </h3>
                    <p className="lead">
                      Straightline is a real-time truckload booking, matching,
                      and payments service that offers unparalleled transparency
                      and speed to the logistics industry.
                    </p>
                    <div>
                      <VideoModal className="mr-3" />
                      <a
                        href="https://www.bookstraightline.com/carriers"
                        className="btn btn-white"
                      >
                        Learn More
                      </a>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>

      {/* Preload the image so it does not flash when loaded later */}
      <img src={emailImage} alt="Check Email" className="d-none" />
    </Container>
  );
}

export function SignupForm({ handleSuccess }) {
  const app = useApp();
  const [uniqueError, setUniqueError] = useState(null);
  const { schema, useCreate } = app.service('api/signup');

  const createSignup = useCreate().create;

  const handleSubmit = (data, formik) => {
    createSignup(data)
      .then(handleSuccess)
      .catch((error) => {
        formik.setSubmitting(false);
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        if (error.errors && hasUniqueError(error.errors)) {
          setUniqueError(error.errors);
        } else {
          toast.errors(error);
        }
      });
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <FormGroup>
                <Label>Business Name*</Label>
                <FormInput
                  name="carrier_name"
                  placeholder="Enter business name"
                  disabled={isSubmitting}
                />
                <FormFeedback name="carrier_name" />
              </FormGroup>
              <FormGroup>
                <Label>First Name*</Label>
                <FormInput
                  name="first_name"
                  placeholder="Enter first name"
                  disabled={isSubmitting}
                />
                <FormFeedback name="first_name" />
              </FormGroup>
              <FormGroup>
                <Label>Last Name*</Label>
                <FormInput
                  name="last_name"
                  placeholder="Enter last name"
                  disabled={isSubmitting}
                />
                <FormFeedback name="last_name" />
              </FormGroup>
              <FormGroup>
                <Label>Email Address*</Label>
                <FormInput
                  type="email"
                  name="email"
                  placeholder="name@address.com"
                  disabled={isSubmitting}
                />
                <FormFeedback name="email" />
              </FormGroup>
              <FormGroup>
                <Label>Phone*</Label>
                <FormInput type="phone" name="phone" disabled={isSubmitting} />
                <FormFeedback name="phone" />
              </FormGroup>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label>MC Number*</Label>
                    <FormInput
                      name="mc_number"
                      placeholder="Enter MC number"
                      maxLength="8"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="mc_number" />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label>DOT Number*</Label>
                    <FormInput
                      name="dot_number"
                      placeholder="Enter DOT number"
                      maxLength="8"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="dot_number" />
                  </FormGroup>
                </Col>
              </Row>
              {uniqueError && (
                <Alert color="danger">
                  {uniqueError.email && (
                    <div>
                      It appears you are trying to sign up with an email that
                      already exists. Go to the{' '}
                      <Link to="/login" className="alert-link">
                        Login Page
                      </Link>{' '}
                      or{' '}
                      <Link to="/forgot-password" className="alert-link">
                        Reset Your Password
                      </Link>{' '}
                      to continue.
                      {(uniqueError.mc_number || uniqueError.dot_number) && (
                        <hr className="border-light my-3" />
                      )}
                    </div>
                  )}
                  {(uniqueError.mc_number || uniqueError.dot_number) && (
                    <div>
                      The MC or DOT number you provided belongs to an account
                      that is already registered. Contact the account owner for
                      an invitation. You can also{' '}
                      <Link to="/login" className="alert-link">
                        Contact Straightline
                      </Link>{' '}
                      if you believe this is a mistake.
                    </div>
                  )}
                </Alert>
              )}
              <Button
                type="submit"
                color="primary"
                size="lg"
                block
                className={`mb-3 ${isSubmitting && 'is-loading'}`}
                disabled={isSubmitting}
              >
                Sign up
              </Button>
              <div className="text-center">
                <p className="text-muted small">
                  By signing up, you expressly agree to the
                  <br />
                  Straightline&nbsp;
                  <Link to="/terms-of-service" target="_blank">
                    Terms of Service
                  </Link>
                  &nbsp; and the &nbsp;
                  <a
                    href="https://stripe.com/connect-account/legal"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Stripe Connected Account Agreement
                  </a>
                  . Already have an account?&nbsp;
                  <Link to="/login">Sign in</Link>
                </p>
              </div>
            </Form>
          );
        }}
      />
    </div>
  );
}
