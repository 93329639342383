import React from 'react';
import LoadingIndicator from 'src/components/LoadingIndicator';
import MapHeader from 'src/components/MapHeader';
import ShipmentPageHeader from 'src/components/ShipmentPageHeader';
import StatusProgress from 'src/components/StatusProgress';
import emptyImg from 'src/images/empty.svg';
import {
  Button,
  Col,
  Container,
  Icon,
  Row,
  TabContent,
  TabPane,
  useApp
} from 'straightline-ui';

import Details from './details';
import Documents from './documents';
import RateCon from './rate-confirmation';

export default function Shipment({ match, location }) {
  const app = useApp();
  const {
    result: shipment,
    isLoading,
    isError,
    error
  } = app.service('api/shipments').useGet(match.params.id);

  const activeTab = location.query.tab || 'details';

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (isError) {
    return (
      <Container>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg="6" className="text-center">
            <img
              src={emptyImg}
              alt="Not Found"
              className="img-fluid animated fadeIn faster mb-5"
            />
            {error.code === 404 ? (
              <h1>Could not find that load</h1>
            ) : (
              <h1>{error.message}</h1>
            )}
            <Button to="/shipments" color="primary" size="lg" className="mr-3">
              <Icon type="package" className="mr-2" />
              Return to My Loads
            </Button>
            <Button to="/support" color="secondary" size="lg">
              <Icon type="send" className="mr-2" />
              Contact Straightline
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <MapHeader shipment={shipment} />
      <StatusProgress
        shipment={shipment}
        unshift={window.innerWidth < 1050}
        trimFlat={true}
        unshiftFlat={true}
      />
      <Container fluid className="pb-3">
        <ShipmentPageHeader shipment={shipment} activeTab={activeTab} />
        <TabContent activeTab={activeTab}>
          <TabPane tabId="details">
            <Details shipment={shipment} />
          </TabPane>
          <TabPane tabId="documents">
            <Documents shipment={shipment} />
          </TabPane>
          <TabPane tabId="rate-confirmation">
            <RateCon shipment={shipment} />
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
}
