import React from 'react';
import { useAppState } from 'src/lib/hooks';
import { Col, Nav, Row, useScreenSize } from 'straightline-ui';

import NavTab from '../NavTab';

const withMy = (isXs, text) => (isXs ? text : 'My ' + text);

export default function SettingsHeader({ activeTab, children }) {
  const { isXs } = useScreenSize();
  const {
    state: { carrier }
  } = useAppState();

  return (
    <Row>
      <Col>
        <div className="header pt-md-2">
          <div className="header-body">
            <Row className="align-items-center">
              <Col>
                <h6 className="header-pretitle">Settings</h6>
                <h1 className="header-title">{carrier.name}</h1>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col>
                <Nav tabs className="nav-overflow header-tabs">
                  <NavTab to="/settings" activeTab={activeTab} tab="carrier">
                    {withMy(isXs, 'Business')}
                  </NavTab>
                  <NavTab
                    to="/settings/profile"
                    activeTab={activeTab}
                    tab="profile"
                  >
                    {withMy(isXs, 'Profile')}
                  </NavTab>
                  {/* <NavTab
                    to="/settings/verification"
                    activeTab={activeTab}
                    tab="verification"
                  >
                    Verification
                  </NavTab> */}
                  {carrier.stripe_account_id && (
                    <NavTab
                      to="/settings/billing"
                      activeTab={activeTab}
                      tab="billing"
                    >
                      Billing
                    </NavTab>
                  )}
                  <NavTab
                    to="/settings/users"
                    activeTab={activeTab}
                    tab="users"
                  >
                    {withMy(isXs, 'Team')}
                  </NavTab>
                </Nav>
              </Col>
              {children && <Col>{children}</Col>}
            </Row>
          </div>
        </div>
      </Col>
    </Row>
  );
}
