import './style.scss';

import React, { useState } from 'react';
import { Circle, DirectionsRenderer, Marker } from 'react-google-maps';
import { Button, useApp } from 'straightline-ui';

import Map from '../Map';

const Expand = ({ onClick, expanded }) => {
  return (
    <Button
      color="white"
      size="sm"
      className="btn-collapse shadow"
      onClick={onClick}
    >
      {expanded ? 'Collapse' : 'Expand'}
    </Button>
  );
};

export default ({ shipment, shipmentRequests }) => {
  const app = useApp();
  const [expanded, setExpanded] = useState(false);
  const { pickup_address, delivery_address } = shipment;

  let { result: route } = app.service('api/shipment-routes').useFind({
    query: {
      pickup_address,
      delivery_address
    }
  });

  return (
    <div className="map-header-match">
      <Map
        containerClassName={`map-header-match-container ${
          expanded ? 'open' : ''
        }`}
      >
        {shipmentRequests &&
          shipmentRequests.length > 0 &&
          shipmentRequests.map((shipmentRequest) => {
            const { pickup_address, delivery_address } = shipmentRequest;
            return (
              <React.Fragment key={shipmentRequest.id}>
                {pickup_address && pickup_address.position && (
                  <Marker position={pickup_address.position} color="blue" />
                )}
                {pickup_address && pickup_address.radius && (
                  <Circle
                    center={pickup_address.position}
                    radius={pickup_address.radius * 1609.344}
                    options={{
                      strokeColor: '#2C7BE5',
                      strokeOpacity: 0.5,
                      strokeWeight: 2,
                      fillColor: '#2C7BE5',
                      fillOpacity: 0.15
                    }}
                  />
                )}

                {delivery_address && delivery_address.position && (
                  <Marker position={delivery_address.position} color="blue" />
                )}
                {delivery_address && delivery_address.radius && (
                  <Circle
                    center={delivery_address.position}
                    radius={delivery_address.radius * 1609.344}
                    options={{
                      strokeColor: '#2C7BE5',
                      strokeOpacity: 0.5,
                      strokeWeight: 2,
                      fillColor: '#2C7BE5',
                      fillOpacity: 0.15
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}

        {route && <DirectionsRenderer directions={route.directions} />}
      </Map>
      <Expand expanded={expanded} onClick={() => setExpanded(!expanded)} />
    </div>
  );
};
