import React, { useMemo, useState } from 'react';
import MapHeaderRequest from 'src/components/MapHeaderRequest';
import { TruckTypePicker } from 'src/components/TruckTypePicker';
import {
  AddressCardPreview,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  Icon,
  InputAddress,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import { TRUCK_TYPE } from 'straightline-utils/constants';

const defaultInitialValues = {
  pickup_address: null,
  delivery_address: null,
  active: true,
  truck_types: [TRUCK_TYPE.DRY_VAN, TRUCK_TYPE.FLATBED]
};

const RadiusDropDown = ({ label, onChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <InputGroupButtonDropdown
      addonType="prepend"
      isOpen={open}
      toggle={() => setOpen(!open)}
    >
      <DropdownToggle color="white" caret>
        {label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onChange(50)}>50 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(100)}>100 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(200)}>200 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(300)}>300 mi</DropdownItem>
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default function ShipmentRequest({ history, location }) {
  const app = useApp();
  const service = app.service('api/shipment-requests');

  const handleSubmit = (data, formik) => {
    data = { ...data };

    if (data.pickup_address?.radius && !data.pickup_address?.latitude) {
      data.pickup_address = null;
    }

    if (data.delivery_address?.radius && !data.delivery_address?.latitude) {
      data.delivery_address = null;
    }

    service
      .create(data)
      .then((shipmentRequest) => {
        toast.success('Truck posted');
        service.removeFind();
        history.push(`/shipment-requests/${shipmentRequest.id}`);
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  const initialValues = {
    ...defaultInitialValues,
    ...location.query.initialValues
  };

  if (initialValues.pickup_address) {
    initialValues.pickup_address = {
      ...initialValues.pickup_address,
      radius: Number(initialValues.pickup_address.radius || 50),
      position: {
        lat: Number(initialValues.pickup_address.latitude),
        lng: Number(initialValues.pickup_address.longitude)
      }
    };
  }

  if (initialValues.delivery_address) {
    initialValues.delivery_address = {
      ...initialValues.delivery_address,
      radius: Number(initialValues.delivery_address.radius || 50),
      position: {
        lat: Number(initialValues.delivery_address.latitude),
        lng: Number(initialValues.delivery_address.longitude)
      }
    };
  }

  const date = useMemo(() => new Date(), []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ isSubmitting, values, setValues, setFieldValue }) => {
        const hasAddress =
          values.pickup_address?.latitude || values.delivery_address?.latitude;
        const hasTruckType = values.truck_types?.length >= 1;

        return (
          <React.Fragment>
            <MapHeaderRequest values={values} />
            <Container fluid className="pb-5">
              <Form>
                <Card className="mt-n6" style={{ borderTopLeftRadius: 0 }}>
                  <CardBody>
                    <Row>
                      <Col xl={6}>
                        <FormGroup>
                          <Label>Where is your truck empty?</Label>
                          <InputGroup>
                            <RadiusDropDown
                              label={`${
                                values.pickup_address?.radius || 50
                              } mi`}
                              onChange={(radius) => {
                                setFieldValue('pickup_address.radius', radius);
                              }}
                            />
                            <InputAddress
                              className="form-control-prepended"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              disabled={isSubmitting}
                              isClearable={true}
                              name="pickup_address"
                              value={values.pickup_address}
                              onChange={({ target: { value } }) => {
                                if (!value) {
                                  return setValues((values) => {
                                    return {
                                      ...values,
                                      pickup_address: null
                                    };
                                  });
                                }
                                return setValues((values) => {
                                  return {
                                    ...values,
                                    pickup_address: {
                                      radius:
                                        values.pickup_address?.radius || 50,
                                      ...value
                                    }
                                  };
                                });
                              }}
                            />
                          </InputGroup>
                          <AddressCardPreview
                            address={
                              values.pickup_address?.latitude
                                ? values.pickup_address
                                : undefined
                            }
                            date={date}
                            className="border rounded bg-lighter mt-3 p-3"
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label>Where would you like to deliver?</Label>
                          <InputGroup>
                            <RadiusDropDown
                              label={`${
                                values.delivery_address?.radius || 50
                              } mi`}
                              onChange={(radius) => {
                                setFieldValue(
                                  'delivery_address.radius',
                                  radius
                                );
                              }}
                            />
                            <InputAddress
                              className="form-control-prepended"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null
                              }}
                              disabled={isSubmitting}
                              isClearable={true}
                              name="delivery_address"
                              value={values.delivery_address}
                              onChange={({ target: { value } }) => {
                                if (!value) {
                                  return setValues((values) => {
                                    return {
                                      ...values,
                                      delivery_address: null
                                    };
                                  });
                                }
                                return setValues((values) => {
                                  return {
                                    ...values,
                                    delivery_address: {
                                      radius:
                                        values.delivery_address?.radius || 50,
                                      ...value
                                    }
                                  };
                                });
                              }}
                            />
                          </InputGroup>
                          <AddressCardPreview
                            address={
                              values.delivery_address?.latitude
                                ? values.delivery_address
                                : undefined
                            }
                            date={date}
                            className="border rounded bg-lighter mt-3 p-3"
                          />
                        </FormGroup>
                        {!hasAddress && (
                          <div className="text-danger mb-3">
                            Pickup or Delivery Address is required
                          </div>
                        )}
                        {!hasTruckType && (
                          <div className="text-danger mb-3">
                            At least one truck type is required
                          </div>
                        )}

                        {/* <FormGroup>
                          <Label>Active</Label>
                          <FormInput
                            type="checkbox"
                            name="active"
                            disabled={isSubmitting}
                          />
                          <FormFeedback name="active" />
                        </FormGroup> */}
                      </Col>
                      <Col xl={6}>
                        <FormGroup>
                          <Label>What type of truck is available?</Label>
                          <TruckTypePicker
                            values={values}
                            setValues={setValues}
                            disabled={isSubmitting}
                          />
                          <FormFeedback name="truck_types" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <div className="border-top pt-4">
                      <Row className="align-items-center">
                        <Col sm="auto">
                          <Button
                            color="primary"
                            type="submit"
                            className={`mr-3 btn-block ${
                              isSubmitting ? 'is-loading' : ''
                            }`}
                            disabled={
                              !hasAddress || !hasTruckType || isSubmitting
                            }
                          >
                            <Icon type="truck" className="mr-2" />
                            Post Truck
                          </Button>
                        </Col>
                        <Col className="mt-3 mt-sm-0">
                          Post a truck by adding pickup and/or delivery
                          locations and truck types. Once submitted, the app's
                          loadboard uses this information to match your truck
                          with suitable shipments. You'll receive timely
                          notifications whenever new shipments align with your
                          criteria.
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Form>
            </Container>
          </React.Fragment>
        );
      }}
    />
  );
}
