import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LineItemsCard from 'src/components/LineItemsCard';
import LoadingIndicator from 'src/components/LoadingIndicator';
import MapHeaderPreview from 'src/components/MapHeaderPreview';
import ShipmentBanner from 'src/components/ShipmentBanner';
import VideoModal from 'src/components/VideoModal';
import emptyImg from 'src/images/empty.svg';
import heavyBoxRedImg from 'src/images/heavy-box-red.svg';
import logo from 'src/images/logo-md.png';
import { SignupForm } from 'src/pages/Auth/signup';
import { Container, useApp } from 'straightline-ui';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row
} from 'straightline-ui';
import { ACCESSORIAL_CODES, isBooked } from 'straightline-utils/constants';
import { differenceInSeconds, formatDate } from 'straightline-utils/time';

// TODO: Memoize MapHeaderMatch because it is a heavy render
// cycle and fires alot during this page

export default function LoadBoardPreview({ match }) {
  const app = useApp();
  const history = useHistory();
  const [loginAttempted, setLoginAttempted] = useState(false);

  const {
    result: shipment,
    isLoading,
    isError,
    error
  } = app.service('api/shipment-previews').useGet(
    match.params.id,
    { query: { type: 'LOAD_BOARD' } },
    {
      refetchInterval: (result) => {
        if (!result) {
          return false;
        }
        // When newly created, there are still dispatches and other side
        // effects happening in the background. There may also be an SL
        // admin used making quick updates as this is created. Ping
        // for updates on new shipments
        const created = differenceInSeconds(new Date(), result.created_at);
        if (created <= 15) {
          return 3000;
        }
        if (created <= 60) {
          return 8000;
        }
        if (created <= 60 * 5) {
          return 15000;
        }
        return 60000;
      }
    }
  );

  useEffect(() => {
    const attemptLogin = async () => {
      try {
        const token = await app.authentication.getAccessToken();
        if (token) {
          await app.authenticate();
          history.push(`/shipment-matches/${shipment.id}`);
        }
        setLoginAttempted(true);
      } catch (error) {
        setLoginAttempted(true);
      }
    };

    if (shipment && !loginAttempted) {
      attemptLogin();
    }
  }, [app, history, shipment, loginAttempted]);

  if (isLoading || !loginAttempted) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <React.Fragment>
      <Container fluid className="fixed-top bg-white shadow-sm bg-white">
        <Row className="align-items-center py-2">
          <Col xs="4">
            <img src={logo} style={{ maxWidth: 138 }} alt="Straightline Logo" />
          </Col>
          <Col xs="8" className="ml-auto text-right">
            <a
              href="https://www.bookstraightline.com/carriers"
              target="_blank"
              rel="noreferrer"
              className="btn btn-white"
            >
              <Icon type="help-circle" className="mr-2" />
              Learn More
            </a>
          </Col>
        </Row>
      </Container>
      <Container className="pb-3" style={{ marginTop: 53 }}>
        <Card className="mb-0">
          <MapHeaderPreview shipment={shipment} />
        </Card>
        <div className="header">
          <div className="header-body">
            <Row className="align-items-center justify-content-between">
              <Col lg="auto" className="mb-3 mb-lg-0">
                <h6 className="header-pretitle">Load Board / Book Load</h6>
                <Row className="align-items-center mb-n1">
                  <Col
                    xs="auto"
                    className="mb-2 pr-0 align-items-center d-flex"
                  >
                    <h1 className="mb-0 font-weight-bold d-inline-block mr-3">{`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}</h1>
                    <Icon
                      type="arrow-right"
                      style={{ fontSize: 24 }}
                      className="text-success lead d-inline-block font-weight-bold"
                    />
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <h1 className="mb-0 font-weight-bold d-inline-block">{`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}</h1>
                  </Col>
                </Row>
              </Col>
              <Col lg="auto">
                <Row className="mb-n3">
                  <Col md="auto" className="p-md-0 mr-md-3 mb-3">
                    <VideoModal className="btn-block" />
                  </Col>
                  <Col md="auto" className="p-md-0 mr-md-3 mb-3">
                    <ContactModal shipment={shipment} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {isBooked(shipment) ? (
          <Preview shipment={shipment} />
        ) : (
          <AlreadyMatched shipment={shipment} />
        )}
      </Container>
    </React.Fragment>
  );
}

const ContactModal = ({ shipment }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((open) => !open);

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={toggle}
        className="mx-auto"
        style={{ maxWidth: 425, width: '95%' }}
      >
        {() => (
          <ModalBody>
            <h2>We're here for you.</h2>
            <p>
              Straightline has a team of logistics professionals ready to help.
              Give us a shout or fill out the form below and we will get back to
              you ASAP.
            </p>
            <p>
              <Icon type="phone" className="mr-3" />
              <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                {process.env.REACT_APP_SUPPORT_PHONE}
              </a>
            </p>
            <p>
              <Icon type="mail" className="mr-3" />
              <a
                href={`mailto:${
                  process.env.REACT_APP_SUPPORT_EMAIL
                }?subject=Shipment #${shipment?.id || 'NA'}`}
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </p>
            <div className="mt-5">
              <Button
                color="white"
                className="mr-3"
                type="button"
                onClick={toggle}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        )}
      </Modal>
      <Button color="white" onClick={toggle} block>
        <Icon type="send" className="mr-2" />
        Contact
      </Button>
    </React.Fragment>
  );
};

const AddressDetails = ({ shipment, type }) => {
  const date = type === 'pickup' ? shipment.pickup_date : shipment.due_date;
  const address = shipment[`${type}_address`];

  const accessorials = ACCESSORIAL_CODES[type.toUpperCase()].filter(
    ({ value }) => {
      return (shipment.accessorial_codes || []).indexOf(value) > -1;
    }
  );

  return (
    <div>
      <div>{`${address.city}, ${address.state} ${address.postal_code}`}</div>
      <div>{formatDate(date, 'iiii, LLLL d, yyyy')}</div>
      {address.appointment && <div>{address.appointment}</div>}
      {accessorials.length > 0 && (
        <div className="mt-1">
          {accessorials.map(({ label }) => {
            return (
              <Badge pill color="light" className="mr-2">
                {label}
              </Badge>
            );
          })}
        </div>
      )}
    </div>
  );
};

const Preview = ({ shipment }) => {
  const [success, setSuccess] = useState(false);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col lg="6" className="mb-4 mb-lg-0 pr-lg-4">
              <ShipmentBanner shipment={shipment} />
              <ListGroup className="list-group list-group-flush list-group-activity my-3">
                {shipment.pickup_distance > 0 && (
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto" className="bg-white text-secondary">
                        {shipment.pickup_distance} mi
                      </Col>
                      <Col className="ml-n2 text-secondary">deadhead</Col>
                    </Row>
                  </ListGroupItem>
                )}
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="clipboard" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div className="font-weight-bold">
                        {shipment.rate.service_level.name}
                      </div>
                      <div>{shipment.rate.service_level.description}</div>
                      {shipment.rate.service_level.code === 'TEM' && (
                        <div className="font-weight-bold text-primary">
                          ** This shipment requires two drivers **
                        </div>
                      )}
                      <div>
                        <span className="text-secondary">SL#: </span>
                        {shipment.id}
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="truck" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <AddressDetails shipment={shipment} type="pickup" />
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="map-pin" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <AddressDetails shipment={shipment} type="delivery" />
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
              <LineItemsCard shipment={shipment} className="shadow-none" />
              <h5 className="text-uppercase text-muted">Notes</h5>
              <div className="pre-line mb-0">
                {shipment.notes || <span className="text-muted">NA</span>}
              </div>
            </Col>
            <Col lg="6" className="pl-lg-4 border-lg-left my-lg-n4 py-lg-4">
              <hr className="d-lg-none mb-5" />
              <div className="mb-3">
                Straightline is a real-time truckload booking, matching, and
                payments service that offers unparalleled transparency and speed
                to the logistics industry. Signup to book this load or make a
                counteroffer.
              </div>
              {success ? (
                <div className="alert alert-primary animated fadeIn fast">
                  <h2 className="">Success!</h2>
                  <p>
                    You have successfully signed up for Straightline. Check your
                    email to validate your account. If you do not see an email,
                    be sure to check your spam filter. Once validated, you can
                    book this load.
                  </p>
                </div>
              ) : (
                <SignupForm handleSuccess={() => setSuccess(true)} />
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const AlreadyMatched = ({ shipment }) => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col lg="6" className="mb-4 mb-lg-0 pr-lg-4">
            <img
              src={heavyBoxRedImg}
              className="img-fluid"
              alt="Shipment not available"
            />
          </Col>
          <Col lg="6" className="pl-lg-4 border-lg-left my-lg-n4 py-lg-4">
            <hr className="d-lg-none mb-5" />
            <div style={{ maxWidth: 250 }}>
              <img
                src={logo}
                className="img-fluid mb-3"
                alt="Straightline Logo"
              />
            </div>
            <div className="bg-danger text-white p-3 rounded mb-3">
              This load is no longer available. Most likely another carrier
              accepted the load before you. Sign up to get notifications of
              loads like this one.
            </div>
            <p className="">
              Straightline is a real-time truckload booking, matching, and
              payments service that offers unparalleled transparency and speed
              to the logistics industry. Sign up and gain access to real time
              load boards, active notifications, digital rate confirmations and
              more.
            </p>
            <Row className="mb-n2">
              <Col md="auto" className="p-md-0 mr-md-3 mb-2">
                <VideoModal className="btn-block" />
              </Col>
              <Col md="auto" className="p-md-0 mr-md-3 mb-2">
                <ContactModal shipment={shipment} />
              </Col>
              <Col md="auto" className="p-md-0 mb-2">
                <Button to="/signup" color="primary" block>
                  <Icon type="truck" className="mr-2" />
                  Sign Up for Free
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

const Error = ({ error }) => {
  return (
    <Container>
      <Row className="align-items-center justify-content-center vh-100">
        <Col lg="6" className="text-center">
          <img
            src={emptyImg}
            alt="Not Found"
            className="img-fluid animated fadeIn faster mb-5"
          />
          {error.code === 404 ? (
            <h1>Could not find that load</h1>
          ) : (
            <h1>{error.message}</h1>
          )}
          <Button
            to="/shipment-matches"
            color="primary"
            size="lg"
            className="mr-3"
          >
            <Icon type="clipboard" className="mr-2" />
            Load Board
          </Button>
          <Button to="/support" color="secondary" size="lg">
            <Icon type="send" className="mr-2" />
            Contact Straightline
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
