import { STATES } from 'straightline-utils/constants';
import { yup } from 'straightline-utils/validation';

export default (app) => {
  return {
    schema: yup.object({
      account_token: yup.string().trim().label('Stripe Account Token'),
      external_account: yup.string().trim().label('External Account Token'),
      plaid_account_id: yup
        .string()
        .trim()
        .label('Plaid Account ID')
        .when('external_account', (external_account, schema) => {
          return external_account && !external_account.startsWith('btok_')
            ? schema.required()
            : schema.strip();
        })
    }),
    company: yup.object({
      name: yup.string().trim().label('Legal Business Name').required(),
      phone: yup.string().trim().label('Phone').required(),
      tax_id: yup
        .string()
        .trim()
        .numbersOnly()
        .min(9)
        .max(9)
        .label('Tax ID (EIN)')
        .required(),
      address: yup.object({
        city: yup.string().trim().label('City').required(),
        line1: yup.string().trim().label('Address 1').required(),
        line2: yup.string().label('Address 2'),
        postal_code: yup
          .string()
          .trim()
          .numbersOnly()
          .min(5)
          .max(5)
          .label('Postal Code')
          .required(),
        state: yup
          .string()
          .trim()
          .min(2)
          .max(2)
          .oneOf(
            STATES,
            'State must be a valid two character state abbreviation'
          )
          .label('State')
          .required(),
        country: yup.string().trim().label('Country').default('US').required()
      })
    }),
    bankAccount: yup.object({
      routing_number: yup
        .string()
        .trim()
        .numbersOnly()
        .min(9)
        .max(9)
        .label('Routing Number')
        .required(),
      account_number: yup
        .string()
        .trim()
        .numbersOnly()
        .min(4)
        .max(17)
        .label('Account Number')
        .required(),
      account_holder_name: yup
        .string()
        .trim()
        .label('Account Holder Name')
        .required()
    })
  };
};
