import React, { useMemo, useRef, useState } from 'react';
import { useIsFetching } from 'react-query';
import { Chart } from 'src/components/Charts';
import { DateRange, rangeConfig } from 'src/components/DateRange';
import { StateMap, ZipMap } from 'src/components/SvgMap';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Progress,
  Row,
  TabContent,
  TabPane,
  UncontrolledDropdown,
  useApp,
  useScreenSize,
  useUrlState
} from 'straightline-ui';
import {
  formatMoney,
  roundNumberToLabel,
  toDollars
} from 'straightline-utils/accounting';
import { TRUCK_TYPE } from 'straightline-utils/constants';
import { differenceInDays, formatDate } from 'straightline-utils/time';

const truckColors = {
  [TRUCK_TYPE.DRY_VAN]: '#2a1bdd',
  [TRUCK_TYPE.FLATBED]: '#1b6ddd',
  [TRUCK_TYPE.BOX]: '#1bcedd'
};

function formatNumber(value, options) {
  return new Intl.NumberFormat(undefined, {
    maximumFractionDigits: 2,
    ...options
  }).format(value);
}

function formatInterval(date, interval) {
  if (!interval || interval === 'month') {
    return formatDate(date, 'MMM yy');
  }
  if (interval === 'week') {
    return formatDate(date, 'MMM dd');
  }
  if (interval === 'day') {
    return formatDate(date, 'MMM dd');
  }
}

function stringToColor(str) {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (let i = 0; i < 3; i++) {
    var value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).slice(-2);
  }
  return colour;
}

function ReportHeader() {
  const app = useApp();
  const { isXs, isSm, md } = useScreenSize();
  const { getInState } = useUrlState();
  const query = getInState('query', {});
  const interval = getInterval(query.$period);

  const LabelTag = isXs || isSm ? 'h2' : 'h1';
  const labelStyle = {
    minWidth: md ? '80px' : '',
    height: isXs || isSm ? '25.5px' : '33px'
  };

  const { result: stats } = app.service('api/shipment-stats').useFind(
    {
      query: {
        ...query,
        $interval: interval
      }
    },
    { keepPreviousData: true }
  );

  const totals = stats?.total;

  const chartOptions = {
    scales: {
      y: {
        display: true,
        ticks: {
          // callback: axisLabel
        },
        grid: {
          // borderDash: [25],
          color: '#283E59'
        }
      },
      x: {
        display: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (item) => {
            return `${formatNumber(item.raw)} Loads`;
          }
        }
      }
    }
  };

  let chartData = {
    labels: [],
    datasets: []
  };

  const dataset = {
    label: '',
    data: []
  };

  if (stats?.data) {
    stats.data.forEach((stat) => {
      chartData.labels.push(formatInterval(stat.key, interval));
      dataset.data.push(stat.shipments);
    });
    chartData.datasets.push(dataset);
  }

  return (
    <div className="header pt-md-2 bg-dark pb-6">
      <Container fluid>
        <div className="header-body">
          <Row className="mb-n3 justify-content-between">
            <Col xs="auto" className="mb-3">
              <h6 className="header-pretitle">Overview</h6>
              <LabelTag className="header-title text-white">Reports</LabelTag>
            </Col>
            <Col className="col-auto mb-3">
              <Row>
                <Col xs="auto" className="text-center pr-1 px-md-3">
                  <h6 className="header-pretitle">Loads</h6>
                  <LabelTag className="text-white" style={labelStyle}>
                    {totals && (
                      <span className="animated fadeIn">
                        {roundNumberToLabel(totals.shipments)}
                      </span>
                    )}
                  </LabelTag>
                </Col>
                <Col xs="auto" className="text-center pl-1 pr-1 px-md-3">
                  <h6 className="header-pretitle">Miles</h6>
                  <LabelTag className="text-white" style={labelStyle}>
                    {totals && (
                      <span className="animated fadeIn">
                        {roundNumberToLabel(totals.miles.sum)}
                      </span>
                    )}
                  </LabelTag>
                </Col>
                <Col xs="auto" className="text-center pl-1 px-md-3">
                  <h6 className="header-pretitle">Payout</h6>
                  <LabelTag className="text-white" style={labelStyle}>
                    {totals && (
                      <span className="animated fadeIn">
                        {`$${roundNumberToLabel(toDollars(totals.price.sum))}`}
                      </span>
                    )}
                  </LabelTag>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="header-footer">
          <div className="chart">
            <Chart options={chartOptions} data={chartData} type="line" />
          </div>
        </div>
      </Container>
    </div>
  );
}

const btnLabel = (value, metricType) => {
  switch (metricType) {
    case 'miles':
      return `${formatNumber(value)} mi`;
    case 'load_weight':
      return `${formatNumber(value)} lbs`;
    case 'price_miles':
      return `${formatMoney(value)}/mi`;
    case 'price_load_weight':
      return `${formatMoney(value)}/lb`;
    default:
      return formatMoney(value);
  }
};

function ShipmentsCard({ stats, interval, ...rest }) {
  const [graphType, setGraphType] = useState('bar');
  const [metricType, setMetricType] = useState('price');
  const [metric, setMetric] = useState('sum');
  const [groupTrucks, setGroupTrucks] = useState(true);
  const switchId = useMemo(() => `${new Date().getTime() + Math.random()}`, []);
  const nonSums = ['price_miles', 'price_load_weight'];

  const tooltipLabel = (item) => {
    const value = item.raw;
    const pre = item.dataset.label ? `${item.dataset.label}: ` : '';
    switch (metricType) {
      case 'miles':
        return `${pre}${formatNumber(value)} mi`;
      case 'load_weight':
        return `${pre}${formatNumber(value)} lbs`;
      case 'price_miles':
        return `${pre}${formatMoney(value)}/mi`;
      case 'price_load_weight':
        return `${pre}${formatMoney(value)}/lb`;
      default:
        return `${pre}${formatMoney(value)}`;
    }
  };

  const label = (value) => {
    if (metricType === 'miles' || metricType === 'load_weight') {
      return formatNumber(value);
    }
    return formatMoney(value);
  };

  const metricLabel = () => {
    if (metricType === 'miles') {
      switch (metric) {
        case 'sum':
          return `The total distance of all loads`;
        case 'mean':
          return `The average load distance`;
        case 'median':
          return `The middle load distance`;
        case 'min':
          return `The shortest load distance`;
        case 'max':
          return `The longest load distance`;
        default:
          break;
      }
    }
    if (metricType === 'load_weight') {
      switch (metric) {
        case 'sum':
          return `The total weight of all loads`;
        case 'mean':
          return `The average load weight`;
        case 'median':
          return `The middle load weight`;
        case 'min':
          return `The lightest load weight`;
        case 'max':
          return `The heaviest load weight`;
        default:
          break;
      }
    }

    switch (metric) {
      case 'sum':
        return `The total price of all loads`;
      case 'mean':
        return `The average load cost`;
      case 'median':
        return `The middle load cost`;
      case 'min':
        return `The cheapest load`;
      case 'max':
        return `The most profitable load`;
      default:
        return '';
    }
  };

  const chartOptions = {
    scales: {
      y: {
        display: true,
        ticks: {
          callback: label
        }
      },
      x: {
        display: true
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipLabel
        }
      }
    }
  };

  let chartData = {
    labels: [],
    datasets: []
  };

  if (stats) {
    if (!groupTrucks) {
      const dataset = {
        label: '',
        data: []
      };
      stats.data.forEach((stat) => {
        chartData.labels.push(formatInterval(stat.key, interval));
        dataset.data.push(stat[metricType][metric]);
      });
      chartData.datasets.push(dataset);
    } else {
      const dataset1 = {
        label: 'Dry Van',
        backgroundColor: truckColors[TRUCK_TYPE.DRY_VAN],
        borderColor: truckColors[TRUCK_TYPE.DRY_VAN],
        data: [],
        stack: 0
      };
      const dataset2 = {
        label: 'Flatbed',
        backgroundColor: truckColors[TRUCK_TYPE.FLATBED],
        borderColor: truckColors[TRUCK_TYPE.FLATBED],
        data: [],
        stack: 0
      };
      const dataset3 = {
        label: 'Box Truck',
        backgroundColor: truckColors[TRUCK_TYPE.BOX],
        borderColor: truckColors[TRUCK_TYPE.BOX],
        data: [],
        stack: 0
      };
      stats.data.forEach((stat) => {
        chartData.labels.push(formatInterval(stat.key, interval));
        dataset1.data.push(
          stat.truck_types[TRUCK_TYPE.DRY_VAN][metricType][metric]
        );
        dataset2.data.push(
          stat.truck_types[TRUCK_TYPE.FLATBED][metricType][metric]
        );
        dataset3.data.push(
          stat.truck_types[TRUCK_TYPE.BOX][metricType][metric]
        );
      });
      chartData.datasets.push(dataset1, dataset2, dataset3);
    }
  }

  return (
    <Card>
      <div className="rounded-top px-4 py-3" style={{ minHeight: 54 }}>
        <Row
          noGutters
          className="mb-n2 align-items-center justify-content-between"
        >
          <Col xs="auto" className="mb-2 px-0">
            <h3 className="card-header-title">Loads</h3>
          </Col>
          <Col xs="auto" className="mb-2 px-0">
            <Row className="mb-n2 align-items-center">
              <Col className="mb-2">
                <ButtonGroup>
                  <UncontrolledDropdown>
                    <DropdownToggle
                      caret
                      color="light"
                      size="sm"
                      style={{ width: 130 }}
                    >
                      {
                        Object.entries(metricTypes).find(([key, value]) => {
                          return metricType === key;
                        })[1].short
                      }
                    </DropdownToggle>
                    <DropdownMenu right>
                      {Object.entries(metricTypes).map(([key, value]) => {
                        return (
                          <DropdownItem
                            key={key}
                            active={metricType === key}
                            onClick={() => {
                              if (nonSums.includes(key) && metric === 'sum') {
                                setMetric('mean');
                              }
                              setMetricType(key);
                            }}
                          >
                            {value.long}
                          </DropdownItem>
                        );
                      })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setGraphType('bar')}
                    active={graphType === 'bar'}
                  >
                    <Icon type="bar-chart" className="mr-2" />
                    Bar
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setGraphType('line')}
                    active={graphType === 'line'}
                  >
                    <Icon type="trending-up" className="mr-2" />
                    Line
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="mb-2">
                <CustomInput
                  type="switch"
                  id={switchId}
                  value={groupTrucks}
                  checked={groupTrucks}
                  label="Truck Type"
                  onChange={(event) => setGroupTrucks(event.target.checked)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="border-top border-bottom px-4">
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Row>
              {!nonSums.includes(metricType) && (
                <Col xs="auto" className="p-2">
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setMetric('sum')}
                    active={metric === 'sum'}
                  >
                    <span className="text-secondary">SUM:</span>{' '}
                    {stats && btnLabel(stats.total[metricType].sum, metricType)}
                  </Button>
                </Col>
              )}
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('mean')}
                  active={metric === 'mean'}
                >
                  <span className="text-secondary">MEAN:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].mean, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('median')}
                  active={metric === 'median'}
                >
                  <span className="text-secondary">MEDIAN:</span>{' '}
                  {stats &&
                    btnLabel(stats.total[metricType].median, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('min')}
                  active={metric === 'min'}
                >
                  <span className="text-secondary">MIN:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].min, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('max')}
                  active={metric === 'max'}
                >
                  <span className="text-secondary">MAX:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].max, metricType)}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <div
            // className="text-secondary text-xl-right"
            // style={{ width: 245 }}
            >
              {metricLabel()}
            </div>
          </Col>
        </Row>
      </div>
      <CardBody>
        <div className="chart">
          <Chart
            options={chartOptions}
            data={chartData}
            type={graphType}
            {...rest}
          />
        </div>
      </CardBody>
    </Card>
  );
}

const getInterval = (period) => {
  if (!period) {
    return 'month';
  }
  const diff = differenceInDays(period[1], period[0]) + 1;
  if (diff <= 31) {
    return 'day';
  }
  if (diff <= 365) {
    return 'month';
  }
  return 'year';
};

function SearchCard() {
  const rangeWrapper = useRef(null);
  const { getInState, opInState, setInState } = useUrlState();
  const isFetching = useIsFetching(['carrier-api/shipment-stats']) > 0;
  const query = getInState('query', {});
  const tab = getInState('tab', 'shipments');
  const truck_types = getInState('query.truck_type.$in', []);
  const { md } = useScreenSize();
  const ranges = useMemo(
    () => rangeConfig(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formatDate(new Date(), 'dateOnly')]
  );

  const handleDateChange = ({ target: { value } }) => {
    opInState((newState) => {
      if (!value || !value.length) {
        newState.del('query.$period');
      }
      newState.set('query.$period', [
        formatDate(value[0], 'dateOnly'),
        formatDate(value[1], 'dateOnly')
      ]);

      return newState;
    });
  };

  const handleTruckChange = (truck_type) => {
    opInState((newState) => {
      if (truck_types.includes(truck_type)) {
        const newTrucks = truck_types.filter((m) => m !== truck_type);
        if (!newTrucks.length) {
          return newState.del('query.truck_type.$in');
        }
        return newState.set('query.truck_type.$in', newTrucks);
      }
      const newTrucks = [...truck_types, truck_type];
      return newState.set('query.truck_type.$in', newTrucks);
    });
  };

  return (
    <Card>
      <CardBody innerRef={rangeWrapper}>
        <Row className="mb-n3">
          <Col md="auto" className="mb-3">
            <div style={{ minWidth: md ? 331 : '' }}>
              <DateRange
                name="pickup_date"
                value={query.$period}
                ranges={ranges}
                onChange={handleDateChange}
                A
                wrapper={rangeWrapper}
              />
            </div>
          </Col>
          <Col md="auto" className="mb-3">
            <ButtonGroup className="w-100">
              <Button
                color="light"
                className="px-5"
                active={tab === 'shipments'}
                onClick={() => {
                  setInState('tab', 'shipments');
                }}
              >
                <Icon type="package" className="mr-2" />
                Loads
              </Button>
              <Button
                color="light"
                className="px-5"
                active={tab === 'lanes'}
                onClick={() => {
                  setInState('tab', 'lanes');
                }}
              >
                <Icon type="map" className="mr-2" />
                Lanes
              </Button>
            </ButtonGroup>
          </Col>
          <Col md="auto" className="mb-3">
            <ButtonGroup className="w-100">
              <Button
                color="light"
                active={truck_types.includes(TRUCK_TYPE.DRY_VAN)}
                onClick={() => {
                  handleTruckChange(TRUCK_TYPE.DRY_VAN);
                }}
              >
                Dry Van
              </Button>
              <Button
                color="light"
                active={truck_types.includes(TRUCK_TYPE.FLATBED)}
                onClick={() => {
                  handleTruckChange(TRUCK_TYPE.FLATBED);
                }}
              >
                Flatbed
              </Button>
              <Button
                color="light"
                active={truck_types.includes(TRUCK_TYPE.BOX)}
                onClick={() => {
                  handleTruckChange(TRUCK_TYPE.BOX);
                }}
              >
                Box Truck
              </Button>
            </ButtonGroup>
          </Col>

          {isFetching && md && (
            <Col
              xs="auto"
              className="mb-3 d-flex align-items-center ml-auto animated fadeIn"
            >
              <div
                className="spinner-border text-secondary"
                role="status"
              ></div>
            </Col>
          )}
        </Row>
        {isFetching && !md && (
          <div
            className="animated fadeIn"
            style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
          >
            <Progress animated value={100} style={{ height: 8 }} />
          </div>
        )}
      </CardBody>
    </Card>
  );
}

export default function Reports() {
  const { getInState } = useUrlState();
  const tab = getInState('tab', 'shipments');

  return (
    <div>
      <ReportHeader />
      <Container fluid className="pb-5">
        <div className="mt-n6">
          <SearchCard />
        </div>
        <TabContent activeTab={tab}>
          <TabPane tabId="shipments">
            <Shipments />
          </TabPane>
          <TabPane tabId="lanes">
            <Lanes />
          </TabPane>
        </TabContent>
      </Container>
    </div>
  );
}

function Shipments() {
  const app = useApp();
  const { getInState } = useUrlState();
  const query = getInState('query', {});
  const group = ['truck_types'];
  const interval = getInterval(query.$period);

  const { result: stats } = app.service('api/shipment-stats').useFind(
    {
      query: {
        ...query,
        $interval: interval,
        $group: group
      }
    },
    { keepPreviousData: true }
  );

  return (
    <div>
      {stats?.total.shipments === 0 && (
        <Alert color="info">
          There are no shipments matching your query. Please adjust the date
          range or query above.
        </Alert>
      )}
      <ShipmentsCard interval={interval} stats={stats} />
      <Row>
        <Col lg="3" md="6">
          <Card>
            <CardBody>
              <div className="small text-uppercase text-muted mb-2">
                Total Loads
              </div>
              <Row noGutters className="align-items-center">
                <Col>
                  <div className="mb-0 h2">
                    {stats && stats.total.shipments}
                  </div>
                </Col>
                <Col xs="auto">
                  <Icon type="package" className="mb-0 h1 text-muted" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6">
          <Card>
            <CardBody>
              <div className="small text-uppercase text-muted mb-2">
                Total Payout
              </div>
              <Row noGutters className="align-items-center">
                <Col>
                  <div className="mb-0 h2">
                    {stats && formatMoney(stats.total.price.sum)}
                  </div>
                </Col>
                <Col xs="auto">
                  <Icon type="dollar-sign" className="mb-0 h1 text-muted" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6">
          <Card>
            <CardBody>
              <div className="small text-uppercase text-muted mb-2">
                Total Miles
              </div>
              <Row noGutters className="align-items-center">
                <Col>
                  <div className="mb-0 h2">
                    {stats && formatNumber(stats.total.miles.sum)} mi
                  </div>
                </Col>
                <Col xs="auto">
                  <Icon type="map" className="mb-0 h1 text-muted" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col lg="3" md="6">
          <Card>
            <CardBody>
              <div className="small text-uppercase text-muted mb-2">
                Total Weight
              </div>
              <Row noGutters className="align-items-center">
                <Col>
                  <div className="mb-0 h2">
                    {stats && formatNumber(stats.total.load_weight.sum)} lbs
                  </div>
                </Col>
                <Col xs="auto">
                  <Icon type="anchor" className="mb-0 h1 text-muted" />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col lg="5">
          <TrucksCard stats={stats} interval={interval} />
        </Col>
        <Col lg="7">
          <ShipmentsDetailsCard stats={stats} interval={interval} />
        </Col>
      </Row>
    </div>
  );
}

function ShipmentsDetailsCard({ stats, interval }) {
  const { sm } = useScreenSize();
  const entries = stats?.data;

  const mostExpensive = () => {
    if (!stats) {
      return null;
    }

    const found = {
      value: 0,
      entryKey: '',
      periodStats: {}
    };

    entries.forEach((entry) => {
      if (entry.price.sum >= found.value) {
        found.value = entry.price.sum;
        found.entryKey = entry.key;
        found.periodStats = entry;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {formatInterval(found.entryKey, interval)}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.periodStats.shipments)}
              {sm
                ? found.periodStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Most profitable period</div>
          <div className="text-secondary">
            {formatNumber((100 * found.value) / stats.total.price.sum)}% of
            total
          </div>
        </div>
      </div>
    );
  };

  const highestAverageCost = () => {
    if (!stats) {
      return null;
    }

    const found = {
      value: 0,
      entryKey: '',
      periodStats: {}
    };

    entries.forEach((entry) => {
      if (entry.price_miles.mean >= found.value) {
        found.value = entry.price_miles.mean;
        found.entryKey = entry.key;
        found.periodStats = entry;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {formatInterval(found.entryKey, interval)}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.periodStats.shipments)}
              {sm
                ? found.periodStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}/mile
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Highest average cost per mile</div>
          <div className="text-secondary">
            {formatMoney(found.periodStats.price_load_weight.mean)}/pound
          </div>
        </div>
      </div>
    );
  };

  const lowestAverageCost = () => {
    if (!stats) {
      return null;
    }

    let found = {
      value: 0,
      entryKey: '',
      periodStats: {}
    };

    entries.forEach((entry) => {
      if (!found.value || entry.price_miles.mean <= found.value) {
        found.value = entry.price_miles.mean;
        found.entryKey = entry.key;
        found.periodStats = entry;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {formatInterval(found.entryKey, interval)}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.periodStats.shipments)}
              {sm
                ? found.periodStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}/mile
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Lowest average cost per mile</div>
          <div className="text-secondary">
            {formatMoney(found.periodStats.price_load_weight.mean)}/pound
          </div>
        </div>
      </div>
    );
  };

  const mostMiles = () => {
    if (!stats) {
      return null;
    }

    const found = {
      value: 0,
      entryKey: '',
      periodStats: {}
    };

    entries.forEach((entry) => {
      if (entry.miles.sum >= found.value) {
        found.value = entry.miles.sum;
        found.entryKey = entry.key;
        found.periodStats = entry;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {formatInterval(found.entryKey, interval)}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.periodStats.shipments)}
              {sm
                ? found.periodStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatNumber(found.value)} miles
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Most miles travelled</div>
          <div className="text-secondary">
            {formatNumber(found.periodStats.load_weight.sum)} pounds
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card className="card-fill">
      <div
        className="rounded-top border-bottom px-4 py-3"
        style={{ minHeight: 54 }}
      >
        <Row
          noGutters
          className="mb-n2 align-items-center justify-content-between h-100"
        >
          <Col xs="auto" className="mb-2 px-0 h-100 d-flex align-items-center">
            <h3 className="card-header-title">Details</h3>
          </Col>
        </Row>
      </div>
      {stats?.total.shipments > 0 && (
        <React.Fragment>
          <div className="border-bottom p-4">{mostExpensive()}</div>
          <div className="border-bottom p-4">{highestAverageCost()}</div>
          <div className="border-bottom p-4">{lowestAverageCost()}</div>
          <div className="border-bottom p-4">{mostMiles()}</div>
        </React.Fragment>
      )}
    </Card>
  );
}

function TrucksCard({ stats }) {
  const [metricType, setMetricType] = useState('price');
  const [metric, setMetric] = useState('sum');
  const nonSums = ['price_miles', 'price_load_weight'];

  const tooltipLabel = (item) => {
    const value = item.raw;
    const pre = ` ${item.label}: `;
    switch (metricType) {
      case 'miles':
        return `${pre}${formatNumber(value)} mi`;
      case 'load_weight':
        return `${pre}${formatNumber(value)} lbs`;
      case 'price_miles':
        return `${pre}${formatMoney(value)}/mi`;
      case 'price_load_weight':
        return `${pre}${formatMoney(value)}/lb`;
      default:
        return `${pre}${formatMoney(value)}`;
    }
  };

  const chartOptions = {
    scales: {
      y: {
        display: false,
        ticks: {
          // callback: label
        }
      },
      x: {
        display: false
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: tooltipLabel
        }
      }
    }
  };

  const chartData = {
    labels: ['Dry Van', 'Flatbed', 'Box Truck'],
    datasets: [
      {
        label: 'Mode Totals',
        data: [
          stats?.total.truck_types[TRUCK_TYPE.DRY_VAN][metricType][metric],
          stats?.total.truck_types[TRUCK_TYPE.FLATBED][metricType][metric],
          stats?.total.truck_types[TRUCK_TYPE.BOX][metricType][metric]
        ],
        backgroundColor: [
          truckColors[TRUCK_TYPE.DRY_VAN],
          truckColors[TRUCK_TYPE.FLATBED],
          truckColors[TRUCK_TYPE.BOX]
        ]
      }
    ]
  };

  return (
    <Card className="card-fill">
      <div className="rounded-top px-4 py-3" style={{ minHeight: 54 }}>
        <Row
          noGutters
          className="mb-n2 align-items-center justify-content-between"
        >
          <Col xs="auto" className="mb-2 px-0">
            <h3 className="card-header-title">Truck Types</h3>
          </Col>
          <Col xs="auto" className="mb-2 px-0">
            <Row className="mb-n2 align-items-center">
              <Col className="mb-2">
                <UncontrolledDropdown>
                  <DropdownToggle
                    caret
                    color="light"
                    size="sm"
                    style={{ width: 130 }}
                  >
                    {
                      Object.entries(metricTypes).find(([key, value]) => {
                        return metricType === key;
                      })[1].short
                    }
                  </DropdownToggle>
                  <DropdownMenu right>
                    {Object.entries(metricTypes).map(([key, value]) => {
                      return (
                        <DropdownItem
                          key={key}
                          active={metricType === key}
                          onClick={() => {
                            if (nonSums.includes(key) && metric === 'sum') {
                              setMetric('mean');
                            }
                            setMetricType(key);
                          }}
                        >
                          {value.long}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="border-top border-bottom px-4">
        <Row className="justify-content-between align-items-center">
          <Col xs="auto">
            <Row>
              {!nonSums.includes(metricType) && (
                <Col xs="auto" className="p-2">
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setMetric('sum')}
                    active={metric === 'sum'}
                  >
                    <span className="text-secondary">SUM:</span>{' '}
                    {stats && btnLabel(stats.total[metricType].sum, metricType)}
                  </Button>
                </Col>
              )}
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('mean')}
                  active={metric === 'mean'}
                >
                  <span className="text-secondary">MEAN:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].mean, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('median')}
                  active={metric === 'median'}
                >
                  <span className="text-secondary">MEDIAN:</span>{' '}
                  {stats &&
                    btnLabel(stats.total[metricType].median, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('min')}
                  active={metric === 'min'}
                >
                  <span className="text-secondary">MIN:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].min, metricType)}
                </Button>
              </Col>
              <Col xs="auto" className="p-2">
                <Button
                  size="sm"
                  color="light"
                  onClick={() => setMetric('max')}
                  active={metric === 'max'}
                >
                  <span className="text-secondary">MAX:</span>{' '}
                  {stats && btnLabel(stats.total[metricType].max, metricType)}
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs="auto">
            <div
            // className="text-secondary text-xl-right"
            // style={{ width: 245 }}
            >
              {/* {metricLabel()} */}
            </div>
          </Col>
        </Row>
      </div>
      <CardBody>
        <div className="chart">
          <Chart
            options={chartOptions}
            data={chartData}
            type="doughnut"
            // {...rest}
          />
        </div>
      </CardBody>
    </Card>
  );
}

const metricTypes = {
  price: {
    long: 'Price',
    short: 'Price'
  },
  price_miles: {
    long: 'Dollars per mile',
    short: 'Dollars/mile'
  },
  price_load_weight: {
    long: 'Dollars per pound',
    short: 'Dollars/pound'
  },
  miles: {
    long: 'Miles',
    short: 'Miles'
  },
  load_weight: {
    long: 'Weight',
    short: 'Weight'
  }
};

function Lanes() {
  const app = useApp();
  const { getInState } = useUrlState();
  const query = getInState('query', {});
  const group = ['states', 'zips', 'truck_types'];

  const { result: stats } = app.service('api/shipment-stats').useFind(
    {
      query: {
        $group: group,
        ...query
      }
    },
    { keepPreviousData: true }
  );

  return (
    <div>
      {stats?.total.shipments === 0 && (
        <Alert color="info">
          There are no loads matching your query. Please adjust the date range
          or query above.
        </Alert>
      )}
      <MapCard stats={stats} />
      <Row>
        <Col lg="5">
          <MapTotalsCard stats={stats} />
        </Col>
        <Col lg="7">
          <MapLanesCard stats={stats} />
        </Col>
      </Row>
    </div>
  );
}

function MapLanesCard({ stats }) {
  const [direction, setDirection] = useState('pickup');
  const [regionType, setRegionType] = useState('states');
  const switchId = useMemo(() => `${new Date().getTime() + Math.random()}`, []);
  const { sm } = useScreenSize();

  const mostExpensive = () => {
    if (!stats) {
      return null;
    }

    const entries =
      regionType === 'states'
        ? stats.total.states[direction]
        : stats.total.zips[direction].triple;

    const found = {
      value: 0,
      entryKey: '',
      laneKey: '',
      laneStats: {}
    };

    Object.entries(entries).forEach(([entryKey, entry]) => {
      const laneEntries = regionType === 'states' ? entry.states : entry.zips;
      const lanes = Object.entries(laneEntries).sort((a, b) => {
        return a[1].price.sum > b[1].price.sum;
      });
      const [laneKey, laneStats] = lanes[0];
      if (laneStats.price.sum >= found.value) {
        found.value = laneStats.price.sum;
        found.laneKey = laneKey;
        found.entryKey = entryKey;
        found.laneStats = laneStats;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {found.entryKey} to {found.laneKey}{' '}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.laneStats.shipments)}
              {sm
                ? found.laneStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Most profitable lane</div>
          <div className="text-secondary">
            {formatNumber((100 * found.value) / stats.total.price.sum)}% of
            total
          </div>
        </div>
      </div>
    );
  };

  const highestAverageCost = () => {
    if (!stats) {
      return null;
    }

    const entries =
      regionType === 'states'
        ? stats.total.states[direction]
        : stats.total.zips[direction].triple;

    const found = {
      value: 0,
      entryKey: '',
      laneKey: '',
      laneStats: {}
    };

    Object.entries(entries).forEach(([entryKey, entry]) => {
      const laneEntries = regionType === 'states' ? entry.states : entry.zips;
      const lanes = Object.entries(laneEntries).sort((a, b) => {
        return a[1].price_miles.mean > b[1].price_miles.mean;
      });
      const [laneKey, laneStats] = lanes[0];
      if (laneStats.price_miles.mean >= found.value) {
        found.value = laneStats.price_miles.mean;
        found.laneKey = laneKey;
        found.entryKey = entryKey;
        found.laneStats = laneStats;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {found.entryKey} to {found.laneKey}{' '}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.laneStats.shipments)}
              {sm
                ? found.laneStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}/mile
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Highest average cost per mile</div>
          <div className="text-secondary">
            {formatMoney(found.laneStats.price_load_weight.mean)}/pound
          </div>
        </div>
      </div>
    );
  };

  const lowestAverageCost = () => {
    if (!stats) {
      return null;
    }

    const entries =
      regionType === 'states'
        ? stats.total.states[direction]
        : stats.total.zips[direction].triple;

    let found = {
      value: 0,
      entryKey: '',
      laneKey: '',
      laneStats: {}
    };

    Object.entries(entries).forEach(([entryKey, entry]) => {
      const laneEntries = regionType === 'states' ? entry.states : entry.zips;
      const lanes = Object.entries(laneEntries).sort((a, b) => {
        return a[1].price_miles.mean < b[1].price_miles.mean;
      });
      const [laneKey, laneStats] = lanes[0];
      if (!found.value || laneStats.price.mean <= found.value) {
        found.value = laneStats.price_miles.mean;
        found.laneKey = laneKey;
        found.entryKey = entryKey;
        found.laneStats = laneStats;
      }
    });
    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {found.entryKey} to {found.laneKey}{' '}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.laneStats.shipments)}
              {sm
                ? found.laneStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatMoney(found.value)}/mile
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Lowest average cost per mile</div>
          <div className="text-secondary">
            {formatMoney(found.laneStats.price_load_weight.mean)}/pound
          </div>
        </div>
      </div>
    );
  };

  const mostMiles = () => {
    if (!stats) {
      return null;
    }

    const entries =
      regionType === 'states'
        ? stats.total.states[direction]
        : stats.total.zips[direction].triple;

    const found = {
      value: 0,
      entryKey: '',
      laneKey: '',
      laneStats: {}
    };

    Object.entries(entries).forEach(([entryKey, entry]) => {
      const laneEntries = regionType === 'states' ? entry.states : entry.zips;
      const lanes = Object.entries(laneEntries).sort((a, b) => {
        return a[1].miles.sum > b[1].miles.sum;
      });
      const [laneKey, laneStats] = lanes[0];
      if (laneStats.miles.sum >= found.value) {
        found.value = laneStats.miles.sum;
        found.laneKey = laneKey;
        found.entryKey = entryKey;
        found.laneStats = laneStats;
      }
    });

    return (
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="lead mb-0">
              {found.entryKey} to {found.laneKey}{' '}
            </span>
            <span className="badge badge-soft-secondary ml-3">
              {formatNumber(found.laneStats.shipments)}
              {sm
                ? found.laneStats.shipments === 1
                  ? ' Load'
                  : ' Loads'
                : null}
            </span>
          </div>
          <div className="lead mb-0 text-primary">
            {formatNumber(found.value)} miles
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div className="text-secondary">Most miles travelled</div>
          <div className="text-secondary">
            {formatNumber(found.laneStats.load_weight.sum)} pounds
          </div>
        </div>
      </div>
    );
  };

  return (
    <Card className="card-fill">
      <div
        className="rounded-top border-bottom px-4 py-3"
        style={{ minHeight: 54 }}
      >
        <Row
          noGutters
          className="mb-n2 align-items-center justify-content-between"
        >
          <Col xs="auto" className="mb-2 px-0">
            <h3 className="card-header-title">Lanes</h3>
          </Col>
          <Col xs="auto" className="mb-2 px-0">
            <Row className="mb-n2 align-items-center">
              <Col className="mb-2">
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setDirection('pickup')}
                    active={direction === 'pickup'}
                  >
                    <Icon type="arrow-up" className="mr-2" />
                    Pickup
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setDirection('delivery')}
                    active={direction === 'delivery'}
                  >
                    <Icon type="arrow-down" className="mr-2" />
                    Delivery
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="mb-2">
                <CustomInput
                  type="switch"
                  id={switchId}
                  value={regionType === 'triple'}
                  checked={regionType === 'triple'}
                  label="Zips"
                  onChange={(event) => {
                    if (regionType === 'triple') {
                      return setRegionType('states');
                    }
                    return setRegionType('triple');
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {stats?.total.shipments > 0 && (
        <React.Fragment>
          <div className="border-bottom p-4">{mostExpensive()}</div>
          <div className="border-bottom p-4">{highestAverageCost()}</div>
          <div className="border-bottom p-4">{lowestAverageCost()}</div>
          <div className="border-bottom p-4">{mostMiles()}</div>
        </React.Fragment>
      )}
    </Card>
  );
}

function MapTotalsCard({ stats }) {
  const [direction, setDirection] = useState('pickup');
  const [regionType, setRegionType] = useState('states');
  const switchId = useMemo(() => `${new Date().getTime() + Math.random()}`, []);

  const chartOptions = {
    scales: {
      y: {
        display: false
      },
      x: {
        display: false
      }
    },
    plugins: {
      // legend: {
      //   display: true
      // },
      tooltip: {
        callbacks: {
          // label: tooltipLabel
        }
      }
    }
  };

  const chartData = {
    labels: [],
    datasets: []
  };

  if (stats) {
    const dataset = {
      data: [],
      backgroundColor: []
    };
    const entries =
      regionType === 'states'
        ? stats.total.states[direction]
        : stats.total.zips[direction].triple;
    Object.entries(entries).forEach(([key, stat]) => {
      chartData.labels.push(key);
      dataset.data.push(formatNumber(stat.shipments));
      dataset.backgroundColor.push(stringToColor(key + '000'));
    });
    chartData.datasets.push(dataset);
  }

  return (
    <Card className="card-fill">
      <div
        className="rounded-top border-bottom px-4 py-3"
        style={{ minHeight: 54 }}
      >
        <Row
          noGutters
          className="mb-n2 align-items-center justify-content-between"
        >
          <Col xs="auto" className="mb-2 px-0">
            <h3 className="card-header-title">Loads</h3>
          </Col>
          <Col xs="auto" className="mb-2 px-0">
            <Row className="mb-n2 align-items-center">
              <Col className="mb-2">
                <ButtonGroup>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setDirection('pickup')}
                    active={direction === 'pickup'}
                  >
                    <Icon type="arrow-up" className="mr-2" />
                    Pickup
                  </Button>
                  <Button
                    size="sm"
                    color="light"
                    onClick={() => setDirection('delivery')}
                    active={direction === 'delivery'}
                  >
                    <Icon type="arrow-down" className="mr-2" />
                    Delivery
                  </Button>
                </ButtonGroup>
              </Col>
              <Col className="mb-2">
                <CustomInput
                  type="switch"
                  id={switchId}
                  value={regionType === 'triple'}
                  checked={regionType === 'triple'}
                  label="Zips"
                  onChange={(event) => {
                    if (regionType === 'triple') {
                      return setRegionType('states');
                    }
                    return setRegionType('triple');
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <CardBody>
        <div style={{ height: 350 }}>
          <Chart
            options={chartOptions}
            data={chartData}
            type="doughnut"
            // {...rest}
          />
        </div>
      </CardBody>
    </Card>
  );
}

function MapCard({ stats }) {
  const [selectedState, setSelectedState] = useState(null);
  const [selectedZip, setSelectedZip] = useState(null);
  const [direction, setDirection] = useState('pickup');
  const [regionType, setRegionType] = useState('states');
  const [metricType, setMetricType] = useState('price');
  const containerRef = useRef(null);
  const switchId = useMemo(() => `${new Date().getTime() + Math.random()}`, []);

  const { screenWidth } = useScreenSize();
  const mapHeight = containerRef?.current?.offsetWidth
    ? containerRef?.current?.offsetWidth * 0.6
    : screenWidth * 0.47;

  let stateConfig = {};
  let zipConfig = {};

  const helpText = () => {
    const region = regionType === 'states' ? 'state' : 'zip';
    if (direction === 'pickup') {
      if (!selectedState && !selectedZip) {
        return (
          <div className="d-flex align-items-center">
            Click a {region} to view its deliveries.
          </div>
        );
      }
      return (
        <div className="d-flex align-items-center">
          Hover a {region} to view its totals.
        </div>
      );
    }
    if (!selectedState && !selectedZip) {
      return (
        <div className="d-flex align-items-center">
          Click a {region} to view its pickups.
        </div>
      );
    }
    return (
      <div className="d-flex align-items-center">
        Hover a {region} to view its totals.
      </div>
    );
  };

  const tooltipLabel = (value) => {
    switch (metricType) {
      case 'miles':
        return `${formatNumber(value)} mi`;
      case 'load_weight':
        return `${formatNumber(value)} lbs`;
      case 'price_miles':
        return `${formatMoney(value)}/mi`;
      case 'price_load_weight':
        return `${formatMoney(value)}/lb`;
      default:
        return `${formatMoney(value)}`;
    }
  };

  const tooltip = (value, key, selectedKey) => {
    const label = selectedKey
      ? direction === 'pickup'
        ? `${selectedKey} to ${key}`
        : `${key} to ${selectedKey}`
      : `${key} ${direction === 'pickup' ? 'Pickups' : 'Deliveries'}`;
    return `
    <div>
      <div class="">
        ${label}
      </div>
      <div class="border-bottom border-top py-1 mt-1 mb-2">
        ${formatNumber(value.shipments)} Load${value.shipments === 1 ? '' : 's'}
      </div>
      <div>
        <span class="text-secondary">SUM:</span> ${tooltipLabel(
          value[metricType].sum
        )}
      </div>
      <div>
        <span class="text-secondary">MEAN:</span>  ${tooltipLabel(
          value[metricType].mean
        )}
      </div>
      <div>
        <span class="text-secondary">MEDIAN:</span> ${tooltipLabel(
          value[metricType].median
        )}</div>
      <div>
        <span class="text-secondary">MIN:</span> ${tooltipLabel(
          value[metricType].min
        )}</div>
      <div>
        <span class="text-secondary">MAX:</span> ${tooltipLabel(
          value[metricType].max
        )}
      </div>
    </div>
    `;
  };

  const unsetSelectedState = () => setSelectedState(null);
  const unsetSelectedZip = () => setSelectedZip(null);

  if (selectedState) {
    stateConfig = Object.entries(selectedState.states).reduce(
      (accum, [key, value]) => {
        accum[key] = {
          tooltip: () => {
            return tooltip(value, key, selectedState.key);
          },
          onClick: unsetSelectedState,
          style: {
            fill: stringToColor(selectedState.key + '000'),
            cursor: 'pointer'
          }
        };
        return accum;
      },
      {}
    );
    if (stateConfig[selectedState.key]) {
      stateConfig[selectedState.key] = {
        ...stateConfig[selectedState.key],
        style: {
          ...stateConfig[selectedState.key]?.style,
          opacity: 0.7
        }
      };
    } else {
      stateConfig[selectedState.key] = {
        onClick: unsetSelectedState,
        style: {
          opacity: 0.7,
          fill: stringToColor(selectedState.key + '000'),
          cursor: 'pointer'
        }
      };
    }
  } else if (stats?.total.states) {
    stateConfig = Object.entries(stats.total.states[direction]).reduce(
      (accum, [key, value]) => {
        accum[key] = {
          tooltip: () => {
            return tooltip(value, key);
          },
          onClick: () => {
            setSelectedState({
              key,
              ...value
            });
          },
          style: {
            fill: stringToColor(key + '000'),
            cursor: 'pointer'
          }
        };
        return accum;
      },
      {}
    );
  }

  if (selectedZip) {
    zipConfig = Object.entries(selectedZip.zips).reduce(
      (accum, [key, value]) => {
        accum[key] = {
          tooltip: () => {
            return tooltip(value, key, selectedZip.key);
          },
          onClick: unsetSelectedZip,
          style: {
            fill: stringToColor(selectedZip.key + '000')
          }
        };
        return accum;
      },
      {}
    );
    if (zipConfig[selectedZip.key]) {
      zipConfig[selectedZip.key] = {
        ...zipConfig[selectedZip.key],
        style: {
          ...zipConfig[selectedZip.key]?.style,
          opacity: 0.7
        }
      };
    } else {
      zipConfig[selectedZip.key] = {
        onClick: unsetSelectedZip,
        style: {
          opacity: 0.7,
          fill: stringToColor(selectedZip.key + '000')
        }
      };
    }
  } else if (stats?.total.zips) {
    zipConfig = Object.entries(stats.total.zips.pickup.triple).reduce(
      (accum, [key, value]) => {
        accum[key] = {
          tooltip: () => {
            return tooltip(value, key);
          },
          onClick: () => {
            setSelectedZip({
              key,
              ...value
            });
          },
          style: {
            fill: stringToColor(key + '000')
          }
        };
        return accum;
      },
      {}
    );
  }

  return (
    <div>
      <Card>
        <div
          className="rounded-top border-bottom px-4 py-3"
          style={{ minHeight: 54 }}
        >
          <Row
            noGutters
            className="mb-n2 align-items-center justify-content-between"
          >
            <Col xs="auto" className="mb-2 px-0">
              <h3 className="card-header-title">Lanes</h3>
            </Col>
            <Col xs="auto" className="mb-2 px-0">
              <Row className="mb-n2 align-items-center">
                <Col className="mb-2">
                  <ButtonGroup>
                    <UncontrolledDropdown>
                      <DropdownToggle
                        caret
                        color="light"
                        size="sm"
                        style={{ width: 130 }}
                      >
                        {
                          Object.entries(metricTypes).find(([key, value]) => {
                            return metricType === key;
                          })[1].short
                        }
                      </DropdownToggle>
                      <DropdownMenu right>
                        {Object.entries(metricTypes).map(([key, value]) => {
                          return (
                            <DropdownItem
                              key={key}
                              active={metricType === key}
                              onClick={() => {
                                // if (nonSums.includes(key) && metric === 'sum') {
                                //   setMetric('mean');
                                // }
                                setMetricType(key);
                              }}
                            >
                              {value.long}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                    <Button
                      size="sm"
                      color="light"
                      onClick={() => {
                        setDirection('pickup');
                        setSelectedState(null);
                        setSelectedZip(null);
                      }}
                      active={direction === 'pickup'}
                    >
                      <Icon type="arrow-up" className="mr-2" />
                      Pickup
                    </Button>
                    <Button
                      size="sm"
                      color="light"
                      onClick={() => {
                        setDirection('delivery');
                        setSelectedState(null);
                        setSelectedZip(null);
                      }}
                      active={direction === 'delivery'}
                    >
                      <Icon type="arrow-down" className="mr-2" />
                      Delivery
                    </Button>
                  </ButtonGroup>
                </Col>
                <Col className="mb-2">
                  <CustomInput
                    type="switch"
                    id={switchId}
                    value={regionType === 'triple'}
                    checked={regionType === 'triple'}
                    label="Zips"
                    onChange={(event) => {
                      setSelectedState(null);
                      setSelectedZip(null);
                      if (regionType === 'triple') {
                        return setRegionType('states');
                      }
                      return setRegionType('triple');
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="border-bottom px-4 py-2">
          {helpText()}
          <Row className="align-items-center">
            <Col xs="auto"></Col>
            <Col xs="auto"></Col>
          </Row>
        </div>
        <CardBody innerRef={containerRef}>
          <div
            style={{
              height: mapHeight
              // overflow: 'hidden'
            }}
          >
            {regionType === 'states' ? (
              <StateMap states={stateConfig} />
            ) : (
              <ZipMap zips={zipConfig} />
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
}
