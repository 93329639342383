import React, { Component } from 'react';
import feathers from 'src/feathers';
import { Link } from 'straightline-ui';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Label,
  Row,
  Stripes
} from 'straightline-ui';

export default class PasswordForgot extends Component {
  constructor(props) {
    super(props);
    this.forgotService = feathers.service('auth/forgot-password');
    this.state = {
      error: null,
      success: null
    };
  }
  handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    this.forgotService
      .create(data)
      .then(({ message }) => {
        this.setState({ success: message, error: null });
      })
      .catch((error) => {
        formik.setSubmitting(false);
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        this.setState({ error, success: null });
      });
  };
  render() {
    const { success, error } = this.state;
    return (
      <div className="vh-100 d-flex align-items-center border-top-2 border-primary">
        <Stripes animation="fadeIn" />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="5">
              <Card className="py-4 p-sm-5">
                <CardBody>
                  <h1 className="display-4 text-center mb-3">
                    Forgot Password
                  </h1>
                  <p className="text-muted text-center mb-5">
                    Enter your email to get a password reset link.
                  </p>
                  {success ? (
                    <Alert color="success" className="animated bounceIn faster">
                      {success}
                    </Alert>
                  ) : (
                    <Formik
                      onSubmit={this.handleSubmit}
                      initialValues={{
                        email: this.props.location?.query?.email || ''
                      }}
                      validationSchema={this.forgotService.schema}
                      render={({ isSubmitting }) => {
                        return (
                          <Form>
                            {error && (
                              <Alert
                                color="warning"
                                className="animated bounceIn faster"
                              >
                                <strong>Error:&nbsp;</strong>&nbsp;
                                {error.message || error}
                              </Alert>
                            )}
                            <FormGroup>
                              <Label>Email*</Label>
                              <FormInput
                                type="email"
                                name="email"
                                placeholder="name@address.com"
                                disabled={isSubmitting}
                              />
                              <FormFeedback name="email" />
                            </FormGroup>
                            <Button
                              type="submit"
                              color="primary"
                              size="lg"
                              block
                              className={`mb-3 ${
                                isSubmitting ? 'is-loading' : ''
                              }`}
                              disabled={isSubmitting}
                            >
                              Send Link
                            </Button>
                          </Form>
                        );
                      }}
                    />
                  )}
                  <div className="text-center">
                    <p className="text-muted text-center">
                      Remember your password?&nbsp;
                      <Link to="/login">Log In</Link>
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
