import { STATUS } from 'straightline-utils/constants';

export const STATUS_COLORS = {
  [STATUS.BOOKED]: 'secondary',
  [STATUS.MATCHED]: 'info',
  [STATUS.DISPATCHED]: 'primary',
  [STATUS.AT_CONSIGNEE]: 'primary',
  [STATUS.IN_TRANSIT]: 'primary',
  [STATUS.AT_SHIPPER]: 'primary',
  [STATUS.DELIVERED]: 'success',
  [STATUS.CANCELLED]: 'danger'
};
