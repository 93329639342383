import {
  NOTIFICATION_SOURCES,
  NOTIFICATION_TYPES
} from 'straightline-utils/constants';
import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    first_name: yup.string().trim().label('First Name'),
    last_name: yup.string().trim().label('Last Name'),
    phone: yup.string().trim().phone().label('Phone'),
    notifications: yup
      .array()
      .label('Notifications')
      .required()
      .uniqueValues(null, (notif) => notif.type)
      .of(
        yup
          .object({
            source: yup
              .array()
              .of(
                yup
                  .string()
                  .trim()
                  .oneOf(NOTIFICATION_SOURCES.map((source) => source.value))
              )
              .label('Notification Source')
              .required(),
            type: yup
              .string()
              .label('Notification Type')
              .required()
              .trim()
              .oneOf(NOTIFICATION_TYPES.map((type) => type.value))
          })
          .label('Notification')
      )
  });
};
