import { STATUS } from 'straightline-utils/constants';
import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    carrier_user_id: yup.number().label('Driver').required(),
    status_type: yup
      .string()
      .trim()
      .oneOf(Object.values(STATUS))
      .label('Status')
  });
};
