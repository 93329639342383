import { authenticateClient } from 'src/feathers/hooks';
import { validateSchema } from 'straightline-utils/hooks';

const updateUserInLogging = async (context) => {
  const sentryClient = context.app.get('sentryClient');
  const logRocketClient = context.app.get('logRocketClient');
  const user = context.result;

  // Set the user in logRocket and sentry, and also attach this
  // session to any errors that Sentry may capture
  logRocketClient.identify(user.id, { ...user });

  logRocketClient.getSessionURL((sessionURL) => {
    sentryClient.configureScope((scope) => {
      scope.setExtra('LogRocket', sessionURL);
      scope.setUser({
        id: user.id,
        username: user.full_name,
        email: user.email
      });
    });
  });

  return context;
};

export default {
  before: {
    all: [authenticateClient],
    find: [],
    create: [validateSchema]
  },
  after: {
    all: [updateUserInLogging],
    find: [],
    create: []
  },
  error: {
    all: [],
    find: [],
    create: []
  }
};
