import React from 'react';
import { Redirect } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator';
import emptyImg from 'src/images/empty.svg';
import logo from 'src/images/logo-md.png';
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormText,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Link,
  Row,
  Stripes,
  toast,
  useApp,
  useStateIn
} from 'straightline-ui';
import { yup } from 'straightline-utils/validation';

export default function AcceptInvitation({ match }) {
  const app = useApp();
  const {
    state: { error, redirect, showPassword },
    assignState
  } = useStateIn({
    redirect: null,
    error: null,
    showPassword: false
  });

  const {
    result: invitation,
    isLoading,
    isError,
    error: fetchError
  } = app.service('auth/invitations').useGet(match.params.token);

  const handleDecline = (event) => {
    event.preventDefault();
    const { token } = match.params;
    toast.confirm({
      title: 'Are you sure?',
      message: 'Declining an invitation cannot be undone.',
      okButtonText: 'Yes, decline invitation',
      okButtonColor: 'danger',
      cancelButtonText: 'Nevermind, do not decline',
      handleOnOk: () => {
        return app
          .service('auth/invitations')
          .remove(token)
          .then(() => {
            toast.success('Invitation declined');
            assignState({ redirect: '/login' });
          })
          .catch((err) => {
            toast.error(err.message);
          });
      }
    });
  };

  const handleSubmit = (data, formik) => {
    const { token } = match.params;
    const method = invitation.user ? 'patch' : 'update';
    app
      .service('auth/invitations')
      [method](token, data)
      .then(({ email }) => {
        return app
          .authenticate({
            strategy: 'local',
            email: email,
            password: data.password
          })
          .then(() => {
            assignState({ redirect: '/guide' });
          });
      })
      .catch((error) => {
        assignState({ error });
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        formik.setSubmitting(false);
      });
  };

  const togglePassword = () => {
    assignState({ showPassword: !showPassword });
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center fadeIn faster">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (isError && fetchError) {
    return (
      <Container>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg="6" className="text-center">
            <img
              src={emptyImg}
              alt="Not Found"
              className="img-fluid fadeIn faster mb-5"
            />
            <h1>{fetchError.message ? fetchError.message : fetchError}</h1>
            <p className="lead">
              You can try to <Link to="/login">Login</Link> or contact your
              administrator for a new invitation. If you are still having
              trouble, contact Straightline at{' '}
              <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                {process.env.REACT_APP_SUPPORT_PHONE}
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="vh-100 d-flex align-items-center border-top-2 border-primary">
      <Container fluid>
        <Row className="justify-content-center">
          <Col
            xs="12"
            md="6"
            lg="5"
            xl="4"
            className="px-lg-5 border-lg-right d-flex align-items-center min-h-100vh"
          >
            <div className="w-100">
              <div className="text-center my-4">
                <img
                  src={logo}
                  alt="Straightline Logo"
                  className="img-fluid"
                  style={{ height: 50 }}
                />
              </div>
              {error && <Error error={error} invitation={invitation} />}
              {invitation.user ? (
                <CurrentUser
                  handleSubmit={handleSubmit}
                  showPassword={showPassword}
                  togglePassword={togglePassword}
                />
              ) : (
                <NewUser
                  handleSubmit={handleSubmit}
                  showPassword={showPassword}
                  togglePassword={togglePassword}
                />
              )}
              <div className="text-center text-muted">
                By accepting this invitation, you expressly agree to the
                Straightline&nbsp;
                <a
                  href="/terms-of-service"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                </a>
                . Not interested?{' '}
                <span
                  className="text-underline pointer"
                  onClick={handleDecline}
                >
                  Decline invitation
                </span>
              </div>
            </div>
          </Col>
          <Col lg="7" xl="8" className="d-none d-lg-block px-0">
            <div
              className="d-flex align-items-center"
              style={{
                overflow: 'hidden',
                position: 'relative',
                height: '100vh'
              }}
            >
              <Stripes animation="fadeIn" />
              <Row className="d-flex justify-content-center">
                <Col xl="8">
                  <div className="text-white">
                    <h1 style={{ fontSize: '3.5rem' }}>Welcome!</h1>
                    <h3 style={{ fontSize: '1.5rem' }}>
                      {invitation.inviter.full_name} has invited you to join{' '}
                      {invitation.entity.name}
                    </h3>
                    <p className="lead">
                      Straightline is a real-time truckload booking, matching,
                      and payments service that offers unparalleled transparency
                      and speed to the logistics industry.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const Error = ({ error, invitation }) => {
  if (error.message === 'Error comparing passwords.') {
    return (
      <Alert color="light" className="animated bounceIn faster">
        <strong>Error:&nbsp;</strong>&nbsp;It appears you have previously signed
        up but never confirmed your password.{' '}
        <Link to={`/forgot-password?email=${invitation.email}`}>
          Set your password
        </Link>{' '}
        and then try again.
      </Alert>
    );
  }
  return (
    <Alert color="warning" className="animated bounceIn faster">
      <strong>Error:&nbsp;</strong>&nbsp;
      {error.errors && Object.values(error.errors).length ? (
        <ul>
          {Object.values(error.errors).map((err) => (
            <li>{err}</li>
          ))}
        </ul>
      ) : (
        error.message || error
      )}
    </Alert>
  );
};

const currentUsersSchema = yup.object({
  password: yup.string().trim().required().label('Password')
});

const CurrentUser = ({ handleSubmit, showPassword, togglePassword }) => {
  return (
    <Formik
      initialValues={{
        password: ''
      }}
      validationSchema={currentUsersSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => {
        return (
          <Form>
            <FormGroup>
              <Label>Password*</Label>
              <InputGroup className="input-group-merge">
                <FormInput
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className="form-control-appended"
                  placeholder="Enter your password"
                  disabled={isSubmitting}
                  autocomplete="new-password"
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <Icon
                      type={showPassword ? 'eye-off' : 'eye'}
                      className="pointer"
                      onClick={togglePassword}
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback name="password" />
              </InputGroup>
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              size="lg"
              block
              className={`mb-3 ${isSubmitting && 'is-loading'}`}
              disabled={isSubmitting}
            >
              Accept Invitation
            </Button>
          </Form>
        );
      }}
    />
  );
};

const newUserSchema = yup.object({
  first_name: yup.string().trim().required().label('First Name'),
  last_name: yup.string().trim().required().label('Last Name'),
  phone: yup.string().trim().phone().required().label('Phone'),
  password: yup.string().trim().password().required().label('Password')
});

const NewUser = ({ handleSubmit, showPassword, togglePassword }) => {
  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        phone: '',
        password: ''
      }}
      validationSchema={newUserSchema}
      onSubmit={handleSubmit}
      render={({ isSubmitting }) => {
        return (
          <Form>
            <FormGroup>
              <Label>First Name*</Label>
              <FormInput
                name="first_name"
                placeholder="Enter first name"
                disabled={isSubmitting}
              />
              <FormFeedback name="first_name" />
            </FormGroup>
            <FormGroup>
              <Label>Last Name*</Label>
              <FormInput
                name="last_name"
                placeholder="Enter last name"
                disabled={isSubmitting}
              />
              <FormFeedback name="last_name" />
            </FormGroup>
            <FormGroup>
              <Label>Phone*</Label>
              <FormInput type="phone" name="phone" disabled={isSubmitting} />
              <FormFeedback name="phone" />
            </FormGroup>
            <FormGroup>
              <Label>Password*</Label>
              <FormText>
                Password must be 8+ characters and contain a lowercase letter,
                uppercase letter, number, and special character.
              </FormText>
              <InputGroup className="input-group-merge">
                <FormInput
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className="form-control-appended"
                  placeholder="Enter your password"
                  disabled={isSubmitting}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <Icon
                      type={showPassword ? 'eye-off' : 'eye'}
                      className="pointer"
                      onClick={togglePassword}
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <FormFeedback name="password" />
              </InputGroup>
            </FormGroup>
            <Button
              type="submit"
              color="primary"
              size="lg"
              block
              className={`mb-3 ${isSubmitting && 'is-loading'}`}
              disabled={isSubmitting}
            >
              Accept Invitation
            </Button>
          </Form>
        );
      }}
    />
  );
};
