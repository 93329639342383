import React, { useState } from 'react';
import { NotificationsPicker } from 'src/components/Notifications';
import SettingsHeader from 'src/components/SettingsHeader';
import { useAppState, useOnMount } from 'src/lib/hooks';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormReset,
  FormSubmit,
  Input,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';

export default function Profile() {
  const {
    state: { user },
    setInState
  } = useAppState();
  const [sendingReset, setSendingReset] = useState();
  const app = useApp();
  const service = app.service('api/user');

  const refresh = () => {
    return service.find().then((user) => {
      setInState('user', user);
    });
  };

  useOnMount(() => {
    refresh();
  });

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then((updatedUser) => {
        setInState('user', updatedUser);
        toast.success(`Profile updated!`);
        formik.setSubmitting(false);
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  const handleResetPassword = () => {
    setSendingReset(true);
    app
      .service('auth/forgot-password')
      .create({ email: user.email })
      .then(() => {
        toast.success(
          'You have been sent an email to change your password. Your password will remain unchanged until you complete the email.'
        );
      })
      .catch(toast.errors)
      .finally(() => {
        setSendingReset(false);
      });
  };

  return (
    <Container fluid>
      <SettingsHeader activeTab="profile" />
      <Row>
        <Col lg="5">
          <Card>
            <CardBody>
              <Formik
                initialValues={user}
                // Bc we get the updated profile on load
                // reinitalize the form with updated values
                enableReinitialize={true}
                onSubmit={handleSubmit}
                validationSchema={service.schema}
                render={(formik) => {
                  const { isSubmitting } = formik;
                  return (
                    <Form>
                      <FormGroup>
                        <Label>First Name*</Label>
                        <FormInput name="first_name" disabled={isSubmitting} />
                        <FormFeedback name="first_name" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Last Name*</Label>
                        <FormInput name="last_name" disabled={isSubmitting} />
                        <FormFeedback name="last_name" />
                      </FormGroup>
                      <FormGroup>
                        <Label>Phone*</Label>
                        <FormInput
                          name="phone"
                          type="phone"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="phone" />
                      </FormGroup>
                      <FormSubmit className="mr-3">Submit</FormSubmit>
                      <FormReset>Discard Changes</FormReset>
                    </Form>
                  );
                }}
              />
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <div className="mb-4">
                <FormGroup>
                  <Label>Reset Password</Label>
                  <Input value={user.email} disabled={true} readOnly={true} />
                </FormGroup>
                <div>
                  To reset your password, click the button below and then follow
                  the instructions in the email sent to you.
                </div>
              </div>
              <Button
                color="primary"
                className={`${sendingReset ? 'is-loading' : ''}`}
                type="button"
                // block
                disabled={sendingReset}
                onClick={handleResetPassword}
              >
                Send Email
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col lg="7">
          <NotificationsPicker />
        </Col>
      </Row>
    </Container>
  );
}
