import React, { useRef, useState } from 'react';
import feathers from 'src/feathers';
import logo from 'src/images/logo-md.png';
import { useOnMount } from 'src/lib/hooks';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Link,
  Row,
  Stripes,
  toast
} from 'straightline-ui';

export default function Login({ location, history }) {
  const [showPassword, setShowPassword] = useState(false);
  const passwordRef = useRef(null);

  useOnMount(() => {
    const handleAutoLogin = async () => {
      try {
        await feathers.authenticate({
          strategy: 'jwt',
          accessToken: location.query.token
        });
        if (location.query && location.query.redirect) {
          history.push(location.query.redirect);
        } else {
          history.push('/');
        }
      } catch (error) {
        toast.errors(error.message);
      }
    };

    if (location.query?.token) {
      handleAutoLogin();
    }
  });

  const schema = feathers
    .service('authentication')
    .schema.pick(['email', 'password', 'strategy']);

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    feathers
      .authenticate(data)
      .then((authData) => {
        formik.setSubmitting(false);
        if (location.query && location.query.redirect) {
          history.push(location.query.redirect);
        } else {
          history.push('/');
        }
      })
      .catch((error) => {
        formik.setSubmitting(false);
        formik.setFieldValue('password', '');
        formik.setFieldTouched('password', false);
        passwordRef.current.focus();
        if (error.formik) {
          formik.setErrors(error.formik);
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <div className="vh-100 d-flex align-items-center border-top-2 border-primary">
      <Stripes animation="fadeIn" />
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="5">
            <Card className="py-5 p-sm-5">
              <CardBody>
                <div className="d-flex justify-content-center mb-4">
                  <img
                    src={logo}
                    alt="Straightline Logo"
                    className="img-fluid"
                    style={{ height: 50 }}
                  />
                </div>
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    // Must pass strategy bc it is required in .when()
                    // on email/password validations
                    strategy: 'local',
                    email: '',
                    password: ''
                  }}
                  validationSchema={schema}
                  render={({ values, isSubmitting }) => {
                    return (
                      <React.Fragment>
                        <Form>
                          <FormGroup>
                            <Label>Email*</Label>
                            <FormInput
                              type="email"
                              name="email"
                              placeholder="name@address.com"
                              innerRef={passwordRef}
                              disabled={isSubmitting}
                            />
                            <FormFeedback name="email" />
                          </FormGroup>
                          <FormGroup>
                            <Row>
                              <Col>
                                <Label>Password*</Label>
                              </Col>
                              <Col xs="auto">
                                <Link
                                  tabIndex={-1}
                                  to={`/forgot-password?email=${values.email}`}
                                  className="form-text text-secondary"
                                >
                                  Forgot password?
                                </Link>
                              </Col>
                            </Row>
                            <InputGroup className="input-group-merge">
                              <FormInput
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                className="form-control-appended"
                                placeholder="Enter your password"
                                disabled={isSubmitting}
                                innerRef={passwordRef}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText>
                                  <Icon
                                    type={showPassword ? 'eye-off' : 'eye'}
                                    className="pointer"
                                    onClick={() => {
                                      setShowPassword(!showPassword);
                                    }}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                              <FormFeedback name="password" />
                            </InputGroup>
                          </FormGroup>
                          <Button
                            type="submit"
                            color="primary"
                            size="lg"
                            block
                            className={`mb-3 ${isSubmitting && 'is-loading'}`}
                            disabled={isSubmitting}
                          >
                            Sign in
                          </Button>
                        </Form>
                        <Button to="/signup" color="outline-secondary" block>
                          Don't have an account yet?
                        </Button>
                      </React.Fragment>
                    );
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
