import React from 'react';
import { Col, Container, Row, useApp, useScreenSize } from 'straightline-ui';
import { roundNumberToLabel, toDollars } from 'straightline-utils/accounting';

import ShipmentMatchesCard from './ShipmentMatchesCard';
import ShipmentsCard from './ShipmentsCard';
import StatusChangesCard from './StatusChangesCard';

export default function Dashboard() {
  // const app = useApp();

  // const handleAccepted = () => {
  //   app.service('api/shipment-stats').invalidateFind();
  //   app.service('api/shipments').invalidateFind();
  // };

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          left: 0,
          height: '100vh',
          overflow: 'hidden'
        }}
      >
        <div className="stripes">
          <span className="animated slideInUp faster"></span>
          <span className="animated slideInDown faster"></span>
          <span className="animated slideInUp faster"></span>
          <span className="animated slideInDown faster"></span>
          <span className="animated slideInUp faster"></span>
        </div>
      </div>
      <Container fluid>
        <div className="header pt-md-2">
          <div className="header-body">
            <StatsHeader />
          </div>
        </div>
        <Row>
          <Col xl="8">
            <ShipmentsCard />
          </Col>
          <Col xl="4">
            <StatusChangesCard />
          </Col>
        </Row>
      </Container>
      <div className="overflow-hidden">
        <Container fluid>
          {/* <ShipmentQuotesCard
              onAccepted={this.handleAccepted}
              container={quotesContainer}
            /> */}
        </Container>
        <div className="with-slant-bg">
          <Container fluid>
            <ShipmentMatchesCard />
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

const StatsHeader = () => {
  const app = useApp();
  const { isXs, isSm } = useScreenSize();
  const {
    result: stats,
    isLoading,
    isError
  } = app.service('api/shipment-stats').useFind({}, { keepPreviousData: true });

  const Tag = isXs || isSm ? 'h2' : 'h1';

  if (isLoading || isError || !stats?.total?.shipments) {
    return (
      <div>
        <h6 className="header-pretitle text-white">Overview</h6>
        <Tag className="header-title text-white">Dashboard</Tag>
      </div>
    );
  }

  const totals = stats.total;

  return (
    <Row className="mb-n3 justify-content-between">
      <Col xs="auto" className="mb-3">
        <h6 className="header-pretitle text-white">Overview</h6>
        <Tag className="header-title text-white">Dashboard</Tag>
      </Col>
      <Col className="col-auto mb-3">
        <Row>
          <Col xs="auto" className="text-center pr-0 px-md-3">
            <h6 className="header-pretitle text-light">Loads</h6>
            <Tag className="text-white mb-0">
              {roundNumberToLabel(totals.shipments)}
            </Tag>
          </Col>
          <Col xs="auto" className="text-center pl-1 pr-0 px-md-3">
            <h6 className="header-pretitle text-light">Miles</h6>
            <Tag className="text-white mb-0">
              {roundNumberToLabel(totals.miles.sum)}
            </Tag>
          </Col>
          <Col xs="auto" className="text-center pl-1 px-md-3">
            <h6 className="header-pretitle text-light">Payout</h6>
            <Tag className="text-white mb-0">{`$${roundNumberToLabel(
              toDollars(totals.price.sum)
            )}`}</Tag>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
