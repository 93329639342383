import { withResult } from 'feathers-fletching';
import { statusType, truckType } from 'straightline-utils/constants';

const withResults = withResult({
  status: (shipment) => {
    if (shipment.status_type) {
      return statusType(shipment.status_type);
    }
  },
  truck: (shipment) => {
    if (shipment.truck_type) {
      return truckType(shipment.truck_type);
    }
  },
  pickup_address: (shipment) => {
    if (shipment.pickup_address) {
      return {
        ...shipment.pickup_address,
        position: {
          lat: Number(shipment.pickup_address.latitude),
          lng: Number(shipment.pickup_address.longitude)
        }
      };
    }
  },
  delivery_address: (shipment) => {
    if (shipment.delivery_address) {
      return {
        ...shipment.delivery_address,
        position: {
          lat: Number(shipment.delivery_address.latitude),
          lng: Number(shipment.delivery_address.longitude)
        }
      };
    }
  },
  cost_per_mile: (shipment) => {
    if (shipment.rate && shipment.travel) {
      return (
        shipment.rate.price.carrier_price / (shipment.travel.miles || 1)
      ).toFixed(1);
    }
  },
  route: async ({ route }, context) => {
    if (!route) {
      return route;
    }

    return {
      ...route,
      directions: await context.app
        .service('api/shipment-routes')
        .parseDirections(route)
    };
  },
  tracking: ({ tracking }) => {
    if (!tracking) {
      return tracking;
    }
    return tracking.map((track) => {
      return {
        ...track,
        position: {
          lat: Number(track.latitude),
          lng: Number(track.longitude)
        }
      };
    });
  }
});

export default {
  before: {
    all: [],
    get: [],
    patch: []
  },
  after: {
    all: [withResults],
    get: [],
    patch: []
  },
  error: {
    all: [],
    get: [],
    patch: []
  }
};
