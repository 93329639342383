import feathers from '@feathersjs/client';
import { cache, stashCache } from 'src/feathers/hooks';
import { scriptLoader } from 'straightline-ui';
const { GeneralError } = feathers.errors;

class Service {
  async find(params) {
    if (!this.directionsService) {
      await scriptLoader.load(process.env.REACT_APP_GOOGLE_MAPS_URL);
      this.directionsService = new window.google.maps.DirectionsService();
    }
    const googleParams = {
      travelMode: window.google.maps.TravelMode.DRIVING,
      ...params.query
    };
    return new Promise((resolve, reject) => {
      this.directionsService.route(googleParams, (response, status) => {
        if (status !== 'OK') {
          reject(
            new GeneralError(
              'There was an error from Google calculating the directions.',
              { status }
            )
          );
        } else {
          resolve(response);
        }
      });
    });
  }
}

export default (app) => {
  app.use('google/directions', new Service());
  const service = app.service('google/directions');

  service.hooks({
    before: {
      all: [],
      find: [cache],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },

    after: {
      all: [],
      find: [cache, stashCache],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },

    error: {
      all: [],
      find: [],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    }
  });
};
