import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    shipment_id: yup.string().id().label('Shipment Id').required(),
    amount: yup
      .number()
      .integer()
      .positive()
      .round()
      .label('Offer Amount')
      .required(),
    notes: yup.string().trim().nullable().label('Notes')
  });
};
