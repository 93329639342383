import { authenticateClient, cache, stashCache } from 'src/feathers/hooks';

// https://gis.stackexchange.com/questions/15197/google-maps-v3-in-javascript-api-render-route-obtained-with-web-api
const parseDirections = async (context) => {
  context.result.directions = await context.service.parseDirections(
    context.result
  );
  return context;
};

export default {
  before: {
    find: [authenticateClient, cache]
  },

  after: {
    find: [parseDirections, cache, stashCache]
  },

  error: {
    find: []
  }
};
