import React from 'react';
import printingInvoices from 'src/images/printing-invoices.svg';
import { Button, Col, Container, Icon, Row } from 'straightline-ui';

export default () => (
  <Container fluid>
    <Row className="text-center d-flex vh-100 align-items-center justify-content-center">
      <Col sm="8">
        <div className="mb-4">
          <img
            src={printingInvoices}
            className="img-fluid"
            alt="Getting Paid"
          />
        </div>
        <h1>Let's get you paid!</h1>
        <p className="lead mb-4">
          You have not completed any loads. Search for loads or post a truck to
          be notified of loads near you!
        </p>
        <div>
          <Button
            to="/shipment-matches"
            color="primary"
            size="lg"
            className="mr-3"
          >
            <Icon type="clipboard" className="mr-2" />
            Load Board
          </Button>
          <Button to="/shipment-request" color="primary" size="lg">
            <Icon type="truck" className="mr-2" />
            Post Truck
          </Button>
        </div>
      </Col>
    </Row>
  </Container>
);
