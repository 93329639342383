import { useEffect } from 'react';
import stateContainer from 'src/lib/state';
import { useGlobalStateIn } from 'straightline-ui';

export const useAppState = () => useGlobalStateIn(stateContainer);

export const useOnMount = (fn) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useEffect(fn, []);
};
