import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import LoadingIndicator from 'src/components/LoadingIndicator';
import NavTab from 'src/components/NavTab';
import PaginationBtns from 'src/components/PaginationBtns';
import SettingsHeader from 'src/components/SettingsHeader';
import { useApp, useUrlState } from 'straightline-ui';
import {
  Button,
  Card,
  Col,
  Container,
  Icon,
  Input,
  Nav,
  Row,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow
} from 'straightline-ui';

function Users() {
  const app = useApp();
  const { getInState, setInState, delInState } = useUrlState();
  const query = getInState('query', {});
  const $sort = getInState('query.$sort', {});
  const $search = getInState('query.$search', '');
  const [text, setText] = useState($search);

  const activeTab = 'users';

  const {
    result: users,
    isLoading,
    isError
  } = app.service('api/users').useFind({ query }, { keepPreviousData: true });

  // eslint-disable-next-line
  const debouncedHandleChange = useCallback(
    debounce((value) => {
      const val = value ? value.trim() : value;
      if (val) {
        setInState('query.$search', val);
      } else {
        delInState('query.$search');
      }
    }, 300),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    setText(value);
    debouncedHandleChange(value);
  };

  const handleSearchClear = async () => {
    delInState('query.$search');
  };

  const handleSort = ({ name, value }) => {
    setInState('query.$sort', { [name]: value });
  };

  if (isLoading || isError) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <div className="card-header">
          <Row>
            <Col className="" xl="8" lg="6">
              <Nav tabs className="card-header-tabs">
                <NavTab tab="users" to="/settings/users" activeTab={activeTab}>
                  Users
                </NavTab>
                {/* <NavTab tab="users" to="/settings/users" activeTab={activeTab}>
                  All
                </NavTab> */}
                {/* {roles.map((role) => (
                  <NavTab
                    key={role.id}
                    tab={role.id}
                    onClick={this.handleTabClick}
                    activeTab={activeTab}
                  >
                    {role.name}
                  </NavTab>
                ))} */}
                <NavTab to="/settings/invitations" activeTab={activeTab}>
                  Invitations
                </NavTab>
              </Nav>
            </Col>
            <Col
              className="border-left"
              style={{
                // Allow the left border to extend past
                // card-header padding to reach top/bottom borders
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
                marginTop: '-0.5rem',
                marginBottom: '-0.5rem'
              }}
              xl="4"
              lg="6"
            >
              <form
                // NavTabs have some weird negative margin that make
                // for a 1px off height
                style={{ paddingTop: 1 }}
                onSubmit={(event) => {
                  event.preventDefault();
                  // search();
                }}
              >
                <Row className="align-items-center">
                  <Col>
                    <Row className="align-items-center">
                      <Col className="col-auto pr-0">
                        <Icon type="search" className="text-muted" />
                      </Col>
                      <Col>
                        <Input
                          value={text}
                          onChange={handleSearchChange}
                          className="form-control-flush"
                          placeholder="Search"
                        />
                      </Col>
                      <Col className="col-auto p-0">
                        <Button
                          disabled={!text}
                          color="default"
                          type="button"
                          onClick={handleSearchClear}
                        >
                          <Icon type="x" />
                        </Button>
                      </Col>
                      {/* <Col className="col-auto">
                        <Button disabled={!text} type="submit">
                          Search
                        </Button>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
        <div className="table-responsive">
          <Table className="table table-nowrap card-table table-hover">
            <TableHead>
              <TableRow>
                <TableHeader
                  sortName="first_name"
                  sort={$sort.first_name}
                  onClick={handleSort}
                >
                  Name
                </TableHeader>
                <TableHeader
                  sortName="email"
                  sort={$sort.email}
                  onClick={handleSort}
                >
                  Email
                </TableHeader>
                <TableHeader
                  sortName="phone"
                  sort={$sort.phone}
                  onClick={handleSort}
                >
                  Phone
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {users.data.map((user) => (
                <TableRow key={user.id} to={`/settings/users/${user.id}`}>
                  <TableData className="font-weight-bold">
                    {user.full_name}
                  </TableData>
                  <TableData>{user.email}</TableData>
                  <TableData>{user.masked_phone}</TableData>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Card>
      <div className="text-right">
        <PaginationBtns
          total={users.total}
          skip={getInState('query.$skip', 0)}
          setSkip={($skip) => setInState('query.$skip', $skip)}
        />
      </div>
    </React.Fragment>
  );
}

export default (routerProps) => {
  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="users">
        <Button to="/settings/user" color="primary" className="float-right">
          Invite New User
        </Button>
      </SettingsHeader>
      <Users {...routerProps} />
    </Container>
  );
};
