import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

export default function (app) {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const dsn =
    environment === 'development'
      ? undefined
      : process.env.REACT_APP_SENTRY_DSN;

  Sentry.init({
    dsn,
    environment,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });

  app.set('sentryClient', Sentry);
}
