import React, { useState } from 'react';
import EtaModal from 'src/components/EtaModal';
import LineItemsCard from 'src/components/LineItemsCard';
import { ShipmentNotifications } from 'src/components/Notifications';
import ShipmentBanner from 'src/components/ShipmentBanner';
import StatusProgress from 'src/components/StatusProgress';
import heavyBoxRedImg from 'src/images/heavy-box-red.svg';
import {
  AddressCard,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  Label,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import {
  isCancelled,
  isDelivered,
  statusType
} from 'straightline-utils/constants';
import { formatDate, formatDistanceToNow } from 'straightline-utils/time';

function ChangeStatus({ shipment, onError }) {
  const app = useApp();
  const changeOptions = shipment.status_change_options.map((option) =>
    statusType(option)
  );
  const currentStatus = statusType(shipment.status_type);

  const handleClick = (status) => {
    toast.confirm({
      title: 'Update Status',
      message: (
        <div>
          <p>Are you sure you want to set the status to "{status.label}"?</p>
          <div className="p-3 bg-light rounded">{status.description}</div>
        </div>
      ),
      okButtonText: 'Yes, update status',
      cancelButtonText: 'Nevermind, do not update',
      handleOnOk: () => {
        return app
          .service('api/shipments')
          .patch(shipment.id, { status_type: status.value })
          .then((updateShipment) => {
            toast.success(`Load ${shipment.id} is "${status.label}"`);
            app.service('api/shipments').invalidateGet(shipment.id);
          })
          .catch((error) => {
            toast.errors(error);
            if (onError) {
              onError(error);
            }
          });
      }
    });
  };

  return (
    <Card>
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="card-header-title">Load Status</h3>
          {changeOptions.length > 0 && (
            <Button
              color="link"
              className="mr-n3"
              onClick={() =>
                handleClick(changeOptions[changeOptions.length - 1])
              }
            >
              Update Status
            </Button>
          )}
        </div>
      </div>
      <StatusProgress
        shipment={shipment}
        onClick={handleClick}
        unshift
        trim
        trimFlat
        unshiftFlat
      />
      <CardBody className="my-0">
        {changeOptions.length > 0 ? (
          <div>
            The status is currently "{currentStatus.label}". Click{' '}
            {changeOptions.length === 1 ? (
              <span
                onClick={() => handleClick(changeOptions[0])}
                className="text-primary pointer"
              >
                {changeOptions[0].label}
              </span>
            ) : (
              changeOptions.map((option, index) => (
                <span key={index}>
                  {index === 0 ? '' : ', or '}
                  <span
                    onClick={() => handleClick(option)}
                    className="text-primary pointer"
                  >
                    {option.label}
                  </span>
                </span>
              ))
            )}{' '}
            to update to the latest status. If you need to change to another
            status, please call Straightline at &nbsp;
            <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
              {process.env.REACT_APP_SUPPORT_PHONE}
            </a>
            .
          </div>
        ) : (
          <div>The status of this shipment cannot be changed.</div>
        )}
      </CardBody>
    </Card>
  );
}

const NotAvailable = () => (
  <span className="text-secondary">Not available</span>
);

export function ShipmentDispatch({ shipment }) {
  const [open, setOpen] = useState(false);
  const { shipment_dispatch } = shipment;

  if (shipment_dispatch) {
    return (
      <Card>
        <div className="card-header">
          <div className="d-flex align-items-center justify-content-between">
            <h3 className="card-header-title">Driver</h3>
            <Button
              color="link"
              className="mr-n3"
              onClick={() => setOpen(true)}
            >
              Update Driver
            </Button>
          </div>
        </div>
        <CardBody className="my-0">
          <div>
            <Icon type="user" className="text-secondary mr-3" />
            {shipment_dispatch.carrier_driver || <NotAvailable />}
          </div>
          <div>
            <Icon type="phone" className="text-secondary mr-3" />
            {shipment_dispatch.carrier_phone || <NotAvailable />}
          </div>
          <div>
            <Icon type="mail" className="text-secondary mr-3" />
            {shipment_dispatch.carrier_email || <NotAvailable />}
          </div>
          <div>
            <Icon type="truck" className="text-secondary mr-3" />
            {shipment_dispatch.carrier_truck_number || <NotAvailable />}
          </div>
          <div>
            <Icon type="book" className="text-secondary mr-3" />
            {shipment_dispatch.carrier_trailer_number || <NotAvailable />}
          </div>
        </CardBody>
        <DriverModal shipment={shipment} open={open} setOpen={setOpen} />
      </Card>
    );
  }

  return (
    <Card className="shadow-lg border-primary">
      <div className="card-header">
        <div className="d-flex align-items-center justify-content-between">
          <h3 className="card-header-title">
            Driver <Badge color="soft-primary">Needs Attention</Badge>
          </h3>
          <Button color="link" className="mr-n3" onClick={() => setOpen(true)}>
            Assign Driver
          </Button>
        </div>
      </div>
      <CardBody>
        <div className="font-weight-bold text-danger mb-4">
          You cannot download the rate confirmation until you have dispatched
          this load.
        </div>
        Dispatch this load by assigning a driver name, phone, and email. The
        driver will automatically be notified of the load details and will be
        able to update the status of the load as it progresses. Straightline may
        also use this information to contact the driver directly.
      </CardBody>
      <DriverModal shipment={shipment} open={open} setOpen={setOpen} />
    </Card>
  );
}

function DriverModal({ shipment, open, setOpen }) {
  const app = useApp();
  const service = app.service('api/shipment-dispatches');

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then(() => {
        toast.success('Driver dispatched');
        setOpen(false);
        formik.resetForm();
        app.service('api/shipments').invalidateGet(shipment.id);
        app.service('api/shipment-dispatches').invalidateFind();
      })
      .catch((error) => {
        toast.errors(error);
        formik.setSubmitting(false);
      });
  };

  return (
    <Modal
      isOpen={open}
      toggle={() => setOpen(!open)}
      style={{ maxWidth: 500 }}
      className="modal-dialog-centered"
    >
      {() => (
        <Formik
          onSubmit={handleSubmit}
          validationSchema={service.schema}
          initialValues={{
            shipment_id: shipment.id,
            carrier_rate_type: shipment.rate.carrier_rate_type,
            carrier_service_level: shipment.rate.service_level.code,
            carrier_driver: undefined,
            carrier_email: undefined,
            carrier_phone: undefined,
            carrier_truck_number: undefined,
            carrier_trailer_number: undefined
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <ModalBody>
                  <div className="mb-4">
                    Provide accurate information for the driver that will be
                    assigned to this load. Straightline will use this
                    information to communicate with the driver throughout the
                    load completion.
                  </div>
                  <FormGroup>
                    <Label>Driver Name*</Label>
                    <FormInput name="carrier_driver" disabled={isSubmitting} />
                    <FormFeedback name="carrier_driver" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Driver Phone*</Label>
                    <FormInput
                      name="carrier_phone"
                      type="phone"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="carrier_phone" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Driver Email</Label>
                    <FormInput
                      name="carrier_email"
                      type="email"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="carrier_email" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Truck Number</Label>
                    <FormInput
                      name="carrier_truck_number"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="carrier_truck_number" />
                  </FormGroup>
                  <FormGroup>
                    <Label>Trailer Number</Label>
                    <FormInput
                      name="carrier_trailer_number"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="carrier_trailer_number" />
                  </FormGroup>
                </ModalBody>
                <ModalFooter className="justify-content-between">
                  <Button
                    color="white"
                    type="button"
                    disabled={isSubmitting}
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    loading={isSubmitting}
                  >
                    Dispatch Driver
                  </Button>
                </ModalFooter>
              </Form>
            );
          }}
        </Formik>
      )}
    </Modal>
  );
}

function ShipmentETA({ shipment }) {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const { eta } = shipment;

  const handleComplete = (newEta) => {
    setOpen(false);
    app.service('api/shipments').invalidateGet(shipment.id);
  };

  return (
    <Card>
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="card-header-title">Estimated Arrival</h3>
          <Button color="link" className="mr-n3" onClick={() => setOpen(true)}>
            Update ETA
          </Button>
        </div>
      </div>
      {eta ? (
        <div
          className="d-flex flex-wrap align-items-center justify-content-between py-1 px-4 btn-primary pointer"
          onClick={() => setOpen(true)}
        >
          <div className="font-weight-bold">
            {formatDate(eta.date, 'iii, LLL do')} at {eta.time}
          </div>
          <div>updated {formatDistanceToNow(eta.created_at)}</div>
        </div>
      ) : (
        <div
          className="py-1 px-4 btn-secondary pointer"
          onClick={() => setOpen(true)}
        >
          There is no ETA set
        </div>
      )}
      <CardBody className="my-0">
        <div>
          Set a date and time that you estimate the shipment to be delivered.
          You can update this ETA anytime and the shipper will be notified of
          your changes instantly.
        </div>
      </CardBody>
      <EtaModal
        shipment={shipment}
        open={open}
        onComplete={handleComplete}
        onCancel={() => setOpen(false)}
      />
    </Card>
  );
}

export default function ShipmentDetails({ shipment }) {
  return (
    <React.Fragment>
      <Row>
        <Col xl="7">
          {!isDelivered(shipment) && !isCancelled(shipment) && (
            <React.Fragment>
              <ShipmentDispatch shipment={shipment} />
              <ChangeStatus shipment={shipment} />
              <ShipmentETA shipment={shipment} />
              <Card>
                <CardBody>
                  <ShipmentNotifications shipment={shipment} />
                </CardBody>
              </Card>
            </React.Fragment>
          )}

          {isDelivered(shipment) && (
            <Card>
              <div className="card-header">
                <Row className="align-items-center justify-content-between">
                  <Col xs="auto">
                    <h2 className="card-header-title text-success">
                      Congratulations!
                    </h2>
                  </Col>
                  <Col xs="auto">
                    <div className="progress progress-status">
                      <div
                        className="bg-success progress-bar"
                        style={{ width: '100%' }}
                      >
                        Delivered
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody>
                <div>
                  <div className="mb-4">
                    You have successfully delivered this shipment! Please{' '}
                    <Link to={`/shipments/${shipment.id}?tab=documents`}>
                      upload any documents
                    </Link>{' '}
                    that you may have, such as POD's or BOL's using the document
                    uploader. If you have any questions, give us a shout
                    at&nbsp;
                    <a
                      href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      {process.env.REACT_APP_SUPPORT_PHONE}
                    </a>
                    .
                  </div>
                  <div className="mb-n2">
                    <Button
                      to={`/shipments/${shipment.id}?tab=documents`}
                      color="primary"
                      className="mr-3 mb-2"
                    >
                      <Icon type="image" className="mr-2" />
                      Upload POD
                    </Button>
                    <Button
                      to="/shipment-matches"
                      query={{
                        query: {
                          pickup_distance: {
                            $lt: 200,
                            city: shipment.delivery_address.city,
                            state: shipment.delivery_address.state,
                            latitude: shipment.delivery_address.latitude,
                            longitude: shipment.delivery_address.longitude
                          }
                        }
                      }}
                      color="primary"
                      className="mb-2"
                    >
                      <Icon type="clipboard" className="mr-2" />
                      Find a load leaving {shipment.delivery_address.city}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}

          {isCancelled(shipment) && (
            <Card>
              <div className="card-header">
                <Row className="align-items-center justify-content-between">
                  <Col xs="auto">
                    <h3 className="card-header-title">Load Status</h3>
                  </Col>
                  <Col xs="auto">
                    <div className="progress progress-status">
                      <div
                        className="bg-danger progress-bar"
                        style={{ width: '100%' }}
                      >
                        Cancelled
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <CardBody>
                <Row>
                  <Col lg="5">
                    <img
                      src={heavyBoxRedImg}
                      alt="Heavy Boxes"
                      className="img-fluid mb-4 animated bounceIn"
                    />
                  </Col>
                  <Col lg="7" className="d-flex align-items-center">
                    <div>
                      <h1 className="text-danger">Bummer!</h1>
                      <p className="mb-0 text-secondary lead">
                        This shipment has been cancelled. If you have any
                        questions, please call Straightline at&nbsp;
                        <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                          {process.env.REACT_APP_SUPPORT_PHONE}
                        </a>
                        .
                      </p>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}

          <Card>
            <CardBody>
              <div className="mb-4">
                <ShipmentBanner shipment={shipment} />
              </div>
              <div className="mb-4">
                <h5 className="text-uppercase text-muted font-weight-bold">
                  Service
                </h5>
                <div>{shipment.rate.service_level.name}</div>
                <div>{shipment.rate.service_level.description}</div>
                {shipment.rate.service_level.code === 'TEM' && (
                  <div className="font-weight-bold text-primary">
                    ** This shipment requires two drivers **
                  </div>
                )}
              </div>
              <div>
                <h5 className="text-uppercase text-muted font-weight-bold">
                  Notes
                </h5>
                <div className="pre-line">
                  {shipment.notes || (
                    <span className="text-muted">Not available</span>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
          <LineItemsCard shipment={shipment} />
        </Col>
        <Col xl="5">
          <AddressCard shipment={shipment} type="pickup" />
          <AddressCard shipment={shipment} type="delivery" />
        </Col>
      </Row>
      {/*
      <Card>
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3 className="card-header-title">Load Details</h3>
            <h3 className="card-header-title text-success">
              {formatMoney(shipment.rate.price.carrier_price)}
            </h3>
          </div>
        </div>
        <CardBody>
          <Row>
            <Col xl="6" sm="6" className="mb-4">
              <h5 className="text-uppercase text-muted font-weight-bold">
                Truck Type
              </h5>
              <p className="mb-0">{shipment.truck.label}</p>
            </Col>
            <Col xl="6" sm="6" className="mb-4">
              <h5 className="text-uppercase text-muted font-weight-bold">
                Weight
              </h5>
              <p className="mb-0">
                {shipment.load_weight?.toLocaleString() || 'NA'} lbs
              </p>
            </Col>
          </Row>
          <h5 className="text-uppercase text-muted font-weight-bold">Notes</h5>
          <p className="pre-line mb-0">{shipment.notes}</p>
        </CardBody>
      </Card> */}
    </React.Fragment>
  );
}
