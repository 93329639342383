import classNames from 'classnames';
import React from 'react';
import { Link, NavItem, NavLink } from 'straightline-ui';

export default ({ activeTab, to, tab, active, onClick, children, ...rest }) => {
  const className = classNames({
    // The tab prop is generally a number OR a string depending on if
    // it came from the props.location.query or off a record. Rather than
    // requiring the dev to always toString() stuff, its safe enough
    // to double equals here.
    active:
      typeof active !== 'undefined'
        ? active
        : activeTab && tab
        ? // eslint-disable-next-line eqeqeq
          activeTab == tab
        : false,
    pointer: true
  });
  if (to) {
    return (
      <NavItem>
        <NavLink tag={Link} to={to} className={className} {...rest}>
          {children}
        </NavLink>
      </NavItem>
    );
  } else {
    return (
      <NavItem>
        <NavLink onClick={() => onClick(tab)} className={className} {...rest}>
          {children}
        </NavLink>
      </NavItem>
    );
  }
};
