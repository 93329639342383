import React, { useEffect } from 'react';
import {
  matchPath,
  Redirect,
  Route as BaseRoute,
  Switch,
  useLocation
} from 'react-router-dom';
import { CarrierDocumentsBanner } from 'src/components/CarrierDocuments';
import LoadingIndicator from 'src/components/LoadingIndicator';
import Sidebar from 'src/components/Sidebar';
import TermsModal from 'src/components/TermsModal';
import { useAppState } from 'src/lib/hooks';
import AcceptInvitation from 'src/pages/Auth/accept-invitation';
import Login from 'src/pages/Auth/login';
import PasswordForgot from 'src/pages/Auth/password-forgot';
import PasswordReset from 'src/pages/Auth/password-reset';
import Signup from 'src/pages/Auth/signup';
import TermsOfService from 'src/pages/Auth/terms-of-service';
import VerifySignup from 'src/pages/Auth/verify-signup';
import Dashboard from 'src/pages/Dashboard';
import Error from 'src/pages/Error';
import Guide from 'src/pages/Guide';
import LoadBoardPreview from 'src/pages/LoadBoardPreview/view';
import Reports from 'src/pages/Reports';
import Settings from 'src/pages/Settings';
import Billing from 'src/pages/Settings/billing';
import InviteUser from 'src/pages/Settings/invitation';
import Invitations from 'src/pages/Settings/invitations';
import Profile from 'src/pages/Settings/profile';
import EditUser from 'src/pages/Settings/user';
import Users from 'src/pages/Settings/users';
import Verification from 'src/pages/Settings/verification';
import ShipmentMatches from 'src/pages/ShipmentMatches';
import ShipmentMatch from 'src/pages/ShipmentMatches/view';
import ShipmentPreview from 'src/pages/ShipmentPreview/view';
import ShipmentRequests from 'src/pages/ShipmentRequests';
import NewShipmentRequest from 'src/pages/ShipmentRequests/new';
import ShipmentRequest from 'src/pages/ShipmentRequests/view';
import Shipments from 'src/pages/Shipments';
import Shipment from 'src/pages/Shipments/view';
// import ShipmentRequestMatches from 'src/pages/ShipmentRequests/matches';
import ShipmentTransfers from 'src/pages/ShipmentTransfers';
import Support from 'src/pages/Support';
import { useApp } from 'straightline-ui';
import { parseQuery } from 'straightline-utils/querystring';

// Add query params to the props.location
// which react-router does not suppoert automatically...
export const Route = (props) => {
  props.location.query = parseQuery(props.location.search, {
    ignoreQueryPrefix: true,
    strictNullHandling: true
  });
  return <BaseRoute {...props} />;
};

const ScrollToTopOnMount = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

export const PrivateRoutes = () => {
  // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
  // Even though we do polyfill via `react-app-polyfill`, there
  // are just still kinks with IE.
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;

  const app = useApp();
  const {
    state: { authenticated, prepped }
  } = useAppState();
  const location = useLocation();

  useEffect(() => {
    const redirect =
      location.pathname === '/' || location.pathname === '/not-found'
        ? null
        : location.pathname;

    if (!authenticated) {
      app.authenticate().catch(() => app.cleanup(redirect));
    }

    if (authenticated && !prepped) {
      app.prep().catch(() => app.cleanup(redirect));
    }
  }, [authenticated, prepped, location, app]);

  if (!authenticated || !prepped) {
    return (
      <div className="min-h-100vh d-flex align-items-center">
        <LoadingIndicator delay={500} text="Straightline" />
      </div>
    );
  }

  return (
    <>
      <Sidebar />
      <ScrollToTopOnMount />
      <TermsModal />
      <div className="main-content">
        {isIE && (
          <div className="p-3 bg-light">
            Your browser is not supported. Some features may not work or be
            available.
            <a
              href="https://browsehappy.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2"
            >
              Please Upgrade
            </a>
          </div>
        )}
        <CarrierDocumentsBanner />
        <Switch>
          {privateRoutes.map((route, index) => (
            <Route
              key={route.key || index}
              exact
              path={route.path}
              component={route.component}
            />
          ))}
          <Route render={(props) => <Error {...props} />} />
        </Switch>
      </div>
    </>
  );
};

export const PublicRoutes = () => {
  return (
    <Switch>
      {publicRoutes.map((route, index) => (
        <Route
          key={route.key || index}
          exact
          path={route.path}
          component={route.component}
        />
      ))}
      <Route render={(props) => <Error {...props} />} />
    </Switch>
  );
};

export function Routes() {
  const location = useLocation();
  const isPrivate = privateRoutes.find((route) => {
    const match = matchPath(location.pathname, {
      path: route.path,
      exact: true
    });
    return match;
  });

  if (isPrivate) {
    return <PrivateRoutes />;
  }

  return <PublicRoutes />;
}

export const privateRoutes = [
  {
    path: '/',
    component: () => <Redirect to="/dashboard" />
  },
  {
    path: '/dashboard',
    component: Dashboard,
    key: 'Dashboard'
  },
  {
    path: '/guide',
    component: Guide,
    key: 'Guide'
  },
  {
    path: '/settings',
    component: Settings,
    key: 'Settings'
  },
  {
    path: '/settings/profile',
    component: Profile,
    key: 'Profile'
  },
  {
    path: '/settings/billing',
    component: Billing,
    key: 'Billing'
  },
  {
    path: '/settings/verification',
    component: Verification,
    key: 'Verification'
  },
  {
    path: '/settings/users',
    component: Users,
    key: 'Users'
  },
  {
    path: '/settings/user',
    component: InviteUser,
    key: 'InviteUser'
  },
  {
    path: '/settings/invitations',
    component: Invitations,
    key: 'Invitations'
  },
  {
    path: '/settings/users/:id',
    component: EditUser,
    key: 'EditUser'
  },
  {
    path: '/support',
    component: Support,
    key: 'Support'
  },
  {
    path: '/shipments',
    component: Shipments,
    key: 'Shipments'
  },
  {
    path: '/shipments/:id',
    component: Shipment,
    key: 'Shipment'
  },
  {
    path: '/shipment-requests',
    component: ShipmentRequests,
    key: 'ShipmentRequests'
  },
  {
    path: '/shipment-requests/:id',
    component: ShipmentRequest,
    key: 'ShipmentRequest'
  },
  {
    path: '/shipment-request',
    component: NewShipmentRequest,
    key: 'NewShipmentRequest'
  },
  {
    path: '/shipment-matches',
    component: ShipmentMatches,
    key: 'ShipmentMatches'
  },
  {
    path: '/shipment-matches/:id',
    component: ShipmentMatch,
    key: 'ShipmentMatch'
  },
  {
    path: '/shipment-transfers',
    component: ShipmentTransfers,
    key: 'ShipmentTransfers'
  },
  {
    path: '/reports',
    component: Reports,
    key: 'Reports'
  }
];

export const publicRoutes = [
  {
    path: '/login',
    component: Login,
    key: 'login'
  },
  {
    path: '/forgot-password',
    component: PasswordForgot,
    key: 'PasswordForgot'
  },
  {
    path: '/reset-password',
    component: PasswordReset,
    key: 'PasswordReset'
  },
  {
    path: '/signup',
    component: Signup,
    key: 'Signup'
  },
  {
    path: '/verify-signup',
    component: VerifySignup,
    key: 'VerifySignup'
  },
  {
    path: '/terms-of-service',
    component: TermsOfService,
    key: 'TermsOfService'
  },
  {
    path: '/accept-invitation/:token',
    component: AcceptInvitation,
    key: 'AcceptInvitation'
  },
  {
    path: '/p/:id',
    component: LoadBoardPreview,
    key: 'LoadBoardPreview'
  },
  {
    path: '/s/:id',
    component: ShipmentPreview,
    key: 'ShipmentPreview'
  }
];
