import feathers from '@feathersjs/client';
import { ContextCacheMap } from 'feathers-fletching';
import { cache, stashCache } from 'src/feathers/hooks';
import { scriptLoader } from 'straightline-ui';
const { GeneralError } = feathers.errors;

class Service {
  constructor() {
    this.options = {
      cacheMap: new ContextCacheMap({
        max: 75,
        maxAge: 1000 * 60 * 60 * 24
      })
    };
  }

  async find(params) {
    if (!this.predictionsService) {
      await scriptLoader.load(process.env.REACT_APP_GOOGLE_MAPS_URL);
      this.predictionsService =
        new window.google.maps.places.AutocompleteService();
    }

    const googleParams = {
      componentRestrictions: { country: ['us', 'ca'] },
      ...params.query
    };
    return new Promise((resolve, reject) => {
      this.predictionsService.getPlacePredictions(
        googleParams,
        (response, status) => {
          if (status !== 'OK') {
            reject(
              new GeneralError(
                'There was an error from Google getting place predictions.',
                { status }
              )
            );
          } else {
            resolve(response);
          }
        }
      );
    });
  }
}

export default (app) => {
  app.use('google/place-predictions', new Service());
  const service = app.service('google/place-predictions');

  service.hooks({
    before: {
      all: [],
      find: [cache],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },

    after: {
      all: [],
      find: [cache, stashCache],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    },

    error: {
      all: [],
      find: [],
      get: [],
      create: [],
      update: [],
      patch: [],
      remove: []
    }
  });
};
