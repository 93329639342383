import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppState } from 'src/lib/hooks';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  ListGroup,
  ListGroupItem,
  Row,
  toast,
  UncontrolledButtonDropdown,
  useApp
} from 'straightline-ui';
import { DOCUMENT_TYPE } from 'straightline-utils/constants';

const { CARRIER_PACKET, W9, CERTIFICATE_OF_INSURANCE } = DOCUMENT_TYPE;

const documentTypes = [
  { value: CARRIER_PACKET, name: 'Carrier Packet' },
  { value: W9, name: 'W9' },
  { value: CERTIFICATE_OF_INSURANCE, name: 'Insurance Certificate' }
];

const queryKey = {
  query: {
    document_type: {
      $in: [CARRIER_PACKET, W9, CERTIFICATE_OF_INSURANCE]
    },
    $limit: 50,
    $sort: {
      created_at: -1
    }
  }
};

const hasPacketOrDocs = (docs, requiredDocTypes) => {
  if (docs.find((doc) => doc.document_type === CARRIER_PACKET)) {
    return true;
  }

  return requiredDocTypes.every((docType) =>
    docs.find((doc) => doc.document_type === docType)
  );
};

export default function CarrierDocuments() {
  const app = useApp();
  const [documentType, setDocumentType] = useState(null);

  const docsService = app.service('api/documents');

  const inputRef = useRef(null);

  const { result, isLoading, isError, error } = docsService.useFind(queryKey, {
    keepPreviousData: true
  });

  const createDoc = docsService.useCreate();

  const handleClick = (documentType) => {
    setDocumentType(documentType);
    inputRef.current.value = null;
    inputRef.current.click();
  };

  const handleImageChange = (event) => {
    const file = Array.from(event.target.files)[0];
    const formData = new FormData();
    formData.append('file', file);
    formData.append('document_type', documentType);
    // formData.append('entity_type', entity_type);
    // formData.append('entity_id', entity_id);
    createDoc
      .create(formData)
      .then(() => {
        setDocumentType(null);
        docsService.invalidateFind(queryKey);
      })
      .catch((err) => toast.errors(err));
  };

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Error error={error} />;
  }

  const docs = result.data;

  return (
    <Card>
      <div className="card-header">
        <Row className="align-items-center">
          <Col>
            <p className="card-header-title">Documents</p>
          </Col>
          <Col className="col-auto">
            <UncontrolledButtonDropdown
              className={createDoc.isLoading ? 'is-loading' : ''}
            >
              <DropdownToggle
                caret
                color="white"
                size="sm"
                disabled={createDoc.isLoading}
              >
                Upload
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem header>Document Type</DropdownItem>
                {documentTypes.map(({ name, value }) => {
                  return (
                    <DropdownItem
                      key={value}
                      onClick={() => handleClick(value)}
                    >
                      {name}
                    </DropdownItem>
                  );
                })}
                {/* <DropdownItem divider /> */}
              </DropdownMenu>
            </UncontrolledButtonDropdown>
            <input
              type="file"
              accept=".jpg, .png, .pdf, .jpeg"
              className="d-none"
              ref={inputRef}
              onChange={handleImageChange}
            />
          </Col>
        </Row>
      </div>
      <CardBody>
        <ListGroup className="list-group list-group-flush list my-n4">
          {!hasPacketOrDocs(docs, [W9, CERTIFICATE_OF_INSURANCE]) && (
            <UploadItem
              title="Carrier Packet"
              onClick={() => handleClick(CARRIER_PACKET)}
              description="Upload a file containing both a W9 and Certificate of Insurance"
              disabled={createDoc.isLoading}
            />
          )}
          {!hasPacketOrDocs(docs, [W9]) && (
            <UploadItem
              title="W9"
              onClick={() => handleClick(W9)}
              description="W9 is required if not uploading Carrier Packet"
              disabled={createDoc.isLoading}
            />
          )}
          {!hasPacketOrDocs(docs, [CERTIFICATE_OF_INSURANCE]) && (
            <UploadItem
              title="Cert. of Insurance"
              onClick={() => handleClick(CERTIFICATE_OF_INSURANCE)}
              description="Certificate of Insurance is required if not uploading Carrier Packet"
              disabled={createDoc.isLoading}
            />
          )}
          {docs.length > 0 &&
            docs.map((doc) => (
              <ListGroupItem className="px-0" key={doc.id}>
                <Row className="align-items-center">
                  <Col className="col-auto">
                    <a
                      href={doc.secure_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="avatar avatar-xl">
                        {doc.secure_url.includes('.pdf') ? (
                          <div className="avatar-img rounded bg-light d-flex align-items-center justify-content-center">
                            <Icon type="image" style={{ fontSize: 35 }} />
                          </div>
                        ) : (
                          <img
                            src={doc.secure_url}
                            alt="..."
                            className="avatar-img rounded"
                          />
                        )}
                      </div>
                    </a>
                  </Col>
                  <Col className="ml-n2">
                    <h4 className="card-title mb-1">
                      <a
                        href={doc.secure_url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {doc.original_name}
                      </a>
                    </h4>

                    {/* <p className="small text-muted mb-1">
                        {(doc.bytes / 1000).toFixed(2)} kb
                        .{doc.format}
                      </p> */}

                    <p className="small text-muted mb-1">
                      Type:{' '}
                      {
                        (
                          documentTypes.find(
                            (type) => doc.document_type === type.value
                          ) || {}
                        ).name
                      }
                    </p>
                  </Col>
                </Row>
              </ListGroupItem>
            ))}
        </ListGroup>
      </CardBody>
    </Card>
  );
}

const Loader = () => {
  return (
    <Card>
      <div className="card-header">
        <Row className="align-items-center">
          <Col>
            <p className="card-header-title">Documents</p>
          </Col>
          <Col className="col-auto">
            <Button
              className="dropdown-toggle"
              color="white"
              size="sm"
              disabled={true}
            >
              Upload
            </Button>
          </Col>
        </Row>
      </div>
      <CardBody>
        <ListGroup className="list-group list-group-flush list my-n4">
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <div className="avatar avatar-xl">
                  <div className="avatar-img rounded bg-light d-flex align-items-center justify-content-center">
                    <Icon type="image" style={{ fontSize: 35 }} />
                  </div>
                </div>
              </Col>
              <Col className="ml-n2">
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 18, width: 95 }}
                />
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 14, width: 75 }}
                />
              </Col>
            </Row>
          </ListGroupItem>
          <ListGroupItem className="px-0">
            <Row className="align-items-center">
              <Col className="col-auto">
                <div className="avatar avatar-xl">
                  <div className="avatar-img rounded bg-light d-flex align-items-center justify-content-center">
                    <Icon type="image" style={{ fontSize: 35 }} />
                  </div>
                </div>
              </Col>
              <Col className="ml-n2">
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 18, width: 95 }}
                />
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 14, width: 75 }}
                />
              </Col>
            </Row>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

const Error = ({ error }) => {
  return (
    <Card>
      <div className="card-header">
        <Row className="align-items-center">
          <Col>
            <p className="card-header-title">Documents</p>
          </Col>
          <Col className="col-auto">
            <Button
              className="dropdown-toggle"
              color="white"
              size="sm"
              disabled={true}
            >
              Upload
            </Button>
          </Col>
        </Row>
      </div>
      <CardBody>
        <div className="text-center">{error.message}</div>
      </CardBody>
    </Card>
  );
};

const UploadItem = ({ title, onClick, description, disabled }) => {
  return (
    <ListGroupItem className="px-0">
      <Row className="align-items-center">
        <Col className="col-auto">
          <div className="avatar avatar-xl">
            <div className="avatar-img rounded bg-light d-flex align-items-center justify-content-center">
              <Icon type="file-text" style={{ fontSize: 35 }} />
            </div>
          </div>
        </Col>
        <Col className="ml-n2">
          <h3 className="mb-1 text-primary">{title}</h3>
          <p className="small text-muted mb-1">{description}</p>
        </Col>
        <Col className="col-auto">
          <Button
            onClick={onClick}
            size="sm"
            color="primary"
            disabled={disabled}
          >
            Upload
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};

export function CarrierDocumentsBanner() {
  const location = useLocation();
  const app = useApp();
  const isShowing = !location.pathname.includes('/guide');
  const {
    state: { documents },
    setInState
  } = useAppState();

  const { result } = app.service('api/documents').useFind(queryKey, {
    enabled: !documents,
    keepPreviousData: true
  });

  useEffect(() => {
    if (documents) {
      return;
    }
    if (!result?.data) {
      return;
    }
    if (hasPacketOrDocs(result.data, [W9, CERTIFICATE_OF_INSURANCE])) {
      setInState('documents', true);
    }
  }, [documents, result, setInState]);

  if (!isShowing || !result || documents) {
    return null;
  }

  if (hasPacketOrDocs(result.data, [W9, CERTIFICATE_OF_INSURANCE])) {
    return null;
  }

  return (
    <Container fluid className="bg-danger">
      <Row className="py-3 justify-content-between align-items-center mb-n2">
        <Col className="text-white mb-2">
          Please complete sign-up by uploading your company documents.
        </Col>
        <Col sm="auto" className="mb-2">
          <Button size="sm" color="white" to="/guide?step=2" block>
            Upload Documents
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
