import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    shipment_id: yup.string().id().label('Shipment Id').required(),
    date: yup.string().dateOnly().label('ETA').required(),
    time: yup
      .string()
      .trim()
      .label('Time')
      .test({
        name: 'time-string',
        exclusive: true,
        // eslint-disable-next-line no-template-curly-in-string
        message: '${label} must be a 24hr time format between 00:00 and 23:59',
        test: (value) => {
          if (value) {
            const regex = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
            // Below commented code is code used for matching in the UI
            // element that handles this input type. They are different
            // because the client UI requires an array of regexs so it
            // can "mask" the input as you are typing
            // const regex = new RegExp([
            //   /[0-2]/,
            //   value[0] ? /[0-3]/ : /[0-9]/,
            //   ':',
            //   /[0-5]/,
            //   /[0-9]/
            // ]);
            return regex.test(value);
          }
          return true;
        }
      })
      .required(),
    notes: yup.string().trim().nullable().label('Notes')
  });
};
