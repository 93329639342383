export const shipmentToShipmentRequest = (shipment) => {
  const { pickup_address, delivery_address, truck_type } = shipment;

  return {
    pickup_address: {
      ...pickup_address,
      name: `${pickup_address.city}, ${pickup_address.state}, ${pickup_address.country}`,
      radius: 50
    },
    delivery_address: {
      ...delivery_address,
      name: `${delivery_address.city}, ${delivery_address.state}, ${delivery_address.country}`,
      radius: 50
    },
    active: true,
    truck_types: [truck_type]
  };
};

export const queryToShipmentRequest = (query) => {
  return {
    pickup_address: query.pickup_address,
    delivery_address: query.delivery_address
  };
};
