import './style.scss';

import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
  PopoverBody,
  PopoverHeader,
  Progress,
  UncontrolledPopover
} from 'straightline-ui';
import {
  isCancelled,
  isDelivered,
  STATUSES
} from 'straightline-utils/constants';

// reactstrap Progress has a bug so we have to use
// manual BS DOM here
// https://github.com/reactstrap/reactstrap/issues/1847
const ProgressBar = ({
  animated,
  color,
  striped,
  value,
  children,
  className,
  ...rest
}) => {
  const progressBarClasses = classNames(
    'progress-bar',
    animated ? 'progress-bar-animated' : null,
    color ? `bg-${color}` : null,
    striped || animated ? 'progress-bar-striped' : null
  );
  return (
    <div
      className={progressBarClasses}
      style={{ width: `${value}%` }}
      {...rest}
    >
      {children}
    </div>
  );
};

const isClickable = (changeOptions, status_type) => {
  return changeOptions.includes(status_type);
};

const getOrderedStatuses = (currentStatus, changeOptions, trim, unshift) => {
  return [...STATUSES]
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .filter((status) => {
      if (isCancelled(status)) {
        return false;
      }
      if (isClickable(changeOptions, status.value)) {
        return true;
      }
      if (status.value === currentStatus.value) {
        return true;
      }
      if (unshift && !trim) {
        return status.order >= currentStatus.order;
      }
      if (trim && !unshift) {
        return status.order <= currentStatus.order;
      }
      if (trim && unshift) {
        return false;
      }
      return true;
    });
};

export default function StatusProgress({
  shipment,
  trim,
  trimFlat,
  unshift,
  unshiftFlat,
  onClick
}) {
  const idPrefix = useMemo(() => {
    return (
      'a' + (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    );
  }, []);

  const changeOptions = shipment.status_change_options;
  const currentStatus = shipment.status;

  const orderedStatuses = getOrderedStatuses(
    currentStatus,
    changeOptions,
    trim,
    unshift
  );

  const width = 100 / orderedStatuses.length;

  return (
    <Progress
      className={`progress-status ${trimFlat ? 'progress-trim-flat' : ''} ${
        unshiftFlat ? 'progress-unshift-flat' : ''
      }`}
      multi
    >
      {orderedStatuses.map((status) => {
        const popoverId = `${idPrefix}-${status.value}`;
        const clickable = onClick && isClickable(changeOptions, status.value);
        const past = status.order < currentStatus.order;
        const current = status.value === currentStatus.value;
        // const delivered = isDelivered(status);
        const shipmentDelivered = isDelivered(shipment);
        const shipmentCancelled = isCancelled(shipment);

        let color = 'light';
        if (shipmentDelivered) {
          color = 'success';
        } else if (shipmentCancelled) {
          color = 'danger';
        } else if (clickable) {
          color = 'clickable';
        } else if (current) {
          color = 'current';
        } else if (past) {
          color = 'past';
        }

        return (
          <ProgressBar
            key={status.value}
            id={popoverId}
            value={width}
            // animated={current && !delivered}
            // striped={past && !clickable}
            striped={current && !shipmentDelivered}
            color={color}
            onClick={() => clickable && onClick(status)}
          >
            {status.label}
            <UncontrolledPopover
              trigger="hover"
              placement="bottom"
              target={popoverId}
              // style={{ minWidth: 200 }}
            >
              <PopoverHeader>{status.label}</PopoverHeader>
              <PopoverBody>
                {status.description}
                {/* {clickable && (
                            <div className="mt-2">
                              <p className="text-dark">
                                Set the status to "{status.label}"
                              </p>
                              <Button color="primary">Set Status</Button>
                            </div>
                          )} */}
              </PopoverBody>
            </UncontrolledPopover>
          </ProgressBar>
        );
      })}
    </Progress>
  );
}
