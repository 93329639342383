import './style.scss';

import React, { useState } from 'react';
import { DirectionsRenderer, Marker } from 'react-google-maps';
import greenDot from 'src/images/green-map-dot.png';
import { Button, Col, Icon, Row } from 'straightline-ui';
import { STATUS } from 'straightline-utils/constants';
import { formatDistanceToNow } from 'straightline-utils/time';

import Map from '../Map';

// const LoadingMap = ({ height = 300 }) => {
//   return <div style={{ height }} />;
// };

const ErrorMap = ({ height = 300, error }) => {
  return (
    <div
      style={{ height }}
      className="d-flex align-items-center justify-content-center"
    >
      <p className="text-danger">
        {(error && error.message) || 'There was an unexpected error'}
      </p>
    </div>
  );
};

const BasicMap = ({ expanded, toggleExpanded, route }) => {
  if (!route) {
    return <ErrorMap />;
  }

  return (
    <React.Fragment>
      <Map
        containerClassName={`map-header-preview-container ${
          expanded ? 'open' : ''
        }`}
      >
        <DirectionsRenderer directions={route.directions} />
      </Map>
      <Expand expanded={expanded} onClick={toggleExpanded} />
    </React.Fragment>
  );
};

const LiveTracking = ({ currentLocation }) => {
  return (
    <div className="bg-white p-3 rounded shadow mb-2">
      <Row className="align-items-center">
        <Col xs="auto">
          <img src={greenDot} alt="Green Dot" />
        </Col>
        <Col xs="auto" className="pl-0">
          <p className="font-weight-bold mb-0">Live Tracking</p>
          <p className="mb-0">
            {formatDistanceToNow(currentLocation.carrier_timestamp)}
          </p>
        </Col>
      </Row>
    </div>
  );
};

const ReCenter = ({ onClick }) => {
  return (
    <Button
      color="white"
      size="sm"
      className="shadow animated slideInLeft faster"
      onClick={onClick}
    >
      <Icon type="target" className="text-success font-weight-bold mr-2" />
      Recenter
    </Button>
  );
};

const Expand = ({ onClick, expanded }) => {
  return (
    <Button
      color="white"
      size="sm"
      className="btn-collapse shadow"
      onClick={onClick}
    >
      {expanded ? 'Collapse' : 'Expand'}
    </Button>
  );
};

// Note the only difference between InTransitMap and
// PrePickupMap is the DirectionsRendered color
// options
const InTransitMap = ({ shipment }) => {
  // const app = useApp();
  const [dragged, setDragged] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const setIsDragged = () => {
    if (!dragged) {
      setDragged(true);
    }
  };

  const unsetDragged = () => {
    setDragged(false);
  };

  // if (!shipment.directions || !shipment.tracking) {
  //   return <LoadingMap />;
  // }

  const currentLocation = shipment.tracking[0];
  const route = shipment.route;

  if (!currentLocation) {
    return (
      <BasicMap
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        route={route}
      />
    );
  }

  return (
    <React.Fragment>
      <Map
        containerClassName={`map-header-preview-container ${
          expanded ? 'open' : ''
        }`}
        center={dragged ? undefined : currentLocation.position}
        zoom={dragged ? undefined : 13}
        onDrag={setIsDragged}
      >
        {route && (
          <DirectionsRenderer
            options={{
              preserveViewport: true,
              polylineOptions: {
                strokeColor: '#00d97e',
                strokeWeight: 6,
                strokeOpacity: 0.6,
                zIndex: 100
              }
            }}
            directions={route.directions}
          />
        )}
        <Marker
          position={currentLocation.position}
          icon={greenDot}
          title="Current Location"
        />
      </Map>
      <div className="controls">
        <LiveTracking currentLocation={currentLocation} />
        {dragged && <ReCenter onClick={unsetDragged} />}
      </div>
      <Expand expanded={expanded} onClick={toggleExpanded} />
    </React.Fragment>
  );
};

const PrePickupMap = ({ shipment }) => {
  // const app = useApp();
  const [dragged, setDragged] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const setIsDragged = () => {
    if (!dragged) {
      setDragged(true);
    }
  };

  const unsetDragged = () => {
    setDragged(false);
  };

  // if (!shipment.directions || !shipment.tracking) {
  //   return <LoadingMap />;
  // }

  const currentLocation = shipment.tracking[0];
  const route = shipment.route;

  if (!currentLocation) {
    return (
      <BasicMap
        expanded={expanded}
        toggleExpanded={toggleExpanded}
        route={route}
      />
    );
  }

  return (
    <React.Fragment>
      <Map
        containerClassName={`map-header-preview-container ${
          expanded ? 'open' : ''
        }`}
        center={dragged ? undefined : currentLocation.position}
        zoom={dragged ? undefined : 13}
        onDrag={setIsDragged}
      >
        {route && (
          <DirectionsRenderer
            options={{ preserveViewport: true }}
            directions={route.directions}
          />
        )}
        <Marker
          position={currentLocation.position}
          icon={greenDot}
          title="Current Location"
        />
      </Map>
      <div className="controls">
        <LiveTracking currentLocation={currentLocation} />
        {dragged && <ReCenter onClick={unsetDragged} />}
      </div>
      <Expand expanded={expanded} onClick={toggleExpanded} />
    </React.Fragment>
  );
};

const DefaultMap = ({ shipment }) => {
  // const app = useApp();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded(!expanded);

  const route = shipment.route;

  return (
    <BasicMap
      expanded={expanded}
      toggleExpanded={toggleExpanded}
      route={route}
    />
  );
};

const statusMaps = {
  [STATUS.MATCHED]: DefaultMap,
  [STATUS.BOOKED]: DefaultMap,
  [STATUS.DISPATCHED]: PrePickupMap,
  [STATUS.CANCELLED]: DefaultMap,
  [STATUS.AT_SHIPPER]: PrePickupMap,
  [STATUS.IN_TRANSIT]: InTransitMap,
  [STATUS.AT_CONSIGNEE]: InTransitMap,
  [STATUS.DELIVERED]: DefaultMap
};

export default function MapHeader(props) {
  if (!props.shipment.route || !props.shipment.tracking) {
    return (
      <div className="map-header-preview">
        <ErrorMap />
      </div>
    );
  }
  const StatusMap = statusMaps[props.shipment.status_type];
  return (
    <div className="map-header-preview">
      <StatusMap {...props} />
    </div>
  );
}
