import React from 'react';
import { Col, Row } from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';

export default function ShipmentBanner({ shipment }) {
  const offer =
    shipment?.offers &&
    [...shipment.offers].sort((a, b) => {
      return a.updated_at < b.updated_at ? 1 : -1;
    })[0];
  return (
    <Row className="justify-content-between mb-n2">
      <Col xs="auto" className="mb-2">
        {offer && offer.accepted_at ? (
          <div className="d-flex">
            <del
              className="h1 mb-0 text-primary mr-3"
              style={{ fontSize: 35, lineHeight: 1, opacity: 0.65 }}
            >
              {formatMoney(shipment.rate.price.carrier_price, {
                precision: 0
              })}
            </del>
            <div
              className="h1 mb-0 text-primary"
              style={{ fontSize: 35, lineHeight: 1 }}
            >
              {formatMoney(offer.amount, {
                precision: 0
              })}
            </div>
          </div>
        ) : (
          <div
            className="h1 mb-0 text-primary"
            style={{ fontSize: 35, lineHeight: 1 }}
          >
            {formatMoney(shipment.rate.price.carrier_price, { precision: 0 })}
          </div>
        )}
      </Col>
      <Col xs="auto" className="text-right mb-2">
        <div className="text-secondary">
          {shipment.truck.label}
          {shipment.load_weight && (
            <React.Fragment>
              <span className="text-muted"> &#9679; </span>
              {`${
                shipment.load_weight >= 1000
                  ? (shipment.load_weight / 1000).toFixed(1) + 'k'
                  : shipment.load_weight.toFixed()
              } lbs`}
            </React.Fragment>
          )}
        </div>
        <div className="text-secondary">
          {shipment.travel.miles} mi
          <span className="text-muted"> &#9679; </span>
          {formatMoney(shipment.cost_per_mile)}/mi
        </div>
      </Col>
    </Row>
  );
}
