import { authenticateClient } from 'src/feathers/hooks';
import { validateSchema } from 'straightline-utils/hooks';

export default {
  before: {
    create: [authenticateClient, validateSchema]
  },
  after: {
    create: []
  },
  error: {
    create: []
  }
};
