import hooks from './verification.hooks';
import createSchema from './verification.schema';

export default (app) => {
  const service = app.service('api/verification');
  const { schema, company, bankAccount } = createSchema(app);
  service.schema = schema;
  service.companySchema = company;
  service.bankAccountSchema = bankAccount;
  service.hooks(hooks);
};
