import React from 'react';
import CompanyForm from 'src/components/CompanyForm';
import SettingsHeader from 'src/components/SettingsHeader';
import { useAppState } from 'src/lib/hooks';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Icon,
  Row,
  toast,
  useApp
} from 'straightline-ui';

export default function Verification({ history }) {
  const app = useApp();
  const {
    state: { carrier }
  } = useAppState();

  const handleOnComplete = () => {
    // Refresh the carrier in state so it now has
    // a stripe_account_id after verification
    return app
      .service('api/carrier')
      .find()
      .then((updatedCarrier) => {
        app.get('stateContainer').setInState('carrier', updatedCarrier);
        toast.success(
          'Please connect your bank account to finish enabling payouts'
        );
        history.push('/settings/billing');
      })
      .catch(toast.errors);
  };

  if (!carrier.stripe_account_id) {
    return <Form handleOnComplete={handleOnComplete} />;
  }

  const { result: verification } = app.service('api/verification').useFind();

  if (!verification) {
    return (
      <Container fluid className="pb-5">
        <SettingsHeader activeTab="verification" />
        <Row className="mb-5">
          <Col xl="6">
            <Card>
              <CardBody>
                <div
                  className="bg-light rounded float-right"
                  style={{ height: 22, width: 75 }}
                />

                <div
                  className="bg-light rounded mb-3"
                  style={{ height: 29, width: 78 }}
                />
                <div className="bg-light rounded mb-2" style={{ height: 22 }} />
                <div className="bg-light rounded mb-4" style={{ height: 22 }} />
                <div className="bg-light rounded mb-2" style={{ height: 22 }} />
                <div className="bg-light rounded" style={{ height: 22 }} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  const currently_due =
    verification.requirements && verification.requirements.currently_due;

  if (!currently_due || currently_due.length === 0) {
    return <Verified />;
  }

  if (currently_due.includes('external_account')) {
    // return <button onClick={handleOnComplete}>Click me</button>;
    return <LinkBank />;
  }

  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="verification" />
      <Row className="mb-5">
        <Col xl="6">
          <Card>
            <CardBody>
              <Badge color="secondary" className="float-right">
                <Icon type="check-circle" className="mr-2" />
                Pending
              </Badge>
              <p className="lead text-secondary">We are working on it!</p>
              <p className="mb-0">
                Your verification process is taking longer than expected, we may
                contact you to collect more information about your business as
                needed.
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const LinkBank = () => {
  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="verification" />
      <Row className="mb-5">
        <Col xl="6">
          <Card>
            <CardBody>
              <Badge color="secondary" className="float-right">
                <Icon type="check-circle" className="mr-2" />
                Pending Bank Account
              </Badge>
              <p className="lead text-secondary">Almost there!</p>
              <p>
                Straightline uses <a href="https://www.stripe.com">Stripe</a> to
                make sure you get paid on time and to keep your personal bank
                and details secure. Please connect a bank encount to continue.
              </p>
              <Button to="/settings/billing" color="primary">
                Link Bank Account
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Verified = () => {
  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="verification" />
      <Row className="mb-5">
        <Col xl="6">
          <Card>
            <CardBody>
              <Badge color="success" className="float-right">
                <Icon type="check-circle" className="mr-2" />
                Verified
              </Badge>
              <p className="lead text-success">Good to go!</p>
              <p>
                Although you are currently in good standing, we may contact you
                to collect more information about your business as needed.
              </p>
              <p className="text-muted mb-0">
                Once you have been verified, you cannot change your company
                information. If your business name, EIN, etc has changed, please
                contact us at {process.env.REACT_APP_SUPPORT_PHONE}
              </p>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

const Form = ({ handleOnComplete }) => {
  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="verification" />
      <Row className="mb-5">
        <Col xl="5" lg="6" xs={{ order: 2 }} md={{ order: 1 }}>
          <CompanyForm onComplete={handleOnComplete} />
        </Col>
        <Col xl="6" xs={{ order: 1 }} md={{ order: 2 }}>
          <p className="text-muted mt-4">
            To enable payouts, we need to verify your company and collect
            information about your business.
          </p>
          <p className="text-muted">
            Straightline uses <a href="https://www.stripe.com">Stripe</a> to
            make sure you get paid on time and to keep your personal bank and
            details secure. By enabling payouts, you agree to{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://stripe.com/connect-account/legal"
            >
              Stripe Connected Account Agreement
            </a>
            .
          </p>
        </Col>
      </Row>
    </Container>
  );
};
