import React, { useState } from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  Label,
  Modal,
  ModalBody,
  toast,
  useApp
} from 'straightline-ui';
import { ENTITY_TYPE } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

// When given a child function, the component's open state is
// managed internally. This is nice because you basically just
// supply the button and the open state is managed. But, its
// also trying to mount the modal markup right next to your
// button, which can clash when trying to use this in dropdowns,
// etc. So, don't pass children and pass isOpen to have just
// a modal that is controlled externally

export default function ShareModal({ children, isOpen, toggle, shipment }) {
  const [isInternalOpen, setIsOpen] = useState(false);
  const toggleInternal =
    toggle ||
    function () {
      setIsOpen(!isInternalOpen);
    };

  const open = children ? isInternalOpen : isOpen;

  return (
    <React.Fragment>
      {children && children(toggleInternal)}
      <Modal
        isOpen={open}
        toggle={toggleInternal}
        className="mx-auto"
        style={{ maxWidth: 425, width: '95%' }}
      >
        {() => <ShareModalBody toggle={toggleInternal} shipment={shipment} />}
      </Modal>
    </React.Fragment>
  );
}

function ShareModalBody({ toggle, shipment }) {
  const app = useApp();

  const { result, error, isLoading } = app
    .service('api/shipment-shares')
    .useFindOne(
      { query: { type: ENTITY_TYPE.CARRIER }, shipment_id: shipment.id },
      { keepPreviousData: true }
    );

  const shareUrl = result
    ? `${process.env.REACT_APP_CARRIER_APP_URL}/s/${result.id}`
    : '';

  const handleSubmit = (data, formik) => {
    app
      .service('api/contact')
      .create({
        service: 'api/shipments',
        service_id: shipment.id,
        emails: data.emails
          ? data.emails.split(',').filter(Boolean)
          : undefined,
        phones: data.phones
          ? data.phones.split(',').filter(Boolean)
          : undefined,
        buttonUrl: shareUrl,
        buttonText: 'View Load',
        subject: `View Load Details`,
        text:
          data.text ||
          `You have been invited to view load details for Straightline #${shipment.id}. Follow the link to get instant access to all shipment information.`,
        header: `${shipment.pickup_address.city} -> ${shipment.delivery_address.city}`,
        subHeader: `${formatDate(shipment.pickup_date)} - ${formatDate(
          shipment.due_date
        )}`
      })
      .then(() => {
        toast.success(`Message Sent!`);
        formik.resetForm();
        toggle();
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  if (error) {
    return (
      <ModalBody>
        <h2>Share Shipment</h2>
        <p className="text-danger">
          {error.message || 'There was an unexpected error. Please try again.'}
        </p>
      </ModalBody>
    );
  }

  if (!isLoading && !result) {
    return (
      <ModalBody>
        <h2>Share Shipment</h2>
        <p className="text-danger">
          Could not find a Share URL for this shipment.
        </p>
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <h2>Share Shipment</h2>
      <p>
        Copy the link below. Or, enter a comma separated list of emails or phone
        numbers to share this shipment link. People with this link will see a
        non-editable and generic view of this shipment without having to login
        or signup.
      </p>
      <p className="animated fadeIn fast">
        {result && (
          <a href={shareUrl} target="_blank" rel="noreferrer">
            {shareUrl}
          </a>
        )}
      </p>
      <Formik
        initialValues={{ emails: '', phones: '', text: '' }}
        onSubmit={handleSubmit}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <FormGroup>
                <Label>Emails</Label>
                <FormInput name="emails" disabled={isSubmitting} />
                <FormFeedback name="message" />
              </FormGroup>
              <FormGroup>
                <Label>Phones</Label>
                <FormInput name="phones" disabled={isSubmitting} />
                <FormFeedback name="message" />
              </FormGroup>
              <FormGroup>
                <Label>Message</Label>
                <FormInput
                  name="text"
                  type="textarea"
                  placeholder="100 characters max"
                  rows="4"
                  disabled={isSubmitting}
                />
                <FormFeedback name="message" />
              </FormGroup>
              <div className="d-flex justify-content-between">
                <div>
                  <Button
                    color="white"
                    className="mr-3"
                    disabled={isSubmitting}
                    type="button"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    className={isSubmitting ? 'is-loading' : ''}
                  >
                    <Icon type="send" className="mr-2" />
                    Submit
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </ModalBody>
  );
}
