import React from 'react';
import { STATUS_COLORS } from 'src/lib/shipment-statuses';
import {
  Badge,
  Card,
  Col,
  Icon,
  Link,
  ListGroup,
  ListGroupItem,
  Row,
  useApp
} from 'straightline-ui';
import { formatDistanceToNow } from 'straightline-utils/time';

export default function StatusChangesCard() {
  const app = useApp();
  const {
    result: statusChanges,
    isLoading,
    isError
  } = app.service('api/shipment-status-changes').useFind(
    {
      query: {
        $limit: 5,
        $sort: { created_at: -1 }
      }
    },
    { keepPreviousData: true, refetchInterval: 60000 }
  );

  if (isLoading || isError || !statusChanges?.total) {
    return <Loader />;
  }

  return (
    <Card className="card-fill">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <h3 className="card-header-title">Activity</h3>
        </div>
      </div>
      <ListGroup className="list-group-flush">
        {statusChanges.data.map((statusChange) => (
          <ListGroupItem
            key={statusChange.id}
            tag={Link}
            to={`/shipments/${statusChange.shipment_id}`}
            className="list-group-item-action"
            style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}
          >
            <Row>
              <Col xs="auto">
                <div className="avatar avatar-sm">
                  <div
                    className={`avatar-title font-size-lg rounded-circle bg-${
                      STATUS_COLORS[statusChange.to_status_type]
                    } text-white`}
                  >
                    <Icon type="truck" />
                  </div>
                </div>
              </Col>
              <Col className="ml-n2">
                <h5 className="mb-1">
                  Shipment {statusChange.shipment_id} is now{' '}
                  <Badge
                    style={{ fontSize: '100%' }}
                    color={`soft-${STATUS_COLORS[statusChange.to_status_type]}`}
                  >
                    {statusChange.to_status.label}
                  </Badge>
                </h5>

                <small className="text-muted">
                  {formatDistanceToNow(statusChange.created_at)}
                </small>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Card>
  );
}

const Loader = () => {
  const loaderLength = [1, 2, 3, 4, 5];
  return (
    <Card className="card-fill">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <h3 className="card-header-title">Activity</h3>
        </div>
      </div>
      <ListGroup className="list-group-flush">
        {loaderLength.map((item) => (
          <ListGroupItem
            key={item}
            style={{ paddingLeft: '1.25rem', paddingRight: '1.25rem' }}
          >
            <Row>
              <Col xs="auto">
                <div className="avatar avatar-sm">
                  <div className="avatar-title font-size-lg rounded-circle bg-light text-white">
                    {/* <Icon type="truck" /> */}
                  </div>
                </div>
              </Col>
              <Col className="ml-n2">
                <h5 className="mb-1">
                  <div
                    className="bg-light rounded"
                    style={{ height: 18, width: 200 }}
                  />
                </h5>

                <div
                  className="bg-light rounded"
                  style={{ height: 16, width: 60 }}
                />
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Card>
  );
};
