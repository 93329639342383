import React, { useState } from 'react';
import { TablePaginationBtns } from 'src/components/PaginationBtns';
import ShipmentBanner from 'src/components/ShipmentBanner';
import addressImg from 'src/images/address.svg';
import { shipmentToShipmentRequest } from 'src/lib';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  Link,
  ListGroup,
  ListGroupItem,
  MapImage,
  MarkerImage,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  toast,
  useApp
} from 'straightline-ui';
import { formatMoney, roundNumberToLabel } from 'straightline-utils/accounting';
import { formatDate } from 'straightline-utils/time';

const Loader = ({ error }) => {
  const loaderLength = [1, 2, 3, 4, 5];
  return (
    <React.Fragment>
      <ScreenSize xs sm>
        {() => (
          <Card className="mx-n3">
            {error && (
              <div
                className="d-flex align-items-center justify-content-center rounded position-absolute h-100 w-100"
                style={{ backgroundColor: 'rgba(237, 242, 249, 0.5)' }}
              >
                <div className="lead text-danger">{error.message}</div>
              </div>
            )}
            <ListGroup>
              {!error &&
                loaderLength.map((item, index) => {
                  return (
                    <ListGroupItem key={index}>
                      <Row className="justify-content-between">
                        <Col xs="auto">
                          <div
                            className="bg-light rounded"
                            style={{ height: 33, width: 100 }}
                          />
                        </Col>
                        <Col xs="auto">
                          <div className="text-secondary d-flex align-items-center">
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 30 }}
                            />
                            <div className="text-muted"> &#9679; </div>
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 40 }}
                            />
                          </div>
                          <div className="text-secondary d-flex align-items-center">
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 40 }}
                            />
                            <div className="text-muted"> &#9679; </div>
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 30 }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <ListGroup className="list-group list-group-flush list-group-activity my-3">
                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto" className="bg-white text-secondary">
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 50 }}
                              />
                            </Col>
                          </Row>
                        </ListGroupItem>

                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto">
                              <div className="avatar avatar-sm">
                                <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                  <Icon type="truck" />
                                </div>
                              </div>
                            </Col>
                            <Col className="ml-n2">
                              <div>
                                <div
                                  className="bg-light rounded mb-2"
                                  style={{ height: 18, width: 90 }}
                                />
                                <div>
                                  <div
                                    className="bg-light rounded"
                                    style={{ height: 16, width: 170 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto">
                              <div className="avatar avatar-sm">
                                <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                  <Icon type="map-pin" />
                                </div>
                              </div>
                            </Col>
                            <Col className="ml-n2">
                              <div>
                                <div
                                  className="bg-light rounded mb-2"
                                  style={{ height: 18, width: 170 }}
                                />
                                <div
                                  className="bg-light rounded"
                                  style={{ height: 18, width: 170 }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </ListGroup>

                      <div
                        className="bg-light rounded"
                        style={{ height: 18, width: 170 }}
                      />
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Card>
        )}
      </ScreenSize>
      <ScreenSize md lg xl>
        {() => (
          <Card>
            <div className="table-responsive">
              <Table className="table table-nowrap card-table">
                <TableHead>
                  <TableRow>
                    <TableHeader className="px-4">Payout</TableHeader>
                    <TableHeader className="px-4">Pickup</TableHeader>
                    <TableHeader className="px-4">Delivery</TableHeader>
                    <TableHeader className="px-4">Travel</TableHeader>
                    <TableHeader className="px-4">Info</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody className="list">
                  {loaderLength.map((item, index) => (
                    <TableRow style={{ height: 79 }} key={index}>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 120 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 105 }}
                        />
                      </TableData>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 95 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 89 }}
                        />
                      </TableData>
                      <TableData
                        className="px-4"
                        style={{ width: '1%', position: 'relative' }}
                      >
                        <div
                          style={{ position: 'absolute', left: -12, top: 21 }}
                        >
                          <Icon
                            type="arrow-right text-light font-weight-bold"
                            style={{ fontSize: 25 }}
                          />
                        </div>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 95 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 89 }}
                        />
                      </TableData>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 42 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 32 }}
                        />
                      </TableData>
                      <TableData className="px-4">
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 60 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 170 }}
                        />
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {error && (
              <div
                className="d-flex align-items-center justify-content-center rounded position-absolute h-100 w-100"
                style={{ backgroundColor: 'rgba(237, 242, 249, 0.5)' }}
              >
                <div className="lead text-danger">{error.message}</div>
              </div>
            )}
          </Card>
        )}
      </ScreenSize>
    </React.Fragment>
  );
};

export function MatchesList({ matches, queryState }) {
  const { result, isLoading, isError, error, isPreviousData } = matches;
  const address = queryState.getInState('query.pickup_distance') || null;
  const city = address?.city;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Loader error={error} />;
  }

  if (result.total === 0) {
    return (
      <Card>
        <CardBody style={{ minHeight: 426 }}>
          <Row className="align-items-center h-100">
            <Col lg="6" className="text-center mb-4 mb-lg-0">
              {address ? (
                <MapImage
                  className="mw-100 rounded-top"
                  size="450x450"
                  zoom="6"
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                >
                  <MarkerImage
                    location={{
                      lat: address.latitude,
                      lng: address.longitude
                    }}
                    color="red"
                  />
                </MapImage>
              ) : (
                <div className="p-5 rounded bg-light">
                  <img
                    src={addressImg}
                    alt="Get Started"
                    className="img-fluid mb-4 mb-xl-0 animated fadeIn faster"
                  />
                </div>
              )}
            </Col>
            <Col lg="6">
              <h1>{city ? `Post Truck in ${city}` : 'Post Truck'}</h1>
              <p className="mb-4 text-muted lead">
                There are no loads matching your search criteria. Post your
                truck here and we will automatically notify you of any new loads
                that match.
              </p>
              <Button
                color="primary"
                to="/shipment-request"
                query={
                  address && {
                    initialValues: {
                      pickup_address: {
                        latitude: address.latitude,
                        longitude: address.longitude,
                        city: address.city,
                        state: address.state,
                        radius: 50
                      }
                    }
                  }
                }
                disabled={isPreviousData}
              >
                <Icon type="truck" className="mr-2" />
                {city ? `Post Truck in ${city}` : 'Post Truck'}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <ScreenSize xs sm>
        {() => (
          <MatchesListMobile
            result={result}
            queryState={queryState}
            isPreviousData={isPreviousData}
          />
        )}
      </ScreenSize>
      <ScreenSize md lg xl>
        {() => (
          <MatchesListDesktop
            result={result}
            queryState={queryState}
            isPreviousData={isPreviousData}
          />
        )}
      </ScreenSize>
    </React.Fragment>
  );
}

function MatchesListDesktop({ isPreviousData, result, queryState }) {
  return (
    <Card className={isPreviousData ? 'is-fetching' : ''}>
      <div className="table-responsive">
        <Table className="table table-nowrap card-table table-hover">
          <TableHead>
            <TableRow>
              <TableHeader className="px-3 px-xl-4">Payout</TableHeader>
              <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
              <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
              <TableHeader className="px-3 px-xl-4">Service</TableHeader>
              <TableHeader className="px-3 px-xl-4">Details</TableHeader>
              <TableHeader className="pr-3 pr-xl-4 pl-0 text-right">
                <TablePaginationBtns
                  total={result.total}
                  limit={10}
                  skip={queryState.getInState('query.$skip', 0)}
                  setSkip={($skip) =>
                    queryState.setInState('query.$skip', $skip)
                  }
                />
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {result.data.map((match) => {
              const offer = match.offers && match.offers[0];
              return (
                <TableRow
                  key={match.id}
                  // className={offer && offer.accepted_at ? 'bg-light' : ''}
                  to={`/shipment-matches/${match.id}`}
                >
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0 text-success font-weight-bold">
                      {formatMoney(match.rate.price.carrier_price)}
                      <span className="small text-muted"> total</span>
                    </p>
                    <p className="mb-0">
                      {formatMoney(match.cost_per_mile)}
                      <span className="small text-muted"> /mile</span>
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0">
                      {match.pickup_address.city}, {match.pickup_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.pickup_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ width: '1%', position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', left: -12, top: 21 }}>
                      <Icon
                        type="arrow-right text-success"
                        style={{ fontSize: 25 }}
                      />
                    </div>
                    <p className="mb-0">
                      {match.delivery_address.city},{' '}
                      {match.delivery_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.due_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    {/* <p className="mb-0">
                      {match.travel.miles} mi
                      <span className="small text-muted"> travel</span>
                    </p>
                    <p className="mb-0">
                      {match.pickup_distance ? (
                        <React.Fragment>
                          {match.pickup_distance} mi
                          <span className="small text-muted"> dead</span>
                        </React.Fragment>
                      ) : (
                        <span className="small text-muted">NA</span>
                      )}
                    </p> */}
                    <div>{match.rate.service_level.name}</div>
                    <div>
                      {match.truck.label}
                      <Icon type="dot" className="text-muted mx-1" />
                      {roundNumberToLabel(match.load_weight)} lbs
                      <Icon type="dot" className="text-muted mx-1" />
                      {match.travel.miles} mi
                      {/* <Icon type="dot" className="text-muted mx-1" />
                  {formatMoney(shipment.cost_per_mile)}/mi */}
                    </div>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ maxWidth: '1px' }}
                  >
                    <p className="mb-0 text-truncate">
                      {(() => {
                        if (!offer) {
                          return (
                            <span className="text-muted">
                              View load for more details
                            </span>
                          );
                        }
                        if (offer.accepted_at) {
                          return (
                            <span className="text-primary">
                              <span className="font-weight-bold">
                                {formatMoney(offer.amount)}
                              </span>{' '}
                              offer accepted!
                            </span>
                          );
                        }
                        return (
                          <span className="text-info">
                            <span>{formatMoney(offer.amount)}</span> offer being
                            reviewed
                          </span>
                        );
                      })()}
                    </p>
                  </TableData>
                  <TableData
                    className="pr-3 pr-xl-4 pl-0 text-right"
                    style={{ width: '1%' }}
                  >
                    {(() => {
                      if (offer && !offer.accepted_at) {
                        return (
                          <Badge color="soft-info" className="p-2">
                            <Icon type="dollar-sign" className="mr-2" />
                            Offer Pending
                          </Badge>
                        );
                      }

                      if (offer?.accepted_at) {
                        return (
                          <Badge color="soft-primary" className="p-2">
                            <Icon type="dollar-sign" className="mr-2" />
                            Offer Accepted
                          </Badge>
                        );
                      }

                      if (match.shipment_requests) {
                        return (
                          <Badge color="soft-secondary" className="p-2">
                            <Icon type="truck" className="mr-2" />
                            {match.shipment_requests} Truck Match
                            {match.shipment_requests === 1 ? '' : 'es'}
                          </Badge>
                        );
                      }

                      // return (
                      //   <Button
                      //     color="outline-primary"
                      //     size="sm"
                      //     onClick={(event) => {
                      //       event.stopPropagation();
                      //     }}
                      //   >
                      //     <Icon type="truck" className="mr-2" />
                      //     Post
                      //   </Button>
                      // );
                    })()}
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}

function MatchesListMobile({ isPreviousData, result, queryState }) {
  return (
    <Card className={`mx-n3 ${isPreviousData ? 'is-fetching' : ''}`}>
      <ListGroup flush>
        {result.total > 10 && (
          <ListGroupItem className="px-3">
            <div className="text-right">
              <TablePaginationBtns
                total={result.total}
                limit={10}
                skip={queryState.getInState('query.$skip', 0)}
                setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
              />
            </div>
          </ListGroupItem>
        )}
        {result.data.map((match) => {
          const offer = match.offers && match.offers[0];
          return (
            <ListGroupItem
              key={match.id}
              tag={Link}
              to={`/shipment-matches/${match.id}`}
              className="list-group-item-action px-3"
            >
              <ShipmentBanner shipment={match} />
              <ListGroup className="list-group list-group-flush list-group-activity my-3">
                {match.pickup_distance ? (
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto" className="bg-white text-secondary">
                        {match.pickup_distance} mi
                      </Col>
                      <Col className="ml-n2 text-secondary">deadhead</Col>
                    </Row>
                  </ListGroupItem>
                ) : null}
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="clipboard" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div className="font-weight-bold">
                        {match.rate.service_level.name}
                      </div>
                      <div>{match.rate.service_level.description}</div>
                      {match.rate.service_level.code === 'TEM' && (
                        <div className="font-weight-bold text-primary">
                          ** This shipment requires two drivers **
                        </div>
                      )}
                      <div>
                        <span className="text-secondary">SL#: </span>
                        {match.id}
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="truck" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div>
                        <div className="font-weight-bold">
                          {`${match.pickup_address.city}, ${match.pickup_address.state}`}
                        </div>
                        <div>
                          {formatDate(match.pickup_date, 'LLL d, yyyy')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="map-pin" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div>
                        <div className="font-weight-bold">
                          {`${match.delivery_address.city}, ${match.delivery_address.state}`}
                        </div>
                        <div>{formatDate(match.due_date, 'LLL d, yyyy')}</div>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>

              <div>
                {(() => {
                  if (offer && offer.accepted_at) {
                    return (
                      <div className="text-primary">
                        <span className="font-weight-bold">
                          {formatMoney(offer.amount)}
                        </span>{' '}
                        offer accepted!
                      </div>
                    );
                  }

                  if (offer && !offer.accepted_at) {
                    return (
                      <div className="text-info">
                        <span>{formatMoney(offer.amount)}</span> offer being
                        reviewed
                      </div>
                    );
                  }

                  if (match.shipment_requests) {
                    return (
                      <div className="text-secondary">
                        <Icon type="truck" className="mr-2" />
                        {match.shipment_requests} Truck Match
                        {match.shipment_requests === 1 ? '' : 'es'}
                      </div>
                    );
                  }

                  return (
                    <div className="text-muted">View load for more details</div>
                  );
                })()}
              </div>
            </ListGroupItem>
          );
        })}
        {result.total > 10 && (
          <ListGroupItem className="px-3">
            <div className="text-right">
              <TablePaginationBtns
                total={result.total}
                limit={10}
                skip={queryState.getInState('query.$skip', 0)}
                setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
              />
            </div>
          </ListGroupItem>
        )}
      </ListGroup>
    </Card>
  );
}

export function MoreMatchesList({ matches, queryState }) {
  const app = useApp();
  const { result, isLoading, isPreviousData } = matches;
  const [postingId, setPostingId] = useState(null);

  const postTruck = app.service('api/shipment-requests').useCreate();

  const handlePostTruck = async (shipment) => {
    setPostingId(shipment.id);
    postTruck
      .create(shipmentToShipmentRequest(shipment))
      .then((shipmentRequest) => {
        toast(
          <div className="p-3">
            <h2 className="text-dark">
              <Icon type="truck" className="mr-2" />
              Truck Posted!
            </h2>
            <div className="lead text-secondary mb-0">
              We have posted a truck from{' '}
              <span className="font-weight-bold">
                {shipment.pickup_address.city}, {shipment.pickup_address.state}
              </span>
              <Icon
                type="arrow-right"
                className="text-success font-weight-bold mx-2"
              />
              <span className="font-weight-bold">
                {shipment.delivery_address.city},{' '}
                {shipment.delivery_address.state}{' '}
              </span>
              . You can{' '}
              <Link to={`/shipment-requests/${shipmentRequest.id}`}>
                view and edit this truck
              </Link>{' '}
              anytime.
            </div>
          </div>,
          { autoClose: 10000, hideProgressBar: true }
        );

        setPostingId(null);
        app.service('api/shipment-matches').invalidateFind();
      })
      .catch((error) => {
        toast.errors(error);
      });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      {/* <Loader />  */}
      <Card
        style={
          isPreviousData
            ? {
                opacity: 0.5
              }
            : {}
        }
      >
        <div className="table-responsive">
          <Table className="table table-nowrap card-table">
            <TableHead>
              <TableRow>
                <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
                <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
                <TableHeader className="px-3 px-xl-4">Travel</TableHeader>
                <TableHeader className="px-3 px-xl-4">Deadhead</TableHeader>
                <TableHeader className="px-3 px-xl-4">Truck</TableHeader>
                <TableHeader className="pr-3 pr-xl-4 pl-0 text-right">
                  <TablePaginationBtns
                    total={result.total}
                    limit={5}
                    max={100}
                    skip={queryState.getInState('query.$skipMore', 0)}
                    setSkip={($skip) =>
                      queryState.setInState('query.$skipMore', $skip)
                    }
                  />
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody className="list">
              {result.data.map((match) => {
                return (
                  <TableRow key={match.id}>
                    <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                      <p className="mb-0">
                        {match.pickup_address.city},{' '}
                        {match.pickup_address.state}
                      </p>
                    </TableData>
                    <TableData
                      className="px-3 px-xl-4"
                      style={{ width: '1%', position: 'relative' }}
                    >
                      <div style={{ position: 'absolute', left: -12, top: 9 }}>
                        <Icon
                          type="arrow-right text-success"
                          style={{ fontSize: 25 }}
                        />
                      </div>
                      <p className="mb-0">
                        {match.delivery_address.city},{' '}
                        {match.delivery_address.state}
                      </p>
                    </TableData>
                    <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                      <p className="mb-0">{match.travel.miles} mi </p>
                    </TableData>
                    <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                      <p className="mb-0">
                        {match.pickup_distance ? (
                          <React.Fragment>
                            {match.pickup_distance} mi
                          </React.Fragment>
                        ) : (
                          <span className="small text-muted">NA</span>
                        )}
                      </p>
                    </TableData>
                    <TableData
                      className="px-3 px-xl-4"
                      style={{ width: '1px' }}
                    >
                      <Badge color="light">{match.truck.label}</Badge>
                    </TableData>
                    <TableData
                      className="px-3 px-xl-4 text-right"
                      style={{ width: '1%' }}
                    >
                      {match.shipment_requests ? (
                        <Button
                          to={`/shipment-requests`}
                          color="light"
                          size="sm"
                        >
                          <Icon type="truck" className="mr-2" />
                          {match.shipment_requests} Truck Match
                          {match.shipment_requests === 1 ? '' : 'es'}
                        </Button>
                      ) : (
                        <Button
                          color="outline-primary"
                          size="sm"
                          disabled={postTruck.isLoading}
                          className={postingId === match.id ? 'is-loading' : ''}
                          onClick={() => handlePostTruck(match)}
                        >
                          <Icon type="truck" className="mr-2" />
                          Post
                        </Button>
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Card>
    </React.Fragment>
  );
}
