import React from 'react';
import ShipmentBanner from 'src/components/ShipmentBanner';
import emptyImg from 'src/images/empty.svg';
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  Link,
  ListGroup,
  ListGroupItem,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow
} from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { formatDate } from 'straightline-utils/time';

const Loader = ({ error }) => {
  const loaderLength = [1, 2, 3, 4, 5];
  return (
    <React.Fragment>
      <ScreenSize xs sm>
        {() => (
          <Card className="mx-n3">
            {error && (
              <div
                className="d-flex align-items-center justify-content-center rounded position-absolute h-100 w-100"
                style={{ backgroundColor: 'rgba(237, 242, 249, 0.5)' }}
              >
                <div className="lead text-danger">{error.message}</div>
              </div>
            )}
            <ListGroup>
              {!error &&
                loaderLength.map((item, index) => {
                  return (
                    <ListGroupItem key={index}>
                      <Row className="justify-content-between">
                        <Col xs="auto">
                          <div
                            className="bg-light rounded"
                            style={{ height: 33, width: 100 }}
                          />
                        </Col>
                        <Col xs="auto">
                          <div className="text-secondary d-flex align-items-center">
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 30 }}
                            />
                            <div className="text-muted"> &#9679; </div>
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 40 }}
                            />
                          </div>
                          <div className="text-secondary d-flex align-items-center">
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 40 }}
                            />
                            <div className="text-muted"> &#9679; </div>
                            <div
                              className="bg-light rounded"
                              style={{ height: 14, width: 30 }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <ListGroup className="list-group list-group-flush list-group-activity my-3">
                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto" className="bg-white text-secondary">
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 50 }}
                              />
                            </Col>
                          </Row>
                        </ListGroupItem>

                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto">
                              <div className="avatar avatar-sm">
                                <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                  <Icon type="truck" />
                                </div>
                              </div>
                            </Col>
                            <Col className="ml-n2">
                              <div>
                                <div
                                  className="bg-light rounded mb-2"
                                  style={{ height: 18, width: 90 }}
                                />
                                <div>
                                  <div
                                    className="bg-light rounded"
                                    style={{ height: 16, width: 170 }}
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                        <ListGroupItem className="py-3">
                          <Row>
                            <Col xs="auto">
                              <div className="avatar avatar-sm">
                                <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                  <Icon type="map-pin" />
                                </div>
                              </div>
                            </Col>
                            <Col className="ml-n2">
                              <div>
                                <div
                                  className="bg-light rounded mb-2"
                                  style={{ height: 18, width: 170 }}
                                />
                                <div
                                  className="bg-light rounded"
                                  style={{ height: 18, width: 170 }}
                                />
                              </div>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      </ListGroup>

                      <div
                        className="bg-light rounded"
                        style={{ height: 18, width: 170 }}
                      />
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Card>
        )}
      </ScreenSize>
      <ScreenSize md lg xl>
        {() => (
          <Card>
            <div className="table-responsive">
              <Table className="table table-nowrap card-table">
                <TableHead>
                  <TableRow>
                    <TableHeader className="px-4">Payout</TableHeader>
                    <TableHeader className="px-4">Pickup</TableHeader>
                    <TableHeader className="px-4">Delivery</TableHeader>
                    <TableHeader className="px-4">Travel</TableHeader>
                    <TableHeader className="px-4">Info</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody className="list">
                  {loaderLength.map((item, index) => (
                    <TableRow style={{ height: 79 }} key={index}>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 120 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 105 }}
                        />
                      </TableData>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 95 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 89 }}
                        />
                      </TableData>
                      <TableData
                        className="px-4"
                        style={{ width: '1%', position: 'relative' }}
                      >
                        <div
                          style={{ position: 'absolute', left: -12, top: 21 }}
                        >
                          <Icon
                            type="arrow-right text-light font-weight-bold"
                            style={{ fontSize: 25 }}
                          />
                        </div>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 95 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 89 }}
                        />
                      </TableData>
                      <TableData className="px-4" style={{ width: '1%' }}>
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 42 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 32 }}
                        />
                      </TableData>
                      <TableData className="px-4">
                        <div
                          className="bg-light rounded mb-2"
                          style={{ height: 18, width: 60 }}
                        />
                        <div
                          className="bg-light rounded"
                          style={{ height: 18, width: 170 }}
                        />
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            {error && (
              <div
                className="d-flex align-items-center justify-content-center rounded position-absolute h-100 w-100"
                style={{ backgroundColor: 'rgba(237, 242, 249, 0.5)' }}
              >
                <div className="lead text-danger">{error.message}</div>
              </div>
            )}
          </Card>
        )}
      </ScreenSize>
    </React.Fragment>
  );
};

export function MatchesList({ matches, onClickEdit }) {
  const { result, isLoading, isError, error, isPreviousData } = matches;

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return <Loader error={error} />;
  }

  if (result.total === 0) {
    return (
      <Card>
        <CardBody style={{ minHeight: 390 }}>
          <Row className="align-items-center ">
            <Col md="6" className="text-center mb-4 mb-md-0">
              <div className="p-5">
                <img
                  src={emptyImg}
                  alt="No Loads"
                  className="img-fluid mb-4 mb-xl-0 animated fadeIn faster"
                />
              </div>
            </Col>
            <Col md="6">
              <h1>No Load Matches</h1>
              <p className="mb-4 text-muted lead">
                There are no loads matching this truck. Edit the truck or check
                back later. We are always adding new loads!
              </p>
              <Row className="mb-n3">
                <Col sm="6" md="auto" className="mb-3 pr-md-0">
                  <Button
                    type="button"
                    onClick={onClickEdit}
                    color="primary"
                    disabled={isPreviousData}
                    className="mr-3"
                    block
                  >
                    <Icon type="truck" className="mr-2" />
                    Edit Truck
                  </Button>
                </Col>
                <Col sm="6" md="auto" className="mb-3">
                  <Button to="/shipment-matches" color="primary" block>
                    <Icon type="clipboard" className="mr-2" />
                    View Load Board
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <ScreenSize xs sm>
        {() => <MatchesListMobile result={result} />}
      </ScreenSize>
      <ScreenSize md lg xl>
        {() => (
          <MatchesListDesktop result={result} isPreviousData={isPreviousData} />
        )}
      </ScreenSize>
    </React.Fragment>
  );
}

function MatchesListDesktop({ isPreviousData, result, queryState }) {
  return (
    <Card className={isPreviousData ? 'is-fetching' : ''}>
      <div className="table-responsive">
        <Table className="table table-nowrap card-table table-hover">
          <TableHead>
            <TableRow>
              <TableHeader className="px-3 px-xl-4">Payout</TableHeader>
              <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
              <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
              <TableHeader className="px-3 px-xl-4">Travel</TableHeader>
              <TableHeader className="px-3 px-xl-4">Info</TableHeader>
              <TableHeader className="pr-3 pr-xl-4 pl-0 text-right">
                {/* <TablePaginationBtns
                  total={result.total}
                  limit={5}
                  skip={queryState.getInState('query.$skip', 0)}
                  setSkip={($skip) =>
                    queryState.setInState('query.$skip', $skip)
                  }
                /> */}
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {result.data.map((match) => {
              const offer = match.offers && match.offers[0];
              return (
                <TableRow
                  key={match.id}
                  // className={offer && offer.accepted_at ? 'bg-light' : ''}
                  to={`/shipment-matches/${match.id}`}
                >
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0 text-success font-weight-bold">
                      {formatMoney(match.rate.price.carrier_price)}
                      <span className="small text-muted"> total</span>
                    </p>
                    <p className="mb-0">
                      {formatMoney(match.cost_per_mile)}
                      <span className="small text-muted"> /mile</span>
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0">
                      {match.pickup_address.city}, {match.pickup_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.pickup_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ width: '1%', position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', left: -12, top: 21 }}>
                      <Icon
                        type="arrow-right text-success"
                        style={{ fontSize: 25 }}
                      />
                    </div>
                    <p className="mb-0">
                      {match.delivery_address.city},{' '}
                      {match.delivery_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.due_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0">
                      {match.travel.miles} mi
                      <span className="small text-muted"> travel</span>
                    </p>
                    <p className="mb-0">
                      {match.pickup_distance ? (
                        <React.Fragment>
                          {match.pickup_distance} mi
                          <span className="small text-muted"> dead</span>
                        </React.Fragment>
                      ) : (
                        <span className="small text-muted">NA</span>
                      )}
                    </p>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ maxWidth: '1px' }}
                  >
                    <p className="mb-0">
                      <Badge color="light">
                        {match.truck.label.toUpperCase()}
                      </Badge>
                    </p>
                    <p className="mb-0 text-truncate">
                      {(() => {
                        if (!offer) {
                          return (
                            <span className="text-muted">
                              View load for more details
                            </span>
                          );
                        }
                        if (offer.accepted_at) {
                          return (
                            <span className="text-primary">
                              <span className="font-weight-bold">
                                {formatMoney(offer.amount)}
                              </span>{' '}
                              offer accepted!
                            </span>
                          );
                        }
                        return (
                          <span className="text-info">
                            <span>{formatMoney(offer.amount)}</span> offer being
                            reviewed
                          </span>
                        );
                      })()}
                    </p>
                  </TableData>
                  <TableData
                    className="pr-3 pr-xl-4 pl-0 text-right"
                    style={{ width: '1%' }}
                  >
                    {(() => {
                      if (offer && !offer.accepted_at) {
                        return (
                          <Badge color="soft-info" className="p-2">
                            <Icon type="dollar-sign" className="mr-2" />
                            Offer Pending
                          </Badge>
                        );
                      }

                      if (offer?.accepted_at) {
                        return (
                          <Badge color="soft-primary" className="p-2">
                            <Icon type="dollar-sign" className="mr-2" />
                            Offer Accepted
                          </Badge>
                        );
                      }

                      if (match.shipment_requests) {
                        return (
                          <Badge color="soft-secondary" className="p-2">
                            <Icon type="truck" className="mr-2" />
                            {match.shipment_requests} Truck Match
                            {match.shipment_requests === 1 ? '' : 'es'}
                          </Badge>
                        );
                      }

                      // return (
                      //   <Button
                      //     color="outline-primary"
                      //     size="sm"
                      //     onClick={(event) => {
                      //       event.stopPropagation();
                      //     }}
                      //   >
                      //     <Icon type="truck" className="mr-2" />
                      //     Post
                      //   </Button>
                      // );
                    })()}
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}

function MatchesListMobile({ result, queryState }) {
  return (
    <Card className="mx-n3">
      <ListGroup>
        {result.data.map((match) => {
          const offer = match.offers && match.offers[0];
          return (
            <ListGroupItem
              key={match.id}
              tag={Link}
              to={`/shipment-matches/${match.id}`}
              className="list-group-item-action px-3"
            >
              <ShipmentBanner shipment={match} />
              <ListGroup className="list-group list-group-flush list-group-activity my-3">
                {match.pickup_distance ? (
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto" className="bg-white text-secondary">
                        {match.pickup_distance} mi
                      </Col>
                      <Col className="ml-n2 text-secondary">deadhead</Col>
                    </Row>
                  </ListGroupItem>
                ) : null}
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="truck" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div>
                        <div className="font-weight-bold">
                          {`${match.pickup_address.city}, ${match.pickup_address.state}`}
                        </div>
                        <div>
                          {formatDate(match.pickup_date, 'LLL d, yyyy')}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="map-pin" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div>
                        <div className="font-weight-bold">
                          {`${match.delivery_address.city}, ${match.delivery_address.state}`}
                        </div>
                        <div>{formatDate(match.due_date, 'LLL d, yyyy')}</div>
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>

              <div>
                {(() => {
                  if (offer && offer.accepted_at) {
                    return (
                      <div className="text-primary">
                        <span className="font-weight-bold">
                          {formatMoney(offer.amount)}
                        </span>{' '}
                        offer accepted!
                      </div>
                    );
                  }

                  if (offer && !offer.accepted_at) {
                    return (
                      <div className="text-info">
                        <span>{formatMoney(offer.amount)}</span> offer being
                        reviewed
                      </div>
                    );
                  }

                  if (match.shipment_requests) {
                    return (
                      <div className="text-secondary">
                        <Icon type="truck" className="mr-2" />
                        {match.shipment_requests} Truck Match
                        {match.shipment_requests === 1 ? '' : 'es'}
                      </div>
                    );
                  }

                  return (
                    <div className="text-muted">View load for more details</div>
                  );
                })()}
              </div>
            </ListGroupItem>
          );
        })}
        {/* <ListGroupItem>
          <TablePaginationBtns
            total={result.total}
            limit={5}
            skip={queryState.getInState('query.$skip', 0)}
            setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
          />
        </ListGroupItem> */}
      </ListGroup>
    </Card>
  );
}
