import React from 'react';
import { Card, CardBody, Col, Row } from 'straightline-ui';
import { isLTL, PACKAGE_TYPES } from 'straightline-utils/constants';

export default ({ shipment, quote, className = '' }) => {
  shipment = shipment || quote;
  return shipment.line_items.map((line_item, index) => {
    const packageType = PACKAGE_TYPES.find(
      (type) => type.value === line_item.type
    );
    return (
      <Card key={index} className={className}>
        <CardBody className="py-3">
          <Row>
            {isLTL(shipment) && (
              <Col className="border-right py-2">
                <div className="small mb-1 text-muted">Class</div>
                <div>{line_item.freight_class}</div>
              </Col>
            )}
            <Col className="border-right py-2">
              <div className="small mb-1 text-muted">Units</div>
              <div>{line_item.units}</div>
            </Col>
            <Col className="border-right py-2">
              <div className="small mb-1 text-muted">Type</div>
              <div>{packageType.label}</div>
            </Col>
            <Col className="border-md-right py-2">
              <div className="small mb-1 text-muted">TotalWeight</div>
              <div className="text-truncate">
                {line_item.total_weight.toLocaleString('en-US')} lbs
              </div>
            </Col>
            <Col
              md="auto"
              className="py-2 mt-3 mt-md-0 border-top border-md-top-0"
            >
              <div className="small mb-1 text-muted">Dimensions</div>
              <div>
                {line_item.length}
                <span className="small"> x </span>
                {line_item.width}
                <span className="small"> x </span>
                {line_item.height} in
              </div>
            </Col>
          </Row>
          <div className="py-2 mt-3 border-top">
            <div className="small mb-1 text-muted">Description</div>
            <div className="text-truncate">{line_item.description}</div>
          </div>
        </CardBody>
      </Card>
    );
  });
};
