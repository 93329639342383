import './style.scss';

import React, { useState } from 'react';
import {
  Circle,
  DirectionsRenderer,
  InfoWindow,
  Marker
} from 'react-google-maps';
import { useHistory } from 'react-router-dom';
import Map from 'src/components/Map';
import { Button, Icon, useApp } from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { formatDate } from 'straightline-utils/time';

const getCenter = (pickup, delivery) => {
  if (pickup?.latitude || delivery?.latitude) {
    const center = [];
    if (pickup?.latitude) {
      center.push({ lat: pickup.latitude, lng: pickup.longitude });
    }
    if (delivery?.latitude) {
      center.push({ lat: delivery.latitude, lng: delivery.longitude });
    }
    return center;
  }

  return { lng: -98.5795, lat: 39.8283 };
};

const Expand = ({ onClick, expanded }) => {
  return (
    <Button
      color="white"
      size="sm"
      className="btn-collapse shadow"
      onClick={onClick}
    >
      {expanded ? 'Collapse' : 'Expand'}
    </Button>
  );
};

export default ({ values, matches }) => {
  const app = useApp();
  const [expanded, setExpanded] = useState(false);
  const [infoWindow, setInfoWindow] = useState(null);
  const history = useHistory();

  const { pickup_address, delivery_address } = values;

  const hasPickup = !!pickup_address?.latitude;
  const hasDelivery = !!delivery_address?.latitude;

  const { result: route } = app.service('api/shipment-routes').useFind(
    {
      query: {
        pickup_address,
        delivery_address
      }
    },
    { enabled: hasPickup && hasDelivery }
  );

  const center = getCenter(pickup_address, delivery_address);

  let zoom = 5;
  if (hasPickup && hasDelivery) {
    zoom = undefined;
  } else if (hasPickup || hasDelivery) {
    zoom = 8;
  }

  return (
    <div className="map-header-request-view">
      <Map
        center={center}
        zoom={zoom}
        containerClassName={`map-header-request-view-container ${
          expanded ? 'open' : ''
        }`}
      >
        {hasPickup && !route && <Marker position={pickup_address.position} />}
        {hasPickup && pickup_address.radius && (
          <Circle
            center={pickup_address.position}
            radius={pickup_address.radius * 1609.344}
            options={{
              strokeColor: '#2C7BE5',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#2C7BE5',
              fillOpacity: 0.25
            }}
          />
        )}

        {hasDelivery && !route && (
          <Marker position={delivery_address.position} />
        )}
        {hasDelivery && delivery_address.radius && (
          <Circle
            center={delivery_address.position}
            radius={delivery_address.radius * 1609.344}
            options={{
              strokeColor: '#2C7BE5',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#2C7BE5',
              fillOpacity: 0.25
            }}
          />
        )}

        {route && <DirectionsRenderer directions={route.directions} />}

        {matches &&
          matches.map((match) => {
            return (
              <Marker
                key={match.id}
                position={match.pickup_address.position}
                onClick={() => setInfoWindow(match.id)}
                icon="http://maps.google.com/mapfiles/ms/micons/blue.png"
              >
                {infoWindow === match.id && (
                  <InfoWindow onCloseClick={() => setInfoWindow(null)}>
                    <div className="p-2">
                      <div className="d-flex align-items-center justify-content-between mb-4">
                        <p className="h2 text-success font-weight-bold mb-0">
                          {formatMoney(match.rate.price.carrier_price)}
                        </p>
                        <p className="lead mb-0">
                          {formatDate(match.pickup_date, 'LLL d, yyyy')}
                        </p>
                      </div>
                      <div className="d-flex align-items-center mb-4">
                        <span className="h2 mb-0 d-inline-block mr-2">{`${match.pickup_address.city}, ${match.pickup_address.state}`}</span>
                        <Icon
                          type="arrow-right"
                          style={{ fontSize: 18 }}
                          className="text-success lead d-inline-block mr-2 font-weight-bold"
                        />
                        <span className="h2 mb-0 d-inline-block">{`${match.delivery_address.city}, ${match.delivery_address.state}`}</span>
                      </div>
                      <Button
                        // InfoWindow does not like <Link /> and throws
                        // 'cannot use Link outside Router' error
                        onClick={() =>
                          history.push(`/shipment-matches/${match.id}`)
                        }
                        block
                        color="primary"
                      >
                        <Icon type="package" className="mr-2" />
                        View Load
                      </Button>
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            );
          })}
      </Map>
      <Expand expanded={expanded} onClick={() => setExpanded(!expanded)} />
    </div>
  );
};
