import { useRef } from 'react';

import allLabels from './labels.json';
import allStates from './states.json';
import allZips from './zips.json';

const markerStyle = {
  fill: 'transparent',
  stroke: '#6E84A3',
  strokeWidth: 1.25
};

const stateProps = {
  style: {
    fill: '#6E84A3',
    fillOpacity: 1,
    stroke: '#fff',
    strokeWidth: 1.25,
    strokeMiterlimit: 4,
    strokeDasharray: 'none',
    strokeOpacity: 1,
    strokeLinecap: 'butt'
  }
};

const stateLabelProps = {
  style: {
    fontStyle: 'normal',
    fontVariant: 'normal',
    fontWeight: 400,
    fontStretch: 'normal',
    fontSize: 21,
    lineHeight: '125%',
    fontFamily: '"Cerebri Sans", sans-serif',
    letterSpacing: 0,
    wordSpacing: 0,
    fill: '#fff',
    fillOpacity: 1,
    stroke: 'none',
    pointerEvents: 'none' // important for tooltips
  }
};

const zipProps = {
  style: {
    ...stateProps.style,
    strokeWidth: 0.3
  }
};

const tooltipStyle = {
  display: 'none',
  postion: 'absolute',
  zIndex: 100,
  top: 0,
  left: 0
};

const applyTooltipStyles = (event, data, tooltip, tooltipRef, containerRef) => {
  if (!event.currentTarget || !tooltipRef.current || !containerRef.current) {
    return;
  }
  const tooltipElem = tooltipRef.current;
  const containerRect = containerRef.current.getBoundingClientRect();
  const zipRect = event.currentTarget.getBoundingClientRect();
  tooltipElem.innerHTML = tooltip(data);
  tooltipElem.style.display = 'inline-block';
  const tooltipRect = tooltipElem.getBoundingClientRect();
  tooltipElem.style.top = `${
    zipRect.y - containerRect.y - tooltipRect.height
  }px`;
  tooltipElem.style.left = `${zipRect.x - containerRect.x}px`;
};

const removeTooltipStyles = (tooltipRef) => {
  if (!tooltipRef.current) {
    return;
  }
  const tooltipElem = tooltipRef.current;
  tooltipElem.style.top = '0px';
  tooltipElem.style.left = '0px';
  tooltipElem.style.display = 'none';
  tooltipElem.innerHtml = '';
};

export function StateMap({ states = {} }) {
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const mergeStateProps = (state) => {
    if (!states[state]) {
      return {
        'data-state': state,
        ...stateProps
      };
    }

    const { tooltip, data, ...rest } = states[state];

    const props = {
      ...stateProps,
      ...rest,
      'data-state': state,
      style: {
        ...stateProps.style,
        ...rest.style
      }
    };

    if (tooltip) {
      props.onMouseOver = (event) => {
        applyTooltipStyles(
          event,
          data || state,
          tooltip,
          tooltipRef,
          containerRef
        );
        if (rest.onMouseOver) {
          return rest.onMouseOver(event);
        }
      };

      props.onMouseLeave = (event) => {
        removeTooltipStyles(tooltipRef);
        if (rest.onMouseLeave) {
          return rest.onMouseLeave(event);
        }
      };
    }

    return props;
  };

  const mergeStateLabelProps = (state) => {
    if (!states[state]?.label) {
      return {
        ...stateLabelProps,
        'data-state': state
      };
    }
    const { callback, ...props } = states[state].label;
    return {
      ...stateLabelProps,
      ...props,
      'data-state': state,
      style: {
        ...stateLabelProps.style,
        ...props.style
      }
    };
  };

  const stateLabelCallback = (state) => {
    if (!states[state]?.label?.callback) {
      return state;
    }
    return states[state].label.callback(state);
  };

  return (
    <div ref={containerRef} className="w-100 h-100 position-relative">
      <div
        ref={tooltipRef}
        style={tooltipStyle}
        className="card mb-0 shadow p-3 animate fadeIn faster"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={962}
        height={583}
        viewBox="0 0 962 583"
        preserveAspectRatio="xMinYMin meet"
        style={{
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <g>
          <g>
            <rect
              {...mergeStateProps('NH')}
              rx={5.23}
              ry={4.971}
              y={39.197}
              x={788.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('VT')}
              rx={5.23}
              ry={4.971}
              y={39.197}
              x={722.784}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('RI')}
              rx={5.23}
              ry={4.971}
              y={161.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('CT')}
              rx={5.23}
              ry={4.971}
              y={198.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('NJ')}
              rx={5.23}
              ry={4.971}
              y={235.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('DE')}
              rx={5.23}
              ry={4.971}
              y={272.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('MD')}
              rx={5.23}
              ry={4.971}
              y={309.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              {...mergeStateProps('DC')}
              rx={5.23}
              ry={4.971}
              y={346.094}
              x={906.216}
              height={30}
              width={55}
            />
            <rect
              {...mergeStateProps('MA')}
              rx={5.23}
              ry={4.971}
              y={124.094}
              x={906.216}
              height={30}
              width={55}
            />

            <path style={markerStyle} d="M855.484 86.904V54.185H843.2" />
            <path style={markerStyle} d="M906.2 176.082h-32.975v-7.514" />
            <path
              style={markerStyle}
              d="M906.2 361.082H836.43l-36.214-118.038"
            />
            <path
              style={markerStyle}
              d="M906.2 324.082h-58.6v-73.944h-14.175"
            />
            <path
              style={markerStyle}
              d="M906.2 287.082h-48.745v-48.114h-26.472"
            />
            <path style={markerStyle} d="M906.2 213.082h-48.745v-37.85" />
            <path
              style={markerStyle}
              d="M906.2 250.082h-38.889v-26.227h-29.1"
            />
            <path style={markerStyle} d="M906.2 139.082H883.26v13.553" />
            <path style={markerStyle} d="M838.4 96.853v-15.58H750.27V69.184" />
          </g>

          {Object.entries(allStates).map(([state, path]) => {
            const label = allLabels[state];
            return (
              <g key={state}>
                <path {...mergeStateProps(state)} d={path} />
                <text {...mergeStateLabelProps(state)} x={label.x} y={label.y}>
                  {stateLabelCallback(state)}
                </text>
              </g>
            );
          })}
        </g>
      </svg>
    </div>
  );
}

export function ZipMap({ zips = {} }) {
  const containerRef = useRef(null);
  const tooltipRef = useRef(null);

  const mergeZipProps = (zip) => {
    if (!zips[zip]) {
      return {
        'data-zip': zip,
        ...zipProps
      };
    }

    const { tooltip, data, ...rest } = zips[zip];

    const props = {
      ...zipProps,
      ...rest,
      'data-zip': zip,
      style: {
        ...zipProps.style,
        ...rest.style
      }
    };

    if (tooltip) {
      props.onMouseOver = (event) => {
        applyTooltipStyles(
          event,
          data || zip,
          tooltip,
          tooltipRef,
          containerRef
        );
        if (rest.onMouseOver) {
          return rest.onMouseOver(event);
        }
      };

      props.onMouseLeave = (event) => {
        removeTooltipStyles(tooltipRef);
        if (rest.onMouseLeave) {
          return rest.onMouseLeave(event);
        }
      };
    }

    return props;
  };

  return (
    <div ref={containerRef} className="w-100 h-100 position-relative">
      <div
        ref={tooltipRef}
        style={tooltipStyle}
        className="card mb-0 shadow p-3 animate fadeIn faster"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={962}
        height={583}
        viewBox="0 0 962 583"
        preserveAspectRatio="xMinYMin meet"
        style={{
          display: 'block',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%'
        }}
      >
        <g>
          <g>
            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={39.197}
              x={788.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={39.197}
              x={722.784}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={161.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={198.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={235.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={272.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={309.094}
              x={906.216}
              height={30}
              width={55}
            />

            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={346.094}
              x={906.216}
              height={30}
              width={55}
            />
            <rect
              style={stateProps.style}
              rx={5.23}
              ry={4.971}
              y={124.094}
              x={906.216}
              height={30}
              width={55}
            />

            <path style={markerStyle} d="M855.484 86.904V54.185H843.2" />
            <path style={markerStyle} d="M906.2 176.082h-32.975v-7.514" />
            <path
              style={markerStyle}
              d="M906.2 361.082H836.43l-36.214-118.038"
            />
            <path
              style={markerStyle}
              d="M906.2 324.082h-58.6v-73.944h-14.175"
            />
            <path
              style={markerStyle}
              d="M906.2 287.082h-48.745v-48.114h-26.472"
            />
            <path style={markerStyle} d="M906.2 213.082h-48.745v-37.85" />
            <path
              style={markerStyle}
              d="M906.2 250.082h-38.889v-26.227h-29.1"
            />
            <path style={markerStyle} d="M906.2 139.082H883.26v13.553" />
            <path style={markerStyle} d="M838.4 96.853v-15.58H750.27V69.184" />
          </g>
          <g>
            {Object.entries(allZips).map(([zip, path]) => {
              return <path {...mergeZipProps(zip)} d={path} key={zip} />;
            })}
          </g>
          {Object.entries(allLabels).map(([state, label]) => {
            return (
              <text
                x={label.x}
                y={label.y}
                key={state}
                style={{
                  ...stateLabelProps.style,
                  opacity: 0.7
                }}
              >
                {state}
              </text>
            );
          })}
        </g>
      </svg>
    </div>
  );
}
