import { isSameDay } from 'straightline-utils/time';

import hooks from './weather.hooks';

class WeatherService {
  async find(params) {
    // if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    //   const result = {
    //     detailed:
    //       'A chance of showers and thunderstorms before 10am, then showers and thunderstorms likely between 10am and 2pm, then showers and thunderstorms. Mostly cloudy. High near 84, with temperatures falling to around 78 in the afternoon. South southeast wind 5 to 10 mph. Chance of precipitation is 80%. New rainfall amounts between a tenth and quarter of an inch possible.',
    //     short:
    //       'Chance Showers And Thunderstorms then Showers And Thunderstorms Likely',
    //     temperature: '75F',
    //     rain: '50%',
    //     wind: '5 to 10 mph',
    //     icon: 'sun'
    //   };
    //   return result;
    //   // return null;
    //   // return new Promise((resolve) => {
    //   //   setTimeout(() => {
    //   //     resolve(result);
    //   //   }, 2000);
    //   // });
    // }

    const { latitude, longitude, date } = params.query;

    const point = await fetch(
      `https://api.weather.gov/points/${latitude},${longitude}`
    ).then((res) => res.json());

    if (!point.properties) {
      throw new Error(point.detail);
    }

    const weather = await fetch(`${point.properties.forecast}?units=us`).then(
      (res) => res.json()
    );

    const periods = weather.properties.periods;

    if (!periods || !periods.length) {
      return null;
    }

    const forecast = periods.find((period) => {
      return isSameDay(date, period.startTime);
    });

    if (!forecast) {
      return null;
    }

    let icon = 'sun';
    if (forecast.icon.includes('tsra_high')) {
      icon = 'cloud-lightning';
    } else if (
      forecast.icon.includes('tsra_sct') ||
      forecast.icon.includes('rain_showers')
    ) {
      icon = 'cloud-rain';
    } else if (forecast.icon.includes('rain')) {
      icon = 'cloud-drizzle';
    } else if (
      forecast.icon.includes('fzra') ||
      forecast.icon.includes('snow') ||
      forecast.icon.includes('blizzard')
    ) {
      icon = 'cloud-snow';
    } else if (forecast.icon.includes('bkn') || forecast.icon.includes('ovc')) {
      icon = 'cloud';
    }

    return {
      icon,
      detailed: forecast.detailedForecast,
      short: forecast.shortForecast,
      temperature: `${forecast.temperature}${forecast.temperatureUnit}`,
      rain: `${forecast.probabilityOfPrecipitation.value || 0}%`,
      wind: forecast.windSpeed
    };
  }
}

export default (app) => {
  app.use('weather', new WeatherService());
  const service = app.service('weather');
  service.hooks(hooks);
};
