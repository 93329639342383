import React, { useRef } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  Row,
  toast,
  UncontrolledButtonDropdown,
  useApp
} from 'straightline-ui';
import { useStateIn } from 'straightline-ui';
import { DOCUMENT_TYPES } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

const documentTypeNames = DOCUMENT_TYPES.reduce(
  (documentTypeNames, docType) => {
    documentTypeNames[docType.value] = docType.label;
    return documentTypeNames;
  },
  {}
);

export default function ShipmentDocuments({
  shipment,
  entity_id,
  entity_type,
  edit,
  upload,
  documentTypes
}) {
  const app = useApp();

  const params = {
    query: {
      shipment_id: shipment.id,
      '$document.entity_type$': entity_type
    }
  };

  const {
    result: shipmentDocuments,
    isLoading,
    isError,
    error
  } = app.service('api/shipment-documents').useFind(params);

  const {
    state: { isSubmitting, document_type },
    assignState
  } = useStateIn({
    isSubmitting: false,
    document_type: null
  });

  const inputRef = useRef(null);

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <Card>
        <div className="card-header">
          <h3 className="card-header-title">Documents</h3>
        </div>
        <CardBody className="text-center text-danger">
          {error.message || 'There was an unexpected error'}
        </CardBody>
      </Card>
    );
  }

  const handleClick = (document_type) => {
    inputRef.current.value = null;
    inputRef.current.click();
    assignState({ document_type });
  };

  const handleImageChange = (event) => {
    assignState({ isSubmitting: true });
    const { id } = shipment;
    const files = Array.from(event.target.files);
    return Promise.all(
      files.map((file) => {
        const formData = new FormData();
        formData.append('file', file);
        // formData.append('original_name', documentTypeNames[document_type]);
        formData.append('document_type', document_type);
        formData.append('entity_type', entity_type);
        formData.append('entity_id', entity_id);
        return app
          .service('api/documents')
          .create(formData)
          .then((document) => {
            return app.service('api/shipment-documents').create({
              shipment_id: id,
              document_id: document.id
            });
          })
          .catch((err) => toast.errors(err));
      })
    ).then(async () => {
      app.service('api/shipment-documents').invalidateFind(params);
      assignState({ isSubmitting: false, document_type: null });
    });
  };

  const handleEdit = () => {
    app.service('api/shipment-documents').invalidateFind(params);
  };

  const canEdit = (shipmentDocument) =>
    edit && typeof edit === 'function' ? edit(shipmentDocument) : edit;

  const canUpload = () =>
    upload && typeof upload === 'function' ? upload() : upload;

  return (
    <Card>
      <div className="card-header">
        <Row className="align-items-center">
          <Col>
            <h3 className="card-header-title">Documents</h3>
          </Col>
          {canUpload() && (
            <Col className="col-auto">
              <UncontrolledButtonDropdown
                className={isSubmitting ? 'is-loading' : ''}
              >
                <DropdownToggle
                  caret
                  color="white"
                  size="sm"
                  disabled={isSubmitting}
                  className={isSubmitting ? 'is-loading' : ''}
                >
                  Upload Document
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem header>Document Type</DropdownItem>
                  {documentTypes.map((docType) => {
                    return (
                      <DropdownItem
                        key={docType.value}
                        onClick={() => handleClick(docType.value)}
                      >
                        {docType.label}
                      </DropdownItem>
                    );
                  })}
                  {/* <DropdownItem divider /> */}
                </DropdownMenu>
              </UncontrolledButtonDropdown>
              <input
                type="file"
                accept=".jpg, .png, .pdf, .jpeg"
                multiple
                className="d-none"
                ref={inputRef}
                onChange={handleImageChange}
              />
            </Col>
          )}
        </Row>
      </div>
      {shipmentDocuments.data.length > 0 && (
        <ListGroup flush className="list">
          {shipmentDocuments.data.map((shipmentDocument) => {
            return canEdit(shipmentDocument) ? (
              <EditShipmentDocument
                key={shipmentDocument.id}
                shipmentDocument={shipmentDocument}
                onSuccess={handleEdit}
                documentTypes={documentTypes}
              />
            ) : (
              <PreviewShipmentDocument
                key={shipmentDocument.id}
                shipmentDocument={shipmentDocument}
              />
            );
          })}
        </ListGroup>
      )}
    </Card>
  );
}

class PreviewShipmentDocument extends React.Component {
  state = { modalOpen: false };

  toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

  render() {
    const { modalOpen } = this.state;
    const { shipmentDocument } = this.props;
    const { document } = shipmentDocument;
    return (
      <React.Fragment>
        <DocumentItem document={document} onClick={this.toggleModal} />
        <Modal
          isOpen={modalOpen}
          toggle={this.toggleModal}
          size="xl"
          onClose={() => this.setState({ modalOpen: false })}
        >
          {() => (
            <Container fluid>
              <Row>
                <Col xl="8" className="p-0">
                  {document.secure_url.includes('.pdf') ? (
                    <embed
                      src={document.secure_url}
                      type="application/pdf"
                      className="rounded-top"
                      style={{
                        width: '100%',
                        height: '85vh'
                      }}
                    ></embed>
                  ) : (
                    <div className="bg-lighter border-bottom rounded-top d-flex align-items-center justify-content-center p-3 h-100">
                      <div>
                        <img
                          src={document.secure_url}
                          alt="Shipment Document"
                          className="rounded shadow-lg img-fluid"
                          style={{ maxHeight: '85vh' }}
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xl="4" className="p-4">
                  <FormGroup>
                    <Label>Name</Label>
                    <input
                      rows="4"
                      className="form-control"
                      readOnly
                      value={document.original_name}
                      placeholder="There is no description for this document"
                    ></input>
                  </FormGroup>
                  <FormGroup>
                    <Label>Description</Label>
                    <textarea
                      rows="4"
                      className="form-control"
                      readOnly
                      placeholder="There is no description for this document"
                    >
                      {document.description}
                    </textarea>
                  </FormGroup>
                  <Row>
                    <Col lg="auto" xl="12" className="mb-3 mb-lg-0 mb-xl-3">
                      <a
                        href={document.download_url}
                        download={document.orignal_name}
                        className="btn btn-block btn-primary"
                      >
                        Download Document
                      </a>
                    </Col>
                    <Col lg="auto" xl="12" className="mb-3 mb-lg-0 mb-xl-3">
                      <Button
                        color="white"
                        block
                        type="button"
                        onClick={this.toggleModal}
                      >
                        Close
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

function DocumentImage({ document, onClick }) {
  return (
    <div className="avatar avatar-xl btn btn-light p-0" onClick={onClick}>
      {document.secure_url.includes('.pdf') ? (
        <div className="avatar-img rounded d-flex align-items-center justify-content-center">
          {/* <Icon type="image" style={{ fontSize: 35 }} /> */}
          <div className="font-weight-bold">PDF</div>
        </div>
      ) : (
        <img
          src={document.secure_url}
          alt={document.original_name}
          className="avatar-img rounded pointer"
          onClick={onClick}
        />
      )}
    </div>
  );
}

function DocumentItem({ document, onClick }) {
  return (
    <ListGroupItem className="px-4">
      <Row>
        <Col className="col-auto">
          <DocumentImage document={document} onClick={onClick} />
        </Col>
        <Col className="ml-n2">
          <Row className="justify-content-between">
            <Col>
              <div className="font-weight-bold">{document.original_name}</div>
              <div className="">
                {formatDate(document.created_at, 'dateTime')}
              </div>
              <div className="text-secondary">
                {documentTypeNames[document.document_type]}
              </div>
              <div className="text-secondary mt-2">{document.description}</div>
            </Col>
            <Col sm="auto" className="mt-3 mt-sm-1">
              <div>
                <Button
                  onClick={onClick}
                  color="outline-primary"
                  size="sm"
                  className="mr-2"
                  style={{ width: 72.3 }}
                >
                  Edit
                </Button>
                <a
                  href={document.download_url}
                  className="btn btn-sm btn-outline-primary"
                >
                  Download
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </ListGroupItem>
  );
}

function EditShipmentDocument({
  shipmentDocument,
  documentTypes,
  onSuccess,
  onError
}) {
  const app = useApp();

  const {
    state: { modalOpen, isRemoving },
    assignState
  } = useStateIn({
    modalOpen: false,
    isRemoving: false
  });

  const handleSubmit = (data, formik) => {
    formik.setSubmitting(true);
    return app
      .service('api/documents')
      .patch(shipmentDocument.document_id, data)
      .then((document) => {
        toast.success('Document saved!');
        formik.setSubmitting(false);
        if (onSuccess) {
          return onSuccess({ ...shipmentDocument, document });
        }
      })
      .catch((error) => {
        formik.setSubmitting(false);
        if (error.errors) {
          toast.errors(error);
        }
        if (onError) {
          return onError(error);
        }
      });
  };

  // const handleRemove = () => {
  //   assignState({ isRemoving: true });
  //   return app
  //     .service('api/shipment-documents')
  //     .remove(shipmentDocument.id)
  //     .then((removedShipmentDocument) => {
  //       toast.success('Document removed!');
  //       assignState({ isRemoving: false });
  //       if (onSuccess) {
  //         return onSuccess({ ...removedShipmentDocument });
  //       }
  //     })
  //     .catch((error) => {
  //       assignState({ isRemoving: false });
  //       if (error.errors) {
  //         toast.errors(error);
  //       }
  //       if (onError) {
  //         return onError(error);
  //       }
  //     });
  // };

  const toggleModal = () => assignState({ modalOpen: !modalOpen });

  const { document } = shipmentDocument;

  return (
    <React.Fragment>
      <DocumentItem document={document} onClick={toggleModal} />
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        size="xl"
        onClose={() => assignState({ modalOpen: false })}
      >
        {() => (
          <Container fluid>
            <Row>
              <Col xl="8" className="p-0">
                {document.secure_url.includes('.pdf') ? (
                  // <div className="avatar-img rounded bg-light d-flex align-items-center justify-content-center">
                  //   <Icon type="image" style={{ fontSize: 35 }} />
                  // </div>
                  <embed
                    src={document.secure_url}
                    type="application/pdf"
                    className="rounded-top"
                    style={{
                      width: '100%',
                      height: '85vh'
                    }}
                  ></embed>
                ) : (
                  <div className="bg-lighter border-bottom rounded-top d-flex align-items-center justify-content-center p-3 h-100">
                    <div>
                      <img
                        src={document.secure_url}
                        alt="Shipment Document"
                        className="rounded shadow-lg img-fluid"
                        style={{ maxHeight: '85vh' }}
                      />
                    </div>
                  </div>
                )}
              </Col>
              <Col xl="4" className="p-4">
                <Formik
                  onSubmit={handleSubmit}
                  initialValues={{
                    description: document.description,
                    document_type: document.document_type,
                    original_name: document.original_name
                  }}
                  // validationSchema={this.schema}
                  render={({ isSubmitting }) => {
                    return (
                      <Form>
                        <Row>
                          <Col lg="6" xl="12">
                            <FormGroup>
                              <Label>Name*</Label>
                              <FormInput
                                name="original_name"
                                disabled={isSubmitting}
                                placeholder="Enter image name"
                              />
                              <FormFeedback name="original_name" />
                            </FormGroup>
                          </Col>
                          <Col lg="6" xl="12">
                            <FormGroup>
                              <Label>Document Type*</Label>
                              <FormInput
                                name="document_type"
                                type="select"
                                options={documentTypes}
                                disabled={isSubmitting}
                                placeholder="Select document type"
                              />
                              <FormFeedback name="document_type" />
                            </FormGroup>
                          </Col>
                        </Row>
                        <FormGroup>
                          <Label>Description</Label>
                          <FormInput
                            name="description"
                            type="textarea"
                            rows="4"
                            disabled={isSubmitting}
                            placeholder="Enter a description"
                          />
                          <FormFeedback name="description" />
                        </FormGroup>
                        <Row>
                          <Col
                            lg="auto"
                            xl="12"
                            className="mb-3 mb-lg-0 mb-xl-3"
                          >
                            <Button
                              color="primary"
                              block
                              type="submit"
                              disabled={isSubmitting || isRemoving}
                              className={isSubmitting ? 'is-loading' : ''}
                            >
                              Save Document
                            </Button>
                          </Col>
                          {/* <Col
                            lg="auto"
                            xl="12"
                            className="mb-3 mb-lg-0 mb-xl-3"
                          >
                            <Button
                              color="white"
                              block
                              type="button"
                              className={`text-danger ${
                                isRemoving ? 'is-loading' : ''
                              }`}
                              disabled={isSubmitting || isRemoving}
                              onClick={this.handleRemove}
                            >
                              Remove Document
                            </Button>
                          </Col> */}
                          <Col
                            lg="auto"
                            xl="12"
                            className="mb-3 mb-lg-0 mb-xl-3"
                          >
                            <a
                              href={document.download_url}
                              className="btn btn-block btn-primary"
                              disabled={isSubmitting || isRemoving}
                            >
                              Download Document
                            </a>
                          </Col>
                          <Col
                            lg="auto"
                            xl="12"
                            className="mb-3 mb-lg-0 mb-xl-3"
                          >
                            <Button
                              color="white"
                              block
                              type="button"
                              disabled={isSubmitting || isRemoving}
                              onClick={toggleModal}
                            >
                              Close
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                />
              </Col>
            </Row>
          </Container>
        )}
      </Modal>
    </React.Fragment>
  );
}
