import { TRUCK_TYPE } from 'straightline-utils/constants';
import { yup } from 'straightline-utils/validation';
import { localeSchema } from 'straightline-utils/validation/schemas';

const stringToNull = (value) => (value === '' ? null : value);

export default (app) => {
  return yup.object().shape(
    {
      active: yup.boolean().label('Active').required(),
      delivery_address: localeSchema
        .default(undefined)
        .label('Delivery Address')
        .when('pickup_address', (pickup_address, schema) => {
          return pickup_address
            ? schema.nullable().transform(stringToNull)
            : schema.required('Pickup or Delivery Address is required');
        }),
      pickup_address: localeSchema
        .default(undefined)
        .label('Pickup Address')
        .when('delivery_address', (delivery_address, schema) => {
          return delivery_address
            ? schema.nullable().transform(stringToNull)
            : schema.required('Pickup or Delivery Address is required');
        }),
      truck_types: yup
        .array()
        .uniqueValues()
        .min(1)
        .of(yup.string().oneOf(Object.values(TRUCK_TYPE)).label('Truck Type'))
        .label('Truck Types')
        .required()
    },
    ['delivery_address', 'pickup_address']
  );
};
