import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator';
import MapHeaderRequestView from 'src/components/MapHeaderRequestView';
import { ShipmentRequestNotifications } from 'src/components/Notifications';
import { TruckTypePicker } from 'src/components/TruckTypePicker';
import emptyImg from 'src/images/empty.svg';
import {
  AddressCardPreview,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  CustomInput,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormReset,
  FormSubmit,
  Icon,
  InputAddress,
  InputGroup,
  InputGroupButtonDropdown,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import { STATUS } from 'straightline-utils/constants';

import { MatchesList } from './list';

const addressKeys = ['city', 'state'];
const addressLabel = (address) => {
  return addressKeys
    .filter((key) => !!address[key] && !!address[key].trim())
    .map((key) => address[key])
    .join(', ');
};

const RadiusDropDown = ({ label, onChange }) => {
  const [open, setOpen] = useState(false);
  return (
    <InputGroupButtonDropdown
      addonType="prepend"
      isOpen={open}
      toggle={() => setOpen(!open)}
    >
      <DropdownToggle color="white" caret>
        {label}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => onChange(50)}>50 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(100)}>100 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(200)}>200 mi</DropdownItem>
        <DropdownItem onClick={() => onChange(300)}>300 mi</DropdownItem>
      </DropdownMenu>
    </InputGroupButtonDropdown>
  );
};

export default function ShipmentRequest({ history, match }) {
  const app = useApp();
  const service = app.service('api/shipment-requests');

  const [isEditing, setIsEditing] = useState(false);

  const {
    result: shipmentRequest,
    isLoading,
    isError,
    error
  } = service.useGet(match.params.id);

  const availableMatches = app.service('api/shipment-matches').useFind(
    {
      query: {
        shipment_request: shipmentRequest,
        status_type: STATUS.BOOKED,
        $limit: 50,
        $sort: { pickup_date: -1 }
      }
    },
    { keepPreviousData: true, enabled: !!shipmentRequest }
  );

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={350} />
      </div>
    );
  }

  if (isError) {
    return <Error error={error} />;
  }

  return (
    <div>
      <MapHeaderRequestView
        values={shipmentRequest}
        matches={availableMatches?.result?.data || []}
      />
      <Container fluid className="pb-5">
        <div className="header">
          <div className="header-body">
            <Row className="align-items-end justify-content-between">
              <Col xs="auto">
                <Row className="font-weight-bold">
                  <Col xs="auto" className="mb-2">
                    {shipmentRequest.active ? (
                      <Badge color="success">Posted</Badge>
                    ) : (
                      <Badge color="danger">Not Posted</Badge>
                    )}
                  </Col>
                  {shipmentRequest.trucks && (
                    <Col xs="auto" className="mb-2">
                      <span className="text-muted">TRUCKS: </span>
                      {shipmentRequest.trucks
                        .map((truck) => truck.label)
                        .join(', ')}
                    </Col>
                  )}
                </Row>
                <Row className="align-items-center">
                  <Col
                    xs="auto"
                    className="mb-2 pr-0 align-items-center d-flex"
                  >
                    <h1 className="mb-0 font-weight-bold d-inline-block mr-3">
                      {shipmentRequest.pickup_address ? (
                        addressLabel(shipmentRequest.pickup_address)
                      ) : (
                        <span className="text-muted">Anywhere</span>
                      )}
                    </h1>
                    <Icon
                      type="arrow-right"
                      style={{ fontSize: 24 }}
                      className="text-success lead d-inline-block font-weight-bold"
                    />
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <h1 className="mb-0 font-weight-bold d-inline-block">
                      {shipmentRequest.delivery_address ? (
                        addressLabel(shipmentRequest.delivery_address)
                      ) : (
                        <span className="text-muted">Anywhere</span>
                      )}
                    </h1>
                  </Col>
                </Row>
              </Col>
              <Col xs="auto">
                <Row>
                  <Col sm="auto">
                    {isEditing ? (
                      <Button
                        color="white"
                        onClick={() => setIsEditing(!isEditing)}
                      >
                        <Icon type="arrow-left" className="mr-2" />
                        Go Back
                      </Button>
                    ) : (
                      <Button
                        color="primary"
                        onClick={() => setIsEditing(!isEditing)}
                      >
                        <Icon type="truck" className="mr-2" />
                        Edit Truck
                      </Button>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        {isEditing ? (
          <EditShipmentRequest
            shipmentRequest={shipmentRequest}
            setIsEditing={setIsEditing}
          />
        ) : (
          <ViewShipmentRequest
            shipmentRequest={shipmentRequest}
            matches={availableMatches}
            setIsEditing={setIsEditing}
          />
        )}
      </Container>
    </div>
  );
}

const Error = ({ error }) => {
  return (
    <Container>
      <Row className="align-items-center justify-content-center vh-100">
        <Col lg="6" className="text-center">
          <img
            src={emptyImg}
            alt="Not Found"
            className="img-fluid animated fadeIn faster mb-5"
          />
          {error.code === 404 ? (
            <h1>Could not find that truck</h1>
          ) : (
            <h1>{error.message}</h1>
          )}
          <Button
            to="/shipment-requests"
            color="primary"
            size="lg"
            className="mr-3"
          >
            <Icon type="truck" className="mr-2" />
            Posted Trucks
          </Button>
          <Button to="/support" color="secondary" size="lg">
            <Icon type="send" className="mr-2" />
            Contact Straightline
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const EditShipmentRequest = ({ shipmentRequest, setIsEditing }) => {
  const app = useApp();
  const history = useHistory();
  const service = app.service('api/shipment-requests');
  const removeTruck = service.useRemove();
  const date = useMemo(() => new Date(), []);

  const handleSubmit = (data, formik) => {
    service
      .update(shipmentRequest.id, data)
      .then(() => {
        service.invalidateGet(shipmentRequest.id);
        service.removeFind();
        toast.success('Truck updated!');
        formik.setSubmitting(false);
        setIsEditing(false);
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  const handleRemove = () => {
    toast.confirm({
      title: 'Are you sure?',
      message:
        "Removing your truck posting cannot be undone. But don't worry, you can always add another.",
      okButtonText: 'Yes, remove truck',
      okButtonColor: 'danger',
      cancelButtonText: 'Nevermind, do not remove',
      handleOnOk: () => {
        return removeTruck
          .remove(shipmentRequest.id)
          .then(() => {
            service.removeFind();
            toast.success('Truck Removed!');
            history.push('/shipment-requests');
          })
          .catch((err) => {
            toast.errors(err);
          });
      }
    });
  };

  return (
    <Formik
      initialValues={shipmentRequest}
      onSubmit={handleSubmit}
      enableReinitialize
      render={({ isSubmitting, values, setValues, setFieldValue }) => {
        const disabled = isSubmitting || removeTruck.isLoading;
        const hasAddress =
          values.pickup_address?.latitude || values.delivery_address?.latitude;
        const hasTruckType = values.truck_types?.length >= 1;
        return (
          <div>
            <Card>
              <Form>
                <CardBody>
                  <Row>
                    <Col xl={6}>
                      <FormGroup>
                        <Label>Where is your truck empty?</Label>
                        <InputGroup>
                          <RadiusDropDown
                            label={`${values.pickup_address?.radius || 50} mi`}
                            onChange={(radius) => {
                              setFieldValue('pickup_address.radius', radius);
                            }}
                          />
                          <InputAddress
                            className="form-control-prepended"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null
                            }}
                            disabled={isSubmitting}
                            isClearable={true}
                            name="pickup_address"
                            value={values.pickup_address}
                            onChange={({ target: { value } }) => {
                              if (!value) {
                                return setValues((values) => {
                                  return {
                                    ...values,
                                    pickup_address: null
                                  };
                                });
                              }
                              return setValues((values) => {
                                return {
                                  ...values,
                                  pickup_address: {
                                    radius: values.pickup_address?.radius || 50,
                                    ...value
                                  }
                                };
                              });
                            }}
                          />
                        </InputGroup>
                        <AddressCardPreview
                          address={
                            values.pickup_address?.latitude
                              ? values.pickup_address
                              : undefined
                          }
                          date={date}
                          className="border rounded bg-lighter mt-3 p-3"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Where would you like to deliver?</Label>
                        <InputGroup>
                          <RadiusDropDown
                            label={`${
                              values.delivery_address?.radius || 50
                            } mi`}
                            onChange={(radius) => {
                              setFieldValue('delivery_address.radius', radius);
                            }}
                          />
                          <InputAddress
                            className="form-control-prepended"
                            components={{
                              DropdownIndicator: () => null,
                              IndicatorSeparator: () => null
                            }}
                            disabled={isSubmitting}
                            isClearable={true}
                            name="delivery_address"
                            value={values.delivery_address}
                            onChange={({ target: { value } }) => {
                              if (!value) {
                                return setValues((values) => {
                                  return {
                                    ...values,
                                    delivery_address: null
                                  };
                                });
                              }
                              return setValues((values) => {
                                return {
                                  ...values,
                                  delivery_address: {
                                    radius:
                                      values.delivery_address?.radius || 50,
                                    ...value
                                  }
                                };
                              });
                            }}
                          />
                        </InputGroup>
                        <AddressCardPreview
                          address={
                            values.delivery_address?.latitude
                              ? values.delivery_address
                              : undefined
                          }
                          date={date}
                          className="border rounded bg-lighter mt-3 p-3"
                        />
                      </FormGroup>
                      {!hasAddress && (
                        <div className="text-danger mb-3">
                          Pickup or Delivery Address is required
                        </div>
                      )}
                      {!hasTruckType && (
                        <div className="text-danger mb-3">
                          At least one truck type is required
                        </div>
                      )}

                      {/* <FormGroup>
                          <Label>Active</Label>
                          <FormInput
                            type="checkbox"
                            name="active"
                            disabled={isSubmitting}
                          />
                          <FormFeedback name="active" />
                        </FormGroup> */}
                    </Col>
                    <Col xl={6}>
                      <FormGroup>
                        <Label>What type of truck is available?</Label>
                        <TruckTypePicker
                          values={values}
                          setValues={setValues}
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="truck_types" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="justify-content-between pt-4 mt-4 border-top">
                    <Col xs="auto">
                      <FormSubmit className="mr-3">Save Truck</FormSubmit>
                      <FormReset>Discard Changes</FormReset>
                    </Col>
                    <Col xs="auto">
                      <Button
                        type="button"
                        onClick={handleRemove}
                        color="outline-danger"
                        disabled={disabled}
                      >
                        Remove Truck
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Form>
            </Card>
          </div>
        );
      }}
    />
  );
};

const ViewShipmentRequest = ({ shipmentRequest, matches, setIsEditing }) => {
  const app = useApp();
  const [checked, setChecked] = useState(shipmentRequest.active);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setChecked((checked) => !checked);
    setIsSubmitting(true);
    app
      .service('api/shipment-requests')
      .update(shipmentRequest.id, {
        ...shipmentRequest,
        active: !checked
      })
      .then((result) => {
        app.service('api/shipment-requests').invalidateFind();
        app.service('api/shipment-requests').invalidateGet(result.id);
        if (result.active) {
          toast.success('Truck Posted');
        } else {
          toast.warning('Truck Un-Posted');
        }
      })
      .catch((error) => {
        setChecked((checked) => !checked);
        toast.errors(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div>
      <Card className="mb-4">
        <CardBody>
          <div className="">
            <Row className="justify-content-between align-items-center mb-4">
              <Col>
                <div className="h3 mb-0 d-flex align-items-center">
                  <Icon
                    type={shipmentRequest.active ? 'truck' : 'slash'}
                    className="mr-3"
                  />
                  {shipmentRequest.active ? 'Truck Posted' : 'Truck Not Posted'}
                </div>
              </Col>
              <Col xs="auto">
                <div onClick={handleChange}>
                  <CustomInput
                    type="switch"
                    checked={checked}
                    disabled={isSubmitting}
                  />
                </div>
              </Col>
            </Row>
            {shipmentRequest.active
              ? "Your truck is currently posted on the Straightline loadboard. The app is actively searching for loads that align with your availability and preferences. You'll receive notifications as soon as a suitable load becomes available."
              : "Don't miss out on potential opportunities - post your truck and start receiving notifications for new matching shipments. Once your truck is posted, Straightline will actively search for compatible shipments and notify you when new opportunities arise."}
          </div>
          {shipmentRequest.active && (
            <div className="mt-4 pt-4 border-top">
              <ShipmentRequestNotifications shipmentRequest={shipmentRequest} />
            </div>
          )}
        </CardBody>
      </Card>
      <Row className="mb-4 justify-content-between">
        <Col xs="auto">
          <h3 className="mb-0">Available Shipments</h3>
        </Col>
        <Col xs="auto">
          <p className="text-secondary mb-0">
            View loads to get more information and book instantly
          </p>
        </Col>
      </Row>
      <MatchesList matches={matches} onClickEdit={() => setIsEditing(true)} />
    </div>
  );
};
