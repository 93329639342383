import './style.scss';

import React, { useState } from 'react';
import { Circle, DirectionsRenderer, Marker } from 'react-google-maps';
import { Button, Icon, Tooltip, useApp, useScreenSize } from 'straightline-ui';

import Map from '../Map';

const getCenter = (pickup, delivery) => {
  if (pickup?.latitude || delivery?.latitude) {
    const center = [];
    if (pickup?.latitude) {
      center.push({
        lat: Number(pickup.latitude),
        lng: Number(pickup.longitude)
      });
    }
    if (delivery?.latitude) {
      center.push({
        lat: Number(delivery.latitude),
        lng: Number(delivery.longitude)
      });
    }
    return center;
  }

  return { lng: -98.5795, lat: 39.8283 };
};

const Expand = ({ onClick, expanded }) => {
  const { sm } = useScreenSize();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const handleClick = (event) => {
    toggle();
    return onClick(event);
  };

  return (
    <React.Fragment>
      <Button
        id="mapToggle"
        color="white"
        className="btn-rounded-circle"
        onClick={handleClick}
        style={{
          position: 'absolute',
          left: sm && 'calc(50% - 20px)',
          right: !sm && '0.75rem',
          bottom: '3.2rem',
          zIndex: 1
        }}
      >
        {expanded ? <Icon type="chevron-up" /> : <Icon type="chevron-down" />}
      </Button>
      <Tooltip
        placement="top"
        // Not much we can do about the arrow color bc it is an
        // ::after css content
        innerClassName="bg-white"
        target="mapToggle"
        toggle={toggle}
        isOpen={isOpen}
      >
        {expanded ? 'Collapse Map' : 'Expand Map'}
      </Tooltip>
    </React.Fragment>
  );
};

export default ({ values }) => {
  const app = useApp();
  const [expanded, setExpanded] = useState(false);

  const { pickup_address, delivery_address } = values;

  const hasPickup = !!pickup_address?.latitude;
  const hasDelivery = !!delivery_address?.latitude;

  const { result: route } = app.service('api/shipment-routes').useFind(
    {
      query: {
        pickup_address,
        delivery_address
      }
    },
    { enabled: hasPickup && hasDelivery }
  );

  const center = getCenter(pickup_address, delivery_address);

  let zoom = 5;
  if (hasPickup && hasDelivery) {
    zoom = undefined;
  } else if (hasPickup || hasDelivery) {
    zoom = 8;
  }

  return (
    <div className="map-header-request">
      <Map
        center={center}
        zoom={zoom}
        containerClassName={`map-header-request-container ${
          expanded ? 'open' : ''
        }`}
      >
        {hasPickup && !route && <Marker position={pickup_address.position} />}
        {hasPickup && pickup_address.radius && (
          <Circle
            center={pickup_address.position}
            radius={pickup_address.radius * 1609.344}
            options={{
              strokeColor: '#2C7BE5',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#2C7BE5',
              fillOpacity: 0.25
            }}
          />
        )}

        {hasDelivery && !route && (
          <Marker position={delivery_address.position} />
        )}
        {hasDelivery && delivery_address.radius && (
          <Circle
            center={delivery_address.position}
            radius={delivery_address.radius * 1609.344}
            options={{
              strokeColor: '#2C7BE5',
              strokeOpacity: 0.8,
              strokeWeight: 2,
              fillColor: '#2C7BE5',
              fillOpacity: 0.25
            }}
          />
        )}

        {route && <DirectionsRenderer directions={route.directions} />}
      </Map>
      <Expand expanded={expanded} onClick={() => setExpanded(!expanded)} />
    </div>
  );
};
