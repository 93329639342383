import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Calendar,
  Card,
  CardBody,
  Col,
  Icon,
  Input,
  ListGroup,
  ListGroupItem,
  Row
} from 'straightline-ui';
import {
  addDays,
  addMonths,
  differenceInDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  formatDate,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  isSameMonth,
  parseDate,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'straightline-utils/time';

const dateOnly = (date) => {
  if (typeof date === 'string') {
    const parts = date.split('/');
    return `${parts[2]}-${parts[0]}-${parts[1]}`;
  }
  return formatDate(date, 'dateOnly');
};

export const rangeConfig = () => {
  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);
  const lastWeek = subWeeks(startOfWeek(today), 1);
  const lastMonth = subMonths(startOfMonth(today), 1);
  const lastYear = subYears(startOfYear(today), 1);

  return [
    {
      label: 'Today',
      value: [dateOnly(today), dateOnly(today)],
      interval: 'day'
    },
    {
      label: 'Yesterday',
      value: [dateOnly(yesterday), dateOnly(yesterday)],
      interval: 'day'
    },
    // tomorrow: {
    //   label: 'Tomorrow',
    //   value: [tomorrow, tomorrow],
    //   period: 'day'
    // },
    {
      label: 'This Week',
      value: [dateOnly(startOfWeek(today)), dateOnly(endOfWeek(today))],
      interval: 'day'
    },
    {
      label: 'Last Week',
      value: [dateOnly(lastWeek), dateOnly(endOfWeek(lastWeek))],
      interval: 'day'
    },
    {
      label: 'This Month',
      value: [dateOnly(startOfMonth(today)), dateOnly(endOfMonth(today))],
      interval: 'day'
    },
    {
      label: 'Last Month',
      value: [dateOnly(lastMonth), dateOnly(endOfMonth(lastMonth))],
      interval: 'day'
    },
    {
      label: 'This Year',
      value: [dateOnly(startOfYear(today)), dateOnly(endOfYear(today))],
      interval: 'month'
    },
    {
      label: 'Last Year',
      value: [dateOnly(lastYear), dateOnly(endOfYear(lastYear))],
      interval: 'month'
    }
    // {
    //   label: 'Last 30 Days',
    //   value: [],
    //   period: ''
    // },
    // {
    //   label: 'Last 90 Days',
    //   value: [],
    //   period: ''
    // },
    // {
    //   label: 'Last 12 Months',
    //   value: [],
    //   period: ''
    // },
  ];
};

function useOutsideClick(ref, callback) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

function RangeCalendar({ onChange, value }) {
  value = value ? [parseDate(value[0]), parseDate(value[1])] : value;

  const handleChange = (values) => {
    const newValue = [dateOnly(values[0]), dateOnly(values[1])];
    onChange(newValue);
  };

  return (
    <Calendar
      className="w-100 border-0"
      minDetail="month"
      selectRange={true}
      allowPartialRange={false}
      onChange={handleChange}
      value={value}
    />
  );
}

function List({ ranges, value, onChange }) {
  const selectedRange =
    value &&
    ranges.find((range) => {
      return range.value[0] === value[0] && range.value[1] === value[1];
    });
  return (
    <ListGroup flush>
      {ranges.map((range) => {
        return (
          <ListGroupItem
            key={range.label}
            tag="button"
            action
            active={selectedRange && selectedRange.label === range.label}
            className="px-4 py-3"
            onClick={() => {
              onChange(range.value);
            }}
          >
            {range.label}
          </ListGroupItem>
        );
      })}
    </ListGroup>
  );
}

function Label({ ranges, value }) {
  if (!value || !value.length) {
    return 'Select Date';
  }
  const selectedRange = ranges.find((range) => {
    return range.value[0] === value[0] && range.value[1] === value[1];
  });
  if (selectedRange) {
    return selectedRange.label;
  }
  return `${formatDate(value[0])} - ${formatDate(value[1])}`;
}

const commonProps = {
  style: { maxWidth: 117.5 },
  mask: '99/99/9999',
  maskPlaceholder: '__/__/____',
  placeholder: '__/__/____',
  className: 'border-0 rounded-0 bg-transparent'
};

function Inputs({ value, onChange }) {
  const start = value?.[0] ? formatDate(value[0], 'MM/dd/yyyy') : '';
  const end = value?.[1] ? formatDate(value[1], 'MM/dd/yyyy') : '';
  const [startValue, setStartValue] = useState(start);
  const [endValue, setEndValue] = useState(end);

  const handleChange = ({ target: { name, value } }) => {
    if (name === 'start') {
      if (!value.includes('_') && !endValue.includes('_')) {
        const newValue = [dateOnly(value), dateOnly(endValue)];
        onChange(newValue);
      }
      setStartValue(value);
    } else {
      if (!value.includes('_') && !startValue.includes('_')) {
        const newValue = [dateOnly(startValue), dateOnly(value)];
        onChange(newValue);
      }
      setEndValue(value);
    }
  };

  return (
    <div className="bg-white d-flex border-top border-bottom align-items-center overflow-hidden w-100">
      <Input
        {...commonProps}
        name="start"
        value={startValue}
        onChange={handleChange}
      />
      <div className="text-muted" style={{ width: 15 }}>
        to
      </div>
      <Input
        {...commonProps}
        name="end"
        value={endValue}
        onChange={handleChange}
      />
    </div>
  );
}

function DirectionButton({ direction, value, onClick }) {
  const handleClick = () => {
    if (!value) {
      onClick();
    }
    const [start, end] = value;
    if (
      isSameMonth(start, end) &&
      isFirstDayOfMonth(start) &&
      isLastDayOfMonth(end)
    ) {
      const method = direction === 'forward' ? addMonths : subMonths;
      const newMonth = method(startOfMonth(start), 1);
      const newValue = [dateOnly(newMonth), dateOnly(endOfMonth(newMonth))];
      return onClick(newValue);
    }
    const method = direction === 'forward' ? addDays : subDays;
    const diff = differenceInDays(end, start) + 1;
    const newValue = [method(start, diff), method(end, diff)];
    onClick(newValue);
  };
  const icon = direction === 'forward' ? 'chevron-right' : 'chevron-left';
  return (
    <Button color="light" onClick={handleClick} style={{ maxWidth: 42.5 }}>
      <Icon type={icon} />
    </Button>
  );
}

export function DateRange({ name, value, onChange, ranges, wrapper }) {
  const containerRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const setClosed = useCallback(() => setIsOpen(false), []);
  const setOpened = useCallback(() => setIsOpen(true), []);
  useOutsideClick(containerRef, setClosed);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const wrapperWidth =
    wrapper && wrapper.current ? wrapper.current.offsetWidth : null;

  const handleChange = (value) => {
    onChange({ target: { name, value } });
    setClosed();
  };

  return (
    <div className="position-relative" ref={containerRef}>
      <ButtonGroup className="w-100">
        <DirectionButton
          direction="back"
          value={value}
          onClick={value ? handleChange : setOpened}
        />
        {isOpen ? (
          <Inputs ranges={ranges} value={value} onChange={handleChange} />
        ) : (
          <Button
            color="light"
            size="sm"
            onClick={setOpened}
            style={{
              midWidth: 250
            }}
          >
            <Icon type="calendar" className="mr-2" />
            <Label ranges={ranges} value={value} onChange={handleChange} />
          </Button>
        )}
        <DirectionButton
          direction="forward"
          value={value}
          onClick={value ? handleChange : setOpened}
        />
      </ButtonGroup>
      {isOpen && (
        <div
          className="position-absolute"
          style={{
            zIndex: 100,
            top: 50,
            minWidth: wrapperWidth > 600 ? 600 : '100%'
          }}
        >
          <Card className="shadow">
            <CardBody className="p-0">
              <Row noGutters>
                <Col
                  className="px-0 border-right"
                  xs={wrapperWidth > 600 ? '4' : '12'}
                >
                  <List ranges={ranges} value={value} onChange={handleChange} />
                  <hr className="d-md-none my-0" />
                </Col>
                <Col className="px-0">
                  <RangeCalendar
                    ranges={ranges}
                    value={value}
                    onChange={handleChange}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      )}
    </div>
  );
}
