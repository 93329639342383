import { contextCache, ContextCacheMap } from 'feathers-fletching';
import { setIn } from 'formik';
import { NotAuthenticated } from 'straightline-utils/errors';
import { isNumbersOnly } from 'straightline-utils/validation';

export const cache = (context) => {
  if (!context.service.options || !context.service.options.cacheMap) {
    context.service.options = {
      ...context.service.options,
      cacheMap: new ContextCacheMap({ max: 50 })
    };
    const storageKey = `${process.env.REACT_APP_LOCAL_STORAGE_TOKEN}:${context.path}`;
    context.service.options.cacheMap.map.load(
      JSON.parse(window.localStorage.getItem(storageKey)) || []
    );
  }
  return contextCache(context.service.options.cacheMap)(context);
};

export const stashCache = (context) => {
  const storageKey = `${process.env.REACT_APP_LOCAL_STORAGE_TOKEN}:${context.path}`;
  try {
    // Avoid throwing when LRU grows too large and cannot be persisted
    // to localStorage due to QuotaExceededError
    // TODO: Use localForage?
    window.localStorage.setItem(
      storageKey,
      JSON.stringify(context.service.options.cacheMap.map.dump())
    );
  } catch (error) {
    window.localStorage.removeItem(storageKey);
  }

  return context;
};

export const withSearch = (searchProps, idProps) => (context) => {
  if (!context.params.query) {
    return context;
  }

  const { $search, ...query } = context.params.query;

  if (!$search) {
    context.params.query = query;
    return context;
  }

  const $or = [];

  searchProps.forEach((prop) => {
    $or.push({ [prop]: { $iLike: `%${$search}%` } });
  });

  if (idProps && isNumbersOnly($search)) {
    idProps.forEach((prop) => {
      $or.push({ [prop]: $search });
    });
  }

  context.params.query = {
    ...query,
    $or: [...(query.$or || []), ...$or]
  };

  return context;
};

/* Convert a ValidationError into the shape that Formik wants so it can map to form UI */
export const convertFormikError = (context) => {
  if (context.error.code === 420 && context.error.errors) {
    let formik = {};
    Object.entries(context.error.errors).forEach(([key, value]) => {
      formik = setIn(formik, key, value);
    });
    context.error.formik = formik;
  }
  return context;
};

let isRefreshing = null;

export const authenticateClient = async (context) => {
  if (isRefreshing) {
    await isRefreshing;
    return context;
  }
  const promise = async () => {
    const { app } = context;
    const auth = await app.get('authentication');
    if (!auth) {
      throw new NotAuthenticated();
    }
    const iat = auth.authentication.payload.iat;
    const exp = auth.authentication.payload.exp;
    const now = Date.now() / 1000;
    if (exp < now) {
      throw new NotAuthenticated();
    }
    if (iat + 60 * 60 * 24 < now) {
      try {
        await app.authenticate({
          strategy: 'jwt',
          accessToken: auth.accessToken,
          refresh: true
        });
      } catch (error) {}
    }
  };
  isRefreshing = promise().then(() => {
    isRefreshing = null;
  });
  await isRefreshing;
  return context;
};
