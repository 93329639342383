import { withResult } from 'feathers-fletching';
import { disallow } from 'feathers-hooks-common';
import { authenticateClient, withSearch } from 'src/feathers/hooks';

const withSearches = withSearch(['email', 'first_name', 'last_name']);

const withResults = withResult({
  masked_phone: (user) => {
    if (user.phone) {
      let mask = user.phone
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !mask[2]
        ? mask[1]
        : '(' + mask[1] + ') ' + mask[2] + (mask[3] ? '-' + mask[3] : '');
    }
  }
});

export default {
  before: {
    all: [authenticateClient, withSearches],
    find: [],
    get: [],
    create: [disallow()],
    update: [disallow()],
    patch: [disallow()],
    remove: [disallow()]
  },
  after: {
    all: [withResults],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
