import { StateContainer } from 'straightline-ui';

const stateContainer = new StateContainer({
  roles: [],
  carrier: null,
  user: null,
  authenticated: false,
  prepped: false
});

export default stateContainer;
