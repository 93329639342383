import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import logo from 'src/images/logo-sm.png';
import {
  Button,
  Collapse,
  Container,
  Icon,
  Link,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
  useApp
  // Tooltip
} from 'straightline-ui';

// Note that there is a NavLink reactstrap item. We do not use it,
// but create our own that wraps a react-router Link
const NavLink = ({ to, className, location, ...rest }) => (
  <Link
    {...rest}
    to={to}
    className={`${className} nav-link ${location.pathname === to && 'active'}`}
  />
);

export default function Sidebar() {
  const app = useApp();
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const location = useLocation();

  return (
    <Navbar light expand="lg" className="navbar-vertical fixed-left">
      <Container fluid>
        <Link className="navbar-brand" to="/">
          <img src={logo} className="navbar-brand-img mx-auto" alt="..." />
        </Link>
        <NavbarToggler onClick={toggle} />
        <Collapse navbar isOpen={open}>
          <hr className="my-3 d-none d-md-block" />
          <Nav navbar>
            <NavItem>
              <NavLink to="/dashboard" location={location}>
                <Icon type="home" /> Dashboard
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/shipment-matches" location={location}>
                <Icon type="clipboard" /> Load Board
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/shipments" location={location}>
                <Icon type="package" /> My Loads
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/shipment-requests" location={location}>
                <Icon type="truck" /> Trucks
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/shipment-transfers" location={location}>
                <Icon type="dollar-sign" /> Payments
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/reports" location={location}>
                <Icon type="bar-chart" /> Reports
              </NavLink>
            </NavItem>
            <div className="px-4 mt-4">
              <Button color="primary" block to="/shipment-request">
                <Icon type="truck" className="mr-2" />
                Post Truck
              </Button>
            </div>
          </Nav>
          {/* <hr className="my-3" /> */}
          <div className="mt-auto">
            <hr className="my-3" />
            {/* <h6 className="navbar-heading text-muted">Manage</h6> */}
            <Nav navbar className="mb-md-3">
              <NavItem>
                <NavLink to="/settings" location={location}>
                  <Icon type="settings" /> Settings
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/support" location={location}>
                  <Icon type="send" /> Support
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/guide" location={location}>
                  <Icon type="compass" /> Guide
                </NavLink>
              </NavItem>
              <NavItem className="nav-item">
                <div className="nav-link pointer" onClick={app.logout}>
                  <Icon type="log-out" /> Log Out
                </div>
              </NavItem>
            </Nav>
          </div>
          {/* <div className="navbar-user mt-auto d-none d-md-flex">
              <span className="icon text-muted">
                <Icon type="bell" />
              </span>
              <AvatarMenu />
              <span className="icon text-muted">
                <Icon type="search" />
              </span>
            </div> */}
        </Collapse>
      </Container>
    </Navbar>
  );
}

// const NavTip = ({ target, message }) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggle = () => setIsOpen(!isOpen);
//   return (
//     <Tooltip placement="right" isOpen={isOpen} toggle={toggle} target={target}>
//       {message}
//     </Tooltip>
//   );
// };

// export const SidebarSmall = ({ location }) => {
//   const [open, setOpen] = useState(false);
//   const toggle = () => setOpen(!open);

//   return (
//     <Navbar
//       className="navbar-vertical navbar-vertical-sm fixed-left navbar-expand-md navbar-light"
//       // style={{ position: 'relative', borderWidth: 0 }}
//     >
//       <Link className="navbar-brand" to="/">
//         <img src={logo} className="navbar-brand-img mx-auto" alt="..." />
//       </Link>
//       <NavbarToggler onClick={toggle} />

//       <Collapse navbar isOpen={open}>
//         <Nav navbar className="mb-md-3">
//           <NavItem>
//             <NavLink to="/dashboard" location={location} id="sidebar-dashboard">
//               <Icon type="home" />
//             </NavLink>
//             <NavTip target="sidebar-dashboard" message="Dashboard" />
//           </NavItem>
//         </Nav>
//       </Collapse>
//     </Navbar>
//   );
// };
