import React from 'react';
import {
  Card,
  CardBody,
  Icon,
  Link,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  useApp
} from 'straightline-ui';
import { formatMoney, roundNumberToLabel } from 'straightline-utils/accounting';
import { STATUS } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

export default function ShipmentStatsCard() {
  const app = useApp();

  const { result, isLoading, isError } = app
    .service('api/shipment-matches')
    .useFind(
      {
        query: {
          status_type: STATUS.BOOKED,
          $limit: 10,
          $sort: { pickup_date: -1 }
        }
      },
      { keepPreviousData: true }
    );

  if (isLoading || isError) {
    return <Loader />;
  }

  return (
    <Card>
      <div className="card-header">
        <h3 className="card-header-title">Load Board</h3>
        <Link to="/shipment-matches">View All</Link>
      </div>
      <div className="table-responsive">
        <Table className="table table-nowrap card-table table-hover">
          <TableHead>
            <TableRow>
              <TableHeader className="px-3 px-xl-4">Payout</TableHeader>
              <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
              <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
              <TableHeader className="px-3 px-xl-4">Service</TableHeader>
              <TableHeader className="px-3 px-xl-4">Details</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {result.data.map((match) => {
              const offer = match.offers && match.offers[0];
              return (
                <TableRow
                  key={match.id}
                  // className={offer && offer.accepted_at ? 'bg-light' : ''}
                  to={`/shipment-matches/${match.id}`}
                >
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0 text-success font-weight-bold">
                      {formatMoney(match.rate.price.carrier_price)}
                      <span className="small text-muted"> total</span>
                    </p>
                    <p className="mb-0">
                      {formatMoney(match.cost_per_mile)}
                      <span className="small text-muted"> /mile</span>
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0">
                      {match.pickup_address.city}, {match.pickup_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.pickup_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ width: '1%', position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', left: -12, top: 21 }}>
                      <Icon
                        type="arrow-right text-success"
                        style={{ fontSize: 25 }}
                      />
                    </div>
                    <p className="mb-0">
                      {match.delivery_address.city},{' '}
                      {match.delivery_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.due_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <div>{match.rate.service_level.name}</div>
                    <div>
                      {match.truck.label}
                      <Icon type="dot" className="text-muted mx-1" />
                      {roundNumberToLabel(match.load_weight)} lbs
                      <Icon type="dot" className="text-muted mx-1" />
                      {match.travel.miles} mi
                    </div>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ maxWidth: '1px' }}
                  >
                    <p className="mb-0 text-truncate">
                      {(() => {
                        if (!offer) {
                          return (
                            <span className="text-muted">
                              View load for more details
                            </span>
                          );
                        }
                        if (offer.accepted_at) {
                          return (
                            <span className="text-primary">
                              <span className="font-weight-bold">
                                {formatMoney(offer.amount)}
                              </span>{' '}
                              offer accepted!
                            </span>
                          );
                        }
                        return (
                          <span className="text-info">
                            <span>{formatMoney(offer.amount)}</span> offer being
                            reviewed
                          </span>
                        );
                      })()}
                    </p>
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}

const Loader = () => {
  return (
    <Card>
      <div className="card-header">
        <h3 className="card-header-title">Load Board</h3>
        <Link to="/shipment-matches">View All</Link>
      </div>
      <CardBody>
        <div className="chart">
          {/* <Line data={{ labels, datasets }} options={options} /> */}
        </div>
      </CardBody>
    </Card>
  );
};
