import { Icon, ListGroup, ListGroupItem } from 'straightline-ui';
import { TRUCK_TYPES } from 'straightline-utils/constants';
const ENABLED_TRUCK_TYPES = TRUCK_TYPES.filter((truck) => truck.enabled);

export function TruckTypePicker({ values, setValues, disabled }) {
  const value = values.truck_types || [];
  const handleChange = (truck) => {
    if (value.includes(truck.value)) {
      return setValues({
        ...values,
        truck_types: value.filter((val) => val !== truck.value)
      });
    }
    return setValues({
      ...values,
      truck_types: [...value, truck.value]
    });
  };
  return (
    <div>
      <ListGroup>
        {ENABLED_TRUCK_TYPES.map((truck) => {
          const active = value.includes(truck.value);
          return (
            <ListGroupItem
              key={truck.value}
              action
              onClick={() => handleChange(truck)}
              tag="button"
              type="button"
              disabled={disabled}
              className={active ? 'text-primary' : ''}
            >
              <div className="d-flex justify-content-between">
                <div className="mr-3">
                  <div className="font-weight-bold mb-1">{truck.label}</div>
                  <div className="mb-1">{truck.description}</div>
                  {/* <div className="d-flex align-items-center">
                    <Icon type="package" className="mr-3" />
                    {truck.max_load_weight.toLocaleString()} lbs
                  </div> */}
                </div>
                <div className="mt-n1">
                  <Icon
                    type={active ? 'check-circle' : 'circle'}
                    className={active ? 'text-primary' : ''}
                    style={{ fontSize: '1.15rem' }}
                  />
                </div>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </div>
  );
}
