import { scriptLoader } from 'straightline-ui';

import hooks from './shipment-routes.hooks';

export default (app) => {
  const service = app.service('api/shipment-routes');
  service.hooks(hooks);

  service.parseDirections = async function (route) {
    if (!scriptLoader.has(process.env.REACT_APP_GOOGLE_MAPS_URL)) {
      await scriptLoader.load(process.env.REACT_APP_GOOGLE_MAPS_URL);
    }

    // This service delivers a `route` that represents the
    // prefferred google directions route. Parse it into
    // a directions object for the DirectionsRenderer because
    // the result of the directions is different from the
    // google REST API (which the server uses) and the
    // google client directionsService. Note this hook
    // only applies the minimal information to get the
    // DirectionsRenderer working, there are many more
    // property differences between the two than what is
    // parsed here.

    route = { ...route };

    route.bounds = new window.google.maps.LatLngBounds(
      route.bounds.southwest,
      route.bounds.northeast
    );

    // When using waypoints, "legs" represent a path between
    // waypoints and creates a path with many markers instead
    // of just A=>B. Reduce all legs to one leg. Also decode
    // the path so it can be drawn
    route.legs = [
      route.legs.reduce(
        (mainLeg, leg) => {
          const legSteps = leg.steps.map((step) => {
            return {
              ...step,
              path: window.google.maps.geometry.encoding.decodePath(
                step.polyline.points
              )
            };
          });
          mainLeg.steps = [...mainLeg.steps, ...legSteps];
          return mainLeg;
        },
        {
          steps: [],
          start_location: route.legs[0].start_location,
          end_location: route.legs[route.legs.length - 1].end_location
        }
      )
    ];

    const directions = {
      routes: [route],
      request: {
        travelMode: 'DRIVING'
      }
    };

    // https://gis.stackexchange.com/questions/15197/google-maps-v3-in-javascript-api-render-route-obtained-with-web-api
    directions.ub = directions.request;

    // context.result.directions = directions;

    return directions;
  };
};
