import feathers from '@feathersjs/client';
import io from 'socket.io-client';
import stateContainer from 'src/lib/state';
import { setIn } from 'straightline-utils/immutable';

import authentication from './authentication';
import { convertFormikError } from './hooks';
import logRocket from './logRocket';
import reactQuery from './reactQuery';
import sentry from './sentry';
import carrier from './services/carrier/carrier.service';
import contact from './services/contact/contact.service';
import documents from './services/documents/documents.service';
import directions from './services/google/directions';
import placePredictions from './services/google/place-predictions';
import places from './services/google/places';
import invitations from './services/invitations/invitations.service';
import shipmentDispatches from './services/shipment-dispatches/shipment-dispatches.service';
import shipmentEtas from './services/shipment-etas/shipment-etas.service';
import shipmentMatches from './services/shipment-matches/shipment-matches.service';
import shipmentOffers from './services/shipment-offers/shipment-offers.service';
import shipmentPreviews from './services/shipment-previews/shipment-previews.service';
import shipmentRequests from './services/shipment-requests/shipment-requests.service';
import shipmentRoutes from './services/shipment-routes/shipment-routes.service';
import shipmentStats from './services/shipment-stats/shipment-stats.service';
import shipmentStatusChanges from './services/shipment-status-changes/shipment-status-changes.service';
import shipmentTracking from './services/shipment-tracking/shipment-tracking.service';
import shipments from './services/shipments/shipments.service';
import signup from './services/signup/signup.service';
import user from './services/user/user.service';
import users from './services/users/users.service';
import verification from './services/verification/verification.service';
import weather from './services/weather/weather.service';

const socket = io(process.env.REACT_APP_API_URL, {
  transports: ['websocket'],
  forceNew: true
});

const app = feathers();

// Because sentry handles all uncaught exceptions,
// enable it before anything else
app.configure(sentry);

app.configure((app) => {
  const key = `${process.env.REACT_APP_LOCAL_STORAGE_TOKEN}:version`;
  const newVersion = process.env.REACT_APP_BUILD_ID;
  const currentVersion = localStorage.getItem(key);
  if (!currentVersion || currentVersion !== newVersion) {
    localStorage.clear();
  }
  localStorage.setItem(key, newVersion);
});

app.configure(feathers.socketio(socket));
app.configure(
  feathers.authentication({
    storage: localStorage,
    storageKey: process.env.REACT_APP_LOCAL_STORAGE_TOKEN
  })
);

app.configure((app) => {
  const makeParams = (params = {}) => {
    return setIn(params, 'query.$limit', 1);
  };
  const getResult = (result) => {
    const data = result.data || result;
    return Array.isArray(data) ? data[0] : data;
  };

  app.mixins.push(function (service) {
    service.findOne = function (params) {
      return service.find(makeParams(params)).then(getResult);
    };
  });
});

app.configure((app) => {
  app.mixins.push((service) => {
    service.timeout = 1000 * 10;
  });
});

// Automagically prepend all api/* routes with
// carrier-api/* to encourage use of the proper endpoints
app.configure((app) => {
  const oldService = app.service;
  app.service = function (path, ...args) {
    const finalPath = path.startsWith('api') ? `carrier-${path}` : path;
    return oldService.call(this, finalPath, ...args);
  };
});

app.configure(authentication);
app.configure(logRocket);
app.configure(reactQuery);

// Services/Hooks
app.configure(shipments);
app.configure(shipmentRequests);
app.configure(shipmentMatches);
app.configure(signup);
app.configure(documents);
app.configure(carrier);
app.configure(contact);
app.configure(users);
app.configure(invitations);
app.configure(user);
app.configure(verification);
app.configure(shipmentTracking);
app.configure(shipmentStats);
app.configure(shipmentRoutes);
app.configure(shipmentEtas);
app.configure(shipmentPreviews);
app.configure(shipmentStatusChanges);
app.configure(shipmentDispatches);
app.configure(shipmentOffers);

app.configure(weather);
app.configure(directions);
app.configure(placePredictions);
app.configure(places);

// App state container
app.configure((app) => {
  app.set('stateContainer', stateContainer);
});

// After all calls, if is not authed, delete the token
// and redirect to the login page with a full page reload
// to purge any data in memory.
const redirectUnAuthed = async (context) => {
  if (context.error.code === 401 && context.path !== 'authentication') {
    await context.app.cleanup();
  }
  return context;
};

// Hooks run on all services
app.hooks({
  error: { all: [redirectUnAuthed, convertFormikError] }
});

// Prep app state by fetching everything the app needs
// to run at app start up
app.configure((app) => {
  app.prep = async function () {
    const promises = [
      app
        .service('api/carrier')
        .find()
        .then((result) => {
          stateContainer.setInState('carrier', result);
        }),
      app
        .service('api/user')
        .find()
        .then((result) => {
          stateContainer.setInState('user', result);
        })
    ];
    return Promise.all(promises).then(() => {
      app.get('stateContainer').setInState('prepped', true);
    });
  };
});

app.configure((app) => {
  app.cleanup = async function (redirect) {
    if (app.authentication) {
      await app.authentication.removeAccessToken();
      await app.authentication.reset();
    }
    if (redirect) {
      window.location.href = `/login?redirect=${redirect}`;
    } else {
      window.location.href = '/login';
    }
  };
});

export default app;
