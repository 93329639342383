import React from 'react';
import ShipmentDocuments from 'src/components/ShipmentDocuments';
import { Col, Row } from 'straightline-ui';
import {
  DOCUMENT_TYPE,
  DOCUMENT_TYPES,
  ENTITY_TYPE
} from 'straightline-utils/constants';

const types = [
  DOCUMENT_TYPE.PROOF_OF_DELIVERY,
  DOCUMENT_TYPE.BILL_OF_LADING,
  DOCUMENT_TYPE.LUMPER_RECEIPT,
  DOCUMENT_TYPE.SCALE_TICKET,
  DOCUMENT_TYPE.DAMAGE,
  DOCUMENT_TYPE.COMMERCIAL_INVOICE,
  DOCUMENT_TYPE.PACKING_LIST,
  DOCUMENT_TYPE.OTHER
];

const documentTypes = DOCUMENT_TYPES.filter((docType) =>
  types.includes(docType.value)
);

export default class Documents extends React.Component {
  render() {
    const { shipment } = this.props;
    return (
      <Row>
        <Col xl="8">
          <ShipmentDocuments
            shipment={shipment}
            entity_type={ENTITY_TYPE.CARRIER}
            entity_id={shipment.carrier_id}
            edit={(shipmentDocument) =>
              shipmentDocument.document.entity_type === ENTITY_TYPE.CARRIER
            }
            upload={true}
            documentTypes={documentTypes}
          />
        </Col>
      </Row>
    );
  }
}
