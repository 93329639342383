import React from 'react';
import WithStripe from 'src/components/WithStripe';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';

function BankAccountForm({ stripe, onStart, onSuccess, onError, onCancel }) {
  const app = useApp();

  const service = app.service('api/verification');

  const handleSubmit = (data, formik) => {
    if (onStart) {
      onStart();
    }

    stripe
      .createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        account_holder_type: 'company',
        ...data
      })
      .then(({ error, token }) => {
        if (error) {
          throw error;
        }
        return service.create({
          external_account: token.id
        });
      })
      .then((paymentMethod) => {
        formik.setSubmitting(false);
        toast.success(`Bank added!`);
        if (onSuccess) {
          return onSuccess(paymentMethod);
        }
      })
      .catch((error) => {
        formik.setSubmitting(false);
        toast.errors(error);
        if (onError) {
          return onError(error);
        }
      });
  };

  return (
    <div data-private>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{
          routing_number: '',
          account_number: '',
          account_holder_name: ''
        }}
        context={{ method: 'create' }}
        validationSchema={service.bankAccountSchema}
        render={({ isSubmitting }) => {
          return (
            <Form>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Routing Number*</Label>
                    <FormInput
                      name="routing_number"
                      disabled={isSubmitting}
                      placeholder="110000000"
                    />
                    <FormFeedback name="routing_number" />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label>Account Number*</Label>
                    <FormInput
                      name="account_number"
                      disabled={isSubmitting}
                      placeholder="000123456789"
                    />
                    <FormFeedback name="account_number" />
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Account Holder Name*</Label>
                <FormInput
                  name="account_holder_name"
                  disabled={isSubmitting}
                  placeholder="Enter account holder name"
                />
                <FormFeedback name="account_holder_name" />
              </FormGroup>
              <Button
                color="primary"
                className={`mr-3 ${isSubmitting ? 'is-loading' : ''}`}
                type="submit"
                disabled={isSubmitting}
              >
                Submit
              </Button>
              <Button
                color="white"
                type="button"
                onClick={onCancel && onCancel}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
            </Form>
          );
        }}
      />
    </div>
  );
}

export default (props) => (
  <WithStripe
    render={({ stripe, elements }) => (
      <BankAccountForm stripe={stripe} elements={elements} {...props} />
    )}
  />
);
