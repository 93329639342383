import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import ContactModal from 'src/components/ContactModal';
import LineItemsCard from 'src/components/LineItemsCard';
import LoadingIndicator from 'src/components/LoadingIndicator';
import MapHeaderMatch from 'src/components/MapHeaderMatch';
import ShipmentBanner from 'src/components/ShipmentBanner';
import emptyImg from 'src/images/empty.svg';
import heavyBoxRedImg from 'src/images/heavy-box-red.svg';
import moneyTransferImg from 'src/images/money-transfer.svg';
import { shipmentToShipmentRequest } from 'src/lib';
import { useAppState } from 'src/lib/hooks';
import { Input } from 'straightline-ui';
import {
  AddressCard,
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Link,
  ListGroup,
  ListGroupItem,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { isBooked } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

// TODO: Memoize MapHeaderMatch because it is a heavy render
// cycle and fires alot during this page

export default function ShipmentMatch({ match, history }) {
  const app = useApp();
  const { state: appState, setInState: setInAppState } = useAppState();

  const getCarrier = app.service('api/carrier').useFind(null, {
    keepPreviousData: true,
    refetchInterval: 5000,
    enabled: !appState.carrier.vetted
  });

  const carrier = appState.carrier.vetted
    ? appState.carrier
    : getCarrier.result;

  useEffect(() => {
    if (!appState.carrier.vetted && carrier?.vetted) {
      setInAppState('carrier', carrier);
    }
  }, [appState.carrier, carrier, setInAppState]);

  const getShipment = app
    .service('api/shipment-matches')
    .useGet(match.params.id, null, {
      keepPreviousData: true
    });

  const { result: shipment } = getShipment;
  const offer =
    shipment?.offers &&
    [...shipment.offers].sort((a, b) => {
      return a.updated_at < b.updated_at ? 1 : -1;
    })[0];

  const postTruck = app.service('api/shipment-requests').useCreate();

  const handlePostTruck = async () => {
    postTruck
      .create(shipmentToShipmentRequest(shipment))
      .then((shipmentRequest) => {
        toast(
          <div className="p-3">
            <h2 className="text-dark">
              <Icon type="truck" className="mr-2" />
              Truck Posted!
            </h2>
            <div className="lead text-secondary mb-0">
              We have posted a truck from{' '}
              <span className="font-weight-bold">
                {shipment.pickup_address.city}, {shipment.pickup_address.state}
              </span>
              <Icon
                type="arrow-right"
                className="text-success font-weight-bold mx-2"
              />
              <span className="font-weight-bold">
                {shipment.delivery_address.city},{' '}
                {shipment.delivery_address.state}{' '}
              </span>
              . You can{' '}
              <Link to={`/shipment-requests/${shipmentRequest.id}`}>
                view and edit this truck
              </Link>{' '}
              anytime.
            </div>
          </div>,
          { autoClose: 10000, hideProgressBar: true }
        );
        app.service('api/shipment-matches').invalidateGet(shipment.id);
      })
      .catch((error) => {
        toast.errors(error);
      });
  };

  if (getShipment.isLoading || getCarrier.isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (getShipment.isError || getCarrier.isError) {
    return <Error error={getShipment.error || getCarrier.error} />;
  }

  if (shipment.carrier_id === carrier.id) {
    return <Redirect to={`/shipments/${getShipment.result.id}`} />;
  }

  if (!isBooked(shipment)) {
    return (
      <AlreadyMatched
        shipment={shipment}
        isLoading={postTruck.isLoading}
        handlePostTruck={handlePostTruck}
      />
    );
  }

  return (
    <div>
      <MapHeaderMatch
        shipment={shipment}
        // shipmentRequests={
        //   state.showRequests ? shipment.shipment_requests : null
        // }
      />
      <Container fluid className="pb-3">
        <div className="header">
          <div className="header-body">
            <Row className="align-items-center justify-content-between">
              <Col lg="auto" className="mb-3 mb-lg-0">
                <h6 className="header-pretitle">Load Board / Book Load</h6>
                <Row className="align-items-center mb-n1">
                  <Col
                    xs="auto"
                    className="mb-2 pr-0 align-items-center d-flex"
                  >
                    <h1 className="mb-0 font-weight-bold d-inline-block mr-3">{`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}</h1>
                    <Icon
                      type="arrow-right"
                      style={{ fontSize: 24 }}
                      className="text-success lead d-inline-block font-weight-bold"
                    />
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <h1 className="mb-0 font-weight-bold d-inline-block">{`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}</h1>
                  </Col>
                </Row>
              </Col>
              <Col lg="auto">
                <ContactModal
                  config={{
                    subject: `App Contact - Shipment #${shipment.id}`
                  }}
                >
                  {(toggle) => (
                    <Button color="light" className="mr-3" onClick={toggle}>
                      <Icon type="send" className="mr-2" />
                      Contact Straightline
                    </Button>
                  )}
                </ContactModal>
              </Col>
            </Row>
          </div>
        </div>
        <Card>
          <Row noGutters>
            <Col xl="6" className="">
              <div className="px-4 pt-4">
                <ShipmentBanner shipment={shipment} />
              </div>
              <div className="p-4">
                <ListGroup className="list-group list-group-flush list-group-activity mb-3">
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto">
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                            <Icon type="clipboard" />
                          </div>
                        </div>
                      </Col>
                      <Col className="ml-n2">
                        <div className="font-weight-bold">
                          {shipment.rate.service_level.name}
                        </div>
                        <div>{shipment.rate.service_level.description}</div>
                        {shipment.rate.service_level.code === 'TEM' && (
                          <div className="font-weight-bold text-primary">
                            ** This shipment requires two drivers **
                          </div>
                        )}
                        <div>
                          <span className="text-secondary">SL#: </span>
                          {shipment.id}
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto">
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                            <Icon type="truck" />
                          </div>
                        </div>
                      </Col>
                      <Col className="ml-n2">
                        <div>
                          {shipment.pickup_address.name && (
                            <div>{shipment.pickup_address.name}</div>
                          )}
                          <div>{shipment.pickup_address.address_1}</div>
                          <div>
                            {`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}
                          </div>
                          <div>
                            {formatDate(
                              shipment.pickup_date,
                              'iiii, LLLL d, yyyy'
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                  <ListGroupItem className="py-3">
                    <Row>
                      <Col xs="auto">
                        <div className="avatar avatar-sm">
                          <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                            <Icon type="map-pin" />
                          </div>
                        </div>
                      </Col>
                      <Col className="ml-n2">
                        <div>
                          {shipment.delivery_address.name && (
                            <div>{shipment.delivery_address.name}</div>
                          )}
                          <div>{shipment.delivery_address.address_1}</div>
                          <div>
                            {`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}
                          </div>
                          <div>
                            {formatDate(
                              shipment.due_date,
                              'iiii, LLLL d, yyyy'
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                </ListGroup>
                <LineItemsCard shipment={shipment} className="shadow-none" />
                <h5 className="text-uppercase text-muted font-weight-bold">
                  Notes
                </h5>
                <p className="pre-line mb-0">
                  {shipment.notes || <span className="text-muted">NA</span>}
                </p>
              </div>
            </Col>
            <Col xl="6" className="border-xl-left">
              {offer ? (
                <React.Fragment>
                  {offer.accepted_at ? (
                    <div className="p-4">
                      <div className="text-center">
                        <img
                          src={moneyTransferImg}
                          alt="Offer Accepted!"
                          className="img-fluid mb-4 animated fadeIn faster w-75"
                        />
                      </div>
                      <p className="lead">
                        Your offer of{' '}
                        <span className="font-weight-bold text-primary">
                          {formatMoney(offer.amount)}
                        </span>{' '}
                        has been accepted!
                      </p>
                      <BookNow
                        price={offer.amount}
                        vetted={carrier.vetted}
                        shipment={shipment}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="p-4 border-top border-xl-top-0">
                        <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
                          Make Offer
                        </h5>
                        <p className="">
                          Your offer is currently being reviewed. You will
                          receive a notification or email once your offer has
                          been accepted or declined.
                        </p>
                        <FormGroup>
                          <Label>Offer Notes</Label>
                          <textarea
                            className="form-control"
                            rows="4"
                            name="notes"
                            readOnly={true}
                          >
                            {offer.notes}
                          </textarea>
                        </FormGroup>
                        <FormGroup className="mb-0">
                          <Label>Offer Amount</Label>
                          <Alert color="light" className="mb-0">
                            <Row className="align-items-center">
                              <Col>
                                <div className="h2 mb-0">
                                  {formatMoney(offer.amount)}
                                </div>
                                <p className="mb-0">
                                  {formatMoney(
                                    (
                                      offer.amount /
                                      (shipment.travel.miles || 1)
                                    ).toFixed(1)
                                  )}
                                  <span> per mile</span>
                                </p>
                              </Col>
                              <Col className="text-right">
                                <div className="">
                                  Your offer of{' '}
                                  <span className="font-weight-bold">
                                    {formatMoney(offer.amount)}
                                  </span>
                                  <br />
                                  is being reviewed
                                </div>
                              </Col>
                            </Row>
                          </Alert>
                        </FormGroup>
                      </div>
                      <div className="p-4 border-top">
                        <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
                          Book Shipment
                        </h5>
                        <BookNow
                          price={shipment.rate.price.carrier_price}
                          vetted={carrier.vetted}
                          shipment={shipment}
                        />
                      </div>
                    </div>
                  )}
                </React.Fragment>
              ) : (
                <ShipmentOffer carrier={carrier} shipment={shipment} />
              )}
            </Col>
          </Row>
        </Card>
        <Row>
          <Col xl="6" lg="9" sm="12">
            <AddressCard shipment={shipment} type="pickup" />
          </Col>
          <Col xl="6" lg="9" sm="12">
            <AddressCard shipment={shipment} type="delivery" />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const ShipmentOffer = ({ carrier, shipment }) => {
  const app = useApp();
  const validationSchema = app.service('api/shipment-offers').schema;
  const makeOffer = app.service('api/shipment-offers').useCreate();
  const shipmentCPM = shipment.rate.price.carrier_price / shipment.travel.miles;
  const [data, setData] = useState({
    cpm: shipmentCPM,
    deadhead: 0
  });

  const miles = shipment.travel.miles + data.deadhead;
  const carrierCost = data.cpm * miles;

  const handleMakeOffer = (data, formik) => {
    makeOffer
      .create({
        shipment_id: shipment.id,
        ...data
      })
      .then((offer) => {
        formik.setSubmitting(false);
        app.service('api/shipment-matches').invalidateGet(shipment.id);
        if (offer.accepted_at) {
          toast(
            <div className="p-3">
              <h2 className="text-success">
                <Icon type="dollar-sign" className="mr-2" />
                Offer Accepted!
              </h2>
              <div className="lead text-secondary mb-0">
                Your offer has been accepted! You can now book this shipment for{' '}
                {formatMoney(offer.amount)}.
              </div>
            </div>,
            { autoClose: 5000, hideProgressBar: true }
          );
        } else {
          toast(
            <div className="p-3">
              <h2 className="text-dark">
                <Icon type="dollar-sign" className="mr-2" />
                Offer Submitted!
              </h2>
              <div className="lead text-secondary mb-0">
                We are reviewing your offer and will get back to you ASAP!
              </div>
            </div>,
            { autoClose: 5000, hideProgressBar: true }
          );
        }
      })
      .catch((error) => {
        toast.errors(error);
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      <div className="border-top border-xl-top-0">
        <Row noGutters>
          <Col xs="6" className="p-4">
            <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
              Fixed CPM
            </h5>
            <InputGroup className="input-group-merge">
              <Input
                type="money"
                className="form-control-prepended form-control-appended"
                value={data.cpm}
                onChange={({ target: { value } }) => {
                  value = value || 0;
                  setData({
                    ...data,
                    cpm: Number(value)
                  });
                }}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <Icon type="dollar-sign" />
                </InputGroupText>
              </InputGroupAddon>
              <InputGroupAddon addonType="append">
                <InputGroupText>/mile</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col xs="6" className="p-4">
            <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
              Deadhead
            </h5>
            <InputGroup className="input-group-merge">
              <Input
                type="number"
                className="form-control-appended"
                min="0"
                value={data.deadhead}
                onChange={({ target: { value } }) => {
                  value = value || 0;
                  setData({
                    ...data,
                    deadhead: Number(value)
                  });
                }}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>miles</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row noGutters className="border-top">
          <Col className="p-4 border-right">
            <h5 className="text-uppercase text-muted mb-1 font-weight-bold ">
              Cost
            </h5>
            <div>{formatMoney(carrierCost)}</div>
            <div>
              {formatMoney(carrierCost / miles)}
              <span className="text-secondary small">/mile</span>
            </div>
          </Col>
          <Col className="p-4 border-right">
            <h5 className="text-uppercase text-muted mb-1 font-weight-bold ">
              Payout
            </h5>
            <div>{formatMoney(shipment.rate.price.carrier_price)}</div>
            <div>
              {formatMoney(shipment.rate.price.carrier_price / miles)}
              <span className="text-secondary small">/mile</span>
            </div>
          </Col>
          <Col className="p-4">
            <h5 className="text-uppercase text-muted mb-1 font-weight-bold ">
              Profit
            </h5>
            <div>
              {formatMoney(shipment.rate.price.carrier_price - carrierCost)}
            </div>
            <div>
              {formatMoney(
                (shipment.rate.price.carrier_price - carrierCost) / miles
              )}
              <span className="text-secondary small">/mile</span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="p-4 border-top">
        <Formik
          onSubmit={handleMakeOffer}
          initialValues={{
            amount: undefined,
            shipment_id: shipment.id,
            notes: null
          }}
          validationSchema={validationSchema}
          render={({ isSubmitting, values }) => {
            return (
              <Form>
                <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
                  Make Offer
                </h5>
                <p className="">
                  You may make one counteroffer to this price. Straightline will
                  review your offer and get back to you ASAP!
                </p>
                <FormGroup>
                  <Label>Offer Notes</Label>
                  <FormInput
                    type="textarea"
                    rows="4"
                    name="notes"
                    disabled={isSubmitting}
                  />
                  <FormFeedback name="notes" />
                </FormGroup>
                <FormGroup className="mb-0">
                  <Row className="">
                    <Col className="pr-0">
                      <div className="d-flex justify-content-between">
                        <Label>Offer Amount</Label>
                        {Number(values.amount) - carrierCost > 0 && (
                          <Label className="text-success font-weight-bold">
                            +{formatMoney(Number(values.amount) - carrierCost)}
                          </Label>
                        )}
                      </div>
                      <InputGroup className="input-group-merge">
                        <FormInput
                          type="money"
                          name="amount"
                          className="form-control-prepended form-control-appended"
                          disabled={isSubmitting}
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <Icon type="dollar-sign" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            {formatMoney(
                              (Number(
                                values.amount ||
                                  shipment.rate.price.carrier_price
                              ) -
                                carrierCost) /
                                miles
                            )}
                            /mile
                          </InputGroupText>
                        </InputGroupAddon>
                        <FormFeedback name="amount" />
                      </InputGroup>
                    </Col>
                    <Col xs="auto" className="text-right pt-4 mt-2">
                      <Button
                        block
                        color="primary"
                        disabled={isSubmitting}
                        className={`${isSubmitting ? 'is-loading' : ''}`}
                      >
                        <Icon type="send" className="mr-2" />
                        Make Offer
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
                {!carrier.vetted && (
                  <div className="text-danger">
                    You may make an offer even though your business is not yet
                    vetted. Once the vetting process is complete, your offer
                    will be reviewed.
                  </div>
                )}
              </Form>
            );
          }}
        />
      </div>
      <div className="p-4 border-top">
        <h5 className="text-uppercase text-muted mb-2 font-weight-bold">
          Book Shipment
        </h5>
        <BookNow
          price={shipment.rate.price.carrier_price}
          vetted={carrier.vetted}
          shipment={shipment}
        />
      </div>
    </div>
  );
};

const BookNow = ({ vetted, price, shipment }) => {
  const app = useApp();
  const history = useHistory();
  const bookMatch = app.service('api/shipment-matches').useUpdate();

  const handleBookMatch = () => {
    bookMatch
      .update(shipment.id, {})
      .then((updatedShipment) => {
        toast(
          <div className="p-3">
            <h2 className="text-dark">
              <Icon type="package" className="mr-2" />
              Load Booked!
            </h2>
            <div className="lead text-secondary mb-0">
              You can now view detailed shipment information, change the load
              status, view your{' '}
              <Link
                to={`/shipments/${updatedShipment.id}?tab=rate-confirmation`}
              >
                rate confirmation
              </Link>{' '}
              and more!
            </div>
          </div>,
          { autoClose: 10000, hideProgressBar: true }
        );
        history.push(`/shipments/${updatedShipment.id}`);
      })
      .catch((error) => {
        if (error.code === 409) {
          // 409 === match already accepted.
          app.service('api/shipment-matches').invalidateGet(shipment.id);
        }
        toast.errors(error);
      });
  };

  return (
    <div>
      {vetted ? (
        <div>
          <p>
            After booking this load, you will have immediate access to your Rate
            Confirmation, shipment contacts, documents and other tools.
          </p>
          <p>By booking this load you accept all terms and conditions.</p>
          <Button
            onClick={handleBookMatch}
            color="primary"
            size="lg"
            block
            className={`${bookMatch.isLoading ? 'is-loading' : ''}`}
            disabled={bookMatch.isLoading}
            style={{ fontSize: '1rem' }}
          >
            <Icon type="package" className="mr-2" />
            Book for {formatMoney(price)}
          </Button>
        </div>
      ) : (
        <div>
          <p className="text-danger">
            Your business has not been vetted and you cannot book loads yet.
            Please click "Complete Settings" below to finalize your account
            setup.
          </p>
          <Button
            color="primary"
            to="/settings"
            block
            // disabled={isBooking}
          >
            <Icon type="settings" className="mr-2" />
            Complete Settings
          </Button>
        </div>
      )}
    </div>
  );
};

const AlreadyMatched = ({ shipment, isLoading }) => {
  return (
    <div>
      <MapHeaderMatch
        shipment={shipment}
        // shipmentRequests={showRequests ? shipment.shipment_requests : null}
      />
      <Container fluid className="pb-3">
        <div className="header">
          <div className="header-body">
            <Row className="align-items-center justify-content-between">
              <Col lg="auto" className="mb-3 mb-lg-0">
                <h6 className="header-pretitle">Load Board / Book Load</h6>
                <Row className="align-items-center mb-n1">
                  <Col
                    xs="auto"
                    className="mb-2 pr-0 align-items-center d-flex"
                  >
                    <h1 className="mb-0 font-weight-bold d-inline-block mr-3">{`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}</h1>
                    <Icon
                      type="arrow-right"
                      style={{ fontSize: 24 }}
                      className="text-success lead d-inline-block font-weight-bold"
                    />
                  </Col>
                  <Col xs="auto" className="mb-2">
                    <h1 className="mb-0 font-weight-bold d-inline-block">{`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}</h1>
                  </Col>
                </Row>
              </Col>
              <Col lg="auto">
                <div className="mb-1">
                  <Button color="light" className="mr-3" disabled={isLoading}>
                    <Icon type="help-circle" className="mr-2" />
                    Contact Straightline
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Card>
          <div className="card-header">
            <div className="d-flex align-items-center">
              <h3 className="mb-0">
                {shipment.truck.label.toUpperCase()} &#9679;{' '}
                {shipment.load_weight?.toLocaleString() || '3,250'} lbs
              </h3>
            </div>
          </div>
          <CardBody>
            <Row>
              <Col
                xl="5"
                md="6"
                className="mb-4 mb-md-0 pr-md-4 order-2 order-md-1"
              >
                <Row className="mb-4">
                  <Col xs="6">
                    <h5 className="text-uppercase text-muted font-weight-bold">
                      Pickup Address
                    </h5>
                    <div
                      className="bg-light rounded mb-2"
                      style={{ height: 18, width: 95 }}
                    />
                    <div
                      className="bg-light rounded mb-2"
                      style={{ height: 18, width: 115 }}
                    />
                  </Col>
                  <Col xs="6" className="text-right">
                    <h5 className="text-uppercase text-muted font-weight-bold">
                      Pickup Date and Time
                    </h5>
                    <div
                      className="bg-light rounded mb-2 ml-auto"
                      style={{ height: 18, width: 75 }}
                    />
                    <div
                      className="bg-light rounded mb-2 ml-auto"
                      style={{ height: 18, width: 105 }}
                    />
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col xs="6">
                    <h5 className="text-uppercase text-muted font-weight-bold">
                      Delivery Address
                    </h5>
                    <div
                      className="bg-light rounded mb-2"
                      style={{ height: 18, width: 95 }}
                    />
                    <div
                      className="bg-light rounded mb-2"
                      style={{ height: 18, width: 115 }}
                    />
                  </Col>
                  <Col xs="6" className="text-right">
                    <h5 className="text-uppercase text-muted font-weight-bold">
                      Delivery Date and Time
                    </h5>
                    <div
                      className="bg-light rounded mb-2 ml-auto"
                      style={{ height: 18, width: 75 }}
                    />
                    <div
                      className="bg-light rounded mb-2 ml-auto"
                      style={{ height: 18, width: 105 }}
                    />
                  </Col>
                </Row>
                <h5 className="text-uppercase text-muted font-weight-bold">
                  Notes
                </h5>
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 18, width: '100%' }}
                />
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 18, width: '98%' }}
                />
                <div
                  className="bg-light rounded mb-2"
                  style={{ height: 18, width: '70%' }}
                />
              </Col>
              <Col xl="7" md="6" className="border-md-left">
                <div className="text-center">
                  <img
                    src={heavyBoxRedImg}
                    alt="Not Found"
                    className="img-fluid mb-4 animated fadeIn faster w-75"
                  />
                  <h1>This load is no longer available</h1>
                  <p className="lead">
                    Most likely another carrier accepted the load before you.
                    It's also possible that the load was cancelled by the
                    shipper.
                  </p>
                  <Button
                    to="/shipment-matches"
                    color="primary"
                    size="lg"
                    className="mr-3"
                  >
                    <Icon type="clipboard" className="mr-2" />
                    Load Board
                  </Button>
                  <Button to="/support" color="secondary" size="lg">
                    <Icon type="send" className="mr-2" />
                    Contact Straightline
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

const Error = ({ error }) => {
  return (
    <Container>
      <Row className="align-items-center justify-content-center vh-100">
        <Col lg="6" className="text-center">
          <img
            src={emptyImg}
            alt="Not Found"
            className="img-fluid animated fadeIn faster mb-5"
          />
          {error.code === 404 ? (
            <h1>Could not find that load</h1>
          ) : (
            <h1>{error.message}</h1>
          )}
          <Button
            to="/shipment-matches"
            color="primary"
            size="lg"
            className="mr-3"
          >
            <Icon type="clipboard" className="mr-2" />
            Load Board
          </Button>
          <Button to="/support" color="secondary" size="lg">
            <Icon type="send" className="mr-2" />
            Contact Straightline
          </Button>
        </Col>
      </Row>
    </Container>
  );
};
