import 'chart.js/auto';

import {
  Bar as ReactBar,
  Chart as ReactChart,
  Doughnut as ReactDoughnut,
  Line as ReactLine
} from 'react-chartjs-2';

export const chartColors = {
  gray: {
    100: '#95AAC9',
    300: '#E3EBF6',
    600: '#95AAC9',
    700: '#6E84A3',
    900: '#283E59'
  },
  primary: {
    100: '#D2DDEC',
    300: '#A6C5F7',
    700: '#2C7BE5'
  },
  black: '#12263F',
  white: '#FFFFFF',
  transparent: 'transparent'
};

export const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  // defaultColor: colors.primary[600],
  // defaultFontColor: colors.gray[600],
  // defaultFontSize: 13,
  backgroundColor: chartColors.primary[700],
  borderRadius: 5,
  font: {
    family: 'Cerebri Sans',
    size: 13
  },
  layout: {
    padding: 0
  },
  plugins: {
    legend: {
      display: false
      // position: 'right'
    }
  },
  elements: {
    point: {
      radius: 4,
      backgroundColor: chartColors.primary[700]
    },
    line: {
      tension: 0.4,
      borderWidth: 3,
      borderColor: chartColors.primary[700],
      backgroundColor: chartColors.transparent,
      borderCapStyle: 'rounded'
    },
    rectangle: {
      backgroundColor: chartColors.primary[700]
    },
    arc: {
      borderWidth: 4,
      backgroundColor: chartColors.primary[700]
    }
  },
  scales: {
    x: {
      grid: {
        display: false
        // borderDash: [0],
        // borderColor: colors.gray[300],
        // drawTicks: true
      },
      ticks: {
        // display: false
        color: chartColors.gray[600],
        font: {
          size: 13
        }
      }
    },
    y: {
      grid: {
        drawBorder: false,
        borderDash: [5],
        color: chartColors.gray[300],
        borderColor: chartColors.gray[300],
        tickColor: 'transparent'
      },
      ticks: {
        // display: false
        color: chartColors.gray[600],
        font: {
          size: 13
        }
      }
    }
  }
};

const deepMerge = (obj1, obj2) => {
  obj1 = { ...obj1 };
  Object.entries(obj2).forEach(([key, value]) => {
    if (
      typeof value === 'object' &&
      value !== null &&
      value.constructor === Object
    ) {
      obj1[key] = deepMerge(obj1[key], value);
    } else {
      obj1[key] = value;
    }
  });
  return obj1;
};

export function Chart({ options, ...rest }) {
  options = deepMerge(chartOptions, options);
  return <ReactChart options={options} {...rest} />;
}

export function Bar({ options, ...rest }) {
  options = deepMerge(chartOptions, options);
  return <ReactBar options={options} {...rest} />;
}

export function Line({ options, ...rest }) {
  options = deepMerge(chartOptions, options);
  return <ReactLine options={options} {...rest} />;
}

export function Doughnut({ options, ...rest }) {
  options = deepMerge(chartOptions, options);
  return <ReactDoughnut options={options} {...rest} />;
}
