import React from 'react';
import {
  Alert,
  Button,
  Col,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  Row,
  useApp,
  useStateIn
} from 'straightline-ui';
import { addMinutes, formatDate } from 'straightline-utils/time';

const makeDefaultTimes = () => {
  const now = new Date();
  return {
    '15 minutes': addMinutes(now, 15),
    '30 minutes': addMinutes(now, 30),
    '1 hour': addMinutes(now, 60),
    '1.5 hours': addMinutes(now, 90),
    '2 hours': addMinutes(now, 120),
    '3 hours': addMinutes(now, 180)
  };
};

export default function EtaModal({ open, shipment, onCancel, onComplete }) {
  const {
    state: { isSetting, defaultTimes },
    assignState
  } = useStateIn({ isSetting: false, defaultTimes: makeDefaultTimes() });

  const toggleSetting = () => {
    assignState({ isSetting: !isSetting });
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
  };

  const handleOnComplete = (newEta) => {
    if (onComplete) {
      onComplete(newEta);
    }
  };

  const handleOnOpened = () => {
    assignState({ defaultTimes: makeDefaultTimes() });
  };

  const handleOnClosed = () => {
    assignState({ isSetting: false });
  };

  return (
    <Modal
      isOpen={open}
      onOpened={handleOnOpened}
      onClosed={handleOnClosed}
      style={{ maxWidth: 500 }}
      className="modal-dialog-centered"
    >
      {() => (
        <ModalBody>
          <h2 className="mb-2">How long before you arrive?</h2>
          <p className="text-muted">This will keep everyone up to date.</p>
          {isSetting ? (
            <TimePicker
              shipment={shipment}
              toggleSetting={toggleSetting}
              onComplete={handleOnComplete}
              onCancel={handleCancel}
            />
          ) : (
            <DefaultPicker
              shipment={shipment}
              defaultTimes={defaultTimes}
              toggleSetting={toggleSetting}
              onComplete={handleOnComplete}
              onCancel={handleCancel}
            />
          )}
        </ModalBody>
      )}
    </Modal>
  );
}

function DefaultPicker({
  shipment,
  defaultTimes,
  onComplete,
  onCancel,
  toggleSetting
}) {
  const app = useApp();

  const {
    state: { isSubmitting, checked, error },
    assignState
  } = useStateIn({ isSubmitting: false, checked: null, error: null });

  // state = {
  //   checked: null,
  //   isSubmitting: false
  // };

  const handleChange = (date) => {
    assignState({ checked: date, error: null });
  };

  const handleSubmit = () => {
    const date = checked;
    if (!date) {
      return assignState({ error: { message: 'Please select an ETA' } });
    }

    let hoursVal = date.getHours();
    hoursVal = hoursVal >= 10 ? hoursVal.toString() : `0${hoursVal}`;

    let minsVal = date.getMinutes();
    minsVal = minsVal >= 10 ? minsVal.toString() : `0${minsVal}`;

    assignState({ isSubmitting: true });

    return app
      .service('api/shipment-etas')
      .create({
        shipment_id: shipment.id,
        date: formatDate(new Date(date), 'dateOnly'),
        time: `${hoursVal}:${minsVal}`
      })
      .then(async (newEta) => {
        assignState({ isSubmitting: true, checked: null });
        if (onComplete) {
          return onComplete(newEta);
        }
      })
      .catch((error) => {
        assignState({ isSubmitting: false, error, checked: null });
      });
  };

  return (
    <React.Fragment>
      {error && (
        <Alert color="danger" className="animated fadeIn faster">
          {error.message}
        </Alert>
      )}
      <ListGroup className="mb-4">
        {Object.entries(defaultTimes).map(([key, value]) => {
          return (
            <ListGroupItem
              key={key}
              tag="button"
              className="list-group-item-action"
              onClick={() => handleChange(value)}
            >
              <Row className="align-items-center">
                <Col>
                  <h4 className="mb-1">{formatDate(value, 'milDateTime')}</h4>
                  <p className="text-muted mb-0">{key} from now</p>
                </Col>
                <Col xs="auto">
                  <div className="custom-control custom-checkbox mr-n3 d-inline-block">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={checked === value}
                    />
                    <label className="custom-control-label"></label>
                  </div>
                </Col>
              </Row>
            </ListGroupItem>
          );
        })}
      </ListGroup>
      <Button
        color="primary"
        size="lg"
        block
        type="button"
        disabled={isSubmitting}
        className={isSubmitting ? 'is-loading' : ''}
        onClick={handleSubmit}
      >
        Update ETA
      </Button>
      <Button
        color="white"
        size="lg"
        block
        type="button"
        disabled={isSubmitting}
        onClick={toggleSetting}
      >
        My ETA is different
      </Button>
      <Button
        color="white"
        size="lg"
        block
        type="button"
        disabled={isSubmitting}
        onClick={onCancel}
      >
        Cancel
      </Button>
    </React.Fragment>
  );
}

function TimePicker({ shipment, onComplete, onCancel, toggleSetting }) {
  const app = useApp();

  const handleSubmit = (data, formik) => {
    return app
      .service('api/shipment-etas')
      .create({
        ...data,
        shipment_id: shipment.id
      })
      .then(async (newEta) => {
        formik.setSubmitting(false);
        if (onComplete) {
          return onComplete(newEta);
        }
      })
      .catch((error) => {
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      <Formik
        initialValues={{
          date: '',
          time: '',
          notes: ''
        }}
        onSubmit={handleSubmit}
        render={({ isSubmitting, submitForm }) => {
          return (
            <React.Fragment>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    <Label>Date*</Label>
                    <FormInput
                      name="date"
                      type="date"
                      icon="calendar"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="date" />
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Label>Time*</Label>
                    <Label className="float-right small text-muted mt-1">
                      24hr clock
                    </Label>
                    {/* <FormInput
                      name="time"
                      type="time-string"
                      disabled={isSubmitting}
                    />
                    <FormFeedback name="time" /> */}
                    <InputGroup className="input-group-merge">
                      <FormInput
                        type="time-string"
                        name="time"
                        className="form-control-prepended"
                        disabled={isSubmitting}
                      />
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <Icon type="clock" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <FormFeedback name="time" />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup>
                <Label>Notes</Label>
                <FormInput
                  name="notes"
                  type="textarea"
                  rows="4"
                  disabled={isSubmitting}
                />
                <FormFeedback name="notes" />
              </FormGroup>

              <Button
                color="primary"
                size="lg"
                block
                type="button"
                disabled={isSubmitting}
                className={isSubmitting ? 'is-loading' : ''}
                onClick={submitForm}
              >
                Update ETA
              </Button>
              <Button
                color="white"
                size="lg"
                block
                type="button"
                disabled={isSubmitting}
                onClick={toggleSetting}
              >
                My ETA is different
              </Button>
              <Button
                color="white"
                size="lg"
                block
                type="button"
                disabled={isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </React.Fragment>
          );
        }}
      />
    </div>
  );
}
