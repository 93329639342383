import { withResult } from 'feathers-fletching';
import { disallow } from 'feathers-hooks-common';
import { authenticateClient } from 'src/feathers/hooks';

const withResults = withResult({
  position: (result) => ({
    lat: Number(result.latitude),
    lng: Number(result.longitude)
  })
});

export default {
  before: {
    all: [authenticateClient],
    find: [],
    get: [],
    create: [],
    update: [disallow()],
    patch: [disallow()],
    remove: [disallow()]
  },
  after: {
    all: [withResults],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
