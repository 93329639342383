import React from 'react';
import QRCode from 'react-qr-code';
import logo from 'src/images/logo-md.png';
import { useAppState } from 'src/lib/hooks';
import { Col, Row, useApp } from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { ENTITY_TYPE, PACKAGE_TYPES } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

export default function RateConfirmation({
  shipment,
  className = '',
  ...rest
}) {
  const { state } = useAppState();

  const carrier = state.carrier ? state.carrier : shipment.carrier;

  return (
    <div className={`bol bg-white p-4  ${className}`} {...rest}>
      <Row className="align-items-center">
        <Col sm="6" className="mb-3">
          <img
            src={logo}
            alt="..."
            className="img-fluid"
            style={{ maxWidth: 200 }}
          />
        </Col>
        <Col sm="6" className="mb-3 text-sm-right">
          <h6 className="text-uppercase text-muted mb-1">Load ID</h6>
          <p className="mb-0 lead">{shipment.id}</p>
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      <Row>
        <Col sm="6" className="mb-3">
          <h6 className="text-uppercase text-muted">Dispatched</h6>
          <p className="mb-0">
            Straightsoft, Inc. dba Straightline
            <br />
            3750 Hacks Cross Rd, Suite 102-205 <br />
            Memphis, TN 38125 <br />
            (901) 295-8844 <br />
            accounting@bookstraightline.com
          </p>
        </Col>
        <Col sm="6" className="text-sm-right mb-3">
          <h6 className="text-uppercase text-muted">Carrier</h6>
          {carrier ? (
            <>
              <p className="mb-0">{carrier.name}</p>
              <p className="mb-0">
                {carrier.mc_number
                  ? `MC-${carrier.mc_number}`
                  : `MC Not Available`}
              </p>
              <p className="mb-0">
                {carrier.dot_number
                  ? `DOT-${carrier.dot_number}`
                  : `DOT Not Available`}
              </p>
            </>
          ) : (
            <>
              <p className="mb-0 text-danger">Not Assigned</p>
            </>
          )}
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      <Row>
        <Col xs="auto" className="mb-3">
          <h6 className="text-uppercase text-muted">Service</h6>
          {shipment.rate.service_level.name}
        </Col>
        <Col xs="auto" className="mb-3">
          <h6 className="text-uppercase text-muted">Weight</h6>
          {shipment.load_weight.toLocaleString()} lbs
        </Col>
        <Col xs="auto" className="mb-3">
          <h6 className="text-uppercase text-muted">Equipment</h6>
          {shipment.truck.label}
        </Col>
        <Col xs="auto" className="ml-auto text-right mb-3">
          <h6 className="text-uppercase text-muted">Payout</h6>
          <p className="mb-0 text-success font-weight-bold">
            {formatMoney(shipment.rate.price.carrier_price)}
          </p>
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      <Row>
        <Col xs="auto" className="mb-3">
          <h6 className="text-uppercase text-muted">Shipper</h6>
          <p className="mb-0">{shipment.shipper.name}</p>
        </Col>
        <Col xs="auto" className="ml-sm-auto text-sm-right mb-3">
          <h6 className="text-uppercase text-muted">Pickup Number</h6>
          <p className="mb-0">{shipment.reference_number || 'NA'}</p>
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      <Row>
        <Col sm="6" className="mb-3">
          <h6 className="text-uppercase text-muted">Pickup</h6>
          {shipment.pickup_address.name && (
            <p className="mb-0">{shipment.pickup_address.name}</p>
          )}
          <p className="mb-0">{shipment.pickup_address.address_1}</p>
          <p className="mb-0">
            {shipment.pickup_address.city},&nbsp;
            {shipment.pickup_address.state}
            ,&nbsp;
            {shipment.pickup_address.postal_code}
          </p>
          <p className="mb-0">
            {formatDate(shipment.pickup_date, 'LLL do, yyyy')}
          </p>
          <p className="mb-0">{shipment.pickup_address.appointment}</p>
        </Col>
        <Col sm="6" className="text-sm-right mb-3">
          <h6 className="text-uppercase text-muted">Pickup Contact</h6>
          <p className="mb-0">{shipment.pickup_address.contact}</p>
          <p className="mb-0">{shipment.pickup_address.phone}</p>
          <p className="mb-0">{shipment.pickup_address.email}</p>
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      <Row>
        <Col sm="6" className="mb-3">
          <h6 className="text-uppercase text-muted">Delivery</h6>
          {shipment.delivery_address.name && (
            <p className="mb-0">{shipment.delivery_address.name}</p>
          )}
          <p className="mb-0">{shipment.delivery_address.address_1}</p>
          <p className="mb-0">
            {shipment.delivery_address.city},&nbsp;
            {shipment.delivery_address.state}
            ,&nbsp;
            {shipment.delivery_address.postal_code}
          </p>
          <p className="mb-0">
            {formatDate(shipment.due_date, 'LLL do, yyyy')}
          </p>
          <p className="mb-0">{shipment.delivery_address.appointment}</p>
        </Col>
        <Col sm="6" className="text-sm-right mb-3">
          <h6 className="text-uppercase text-muted">Delivery Contact</h6>
          <p className="mb-0">{shipment.delivery_address.contact}</p>
          <p className="mb-0">{shipment.delivery_address.phone}</p>
          <p className="mb-0">{shipment.delivery_address.email}</p>
        </Col>
      </Row>
      <hr className="mt-0 mb-3" />
      {shipment.line_items.map((line_item, index) => {
        const packageType = PACKAGE_TYPES.find(
          (type) => type.value === line_item.type
        );
        return (
          <div key={index}>
            <Row>
              <Col sm="6" className="mb-3">
                <h6 className="text-uppercase text-muted">Pallets</h6>
                <div>
                  {line_item.units} {packageType.label}
                </div>
                <div>{line_item.description}</div>
              </Col>
              <Col sm="6" className="text-sm-right mb-3">
                <h6 className="text-uppercase text-muted">Dimensions</h6>
                <div>
                  <span>
                    {line_item.total_weight.toLocaleString('en-US')} lbs
                  </span>
                  {/* <Icon type="dot" className="mx-2 text-muted" />
                  <span>{line_item.freight_class} cls</span> */}
                </div>
                <div>
                  {line_item.length}
                  <span className="small"> x </span>
                  {line_item.width}
                  <span className="small"> x </span>
                  {line_item.height} in
                </div>
              </Col>
            </Row>
          </div>
        );
      })}
      <hr className="mt-0 mb-3" />
      <div className="mb-3">
        <h6 className="text-uppercase text-muted">Special Instructions</h6>
        <p className="pre-line mb-0">{shipment.notes || 'NA'}</p>
      </div>
      <hr className="mt-0 mb-3" />
      <div>
        <ShareQRCode shipment={shipment} />
      </div>
    </div>
  );
}

// This `driver_url` is used for shipment shares where the user is
// not authenticated to make the api/shipment-shares req. See
// server carrier-api/shipment-previews
function ShareQRCode({ shipment }) {
  const app = useApp();

  const { result: share } = app.service('api/shipment-shares').useFindOne(
    {
      query: {
        shipment_id: shipment.id,
        type: ENTITY_TYPE.CARRIER,
        $select: ['id']
      }
    },
    {
      enabled: !shipment.driver_url,
      refetchOnWindowFocus: false,
      keepPreviousData: true
    }
  );

  let Component = (
    <div className="bg-light text-center d-flex align-items-center justify-content-center h-100 w-100"></div>
  );

  if (share || shipment.driver_url) {
    const value =
      shipment.driver_url ||
      `${process.env.REACT_APP_DRIVER_APP_URL}/${share.id}`;
    Component = (
      <QRCode
        // size={100}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={value}
      />
    );
  }

  return (
    <div className="d-flex">
      <div className="mr-2">
        <div className="p-2" style={{ width: 90, height: 90 }}>
          {Component}
        </div>
      </div>
      <div className="pt-2">
        <h6 className="text-uppercase text-muted">STRAIGHTLINE DRIVER APP</h6>
        <div className="">
          Scan the QR to use the free web app. The app does not require download
          or login and allows you to update status, ETA, and documents.
        </div>
      </div>
    </div>
  );
}
