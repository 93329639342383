import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import CarrierDocuments from 'src/components/CarrierDocuments';
import { TruckTypePicker } from 'src/components/TruckTypePicker';
import addressImg from 'src/images/address.svg';
import filesImg from 'src/images/files-and-folder.svg';
import logisticsImg from 'src/images/logistics.svg';
import moneyTransferImg from 'src/images/money-transfer.svg';
import newYorkImg from 'src/images/new-york.svg';
import userGroupImg from 'src/images/user-group.svg';
import {
  AddressCardPreview,
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputAddress,
  Label,
  Link,
  Row,
  TabContent,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TabPane,
  toast,
  useApp,
  useScreenSize
} from 'straightline-ui';
import { formatMoney, roundNumberToLabel } from 'straightline-utils/accounting';
import {
  DOCUMENT_TYPE,
  ROLE,
  STATUS,
  TRUCK_TYPE
} from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

const Image = ({ src, alt }) => {
  const { isXs, isSm } = useScreenSize();
  const maxHeight = isXs || isSm ? 225 : 375;
  return (
    <div className="d-flex justify-content-center">
      <img
        src={src}
        alt={alt || 'Onboarding'}
        style={{ maxHeight }}
        className="mb-4 mb-md-5 animated fadeIn img-fluid"
      />
    </div>
  );
};

const Header = ({ children }) => {
  return <h1 className="text-md-center">{children}</h1>;
};

const Lead = ({ children }) => {
  return (
    <p className="mb-4 mb-md-5 text-md-center text-muted lead">{children}</p>
  );
};

const Tab = ({ children, ...rest }) => {
  const { isLg, isXl } = useScreenSize();
  const hasNavBar = !isLg && !isXl;
  return (
    <TabPane {...rest} mountOnEnter={false}>
      <div
        style={{
          height: hasNavBar ? 'calc(100vh - 61px)' : '100vh'
        }}
      >
        <div
          className="h-100 d-flex flex-column justify-content-between pt-5 pb-3 py-md-6"
          style={{
            maxHeight: 800
          }}
        >
          {children}
        </div>
      </div>
    </TabPane>
  );
};

const Buttons = (props) => {
  return <div className="mt-auto d-flex justify-content-between" {...props} />;
};

export default function Guide({ location }) {
  const { isXs, isSm } = useScreenSize();
  const isSmall = isXs || isSm;
  const step = location.query.step || '1';
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xl="8">
          <TabContent activeTab={step}>
            <Tab tabId="1">
              {/* <Image src={sanFranImg} alt="San Fransisco" /> */}
              <Image src={newYorkImg} alt="New York" />
              <Header>Let's get rolling!</Header>
              <Lead>
                You're just a few easy steps away from booking loads and getting
                paid faster than ever. Follow the guide to get setup. You can
                always get back to this page by using the "Guide" link in the
                menu.
              </Lead>
              <Buttons>
                <div>{/*justify-content-between-spacer*/}</div>
                <Button to="/guide?step=2" color="primary">
                  Next
                  <Icon type="arrow-right" className="ml-2" />
                </Button>
              </Buttons>
            </Tab>

            <Tab tabId="2">
              <Image src={filesImg} alt="Upload Files" />
              <Header>Upload Documents</Header>
              <Lead>
                Upload your W9 and other related documents by visiting
                "Settings" in the menu. Straightline will use this information
                along with your MC and DOT numbers to review and confirm your
                business.
              </Lead>
              <div>
                <CarrierDocuments />
              </div>
              <div className="pb-4">
                <Buttons>
                  <div>
                    <Button to="/guide?step=1" color="white">
                      <Icon type="arrow-left" className="mr-2" />
                      Back
                    </Button>
                  </div>
                  <div>
                    <Button to="/settings" color="primary" className="mr-3">
                      <Icon type="settings" className="mr-2" />
                      Settings
                    </Button>
                    <Button to="/guide?step=3" color="primary">
                      Skip
                      <Icon type="arrow-right" className="ml-2" />
                    </Button>
                  </div>
                </Buttons>
              </div>
            </Tab>

            <Tab tabId="3">
              <Image src={logisticsImg} alt="New York" />
              <Header>Post a Truck</Header>
              <Lead>
                Request loads by telling Straightline when and where your truck
                will be empty. When a new load is available you will be notified
                and can book instantly! You can make new requests anytime by
                clicking "Post Truck" in the menu.
              </Lead>
              <div className="mt-4">
                <ShipmentRequest />
              </div>
              <div className="pb-4">
                <Buttons>
                  <div>
                    <Button to="/guide?step=2" color="white">
                      <Icon type="arrow-left" className="mr-2" />
                      Back
                    </Button>
                  </div>
                  <div>
                    <Button
                      to="/shipment-requests"
                      color="primary"
                      className="mr-3"
                    >
                      <Icon type="truck" className="mr-2" />
                      View Trucks
                    </Button>
                    <Button to="/guide?step=4" color="primary">
                      Skip
                      <Icon type="arrow-right" className="ml-2" />
                    </Button>
                  </div>
                </Buttons>
              </div>
            </Tab>

            <Tab tabId="4">
              <Image src={addressImg} alt="Find Loads" />
              <Header>Find Loads</Header>
              <Lead>
                Use the "Load Board" link in the menu to find loads anywhere in
                the country. You can view detailed information, maps, and images
                for any load. You can even make offers on loads you like.
              </Lead>
              <div>
                <ShipmentMatches />
              </div>
              <div className="pb-4">
                <Buttons>
                  <div>
                    <Button to="/guide?step=3" color="white">
                      <Icon type="arrow-left" className="mr-2" />
                      Back
                    </Button>
                  </div>
                  <div>
                    <Button
                      to="/shipment-matches"
                      color="primary"
                      className="mr-3"
                    >
                      <Icon type="clipboard" className="mr-2" />
                      {!isSmall && 'Find '}Loads
                    </Button>
                    <Button to="/guide?step=5" color="primary">
                      Skip
                      <Icon type="arrow-right" className="ml-2" />
                    </Button>
                  </div>
                </Buttons>
              </div>
            </Tab>

            <Tab tabId="5">
              <Image src={userGroupImg} alt="Invite Users" />
              <Header>Invite Team</Header>
              <Lead>
                Invite team members to help manage your business. You can invite
                other admins as well as drivers who will have access to the
                Straightline Driver App to enable realtime updates.
              </Lead>
              <div>
                <Invitations />
              </div>

              <div className="pb-4">
                <Buttons>
                  <div>
                    <Button to="/guide?step=4" color="white">
                      <Icon type="arrow-left" className="mr-2" />
                      Back
                    </Button>
                  </div>
                  <div>
                    <Button
                      to="/settings/users"
                      color="primary"
                      className="mr-3"
                    >
                      <Icon type="users" className="mr-2" />
                      {!isSmall && 'View '}Team
                    </Button>
                    <Button to="/guide?step=6" color="primary">
                      Skip
                      <Icon type="arrow-right" className="ml-2" />
                    </Button>
                  </div>
                </Buttons>
              </div>
            </Tab>

            {/* <Tab tabId="6">
              <Image src={moneyTransferImg} alt="Setup Payments" />
              <Header>Setup Payments</Header>
              <Lead>
                Straightline uses <a href="https://www.stripe.com">Stripe</a> to
                make sure you get paid on time and to keep your personal bank
                and details secure. Complete the verification process to enable
                the fastest payout process in the industry.
              </Lead>

              <Buttons>
                <div>
                  <Button to="/guide?step=2" color="white">
                    <Icon type="arrow-left" className="mr-2" />
                    Back
                  </Button>
                </div>
                <div>
                  <Button
                    to="/settings/verification"
                    color="primary"
                    className="mr-3"
                  >
                    <Icon type="dollar-sign" className="mr-2" />
                    {!isSmall && 'Setup '}Payments
                  </Button>
                  <Button to="/guide?step=4" color="primary">
                    Skip
                    <Icon type="arrow-right" className="ml-2" />
                  </Button>
                </div>
              </Buttons>
            </Tab> */}

            <Tab tabId="6">
              <Image src={moneyTransferImg} alt="Setup Complete" />
              <Header>Setup Complete</Header>
              <Lead>
                You have successfully completed the guide. If you skipped any
                steps along the way, you can restart the guide or go back.
                Straightline may still be verifying your account, but you can
                now search for loads, post trucks, and more.
              </Lead>
              <div>
                <SetupComplete />
              </div>
              <div className="pb-4">
                <Buttons>
                  <div>
                    <Button to="/guide?step=5" color="white">
                      <Icon type="arrow-left" className="mr-2" />
                      Back
                    </Button>
                  </div>
                  <div>
                    <Button to="/shipment-matches" color="primary">
                      <Icon type="clipboard" className="mr-2" />
                      Load Board
                    </Button>
                  </div>
                </Buttons>
              </div>
            </Tab>
          </TabContent>
        </Col>
      </Row>
    </Container>
  );
}

const hasPacketOrDocs = (docs, requiredDocTypes) => {
  if (docs.find((doc) => doc.document_type === DOCUMENT_TYPE.CARRIER_PACKET)) {
    return true;
  }

  const w9Doc = docs.find((doc) => doc.document_type === DOCUMENT_TYPE.W9);
  const certDoc = docs.find(
    (doc) => doc.document_type === DOCUMENT_TYPE.CERTIFICATE_OF_INSURANCE
  );

  return !!w9Doc || !!certDoc;
};

function SetupComplete() {
  const app = useApp();

  const { result: shipmentRequest } = app
    .service('api/shipment-requests')
    .useFindOne({});

  const { result: documents } = app.service('api/documents').useFind({
    query: {
      document_type: {
        $in: [
          DOCUMENT_TYPE.CARRIER_PACKET,
          DOCUMENT_TYPE.W9,
          DOCUMENT_TYPE.CERTIFICATE_OF_INSURANCE
        ]
      },
      $limit: 3
    }
  });

  const hasDocs = documents && hasPacketOrDocs(documents.data);

  return (
    <div>
      <Card>
        <CardBody>
          <div className="pb-4 border-bottom">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="font-weight-bold">Posted Truck</div>
              <div>
                {shipmentRequest ? (
                  <Badge color="soft-primary">
                    <Icon type="check-circle" className="mr-2" />
                    Complete
                  </Badge>
                ) : (
                  <Link to="/guide?step=3">Post Truck</Link>
                )}
              </div>
            </div>
            <div>
              {shipmentRequest
                ? 'You have posted a truck to the Straightline Dashboard. We are actively looking for matching shipments.'
                : 'Post a truck to enable notifications, price offers, metrics, and more from the Straightline app.'}
            </div>
          </div>
          <div className="pt-4">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="font-weight-bold">
                Uploaded Documents{' '}
                {!hasDocs && (
                  <Badge color="soft-danger" className="ml-3">
                    <Icon type="check-circle" className="mr-2" />
                    Required
                  </Badge>
                )}
              </div>
              <div>
                {hasDocs ? (
                  <Badge color="soft-primary">
                    <Icon type="check-circle" className="mr-2" />
                    Complete
                  </Badge>
                ) : (
                  <Link to="/guide?step=2">Add Docs</Link>
                )}
              </div>
            </div>
            <div>
              {hasDocs ? (
                'You have uploaded all required documents for Straightline to review and confirm your business.'
              ) : (
                <div>
                  <div className="text-danger mb-2">
                    You must upload your company documents before booking loads.
                  </div>
                  <div>
                    Upload a Carrier Packet, W9, or Certificate of Insurance so
                    that Straightline can verify your business.
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

function Invitations() {
  const app = useApp();
  const service = app.service('api/invitations');
  const initialValues = {
    email: '',
    role_type: ROLE.CARRIER_ADMIN
  };

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then(() => {
        toast.success('Invitation Sent!');
        formik.resetForm({ values: initialValues });
        formik.setSubmitting(false);
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      <Card>
        <CardBody>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            // validationSchema={service.schema}
            render={({ isSubmitting, values }) => {
              return (
                <Form>
                  <Label>Who would you like to invite to Straightline?</Label>
                  <Row>
                    <Col>
                      <FormInput
                        name="email"
                        disabled={isSubmitting}
                        placeholder="Enter email"
                      />
                    </Col>
                    <Col sm="auto" className="mt-3 mt-sm-0">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmitting}
                      >
                        <Icon type="send" className="mr-2" />
                        Send Invitation
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
}

function ShipmentMatches() {
  const app = useApp();

  const { isLoading, isError, result } = app
    .service('api/shipment-matches')
    .useFind(
      {
        query: {
          status_type: STATUS.BOOKED,
          $limit: 5,
          $sort: { pickup_date: -1 }
        }
      },
      { keepPreviousData: true }
    );

  if (isLoading || isError || result?.total === 0) {
    return null;
  }

  return (
    <Card>
      <div className="table-responsive">
        <Table className="table table-nowrap card-table table-hover">
          <TableHead>
            <TableRow>
              <TableHeader className="px-3 px-xl-4">Payout</TableHeader>
              <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
              <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
              <TableHeader className="px-3 px-xl-4">Service</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {result.data.map((match) => {
              return (
                <TableRow
                  key={match.id}
                  // className={offer && offer.accepted_at ? 'bg-light' : ''}
                  to={`/shipment-matches/${match.id}`}
                >
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0 text-success font-weight-bold">
                      {formatMoney(match.rate.price.carrier_price)}
                      <span className="small text-muted"> total</span>
                    </p>
                    <p className="mb-0">
                      {formatMoney(match.cost_per_mile)}
                      <span className="small text-muted"> /mile</span>
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <p className="mb-0">
                      {match.pickup_address.city}, {match.pickup_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.pickup_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData
                    className="px-3 px-xl-4"
                    style={{ width: '1%', position: 'relative' }}
                  >
                    <div style={{ position: 'absolute', left: -12, top: 21 }}>
                      <Icon
                        type="arrow-right text-success"
                        style={{ fontSize: 25 }}
                      />
                    </div>
                    <p className="mb-0">
                      {match.delivery_address.city},{' '}
                      {match.delivery_address.state}
                    </p>
                    <p className="mb-0">
                      {formatDate(match.due_date, 'LLL d, yyyy')}
                    </p>
                  </TableData>
                  <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                    <div>{match.rate.service_level.name}</div>
                    <div>
                      {match.truck.label}
                      <Icon type="dot" className="text-muted mx-1" />
                      {roundNumberToLabel(match.load_weight)} lbs
                      <Icon type="dot" className="text-muted mx-1" />
                      {match.travel.miles} mi
                    </div>
                  </TableData>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}

function ShipmentRequest() {
  const history = useHistory();
  const app = useApp();
  const service = app.service('api/shipment-requests');

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then(() => {
        toast.success('Truck posted');
        service.removeFind();
        history.push('/guide?step=4');
      })
      .catch((err) => {
        toast.errors(err);
        formik.resetForm();
        formik.setSubmitting(false);
      });
  };

  const initialValues = {
    active: true,
    delivery_address: null,
    pickup_address: undefined,
    truck_types: [TRUCK_TYPE.DRY_VAN]
  };

  const date = useMemo(() => new Date(), []);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      render={({ isSubmitting, values, setValues, setFieldValue }) => {
        const hasAddress = values.pickup_address?.latitude;
        const hasTruckType = values.truck_types?.length >= 1;

        return (
          <React.Fragment>
            <Form>
              <Card>
                <CardBody>
                  <FormGroup>
                    <Label>Where is your truck empty?</Label>
                    <InputAddress
                      className="form-control-prepended"
                      components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                      }}
                      disabled={isSubmitting}
                      name="pickup_address"
                      value={values.pickup_address}
                      onChange={({ target: { value } }) => {
                        if (!value) {
                          return setValues((values) => {
                            return {
                              ...values,
                              pickup_address: null
                            };
                          });
                        }
                        return setValues((values) => {
                          return {
                            ...values,
                            pickup_address: {
                              radius: values.pickup_address?.radius || 300,
                              ...value
                            }
                          };
                        });
                      }}
                    />
                    <AddressCardPreview
                      address={
                        values.pickup_address?.latitude
                          ? values.pickup_address
                          : undefined
                      }
                      date={date}
                      className="border rounded bg-lighter mt-3 p-3"
                    />
                  </FormGroup>

                  {!hasAddress && (
                    <div className="text-danger mb-3">
                      Pickup Address is required
                    </div>
                  )}
                  {!hasTruckType && (
                    <div className="text-danger mb-3">
                      At least one truck type is required
                    </div>
                  )}

                  <FormGroup>
                    <Label>What type of truck is available?</Label>
                    <TruckTypePicker
                      values={values}
                      setValues={setValues}
                      disabled={isSubmitting}
                    />
                  </FormGroup>

                  <div className="border-top pt-4">
                    <Row className="align-items-center">
                      <Col sm="auto">
                        <Button
                          color="primary"
                          type="submit"
                          className={`mr-3 btn-block ${
                            isSubmitting ? 'is-loading' : ''
                          }`}
                          disabled={
                            !hasAddress || !hasTruckType || isSubmitting
                          }
                        >
                          <Icon type="truck" className="mr-2" />
                          Post Truck
                        </Button>
                      </Col>
                      <Col className="mt-3 mt-sm-0">
                        Post this truck to receive notifications about upcoming
                        shipments. You can add more trucks later.
                      </Col>
                    </Row>
                  </div>
                </CardBody>
              </Card>
            </Form>
          </React.Fragment>
        );
      }}
    />
  );
}
