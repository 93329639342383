import { withData } from 'feathers-fletching';
import { ENTITY_TYPE } from 'straightline-utils/constants';
import { validateSchema } from 'straightline-utils/hooks';
import { yup } from 'straightline-utils/validation';

const cleanup = async (context) => {
  await context.app.cleanup();
  return context;
};

export default (app) => {
  app.service('authentication').schema = yup.object({
    strategy: yup
      .string()
      .trim()
      .required()
      .label('Strategy')
      .oneOf(['local', 'jwt']),
    app: yup
      .string()
      .trim()
      .required()
      .label('App')
      .oneOf([ENTITY_TYPE.CARRIER]),
    email: yup
      .string()
      .trim()
      .email()
      .label('Email')
      .when('strategy', (strategy, schema) => {
        return strategy === 'local' ? schema.required() : schema.strip();
      }),
    password: yup
      .string()
      .trim()
      .label('Password')
      .when('strategy', (strategy, schema) => {
        return strategy === 'local' ? schema.required() : schema.strip();
      }),
    accessToken: yup
      .string()
      .trim()
      .label('Access Token')
      .when('strategy', (strategy, schema) => {
        return strategy === 'jwt' ? schema.required() : schema.strip();
      }),
    refresh: yup.boolean().label('Refresh')
  });

  app.service('authentication').hooks({
    before: {
      create: [withData({ app: ENTITY_TYPE.CARRIER }), validateSchema]
    },
    after: {
      create: [
        (context) => {
          app.get('stateContainer').setInState('authenticated', true);
          return context;
        }
      ],
      remove: [cleanup]
    },
    // If you log out with an expired/invalid token, the server will error.
    // This is expected behavior on the server bc you have to have a valid
    // token in order to logout. But we don't care about the error here. Even
    // if serverside logout failed, wipe the client authentication
    error: {
      remove: [cleanup]
    }
  });

  // Reset PW
  app.service('auth/reset-password').schema = yup.object({
    password_reset_token: yup
      .string()
      .trim()
      .required()
      .label('Password reset token'),
    password: yup.string().trim().required().label('Password').password()
  });
  app.service('auth/reset-password').hooks({
    before: {
      create: [validateSchema]
    }
  });

  // Forgot PW
  app.service('auth/forgot-password').schema = yup.object({
    email: yup.string().trim().email().required().label('Email')
  });
  app.service('auth/forgot-password').hooks({
    before: {
      create: [
        validateSchema,
        withData({
          app_url: `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? ':' + window.location.port : ''
          }/reset-password`
        })
      ]
    }
  });
};
