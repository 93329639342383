import React from 'react';
import { STATUS_COLORS } from 'src/lib/shipment-statuses';
import { Badge } from 'straightline-ui';

export default ({ status }) => {
  return (
    <Badge
      className={`badge-soft-${STATUS_COLORS[status.value]}`}
      style={{ padding: 8.5 }}
    >
      {status.label}
    </Badge>
  );
};
