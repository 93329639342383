import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
// import MatchMap from 'src/components/MatchMap';
import { queryToShipmentRequest } from 'src/lib';
import {
  Button,
  // ButtonGroup,
  Card,
  Col,
  // toast,
  Container,
  Icon,
  InputAddress,
  InputDate,
  Label,
  Row,
  useApp,
  useScreenSize,
  useUrlState
} from 'straightline-ui';
import { STATUS } from 'straightline-utils/constants';

import { MatchesList, MoreMatchesList } from './list';

export default function ShipmentMatches(props) {
  const app = useApp();
  const urlState = useUrlState();
  const { getInState } = urlState;

  const tab = getInState('tab', 'list');
  const { $skipMore, ...query } = getInState('query', {});

  const moreMatches = app.service('api/shipment-matches').useFind(
    {
      query: {
        ...query,
        status_type: { $ne: STATUS.BOOKED },
        $limit: 5,
        $sort: { pickup_date: -1 },
        $skip: $skipMore
      },
      $include: ['shipment_requests']
    },
    { keepPreviousData: true }
  );

  const availableMatches = app.service('api/shipment-matches').useFind(
    {
      query: {
        ...query,
        carrier_id: null,
        status_type: STATUS.BOOKED,
        $limit: 10,
        $sort: { pickup_date: -1 }
      },
      $include: ['shipment_requests']
    },
    { keepPreviousData: true }
  );

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col>
          <div className="header pt-md-2">
            <div className="header-body">
              <Row className="align-items-center justify-content-between">
                <Col md="auto" className="mb-2 mb-lg-0">
                  <h6 className="header-pretitle">Overview</h6>
                  <h1 className="header-title">Load Board</h1>
                </Col>
                <Col md="auto">
                  <Row className="align-items-center justify-content-between">
                    <Col className="mb-3 mb-sm-0" style={{ maxWidth: 350 }}>
                      <p className="text-secondary small mb-0 text-md-right">
                        Save search criteria and shipments as a "Posted Truck"
                        to automatically receive notifications of matches
                      </p>
                    </Col>
                    <Col sm="auto">
                      <Button
                        to="/shipment-request"
                        query={{
                          initialValues: queryToShipmentRequest(query)
                        }}
                        color="primary"
                      >
                        <Icon type="truck" className="mr-2" />
                        Post Truck
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>

      <SearchBar queryState={urlState} />

      {tab === 'list' ? (
        <React.Fragment>
          <Row className="mb-3 justify-content-between">
            <Col xs="auto">
              <h3 className="mb-0">Available Shipments</h3>
            </Col>
            <Col xs="auto">
              <p className="text-secondary mb-0 small">
                View loads to get more information and book instantly
              </p>
            </Col>
          </Row>
          <MatchesList matches={availableMatches} queryState={urlState} />
          {moreMatches?.result?.data?.length > 0 && (
            <React.Fragment>
              <Row className="mb-3 justify-content-between">
                <Col xs="auto">
                  <h3 className="mb-0">Past Shipments</h3>
                </Col>
                <Col xs="auto">
                  <p className="text-secondary mb-0 small">
                    Click "Post" to automatically be notified of new shipments
                    like these
                  </p>
                </Col>
              </Row>
              <MoreMatchesList matches={moreMatches} queryState={urlState} />
            </React.Fragment>
          )}
        </React.Fragment>
      ) : // <MatchMap matches={matches} />
      null}
    </Container>
  );
}

const SearchBar = ({ queryState }) => {
  const { isXs, isSm, isMd, isLg, isXl } = useScreenSize();
  // const [focused, setFocused] = useState(null);

  const pickupRef = useRef(null);
  const deliveryRef = useRef(null);
  // const datesRef = useRef(null);

  const { getInState, setInState, opInState } = queryState;

  const query = getInState('query', {});

  const handleAddressChange = (type, value) => {
    opInState((newState) => {
      newState.del('query.$skip').del('query.$skipMore');

      if (type === 'pickup') {
        if (!value) {
          return newState.del('query.pickup_distance');
        }
        return newState.assign('query.pickup_distance', {
          latitude: value.latitude,
          longitude: value.longitude,
          city: value.city,
          state: value.state,
          $lte: query.pickup_distance?.$lte
            ? Number(query.pickup_distance.$lte)
            : 300
        });
      }

      if (!value) {
        return newState.del('query.delivery_distance');
      }

      return newState.assign('query.delivery_distance', {
        latitude: value.latitude,
        longitude: value.longitude,
        city: value.city,
        state: value.state,
        $lte: query.delivery_distance?.$lte
          ? Number(query.delivery_distance.$lte)
          : 300
      });
    });
  };

  const handleDateChange = (dates) => {
    opInState((newState) => {
      newState.del('query.$skip').del('query.$skipMore');

      if (!dates.length) {
        newState.del('query.pickup_date');
      }
      if (dates.length === 2) {
        newState.set('query.pickup_date.$between', dates);
      }

      return newState;
    });
  };

  return (
    <Card>
      <div className="px-3">
        <Row className="align-items-center">
          <Col
            xs="12"
            sm="6"
            lg={true}
            className={classNames('py-2', {
              'border-right': !isXs,
              'border-bottom': isXs || isSm || isMd
              // shadow: focused === 1
            })}
            onClick={() => {
              pickupRef.current.focus();
              // setFocused(1);
            }}
          >
            <Label className="small mb-0 text-secondary">Pickup</Label>
            <InputAddressCol
              innerRef={pickupRef}
              value={query.pickup_distance}
              onChange={({ target }) =>
                handleAddressChange('pickup', target.value)
              }
            />
            <ColDropDown
              disabled={!query.pickup_distance}
              label={() =>
                query.pickup_distance?.$lte
                  ? `${query.pickup_distance.$lte} mi`
                  : 'Radius'
              }
              render={(dropdownOpen, setDropdownOpen) => {
                return [50, 100, 200, 300].map((miles) => {
                  return (
                    <button
                      key={miles}
                      className="dropdown-item pl-3"
                      onClick={() => {
                        setDropdownOpen(false);
                        setInState('query.pickup_distance.$lte', miles);
                      }}
                    >
                      {miles} miles
                    </button>
                  );
                });
              }}
            />
          </Col>
          <Col
            xs="12"
            sm="6"
            lg={true}
            className={classNames('py-2', {
              'border-right': isLg || isXl,
              'border-bottom': isXs || isSm || isMd
              // shadow: focused === 2
            })}
            onClick={() => {
              deliveryRef.current.focus();
              // setFocused(2);
            }}
          >
            <Label className="small mb-0 text-secondary">Delivery</Label>
            <InputAddressCol
              innerRef={deliveryRef}
              value={query.delivery_distance}
              onChange={({ target }) =>
                handleAddressChange('delivery', target.value)
              }
            />
            <ColDropDown
              disabled={!query.delivery_distance}
              label={() =>
                query.delivery_distance?.$lte
                  ? `${query.delivery_distance.$lte} mi`
                  : 'Radius'
              }
              render={(dropdownOpen, setDropdownOpen) => {
                return [50, 100, 200, 300].map((miles) => {
                  return (
                    <button
                      key={miles}
                      className="dropdown-item pl-3"
                      onClick={() => {
                        setDropdownOpen(false);
                        setInState('query.delivery_distance.$lte', miles);
                      }}
                    >
                      {miles} miles
                    </button>
                  );
                });
              }}
            />
          </Col>
          <Col
            xs="12"
            sm={true}
            lg="3"
            className={classNames('py-2', {
              'border-right': !isSm || !isMd,
              'border-bottom': isXs
            })}
            // onClick={() => datesRef.current.focus()}
          >
            <Label className="small mb-0 text-secondary">Dates</Label>
            <InputDate
              className="form-control-flush py-1"
              style={{ height: 'auto' }}
              options={{ mode: 'range', position: 'bottom left' }}
              onChange={handleDateChange}
              value={query.pickup_date?.$between}
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
};

const dropdownStyle = {
  top: 33,
  borderBottomLeftRadius: '0.5rem',
  borderBottomRightRadius: '0.5rem',
  paddingBottom: '0.75rem',
  position: 'absolute',
  left: -1,
  right: -1,
  zIndex: 1
};

const ColDropDown = ({ render, label, initialOpen = false, disabled }) => {
  const [open, setOpen] = useState(initialOpen);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <React.Fragment>
      <div ref={ref}>
        {open && (
          <React.Fragment>
            <div
              style={dropdownStyle}
              // onClick={() => setOpen(false)}
              className={classNames('bg-white border border-top-0 small')}
            >
              {/* <button className="dropdown-item pl-3" onClick={() => {}}>
                Pickup
              </button>
              <button className="dropdown-item pl-3" onClick={() => {}}>
                Delivery
              </button>
              <button className="dropdown-item pl-3" onClick={() => null}>
                <span className="text-muted">Clear</span>
              </button> */}
              {render(open, setOpen)}
              <div
                style={{
                  ...dropdownStyle,
                  height: 'calc(100% - 33px)',
                  boxShadow: '0 1rem 1rem rgba(18, 38, 63, 0.15)',
                  zIndex: -1
                }}
              />
            </div>
          </React.Fragment>
        )}
        <div style={{ position: 'absolute', right: 6, top: 5 }}>
          <Button
            caret
            size="sm"
            color="white"
            className="border-0 text-muted dropdown-toggle"
            style={{ padding: '0.125rem 6px' }}
            onClick={() => setOpen(!open)}
            disabled={disabled}
          >
            {label(open, setOpen)}
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const InputAddressCol = (props) => {
  return (
    <InputAddress
      components={selectOptions.components}
      styles={selectOptions.styles}
      className="form-control-flush h-100"
      isClearable={true}
      {...props}
    />
  );
};

const selectOptions = {
  components: {
    ClearIndicator: (props) => {
      const {
        children = <Icon type="x text-muted"></Icon>,
        getStyles,
        innerProps: { ref, ...restInnerProps }
      } = props;
      return (
        <div
          {...restInnerProps}
          ref={ref}
          style={getStyles('clearIndicator', props)}
        >
          <div>{children}</div>
        </div>
      );
    },
    DropdownIndicator: () => null,
    IndicatorSeparator: () => null
  },
  styles: {
    valueContainer: (provided) => {
      return {
        ...provided,
        padding: 0,
        backgroundColor: 'white'
      };
    },
    control: (provided) => {
      return {
        ...provided,
        border: 'none !important',
        boxShadow: 'none !important',
        minHeight: 'none',
        height: 30,
        padding: 0
      };
    },
    noOptionsMessage: (provided) => {
      return {
        ...provided,
        textAlign: 'left',
        fontSize: '0.75rem',
        color: '#95AAC9'
      };
    },
    loadingMessage: (provided) => {
      return {
        ...provided,
        textAlign: 'left',
        fontSize: '0.75rem',
        color: '#95AAC9'
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        padding: 0,
        cursor: 'pointer'
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        margin: '0 -14px',
        width: 'calc(100% + 28px)',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: '0.5rem',
        borderBottomRightRadius: '0.5rem',
        boxShadow: '0 1rem 1rem rgba(18, 38, 63, 0.15)',
        border: '1px solid #EDF2F9',
        borderTop: 0,
        top: 30
      };
    }
  }
};
