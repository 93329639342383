import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import BankAccountForm from 'src/components/BankAccountForm';
import PlaidLink from 'src/components/PlaidLink';
import SettingsHeader from 'src/components/SettingsHeader';
import SettingsLoadingIndicator from 'src/components/SettingsLoadingIndicator';
import { useAppState } from 'src/lib/hooks';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  useApp
} from 'straightline-ui';
import { PAYMENT_METHOD } from 'straightline-utils/constants';

export default function Billing() {
  const app = useApp();
  const {
    state: { carrier }
  } = useAppState();
  const [activePanel, setActivePanel] = useState(null);

  const handleSuccess = () => {
    setActivePanel(null);
    app.service('api/verification').invalidateFind();
  };

  if (!carrier.stripe_account_id) {
    return <Redirect to="/settings/verification" />;
  }

  const { result: verification } = app.service('api/verification').useFind();

  if (!verification) {
    return <SettingsLoadingIndicator activeTab="billing" />;
  }

  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="billing" />
      <Row>
        {verification.external_account && (
          <Col xl="6">
            <ExternalAccount externalAccount={verification.external_account} />
          </Col>
        )}
        <Col xl="6">
          <Card>
            <CardBody>
              <p className="lead">Link bank account</p>
              <p>
                Securely and instantly connect your bank account to
                Straightline. We never know or see any of your bank credentials
                or balances.
              </p>
              {!activePanel && (
                <React.Fragment>
                  <Button
                    color="primary"
                    className="mr-3"
                    onClick={() => setActivePanel('plaid')}
                  >
                    Link with password
                  </Button>
                  <Button
                    color="white"
                    onClick={() => setActivePanel(PAYMENT_METHOD.BANK_ACCOUNT)}
                  >
                    Link with routing number
                  </Button>
                </React.Fragment>
              )}
              {activePanel === 'plaid' && (
                <PlaidLink
                  onSuccess={handleSuccess}
                  onCancel={() => setActivePanel(null)}
                />
              )}
              {activePanel === PAYMENT_METHOD.BANK_ACCOUNT && (
                <BankAccountForm
                  onSuccess={handleSuccess}
                  onCancel={() => setActivePanel(null)}
                />
              )}
              {/* {activePanel === PAYMENT_METHOD.CARD && (
                  <CreditCardForm
                    onSuccess={handleSuccess}
                    onCancel={() => setActivePanel(null)}
                  />
                )} */}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

const ExternalAccount = ({ externalAccount }) => (
  <Card>
    <CardBody>
      <Row>
        <Col sm="6">
          <p className="mb-1 font-weight-bold">{externalAccount.bank_name}</p>
          <p className="mb-0">Account ending in: *{externalAccount.last4}</p>
          {externalAccount.account_holder_name && (
            <p className="mb-0">{externalAccount.account_holder_name}</p>
          )}
        </Col>
        <Col sm="6">
          <p className="mb-0 text-muted">
            You’ll receive payouts to this account daily. To update your bank
            information, link a new one.
          </p>
        </Col>
      </Row>
    </CardBody>
  </Card>
);
