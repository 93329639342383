import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';

// https://github.com/stripe/stripe-js/issues/43
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

export default (props) => (
  <Elements stripe={getStripe()}>
    <ElementsConsumer>{(stripe) => props.render(stripe)}</ElementsConsumer>
  </Elements>
);
