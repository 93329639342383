import React from 'react';
// import CountUp from 'react-countup';
import { useHistory } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator';
import NoTransfers from 'src/components/NoTransfers';
import PaginationBtns from 'src/components/PaginationBtns';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Icon,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  useApp,
  useUrlState
} from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { formatDate } from 'straightline-utils/time';

export default function ShipmentTransfers() {
  const app = useApp();
  const history = useHistory();
  const { getInState, setInState } = useUrlState();
  const $skip = getInState('query.$skip', 0);

  const {
    result: shipmentTransfers,
    isLoading,
    isError
  } = app.service('api/shipment-transfers').useFind(
    {
      query: { $skip, $sort: { updated_at: -1 } }
    },
    { keepPreviousData: true }
  );

  // const { result: balance } = app.service('api/balance').useFind();

  if (isLoading || isError) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (!shipmentTransfers.total === 0) {
    return <NoTransfers />;
  }

  return (
    <Container fluid className="pb-5">
      <Row className="justify-content-center">
        <Col>
          <div className="header pt-md-2">
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <h6 className="header-pretitle">Overview</h6>
                  <h1 className="header-title">Payments</h1>
                </Col>
                {/* <Col className="text-right">
                  <h1 className="header-title text-success">
                    {balance && (
                      <CountUp
                        end={balance.amount / 100}
                        decimals={2}
                        prefix="$"
                        separator=","
                      />
                    )}
                  </h1>
                  <p className="text-muted small mb-0">
                    You’ll receive payouts daily
                  </p>
                </Col> */}
              </Row>
            </div>
          </div>
          <ScreenSize md lg xl>
            {() => (
              <Card>
                <div className="table-responsive">
                  <Table className="table table-nowrap card-table table-hover">
                    <TableHead>
                      <TableRow>
                        <TableHeader>Payout</TableHeader>
                        <TableHeader>Shipment</TableHeader>
                        <TableHeader>Pickup</TableHeader>
                        <TableHeader style={{ paddingLeft: 38 }}>
                          Delivery
                        </TableHeader>
                        <TableHeader>Shipper</TableHeader>
                        <TableHeader>Status</TableHeader>
                      </TableRow>
                    </TableHead>
                    <TableBody className="list">
                      {shipmentTransfers.data.map((transfer) => (
                        <TransferRow transfer={transfer} key={transfer.id} />
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Card>
            )}
          </ScreenSize>
          <ScreenSize xs sm>
            {() => (
              <TransferCards
                transfers={shipmentTransfers.data}
                onClick={(transfer) =>
                  history.push(
                    `/shipments/${transfer.shipment_id}?tab=rate-confirmation`
                  )
                }
              />
            )}
          </ScreenSize>
          <div className="text-right">
            <PaginationBtns
              total={shipmentTransfers.total}
              skip={getInState('query.$skip', 0)}
              setSkip={($skip) => setInState('query.$skip', $skip)}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

const TransferRow = ({ transfer }) => {
  // TODO: This is a little weird. The carrier could have a transfer for a shipment they are no longer assigned to. That means the shipment is not joined on the transfer. We should probably handle this better.
  let { shipment } = transfer;

  return (
    <TableRow
      className="align-items-center"
      to={`/shipments/${transfer.shipment_id}?tab=rate-confirmation`}
    >
      <TableData>
        <p className="mb-0 text-success font-weight-bold">
          {formatMoney(transfer.amount)}
          <span className="small text-muted"> amount</span>
        </p>
        <p className="mb-0">
          {formatDate(transfer.created_at, 'LLL d, yyyy')}
          <span className="small text-muted"> date</span>
        </p>
      </TableData>
      {shipment ? (
        <>
          <TableData>
            <p className="mb-0">
              <span className="text-muted">PICKUP#: </span>
              {shipment.reference_number || (
                <span className="text-muted">NA</span>
              )}
            </p>
            <p className="mb-0">
              <span className="text-muted">SL#: </span> {shipment.id}
            </p>
          </TableData>
          <TableData>
            <p className="mb-0 font-weight-bold">
              {shipment.pickup_address.city}, {shipment.pickup_address.state}
            </p>
            <p className="mb-0">
              {formatDate(shipment.pickup_date, 'LLL d, yyyy')}
            </p>
          </TableData>
          <TableData className="d-flex align-items-center pl-0">
            <div className="d-inline-block mr-3">
              <Icon type="arrow-right text-success" style={{ fontSize: 25 }} />
            </div>
            <div className="d-inline-block">
              <p className="mb-0 font-weight-bold">
                {shipment.delivery_address.city},&nbsp;
                {shipment.delivery_address.state}
              </p>
              <p className="mb-0">
                {formatDate(shipment.due_date, 'LLL d, yyyy')}
              </p>
            </div>
          </TableData>
          <TableData>
            <p className="mb-0 font-weight-bold">{shipment.shipper.name}</p>
          </TableData>
        </>
      ) : (
        <>
          <TableData>
            <p className="mb-0">
              <span className="text-muted">PICKUP#: </span>
              <span className="text-muted">NA</span>
            </p>
            <p className="mb-0">
              <span className="text-muted">SL#: </span>{' '}
              <span className="text-muted">NA</span>
            </p>
          </TableData>
          <TableData>
            <p className="mb-0 font-weight-bold">
              <span className="text-muted">NA</span>
            </p>
            <p className="mb-0">
              <span className="text-muted">NA</span>
            </p>
          </TableData>
          <TableData className="d-flex align-items-center pl-0">
            <div className="d-inline-block mr-3">
              <Icon type="arrow-right text-success" style={{ fontSize: 25 }} />
            </div>
            <div className="d-inline-block">
              <p className="mb-0 font-weight-bold">
                <span className="text-muted">NA</span>
              </p>
              <p className="mb-0">
                <span className="text-muted">NA</span>
              </p>
            </div>
          </TableData>
          <TableData>
            <p className="mb-0 font-weight-bold">
              <span className="text-muted">NA</span>
            </p>
          </TableData>
        </>
      )}

      <TableData>
        {transfer.reversed ? (
          <Badge color="danger">Reversed</Badge>
        ) : (
          <React.Fragment>
            <Badge color="success">Paid</Badge>
            <p className="mb-0">
              via{' '}
              {transfer.type.charAt(0).toUpperCase() + transfer.type.slice(1)}
            </p>
          </React.Fragment>
        )}
      </TableData>
    </TableRow>
  );
};

const TransferCards = ({ transfers, onClick }) => {
  return (
    <div>
      {transfers.map((transfer) => {
        const { shipment } = transfer;
        return (
          <Card
            key={transfer.id}
            className="ripple"
            onClick={() => onClick(transfer)}
          >
            <div className="card-header">
              <Row className="align-items-center">
                <Col>
                  <p className="mb-0 h3 text-success">
                    {formatMoney(transfer.amount)}
                  </p>
                </Col>
                <Col>
                  <div className="text-right">
                    <p className="mb-0">
                      {formatDate(transfer.created_at, 'LLL d, yyyy')}
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody>
              <div className="d-flex mb-4">
                <div>
                  <Icon
                    type="package"
                    className="mr-4 font-weight-bold text-secondary"
                  />
                </div>
                <div>
                  <p className="font-weight-bold mb-0">
                    {shipment.shipper.name}
                  </p>
                  <p className="mb-0">
                    <span className="text-muted">PICKUP#: </span>
                    {shipment.reference_number || (
                      <span className="text-muted">NA</span>
                    )}
                  </p>
                  <p className="mb-0">
                    <span className="text-muted">SL#: </span> {shipment.id}
                  </p>
                </div>
              </div>
              <div className="d-flex mb-4">
                <div>
                  <Icon
                    type="truck"
                    className="mr-4 font-weight-bold text-secondary"
                  />
                </div>
                <div>
                  <p className="font-weight-bold mb-0">
                    {shipment.pickup_address.city},{' '}
                    {shipment.pickup_address.state}
                  </p>
                  <p className="mb-0">
                    {formatDate(shipment.pickup_date, 'MMM dd, yyyy')}
                  </p>
                  {shipment.pickup_address.appointment && (
                    <p className="text-muted small mb-0">
                      {shipment.pickup_address.appointment}
                    </p>
                  )}
                </div>
              </div>
              <div className="d-flex">
                <div>
                  <Icon
                    type="map-pin"
                    className="mr-4 font-weight-bold text-secondary"
                  />
                </div>
                <div>
                  <p className="font-weight-bold mb-0">
                    {shipment.delivery_address.city},{' '}
                    {shipment.delivery_address.state}
                  </p>
                  <p className="mb-0">
                    {formatDate(shipment.due_date, 'MMM dd, yyyy')}
                  </p>
                  {shipment.delivery_address.appointment && (
                    <p className="text-muted small mb-0">
                      {shipment.delivery_address.appointment}
                    </p>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        );
      })}
    </div>
  );
};
