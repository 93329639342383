import feathers from '@feathersjs/client';
import { withResult } from 'feathers-fletching';
import { cache, stashCache } from 'src/feathers/hooks';
import { scriptLoader } from 'straightline-ui';
import { parsePlace } from 'straightline-utils/validation';

const { GeneralError } = feathers.errors;

class Service {
  async get(id, params) {
    if (!this.placesService) {
      await scriptLoader.load(process.env.REACT_APP_GOOGLE_MAPS_URL);
      this.placesService = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }
    return new Promise((resolve, reject) => {
      this.placesService.getDetails({ placeId: id }, (response, status) => {
        if (status !== 'OK') {
          reject(
            new GeneralError(
              'There was an error from Google getting place predictions.',
              { status }
            )
          );
        } else {
          resolve(parsePlace(response));
        }
      });
    });
  }
}

const withResults = withResult({
  timezone: async (place, context) => {
    const { timezone } = await context.app.service('api/addresses').find({
      query: {
        latitude: place.latitude,
        longitude: place.longitude
      }
    });
    return timezone;
  }
});

export default (app) => {
  app.use('google/places', new Service());
  const service = app.service('google/places');

  service.hooks({
    before: {
      get: [cache]
    },
    after: {
      get: [withResults, cache, stashCache]
    },
    error: {
      get: []
    }
  });
};
