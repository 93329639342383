import { authenticateClient, withSearch } from 'src/feathers/hooks';
import { validateSchema } from 'straightline-utils/hooks';

const withSearches = withSearch(['email']);

export default {
  before: {
    all: [authenticateClient, withSearches],
    find: [],
    get: [],
    create: [validateSchema],
    update: [validateSchema],
    patch: [validateSchema],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
