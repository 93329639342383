import React, { useState } from 'react';
import ContactModal from 'src/components/ContactModal';
import ShareModal from 'src/components/ShareModal';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Icon,
  Link,
  Nav,
  NavItem,
  Row,
  UncontrolledDropdown
} from 'straightline-ui';

// Note that there is a NavLink reactstrap item. We do not use it,
// but create our own that wraps a react-router Link
const NavLink = ({ to, active, children, ...rest }) => (
  <Link to={to} className={`nav-link ${active ? 'active' : ''}`} {...rest}>
    {children}
  </Link>
);

export default function ShipmentPageHeader({ shipment, activeTab }) {
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const toggleContactModal = () => setContactModalOpen(!contactModalOpen);

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const toggleShareModal = () => setShareModalOpen(!shareModalOpen);

  return (
    <div className="header">
      <div className="header-body">
        <Row className="font-weight-bold">
          <Col xs="auto" className="mb-2">
            <span className="text-muted">SL#: </span>
            {shipment.id}
          </Col>
          {shipment.reference_number && (
            <Col xs="auto" className="mb-2">
              <span className="text-muted">PICKUP#: </span>
              {shipment.reference_number}
            </Col>
          )}
          {shipment.truck_type && (
            <Col xs="auto" className="mb-2">
              <span className="text-muted">TRUCK: </span>
              {shipment.truck.label}
            </Col>
          )}
          <Col xs="auto" className="mb-2">
            <span className="text-muted">SHIPPER: </span>
            {shipment.shipper.name}
          </Col>
          {shipment.rate && (
            <Col xs="auto" className="mb-2">
              <span className="text-muted">SERVICE: </span>
              {shipment.rate.service_level.name}
            </Col>
          )}
        </Row>
        <Row className="align-items-end justify-content-between mt-md-n3">
          <Col xs="auto">
            <Row className="align-items-center mb-n3">
              <Col xs="auto" className="mb-2 pr-0 align-items-center d-flex">
                <h1 className="mb-0 font-weight-bold d-inline-block mr-3">{`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}</h1>
                <Icon
                  type="arrow-right"
                  style={{ fontSize: 24 }}
                  className="text-success lead d-inline-block font-weight-bold"
                />
              </Col>
              <Col xs="auto" className="mb-2">
                <h1 className="mb-0 font-weight-bold d-inline-block">{`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}</h1>
              </Col>
            </Row>
          </Col>
          <Col xs="auto" className="text-right">
            <Nav className="nav nav-tabs header-tabs">
              <NavItem>
                <NavLink
                  active={activeTab === 'details'}
                  to={`/shipments/${shipment.id}`}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'documents'}
                  to={`/shipments/${shipment.id}?tab=documents`}
                >
                  Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  active={activeTab === 'rate-confirmation'}
                  to={`/shipments/${shipment.id}?tab=rate-confirmation`}
                >
                  Rate Con
                </NavLink>
              </NavItem>
              <NavItem>
                <UncontrolledDropdown>
                  <DropdownToggle caret nav>
                    Actions
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={toggleShareModal}>
                      <div className="d-flex">
                        <div className="mr-3">
                          <Icon type="share" style={{ fontSize: '1.1rem' }} />
                        </div>
                        <div>
                          <div className="font-weight-bold">Share Load</div>
                          <div className="small">
                            Share this load via link, email, or text.
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                    <DropdownItem divider className="my-1" />
                    <DropdownItem onClick={toggleContactModal}>
                      <div className="d-flex">
                        <div className="mr-3">
                          <Icon
                            type="phone-outgoing"
                            style={{ fontSize: '1.1rem' }}
                          />
                        </div>
                        <div>
                          <div className="font-weight-bold">
                            Contact Straightline
                          </div>
                          <div className="small">
                            Call or email us with any questions
                          </div>
                        </div>
                      </div>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </NavItem>
            </Nav>
          </Col>
        </Row>
      </div>
      <ContactModal
        isOpen={contactModalOpen}
        toggle={toggleContactModal}
        config={{
          subject: `App Contact - Shipment #${shipment.id}`
        }}
      />
      <ShareModal
        isOpen={shareModalOpen}
        toggle={toggleShareModal}
        shipment={shipment}
      />
    </div>
  );
}
