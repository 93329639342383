// @jsxRuntime classic
// https://github.com/facebook/create-react-app/issues/9906
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// import 'straightline-ui/dist/styles/theme.scss';
import 'src/scss/theme.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import app from 'src/feathers';
import programming from 'src/images/programming.svg';
import { Routes } from 'src/routes';
import {
  AppProvider,
  ConfirmModal,
  ErrorBoundary,
  ToastContainer
} from 'straightline-ui';

class App extends React.Component {
  render() {
    return (
      <ErrorBoundary sentry={app.get('sentryClient')} img={programming}>
        <AppProvider app={app}>
          <QueryClientProvider client={app.get('queryClient')}>
            <BrowserRouter basename="/">
              <Routes />
              <ToastContainer position="bottom-right" />
              <ConfirmModal />
            </BrowserRouter>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AppProvider>
      </ErrorBoundary>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));
