import { withResult } from 'feathers-fletching';
import { authenticateClient } from 'src/feathers/hooks';
import { statusType, truckType } from 'straightline-utils/constants';

const withResults = withResult({
  status: (shipment) => {
    if (shipment.status_type) {
      return statusType(shipment.status_type);
    }
  },
  truck: (shipment) => {
    if (shipment.truck_type) {
      return truckType(shipment.truck_type);
    }
  },
  pickup_address: (match) => {
    if (match.pickup_address) {
      return {
        ...match.pickup_address,
        position: {
          lat: Number(match.pickup_address.latitude),
          lng: Number(match.pickup_address.longitude)
        }
      };
    }
  },
  delivery_address: (match) => {
    if (match.delivery_address) {
      return {
        ...match.delivery_address,
        position: {
          lat: Number(match.delivery_address.latitude),
          lng: Number(match.delivery_address.longitude)
        }
      };
    }
  },
  cost_per_mile: (match) => {
    if (match.rate && match.travel) {
      return (
        match.rate.price.carrier_price / (match.travel.miles || 1)
      ).toFixed(1);
    }
  },
  shipment_requests: async (result, context) => {
    if (!context.params.$include?.includes('shipment_requests')) {
      return undefined;
    }

    const { total } = await context.app.service('api/shipment-requests').find({
      query: {
        $limit: 0,
        shipment: result
      }
    });

    return total;
  }
});

export default {
  before: {
    all: [authenticateClient],
    find: [],
    update: []
  },
  after: {
    all: [],
    find: [withResults],
    get: [withResults],
    update: [withResults]
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
