import React from 'react';
import ShipmentBanner from 'src/components/ShipmentBanner';
import StatusBadge from 'src/components/StatusBadge';
import logisticsImg from 'src/images/logistics.svg';
import {
  Button,
  Card,
  CardBody,
  Col,
  Icon,
  Link,
  ListGroup,
  ListGroupItem,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  useApp
} from 'straightline-ui';
import { formatDate } from 'straightline-utils/time';

export default function ShipmentsCard() {
  const app = useApp();
  const {
    result: shipments,
    isLoading,
    isError,
    error
  } = app.service('api/shipments').useFind(
    {
      query: {
        $limit: 5,
        $sort: { pickup_date: -1 },
        $select: [
          'id',
          'reference_number',
          'pickup_address',
          'delivery_address',
          'shipper_id',
          'pickup_date',
          'due_date',
          'status_type',
          'truck_type',
          'travel',
          'load_weight',
          'rate'
        ]
      }
    },
    { keepPreviousData: true, refetchInterval: 60000 }
  );

  if (isLoading || isError) {
    return <Loader error={error} />;
  }

  if (shipments.total === 0) {
    return (
      <Card>
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h3 className="card-header-title">My Loads</h3>
          </div>
        </div>
        <CardBody style={{ minHeight: 426 }}>
          <Row className="align-items-center">
            <Col lg="6" className="text-center mb-4 mb-lg-0">
              <img
                src={logisticsImg}
                alt="Get Started"
                className="img-fluid mb-4 mb-xl-0"
              />
            </Col>
            <Col lg="6">
              <h1>Find Loads</h1>
              <p className="mb-4 text-muted lead">
                Post your truck to be alerted of loads near you. Or search for
                loads by location and date.
              </p>
              <Row className="mb-n3">
                <Col xs="6" className="mb-3 pr-0">
                  <Button to="/shipment-request" color="primary" block>
                    <Icon type="truck" className="mr-2" />
                    Post Truck
                  </Button>
                </Col>
                <Col xs="6" className="mb-3">
                  <Button to="/shipment-matches" color="primary" block>
                    <Icon type="clipboard" className="mr-2" />
                    Load Board
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }

  return (
    <React.Fragment>
      <ScreenSize xs sm md>
        {() => (
          <Card className="card-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">My Loads</h3>
                <Link to="/shipments">View All</Link>
              </div>
            </div>
            <ListGroup className="list-group-flush">
              {shipments.data.map((shipment) => {
                return (
                  <ListGroupItem
                    key={shipment.id}
                    tag={Link}
                    to={`/shipments/${shipment.id}`}
                    className="list-group-item-action px-3"
                  >
                    <ShipmentBanner shipment={shipment} />
                    <ListGroup className="list-group list-group-flush list-group-activity">
                      <ListGroupItem className="py-3">
                        <Row>
                          <Col xs="auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                <Icon type="clipboard" />
                              </div>
                            </div>
                          </Col>
                          <Col className="ml-n2">
                            <div className="mb-1 font-weight-bold">
                              {shipment.shipper.name}
                            </div>
                            <div className="mb-3 small text-primary mt-n1 font-weight-bold">
                              {shipment.rate.service_level.name}
                            </div>
                            {shipment.rate.service_level.code === 'TEM' && (
                              <span className="font-weight-bold text-primary">
                                ** This shipment requires two drivers **
                              </span>
                            )}
                            <div>
                              <span className="text-secondary">SL#: </span>
                              {shipment.id}
                            </div>
                            {shipment.reference_number && (
                              <div>
                                <span className="text-secondary">
                                  PICKUP#:{' '}
                                </span>
                                {shipment.reference_number}
                              </div>
                            )}
                            <StatusBadge status={shipment.status} />
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="py-3">
                        <Row>
                          <Col xs="auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                <Icon type="truck" />
                              </div>
                            </div>
                          </Col>
                          <Col className="ml-n2">
                            <div className="font-weight-bold">
                              {`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}
                            </div>
                            <div>
                              {formatDate(shipment.pickup_date, 'LLL d, yyyy')}
                            </div>
                          </Col>
                        </Row>
                      </ListGroupItem>
                      <ListGroupItem className="pt-3 pb-0">
                        <Row>
                          <Col xs="auto">
                            <div className="avatar avatar-sm">
                              <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                <Icon type="map-pin" />
                              </div>
                            </div>
                          </Col>
                          <Col className="ml-n2">
                            <div className="font-weight-bold">
                              {`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}
                            </div>
                            <div>
                              {formatDate(shipment.due_date, 'LLL d, yyyy')}
                            </div>
                          </Col>
                        </Row>
                      </ListGroupItem>
                    </ListGroup>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
          </Card>
        )}
      </ScreenSize>
      <ScreenSize lg xl>
        {() => (
          <Card className="card-fill">
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">My Loads</h3>
                <Link to="/shipments">View All</Link>
              </div>
            </div>
            <div
              className="table-responsive"
              style={{
                // force the scroll bar to the bottom when less than 5
                height: 'calc(100% - 60px)'
              }}
            >
              <Table className="table-nowrap card-table table-hover">
                <TableHead>
                  <TableRow>
                    <TableHeader className="py-2">Status</TableHeader>
                    <TableHeader className="py-2">ID</TableHeader>
                    <TableHeader className="py-2">Pickup</TableHeader>
                    <TableHeader className="py-2">
                      <p className="ml-4 mb-0">Delivery</p>
                    </TableHeader>
                    <TableHeader className="py-2">Shipper</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody className="list">
                  {shipments.data.map((shipment) => (
                    <TableRow
                      key={shipment.id}
                      to={`/shipments/${shipment.id}`}
                    >
                      <TableData>
                        <StatusBadge status={shipment.status} />
                      </TableData>
                      <TableData>
                        <p className="mb-0">{shipment.id}</p>
                        <p className="mb-0">
                          {shipment.reference_number || (
                            <span className="text-muted">NA</span>
                          )}
                        </p>
                      </TableData>
                      <TableData className="pr-0">
                        <p className="mb-0">
                          {`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}
                        </p>
                        <p className="mb-0">
                          {formatDate(shipment.pickup_date)}
                        </p>
                      </TableData>
                      <TableData className="pl-0">
                        <div className="d-inline-block mx-2">
                          <Icon
                            type="arrow-right"
                            className="text-success"
                            style={{ fontSize: 25 }}
                          />
                        </div>
                        <div className="d-inline-block">
                          <p className="mb-0">
                            {`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}
                          </p>
                          <p className="mb-0">
                            {formatDate(shipment.due_date)}
                          </p>
                        </div>
                      </TableData>
                      <TableData>
                        {shipment.shipper && shipment.shipper.name}
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Card>
        )}
      </ScreenSize>
    </React.Fragment>
  );
}

const Loader = ({ error }) => {
  const loaderLength = [1, 2, 3, 4, 5];
  return (
    <React.Fragment>
      <ScreenSize xs sm>
        {() => (
          <Card>
            {error && (
              <div className="d-flex align-items-center justify-content-center rounded p-3">
                <div className="lead text-danger">{error.message}</div>
              </div>
            )}
            <div className="card-header">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="card-header-title">My Loads</h3>
                <Link to="/shipments">View All</Link>
              </div>
            </div>
            <ListGroup className="list-group-flush mb-0">
              {!error &&
                loaderLength.map((item, index) => {
                  return (
                    <ListGroupItem key={index} className="px-4">
                      <div
                      // className="animated flash infinite slower"
                      >
                        <Row className="justify-content-between mb-3">
                          <Col xs="auto">
                            <div
                              className="bg-light rounded"
                              style={{ height: 33, width: 100 }}
                            />
                          </Col>
                          <Col xs="auto">
                            <div className="text-secondary d-flex align-items-center">
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 40 }}
                              />
                              <div className="text-muted"> &#9679; </div>
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 60 }}
                              />
                            </div>
                            <div className="text-secondary d-flex align-items-center">
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 60 }}
                              />
                              <div className="text-muted"> &#9679; </div>
                              <div
                                className="bg-light rounded"
                                style={{ height: 14, width: 40 }}
                              />
                            </div>
                          </Col>
                        </Row>
                        <ListGroup className="list-group list-group-flush list-group-activity">
                          <ListGroupItem className="py-3">
                            <Row>
                              <Col xs="auto">
                                <div className="avatar avatar-sm">
                                  <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                    <Icon type="clipboard" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="ml-n2">
                                <div
                                  className="bg-light rounded mb-1"
                                  style={{ height: 14, width: 120 }}
                                />
                                <div
                                  className="bg-light rounded mb-1"
                                  style={{ height: 14, width: 100 }}
                                />
                                <div
                                  className="bg-light rounded mb-1"
                                  style={{ height: 14, width: 190 }}
                                />
                                <div
                                  className="bg-light rounded mb-1"
                                  style={{ height: 14, width: 75 }}
                                />
                              </Col>
                            </Row>
                          </ListGroupItem>

                          <ListGroupItem className="py-3">
                            <Row>
                              <Col xs="auto">
                                <div className="avatar avatar-sm">
                                  <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                    <Icon type="truck" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="ml-n2">
                                <div>
                                  <div
                                    className="bg-light rounded mb-2"
                                    style={{ height: 18, width: 160 }}
                                  />
                                  <div>
                                    <div
                                      className="bg-light rounded"
                                      style={{ height: 16, width: 140 }}
                                    />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </ListGroupItem>
                          <ListGroupItem className="pt-3 pb-0">
                            <Row>
                              <Col xs="auto">
                                <div className="avatar avatar-sm">
                                  <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                                    <Icon type="map-pin" />
                                  </div>
                                </div>
                              </Col>
                              <Col className="ml-n2">
                                <div>
                                  <div
                                    className="bg-light rounded mb-2"
                                    style={{ height: 18, width: 170 }}
                                  />
                                  <div
                                    className="bg-light rounded"
                                    style={{ height: 18, width: 170 }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </div>
                    </ListGroupItem>
                  );
                })}
            </ListGroup>
          </Card>
        )}
      </ScreenSize>
      <ScreenSize md lg xl>
        {() => (
          <Card className="card-fill">
            <div className="card-header border-bottom-0">
              <div className="d-flex justify-content-between">
                <h3 className="card-header-title">My Loads</h3>
                <Link to="/shipments">View All</Link>
              </div>
            </div>
            <div>
              <div className="table-responsive">
                <Table className="table table-nowrap card-table">
                  <TableHead>
                    <TableRow>
                      <TableHeader className="py-2">Status</TableHeader>
                      <TableHeader className="py-2">ID</TableHeader>
                      <TableHeader className="py-2">Pickup</TableHeader>
                      <TableHeader className="py-2">
                        <p className="ml-4 mb-0">Delivery</p>
                      </TableHeader>
                      <TableHeader className="py-2">Carrier</TableHeader>
                    </TableRow>
                  </TableHead>
                  <TableBody className="list">
                    {loaderLength.map((item) => (
                      <TableRow style={{ height: 79 }} key={item}>
                        <TableData>
                          <div
                            className="bg-light rounded"
                            style={{ height: 29, width: 78 }}
                          />
                        </TableData>
                        <TableData>
                          <div
                            className="bg-light rounded mb-2"
                            style={{ height: 18, width: 20 }}
                          />
                          <div
                            className="bg-light rounded"
                            style={{ height: 18, width: 55 }}
                          />
                        </TableData>
                        <TableData className="pr-0">
                          <div
                            className="bg-light rounded mb-2"
                            style={{ height: 18, width: 92 }}
                          />
                          <div
                            className="bg-light rounded"
                            style={{ height: 18, width: 89 }}
                          />
                        </TableData>
                        <TableData className="pl-0">
                          <div className="d-inline-block mx-2">
                            <Icon
                              type="arrow-right"
                              className="text-light"
                              style={{ fontSize: 25 }}
                            />
                          </div>
                          <div
                            className="d-inline-block"
                            style={{ marginBottom: -7 }}
                          >
                            <div
                              className="bg-light rounded mb-2"
                              style={{ height: 18, width: 92 }}
                            />
                            <div
                              className="bg-light rounded"
                              style={{ height: 18, width: 89 }}
                            />
                          </div>
                        </TableData>
                        <TableData>
                          <div
                            className="bg-light rounded"
                            style={{ height: 18, width: 170 }}
                          />
                        </TableData>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </div>
          </Card>
        )}
      </ScreenSize>
    </React.Fragment>
  );
};
