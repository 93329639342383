import React, { Component } from 'react';
import feathers from 'src/feathers';
import logo from 'src/images/logo-md.png';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Stripes
} from 'straightline-ui';

export default class VerifySignup extends Component {
  constructor(props) {
    super(props);
    this.resetService = feathers.service(`auth/reset-password`);
    this.state = {
      showPassword: false,
      error: null,
      success: null,
      fetching: true,
      valid: false
    };
  }

  componentDidMount() {
    const { resetToken } = this.props.location.query;
    if (!resetToken) {
      this.props.history.push('/login');
    }
    this.resetService
      .get(resetToken)
      .then(({ valid }) => this.setState({ valid, fetching: false }))
      .catch(() => this.props.history.push('/login'));
  }

  handleSubmit = (data, formik) => {
    const { resetToken } = this.props.location.query;
    const { password } = data;
    formik.setSubmitting(true);
    this.resetService
      .create({ password, password_reset_token: resetToken })
      .then(async (user) => {
        feathers
          .authenticate({
            strategy: 'local',
            email: user.email,
            password
          })
          .then((authResult) => {
            formik.setSubmitting(false);
            this.props.history.push('/guide');
          })
          .catch((error) => {
            formik.setSubmitting(false);
            this.props.history.push('/login');
          });
      })
      .catch((error) => {
        formik.setSubmitting(false);
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        this.setState({ error, success: null });
      });
  };

  render() {
    const { error, success, fetching, valid, showPassword } = this.state;
    const { resetToken } = this.props.location.query;
    if (fetching) {
      return null;
    }
    if (!valid) {
      return (
        <div className="min-h-100vh d-flex align-items-center border-top-2 border-primary">
          <Container>
            <Row className="align-items-center">
              <Col sm="12" md={{ size: 5, offset: 4 }}>
                <h1 className="display-4 text-center mb-3 animated slideInDown">
                  Invalid or Expired Link
                </h1>
                <p className="text-muted text-center mb-5 animated slideInDown">
                  This link has either already been used or is expired. Account
                  Verification links will expire after 7 days.
                </p>
                <Button
                  to="/forgot-password"
                  color="primary"
                  className="animated slideInDown"
                  block
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div className="min-h-100vh d-flex align-items-center border-top-2 border-primary">
        <Stripes animation="fadeIn" />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="5">
              <div className="text-center"></div>
              <Card className="py-5 p-sm-5">
                <CardBody>
                  <div className="text-center">
                    <img
                      src={logo}
                      alt="Straightline Logo"
                      className="img-fluid mb-3"
                      style={{ height: 50 }}
                    />
                  </div>
                  <p className="text-secondary lead text-center">
                    Welcome to Straightline! First things first, lets get you a
                    password. Your password must be 8+ characters and contain a
                    lowercase letter, uppercase letter, number, and special
                    character.
                  </p>
                  {success ? (
                    <Alert color="success" className="animated bounceIn faster">
                      {success}
                    </Alert>
                  ) : (
                    <React.Fragment>
                      <Formik
                        onSubmit={this.handleSubmit}
                        initialValues={{
                          password: '',
                          password_reset_token: resetToken
                        }}
                        validationSchema={this.resetService.schema}
                        render={({ isSubmitting, errors }) => {
                          return (
                            <Form>
                              {error && (
                                <Alert
                                  color="warning"
                                  className="animated bounceIn faster"
                                >
                                  <strong>Error:&nbsp;</strong>&nbsp;
                                  {error.message || error}
                                </Alert>
                              )}
                              <FormGroup>
                                <Label>Password*</Label>
                                <InputGroup className="input-group-merge">
                                  <FormInput
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    className="form-control-appended"
                                    placeholder="Enter a new password"
                                    disabled={isSubmitting}
                                  />
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      <Icon
                                        type={showPassword ? 'eye-off' : 'eye'}
                                        className="pointer"
                                        onClick={() =>
                                          this.setState({
                                            showPassword: !showPassword
                                          })
                                        }
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                  <FormFeedback name="password" />
                                </InputGroup>
                              </FormGroup>
                              <Button
                                type="submit"
                                color="primary"
                                size="lg"
                                block
                                className={`mb-3 ${
                                  isSubmitting ? 'is-loading' : ''
                                }`}
                                disabled={isSubmitting}
                              >
                                Set Password
                              </Button>
                            </Form>
                          );
                        }}
                      />
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
