import { withResult } from 'feathers-fletching';
import { authenticateClient, withSearch } from 'src/feathers/hooks';
import { STATUS, TRUCK_TYPES } from 'straightline-utils/constants';
import { validateSchema } from 'straightline-utils/hooks';

const withResults = withResult({
  pickup_address: (result) => {
    if (result.pickup_address) {
      return {
        ...result.pickup_address,
        position: {
          lat: Number(result.pickup_address.latitude),
          lng: Number(result.pickup_address.longitude)
        }
      };
    }
  },
  delivery_address: (result) => {
    if (result.delivery_address) {
      return {
        ...result.delivery_address,
        position: {
          lat: Number(result.delivery_address.latitude),
          lng: Number(result.delivery_address.longitude)
        }
      };
    }
  },
  matches: async (result, context) => {
    if (!context.params.$include?.includes('matches')) {
      return undefined;
    }

    const { total } = await context.app.service('api/shipment-matches').find({
      query: {
        $limit: 0,
        shipment_request: result,
        status_type: STATUS.BOOKED
      }
    });

    return total;
  },
  trucks: (result) => {
    return TRUCK_TYPES.filter((truck) =>
      result.truck_types.find((truck_type) => truck.value === truck_type)
    );
  }
});

const withSearches = withSearch([
  'pickup_address.city',
  'pickup_address.state',
  'pickup_address.address_1',
  'pickup_address.postal_code',
  'pickup_address.name',
  'delivery_address.city',
  'delivery_address.state',
  'delivery_address.address_1',
  'delivery_address.postal_code',
  'delivery_address.name'
]);

export default {
  before: {
    all: [authenticateClient, withSearches],
    find: [],
    get: [],
    create: [validateSchema],
    update: [validateSchema],
    patch: [validateSchema],
    remove: []
  },
  after: {
    all: [withResults],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
