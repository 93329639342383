import feathers from '@feathersjs/client';
import { disallow } from 'feathers-hooks-common';
import { authenticateClient } from 'src/feathers/hooks';
const { FeathersError } = feathers.errors;

// Image uploads must use a REST provider to user multipart-form.
// api/documents service is disallowed() for socket.io. But, our
// clientside `app` is configured as socket.io. Skip the actual
// service call by using standard fetch and setting context.result
const useFetch = async (context) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/carrier-api/documents`,
    {
      method: 'POST',
      headers: {
        Authorization: context.params.accessToken
      },
      body: context.data
    }
  );
  if (response.ok) {
    context.result = await response.json();
  } else {
    const error = await response.json();
    throw new FeathersError(error);
  }
  return context;
};

export default {
  before: {
    all: [authenticateClient],
    find: [],
    get: [],
    create: [useFetch],
    update: [],
    patch: [],
    remove: [disallow()]
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
