import React, { Component } from 'react';
import { Button, Icon, Modal, ModalFooter } from 'straightline-ui';

export default class VideoModal extends Component {
  state = { isOpen: false };

  playVideo = () => {
    this.video.play();
  };

  pauseVideo = () => {
    this.video.pause();
  };

  toggleModal = () => this.setState({ isOpen: !this.state.isOpen });

  render() {
    const { isOpen } = this.state;
    return (
      <React.Fragment>
        <Button {...this.props} onClick={this.toggleModal} color="white">
          <Icon type="play-circle" className="mr-2" />
          Carrier Video
        </Button>
        <Modal
          isOpen={isOpen}
          size="lg"
          onOpened={this.playVideo}
          onClosed={this.pauseVideo}
        >
          {() => {
            return (
              <React.Fragment>
                <video
                  width="100%"
                  controls={true}
                  ref={(ref) => {
                    this.video = ref;
                  }}
                >
                  <source src="https://www.bookstraightline.com/videos/carrier-promo.mp4" />
                </video>
                <ModalFooter>
                  <Button color="white" onClick={this.toggleModal}>
                    Close
                  </Button>
                  <a
                    href="https://www.bookstraightline.com/carriers"
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary"
                  >
                    Learn More
                  </a>
                </ModalFooter>
              </React.Fragment>
            );
          }}
        </Modal>
      </React.Fragment>
    );
  }
}
