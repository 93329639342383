import { QueryClient, useMutation, useQuery } from 'react-query';

// A half baked attempt at adding react-query to feathers. This is not
// necessary but I thought it would be nice for the hooks to be on the
// service interface so the dev can "just use the service interface".
// It also renames react-quey's `data` to `result` to better align with
// feathers conventions.

export default function (app) {
  const getServiceName = (service) => {
    const [serviceName] = Object.entries(app.services).find(
      ([serviceName, serviceInstance]) => service === serviceInstance
    );
    return serviceName;
  };

  const createGetKey = (service, _id, params) => {
    const serviceName = getServiceName(service);
    const id = _id && _id.toString ? _id.toString() : _id;
    const key = [serviceName, 'get', id];
    if (params) {
      key.push(params);
    }
    return key;
  };

  const createFindKey = (service, params) => {
    const serviceName = getServiceName(service);
    const key = [serviceName, 'find'];
    if (params) {
      key.push(params);
    }
    return key;
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: (failureCount, error) => {
          // failureCount starts at 0. Retry 3 times max
          if (failureCount === 2) {
            return false;
          }

          // 408 === timeout
          if (error.code === 408) {
            return true;
          }

          // TODO: Better detect network/socket errors

          // By default, do not retry. We trust that if the server
          // gave us an error, and it was not an error handled above
          // that we do not need to retry. This handles stuff like 404
          return false;
        }
      }
    }
  });

  app.set('queryClient', queryClient);

  app.mixins.push((service, path) => {
    // service.on('created', (result) => {
    //   service.invalidateFind();
    // });

    // service.on('patched', (result) => {
    //   const results = Array.isArray(result) ? result : [result];
    //   service.invalidateFind();
    //   results.forEach((res) => {
    //     service.invalidateGet(res[service.id]);
    //   });
    // });

    // invalidateFind and invalidGet mark a query as stale. If the query
    // is mounted, it is refetched. But, for example, if you are on an edit
    // screen and update a record, then go back to the list screen for
    // those records, the old data will still show as the new data is being
    // fetched. This means that record you just updated will show in the
    // list with its old data as the list updates and then show its new data.
    // If its OK to show that old data for a sec, then these are the methods
    // to use.
    service.invalidateFind = function (params) {
      const key = createFindKey(service, params);
      queryClient.invalidateQueries(key);
    };

    service.invalidateGet = function (_id, params) {
      const key = createGetKey(service, _id, params);
      queryClient.invalidateQueries(key);
    };

    // removeFind and removeGet remove the entry from the cache totally,
    // but nothing is automatically refetched. If you are on an edit
    // screen and update a record, then go back to the list screen for
    // those records, the entire list has been blown away and will start
    // from a loading state. If it is imperative that the user not see the
    // old data for a sec and that it starts totally over, use these methods
    service.removeFind = function (params) {
      const key = createFindKey(service, params);
      queryClient.removeQueries(key);
    };

    service.removeGet = function (_id, params) {
      const key = createGetKey(service, _id, params);
      queryClient.removeQueries(key);
    };

    const useFind = (params, options) => {
      const key = createFindKey(service, params);
      const { params: extraParams, ...opts } = options || {};
      const { data, ...rest } = useQuery(
        key,
        () => service.find({ ...params, ...extraParams }),
        opts
      );
      return { ...rest, result: data };
    };

    service.useFind = useFind;

    const useFindOne = (params, options) => {
      const key = createFindKey(service, params);
      const { params: extraParams, ...opts } = options || {};
      const { data, ...rest } = useQuery(
        key,
        () => service.findOne({ ...params, ...extraParams }),
        opts
      );
      return { ...rest, result: data };
    };

    service.useFindOne = useFindOne;

    const useGet = (_id, params, options) => {
      const key = createGetKey(service, _id, params);
      const { params: extraParams, ...opts } = options || {};
      const { data, ...rest } = useQuery(
        key,
        () => service.get(_id, { ...params, ...extraParams }),
        opts
      );
      return { ...rest, result: data };
    };

    service.useGet = useGet;

    const useCreate = (options) => {
      const { data, ...rest } = useMutation(
        ({ data, params }) => service.create(data, params),
        options
      );
      return {
        ...rest,
        create: (data, params) => rest.mutateAsync({ data, params }),
        result: data
      };
    };

    service.useCreate = useCreate;

    const usePatch = (options) => {
      const { data, ...rest } = useMutation(
        ({ id, data, params }) => service.patch(id, data, params),
        options
      );
      return {
        ...rest,
        patch: (id, data, params) => rest.mutateAsync({ id, data, params }),
        result: data
      };
    };

    service.usePatch = usePatch;

    const useUpdate = (options) => {
      const { data, ...rest } = useMutation(
        ({ id, data, params }) => service.update(id, data, params),
        options
      );
      return {
        ...rest,
        update: (id, data, params) => rest.mutateAsync({ id, data, params }),
        result: data
      };
    };

    service.useUpdate = useUpdate;

    const useRemove = (options) => {
      const { data, ...rest } = useMutation(
        ({ id, data, params }) => service.remove(id, data, params),
        options
      );
      return {
        ...rest,
        remove: (id, data, params) => rest.mutateAsync({ id, data, params }),
        result: data
      };
    };

    service.useRemove = useRemove;
  });
}
