import { withResult } from 'feathers-fletching';
import { disallow } from 'feathers-hooks-common';
import { authenticateClient, withSearch } from 'src/feathers/hooks';
import { statusType, truckType } from 'straightline-utils/constants';

const withSearches = withSearch(
  [
    '$shipper.name$',
    'pickup_address.city',
    'pickup_address.state',
    'delivery_address.city',
    'delivery_address.state',
    'reference_number'
  ],
  ['id']
);

const withResults = withResult({
  status: (shipment) => {
    if (shipment.status_type) {
      return statusType(shipment.status_type);
    }
  },
  truck: (shipment) => {
    if (shipment.truck_type) {
      return truckType(shipment.truck_type);
    }
  },
  pickup_address: (shipment) => {
    if (shipment.pickup_address) {
      return {
        ...shipment.pickup_address,
        position: {
          lat: Number(shipment.pickup_address.latitude),
          lng: Number(shipment.pickup_address.longitude)
        }
      };
    }
  },
  delivery_address: (shipment) => {
    if (shipment.delivery_address) {
      return {
        ...shipment.delivery_address,
        position: {
          lat: Number(shipment.delivery_address.latitude),
          lng: Number(shipment.delivery_address.longitude)
        }
      };
    }
  },
  cost_per_mile: (shipment) => {
    if (shipment.rate && shipment.travel) {
      return (
        shipment.rate.price.carrier_price / (shipment.travel.miles || 1)
      ).toFixed(1);
    }
  }
});

export default {
  before: {
    all: [authenticateClient, withSearches],
    find: [],
    get: [],
    create: [disallow()],
    update: [disallow()],
    patch: [],
    remove: [disallow()]
  },
  after: {
    all: [withResults],
    find: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
};
