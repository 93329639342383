import React from 'react';
import CarrierDocuments from 'src/components/CarrierDocuments';
import SettingsHeader from 'src/components/SettingsHeader';
import { useAppState, useOnMount } from 'src/lib/hooks';
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormReset,
  FormSubmit,
  Icon,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';

export default function Carrier() {
  const {
    state: { carrier },
    setInState
  } = useAppState();
  const app = useApp();
  const service = app.service('api/carrier');

  useOnMount(() => {
    service.find().then((carrier) => {
      setInState('carrier', carrier);
    });
  });

  const handleSubmit = (data, formik) => {
    service
      .create(data)
      .then((updatedCarrier) => {
        setInState('carrier', updatedCarrier);
        formik.resetForm({ values: updatedCarrier });
        toast.success(`Business updated!`);
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <Container fluid className="pb-5">
      <SettingsHeader activeTab="carrier" />
      <Row>
        <Col xl="5" lg="6" className="mb-5 mb-md-0">
          <Formik
            initialValues={carrier}
            onSubmit={handleSubmit}
            validationSchema={service.schema}
            render={(formik) => {
              const { isSubmitting } = formik;
              return (
                <Form>
                  <Card>
                    <CardBody>
                      <FormGroup>
                        <Label>Business Name*</Label>
                        <FormInput name="name" disabled={isSubmitting} />
                        <FormFeedback name="name" />
                      </FormGroup>
                      <Row>
                        <Col xs="6">
                          <FormGroup className="mb-2">
                            <Label>MC Number</Label>
                            <FormInput
                              name="mc_number"
                              disabled={isSubmitting}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col xs="6">
                          <FormGroup className="mb-2">
                            <Label>DOT Number</Label>
                            <FormInput
                              name="dot_number"
                              disabled={isSubmitting}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <p className="text-muted small mb-4">
                        Please call {process.env.REACT_APP_SUPPORT_PHONE} to
                        change your DOT or MC number
                      </p>
                      <FormSubmit className="mr-3">Submit</FormSubmit>
                      <FormReset>Discard Changes</FormReset>
                    </CardBody>
                  </Card>
                  <CarrierDocuments />
                </Form>
              );
            }}
          />
        </Col>
        <Col xl="5" lg="6" className="mb-3 mb-md-0">
          <Card>
            <CardBody>
              {carrier.vetted ? (
                <React.Fragment>
                  <Badge color="success" className="float-right">
                    <Icon type="check-circle" className="mr-2" />
                    Vetted
                  </Badge>
                  <p className="lead text-success">Good to go!</p>
                  Although you are currently in good standing, we may contact
                  you to collect more information about your business as needed.
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Badge color="danger" className="float-right">
                    <Icon type="alert-circle" className="mr-2" />
                    Not Vetted
                  </Badge>
                  <div className="lead text-danger mb-3">Pending review</div>
                  <div className="mb-3">
                    Your business is still awaiting the vetting process. Please
                    upload a "Carrier Packet" (A file containing both a W9 and
                    Certificate of Insurance) or both your W9 and Certificate of
                    Insurance.
                  </div>
                  <div className="mb-3">
                    Once your files are uploaded, Straightline will manually
                    verify your business by reviewing your documents, DOT
                    profile, and insurance.
                  </div>
                  <div>
                    You can check back here later to review your status or you
                    will receive a notification when your account is activated!
                  </div>
                </React.Fragment>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
