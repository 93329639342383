import React from 'react';
import LoadingIndicator from 'src/components/LoadingIndicator';
import emptyImg from 'src/images/empty.svg';
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Icon,
  Link,
  Row,
  useApp
} from 'straightline-ui';
import { ROLES } from 'straightline-utils/constants';

export default function User({ match }) {
  const app = useApp();
  const {
    result: user,
    isLoading,
    isError,
    error
  } = app.service('api/users').useGet(match.params.id);

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center fadeIn faster">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (isError && error) {
    return (
      <Container>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg="6" className="text-center">
            <img
              src={emptyImg}
              alt="Not Found"
              className="img-fluid fadeIn faster mb-5"
            />
            {error.code === 404 ? (
              <h1>Could not find that user</h1>
            ) : (
              <h1>{error.message}</h1>
            )}
            <Button
              to="/settings/users"
              color="primary"
              size="lg"
              className="mr-3"
            >
              <Icon type="arrow-left" className="mr-2" />
              Return to Users
            </Button>
            <Button to="/support" color="secondary" size="lg">
              <Icon type="send" className="mr-2" />
              Contact Straightline
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col>
          <div className="header pt-md-2">
            <div className="header-body">
              <Row className="align-items-center">
                <Col>
                  <h6 className="header-pretitle">
                    <Breadcrumb listClassName="p-0">
                      <BreadcrumbItem>
                        <Link to="/settings/users">My Team</Link>
                      </BreadcrumbItem>
                      <BreadcrumbItem active>View User</BreadcrumbItem>
                    </Breadcrumb>
                  </h6>
                  <h1 className="header-title">{user.full_name}</h1>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="4" sm="6">
          <Card>
            <CardBody>
              <p className="lead mb-1">{user.full_name}</p>
              <p className="mb-1">{user.email}</p>
              <p>{user.masked_phone}</p>
              <p className="mb-0">
                Roles:{' '}
                {user.roles.map((userRole, index) => {
                  const role = ROLES.find(
                    (role) => role.value === userRole.role_type
                  );
                  return <div key={userRole.role_type}>{role.label}</div>;
                })}
              </p>
            </CardBody>
          </Card>
          <Button to="/settings/users" color="white">
            Cancel
          </Button>
        </Col>
      </Row>
    </Container>
  );
}
