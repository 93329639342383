import React, { Component } from 'react';
import { Button, Col, Container, Row } from 'straightline-ui';

export default class Error extends Component {
  render() {
    return (
      <div className="d-flex align-items-center border-top-2 border-primary vh-100">
        <Container>
          <Row className="justify-content-center">
            <Col sm="12" md="5" xl="4" className="my-5">
              <div className="text-center">
                <h6 className="text-uppercase text-muted mb-4">404 error</h6>
                <h1 className="display-4 mb-3">
                  There’s no page here&nbsp;
                  <span role="img" aria-label="crying emoji">
                    😭
                  </span>
                </h1>
                <p className="text-muted mb-4">
                  Looks like you ended up here by accident?
                </p>
                <Button to="/" color="primary" size="lg">
                  Return to home page
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
