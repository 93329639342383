import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LineItemsCard from 'src/components/LineItemsCard';
import LoadingIndicator from 'src/components/LoadingIndicator';
import MapHeaderPreview from 'src/components/MapHeaderPreview';
import deliveriesImg from 'src/images/deliveries.svg';
import emptyImg from 'src/images/empty.svg';
import heavyBoxRedImg from 'src/images/heavy-box-red.svg';
import logo from 'src/images/logo-md.png';
import {
  AddressCard,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Icon,
  Modal,
  ModalBody,
  Row,
  toast,
  useApp,
  useScreenSize
} from 'straightline-ui';
import { roundNumberToLabel } from 'straightline-utils/accounting';
import {
  ENTITY_TYPE,
  isCancelled,
  isDelivered
} from 'straightline-utils/constants';
import {
  differenceInSeconds,
  formatDate,
  formatDistanceToNow
} from 'straightline-utils/time';

const DownloadElement = React.lazy(() =>
  import('src/components/DownloadElement').catch(() => window.location.reload())
);

export default function ShipmentPreview({ match, location }) {
  const app = useApp();
  const history = useHistory();
  const { xl } = useScreenSize();

  const {
    result: shipment,
    isLoading,
    isError,
    error
  } = app.service('api/shipment-previews').useGet(
    match.params.id,
    { query: { type: ENTITY_TYPE.CARRIER } },
    {
      refetchInterval: (result) => {
        if (!result) {
          return false;
        }
        // When newly created, there are still dispatches and other side
        // effects happening in the background. There may also be an SL
        // admin user making quick updates as this is created. Ping
        // for updates on new shipments
        const created = differenceInSeconds(new Date(), result.created_at);
        if (created <= 15) {
          return 3000;
        }
        if (created <= 60) {
          return 8000;
        }
        if (created <= 60 * 5) {
          return 15000;
        }
        return 60000;
      }
    }
  );

  useEffect(() => {
    const query = location.query;

    if (!shipment || !query?.action) {
      return;
    }

    async function updateShipment() {
      if (query.action === 'status' && query.status_type) {
        console.log(query.status_type, shipment.status_type);
        if (query.status_type === shipment.status_type) {
          history.replace(location.pathname);
          return;
        }
        try {
          const updatedShipment = await app
            .service('api/shipment-previews')
            .patch(match.params.id, {
              status_type: query.status_type
            });
          history.replace(location.pathname);
          app.service('api/shipment-previews').invalidateGet(match.params.id);
          toast(
            <div className="p-3">
              <p className="text-success lead font-weight-bold">
                Status Updated!
              </p>
              <span className="lead">
                The status has been updated to "{updatedShipment.status.label}".
                You don't need to do anything else. We'll keep you posted.
              </span>
            </div>,
            { hideProgressBar: true }
          );
        } catch (error) {
          history.replace(location.pathname);
          toast.errors(error);
        }
      }
    }

    updateShipment();
  }, [app, history, shipment, match, location]);

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  if (isError) {
    return (
      <Container>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg="6" className="text-center">
            <img
              src={emptyImg}
              alt="Not Found"
              className="img-fluid animated fadeIn faster mb-5"
            />
            {error.code === 404 ? (
              <h1>Could not find that load</h1>
            ) : (
              <h1>{error.message}</h1>
            )}
            <ContactModal shipment={{ id: 'NA' }} />
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div>
      <Container fluid className="fixed-top bg-white shadow-sm bg-white">
        <Row className="align-items-center py-2">
          <Col xs="4">
            <img src={logo} style={{ maxWidth: 138 }} alt="Straightline Logo" />
          </Col>
          <Col xs="8" className="ml-auto text-right">
            <a
              href="https://www.bookstraightline.com/shippers"
              target="_blank"
              rel="noreferrer"
              className="btn btn-white"
            >
              <Icon type="help-circle" className="mr-2" />
              Learn More
            </a>
          </Col>
        </Row>
      </Container>
      <div style={{ marginTop: 54 }}>
        <div className="">
          <MapHeaderPreview shipment={shipment} />
        </div>
        <Container fluid className="bg-white mb-5 border-bottom">
          <PageHeader shipment={shipment} />
        </Container>
        <Container fluid="lg" className="pb-5">
          <Row>
            {xl ? (
              <React.Fragment>
                <Col xl="7">
                  <StatusCard shipment={shipment} />
                  <DetailsCard shipment={shipment} />
                  <LineItemsCard shipment={shipment} />
                </Col>
                <Col xl="5">
                  <AddressCard shipment={shipment} type="pickup" />
                  <AddressCard shipment={shipment} type="delivery" />
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col sm="12">
                  <StatusCard shipment={shipment} />
                  <DetailsCard shipment={shipment} />
                  <AddressCard shipment={shipment} type="pickup" />
                  <AddressCard shipment={shipment} type="delivery" />
                  <LineItemsCard shipment={shipment} />
                </Col>
              </React.Fragment>
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
}

const PageHeader = ({ shipment }) => {
  return (
    <div className="header mb-0">
      <div className="header-body border-0">
        <Row className="font-weight-bold">
          <Col xs="auto" className="mb-3">
            <span className="text-muted">SL#: </span>
            {shipment.id}
          </Col>
          {shipment.reference_number && (
            <Col xs="auto" className="mb-3">
              <span className="text-muted">PICKUP#: </span>
              {shipment.reference_number}
            </Col>
          )}
          {shipment.pro_number && (
            <Col xs="auto" className="mb-3">
              <span className="text-muted">PRO#: </span>
              {shipment.pro_number}
            </Col>
          )}
          {shipment.mode && (
            <Col xs="auto" className="mb-3">
              <span className="text-muted">MODE: </span>
              {shipment.mode}
            </Col>
          )}
          {shipment.carrier && (
            <Col xs="auto" className="mb-3">
              <span className="text-muted">CARRIER: </span>
              {shipment.carrier.name}
            </Col>
          )}
          {shipment.rate && (
            <Col xs="auto" className="mb-3">
              <span className="text-muted">SERVICE: </span>
              {shipment.rate.service_level.name} (
              {shipment.rate.service_level.code})
            </Col>
          )}
        </Row>
        <Row className="align-items-end justify-content-between mt-md-n1 mb-n3">
          <Col sm="auto" className="mb-3">
            <Row className="align-items-center mb-n3">
              <Col xs="auto" className="mb-3 pr-0 align-items-center d-flex">
                <h1 className="mb-0 font-weight-bold d-inline-block mr-3">{`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}</h1>
                <Icon
                  type="arrow-right"
                  style={{ fontSize: 24 }}
                  className="text-success lead d-inline-block font-weight-bold"
                />
              </Col>
              <Col xs="auto" className="mb-3">
                <h1 className="mb-0 font-weight-bold d-inline-block">{`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}</h1>
              </Col>
            </Row>
          </Col>
          <Col sm="auto" className="text-right mb-3">
            <Row className="d-flex mb-n3">
              <Col sm="auto" className="mb-3">
                <React.Suspense
                  fallback={
                    <Button color="primary" block disabled>
                      <Icon type="download" className="mr-2" />
                      Download Rate Con
                    </Button>
                  }
                >
                  <DownloadElement
                    elementId="rateConfirmation"
                    disabled={!shipment.carrier_id}
                    options={{
                      filename: `Straightline Load ${shipment.id} Rate Confirmation`
                    }}
                    color="primary"
                    block
                  >
                    <Icon type="download" className="mr-2" />
                    Download Rate Con
                  </DownloadElement>
                </React.Suspense>
              </Col>
              <Col sm="auto" className="mb-3">
                <ContactModal shipment={shipment} />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const ContactModal = ({ shipment }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen((open) => !open);

  return (
    <React.Fragment>
      <Modal
        isOpen={open}
        toggle={toggle}
        className="mx-auto"
        style={{ maxWidth: 425, width: '95%' }}
      >
        {() => (
          <ModalBody>
            <h2>We're here for you.</h2>
            <p>
              Straightline has a team of logistics professionals ready to help.
              Give us a shout or fill out the form below and we will get back to
              you ASAP.
            </p>
            <p>
              <Icon type="phone" className="mr-3" />
              <a href={`tel:${process.env.REACT_APP_SUPPORT_PHONE}`}>
                {process.env.REACT_APP_SUPPORT_PHONE}
              </a>
            </p>
            <p>
              <Icon type="mail" className="mr-3" />
              <a
                href={`mailto:${
                  process.env.REACT_APP_SUPPORT_EMAIL
                }?subject=Shipment #${shipment?.id || 'NA'}`}
              >
                {process.env.REACT_APP_SUPPORT_EMAIL}
              </a>
            </p>
            <div className="mt-5">
              <Button
                color="white"
                className="mr-3"
                type="button"
                onClick={toggle}
              >
                Close
              </Button>
            </div>
          </ModalBody>
        )}
      </Modal>
      <Button color="white" onClick={toggle} block>
        <Icon type="send" className="mr-2" />
        Contact Straightline
      </Button>
    </React.Fragment>
  );
};

const DetailsCard = ({ shipment }) => {
  return (
    <Card>
      <CardBody>
        <h5 className="text-uppercase text-muted font-weight-bold">Truck</h5>
        <div className="pre-line mb-4">
          {shipment.truck.label}
          <Icon type="dot" className="text-muted mx-1" />
          {roundNumberToLabel(shipment.load_weight)} lbs
        </div>
        <h5 className="text-uppercase text-muted font-weight-bold">Notes</h5>
        <div className="pre-line">
          {shipment.notes || <span className="text-muted">Not available</span>}
        </div>
      </CardBody>
    </Card>
  );
};

const StatusCard = ({ shipment }) => {
  const { eta, status } = shipment;

  if (isCancelled(shipment)) {
    return (
      <Card>
        <div className="px-4 py-3 border-bottom">
          <Row className="align-items-center justify-content-between">
            <Col xs="auto">
              <h3 className="mb-1">Load Status</h3>
              <div>{shipment.status.description}</div>
            </Col>
            <Col xs="auto">
              <div className="progress progress-status">
                <div
                  className="bg-danger progress-bar"
                  style={{ width: '100%' }}
                >
                  Cancelled
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="text-center">
            <img
              src={heavyBoxRedImg}
              alt="Heavy Boxes"
              className="w-50 mb-4 animated bounceIn"
            />
          </div>
          <h1 className="text-danger">Bummer!</h1>
          <p>This shipment has been cancelled.</p>
          <ContactModal shipment={shipment} />
        </CardBody>
      </Card>
    );
  }

  if (isDelivered(shipment)) {
    return (
      <Card>
        <div className="px-4 py-3 border-bottom">
          <Row className="align-items-center justify-content-between">
            <Col xs="auto">
              <h3 className="mb-1">Load Status</h3>
              <div>{shipment.status.description}</div>
            </Col>
            <Col xs="auto">
              <div className="progress progress-status">
                <div
                  className="bg-success progress-bar"
                  style={{ width: '100%' }}
                >
                  Delivered
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <CardBody>
          <div className="text-center">
            <img
              src={deliveriesImg}
              alt="Heavy Boxes"
              className="w-50 mb-4 animated bounceIn"
            />
          </div>
          <h1 className="text-success">Congratulations!</h1>
          <p>This shipment has been successfully delivered.</p>
          <ContactModal shipment={shipment} />
          <div className="mb-n2">
            {/* <Button
              to="/quote"
              query={{ copied_shipment_id: shipment.id }}
              color="secondary"
              className="mb-2 btn-xs-block btn-sm-block btn-md-block"
            >
              <Icon type="repeat" className="mr-2" />
              Repeat Shipment
            </Button> */}
          </div>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <div className="px-4 py-3 border-bottom">
        <Row className="justify-content-between">
          <Col xs="auto">
            <h3 className="mb-1">Load Status</h3>
            <div>{shipment.status.description}</div>
          </Col>
          <Col xs="auto">
            <div className="progress progress-status">
              <div
                className="bg-primary progress-bar"
                style={{ width: '100%' }}
              >
                {status.label}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className="px-4 py-3 ">
        <Row className="align-items-center justify-content-between">
          <Col xs="auto">
            <h3 className="mb-0">ETA</h3>
          </Col>
          <Col xs="auto">
            {eta ? (
              <div className="text-right">
                <p
                  className="mb-0 text-primary mb-0 font-weight-bold"
                  style={{
                    lineHeight: 1
                  }}
                >
                  {formatDate(eta.date, 'iii, LLL do')} at {eta.time}
                </p>
                <p className="mb-0 text-muted small">
                  last updated {formatDistanceToNow(eta.created_at)}
                </p>
              </div>
            ) : (
              <h4 className="mb-0 text-muted">ETA not set</h4>
            )}
          </Col>
        </Row>
      </div>
    </Card>
  );
};
