import once from 'lodash/once';
import LogRocket from 'logrocket';

// We get lots of wasted 1s logrocket vids where the user
// loads the page and never interacts. Wait for interaction
// before starting video capture
const awaitInteraction = (events, callback) => {
  const onceCallback = once(callback);
  events.forEach((event) => {
    window.addEventListener(event, onceCallback, { once: true });
  });
};

export default function (app) {
  // https://docs.logrocket.com/docs/development
  if (process.env.REACT_APP_ENVIRONMENT !== 'development') {
    awaitInteraction(['scroll', 'keydown', 'click', 'touchstart'], () =>
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY)
    );
  }

  app.set('logRocketClient', LogRocket);
}
