import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import { Redirect } from 'react-router-dom';
import LoadingIndicator from 'src/components/LoadingIndicator';
import NavTab from 'src/components/NavTab';
import { TablePaginationBtns } from 'src/components/PaginationBtns';
import emptyImg from 'src/images/empty.svg';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  CustomInput,
  Icon,
  Link,
  ListGroup,
  ListGroupItem,
  MapImage,
  MarkerImage,
  Nav,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableSearch,
  toast,
  useApp
} from 'straightline-ui';
import { useUrlState } from 'straightline-ui';

const addressKeys = ['city', 'state'];
const addressLabel = (address) => {
  return addressKeys
    .filter((key) => !!address[key] && !!address[key].trim())
    .map((key) => address[key])
    .join(', ');
};

export default function ShipmentRequests() {
  const app = useApp();
  const queryState = useUrlState();

  const { getInState, setInState, delInState } = queryState;

  const query = getInState('query', {});
  const $search = getInState('query.$search', '');
  const tab = getInState('tab', 'active');

  const [text, setText] = useState($search);

  const handleTabChange = (tab) => {
    setInState('tab', tab);
  };

  // eslint-disable-next-line
  const debouncedHandleChange = useCallback(
    debounce((value) => {
      const val = value && value.trim ? value.trim() : value;
      if (val) {
        setInState('query.$search', value);
      } else {
        delInState('query.$search');
      }
    }, 300),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    setText(value);
    debouncedHandleChange(value);
  };

  const handleSearchClear = () => {
    handleSearchChange({ target: { value: '' } });
  };

  const {
    result: shipmentRequests,
    isLoading,
    isFetching,
    isError,
    error
  } = app.service('api/shipment-requests').useFind(
    {
      query: {
        ...query,
        active: tab === 'active' ? true : undefined
      },
      $include: ['matches']
    },
    { keepPreviousData: true }
  );

  if (isLoading) {
    return (
      <div className="d-flex vh-100 align-items-center fadeIn faster">
        <LoadingIndicator delay={350} />
      </div>
    );
  }

  if (isError && error) {
    return (
      <Container>
        <Row className="align-items-center justify-content-center vh-100">
          <Col lg="6" className="text-center">
            <img
              src={emptyImg}
              alt="Not Found"
              className="img-fluid fadeIn faster mb-5"
            />
            <h1>{error.message}</h1>
            <Button to="/dashboard" color="primary" size="lg" className="mr-3">
              <Icon type="home" className="mr-2" />
              Return to Dashboard
            </Button>
            <Button to="/support" color="secondary" size="lg">
              <Icon type="send" className="mr-2" />
              Contact Straightline
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!$search && shipmentRequests.total === 0) {
    return <Redirect to="/shipment-request" />;
  }

  return (
    <Container fluid className="pb-5">
      <Row>
        <Col>
          <div className="header pt-md-2">
            <div className="header-body">
              <Row className="align-items-center justify-content-between">
                <Col lg="4" className="mb-2 mb-lg-0">
                  <h6 className="header-pretitle">Overview</h6>
                  <h1 className="header-title">Posted Trucks</h1>
                </Col>
                <Col className="col-auto">
                  <Row>
                    <Col className="mb-3 mb-sm-0">
                      <p className="text-secondary small mb-0 text-xl-right">
                        Post when and where your trucks are available. <br />
                        Straightline will notify you whenever a new shipment
                        matches.
                      </p>
                    </Col>
                    <Col sm="auto">
                      <Button to="/shipment-request" color="primary">
                        <Icon type="truck" className="mr-2" />
                        Post Truck
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="align-items-center">
                <Col>
                  <Nav tabs className="nav-overflow header-tabs">
                    <NavTab
                      tab="active"
                      onClick={handleTabChange}
                      activeTab={tab}
                    >
                      Posted Trucks
                    </NavTab>
                    <NavTab tab="all" onClick={handleTabChange} activeTab={tab}>
                      All Trucks
                    </NavTab>
                  </Nav>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <ScreenSize sm md lg xl>
        {() => (
          <Card>
            <TableSearch
              value={text}
              onChange={handleSearchChange}
              onClear={handleSearchClear}
              placeholder="Search by address, city, state, zip"
            />
            {shipmentRequests.data.length === 0 && <NoResults />}
            <ShipmentRequestsTable
              result={shipmentRequests}
              queryState={queryState}
              isFetching={isFetching}
            />
          </Card>
        )}
      </ScreenSize>
      <ScreenSize xs>
        {() => (
          <React.Fragment>
            <Card>
              <TableSearch
                value={text}
                onChange={handleSearchChange}
                onClear={handleSearchClear}
                placeholder="Search by address, city, state, zip"
              />
              {shipmentRequests.data.length === 0 && <NoResults />}
            </Card>
            <ShipmentRequestCards
              result={shipmentRequests}
              queryState={queryState}
              isFetching={isFetching}
            />
          </React.Fragment>
        )}
      </ScreenSize>
    </Container>
  );
}

const ShipmentRequestsTable = ({ result, isFetching, queryState }) => {
  return (
    <div className="table-responsive">
      <Table className="table table-nowrap card-table table-hover">
        <TableHead>
          <TableRow>
            <TableHeader className="px-3 px-xl-4">Posted</TableHeader>
            <TableHeader className="px-3 px-xl-4">Pickup</TableHeader>
            <TableHeader className="px-3 px-xl-4">Delivery</TableHeader>
            <TableHeader className="px-3 px-xl-4">Trucks</TableHeader>
            <TableHeader className="pr-3 pr-xl-4 pl-0 text-right">
              <TablePaginationBtns
                total={result.total}
                limit={queryState.getInState('query.$limit')}
                skip={queryState.getInState('query.$skip')}
                setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
                disabled={isFetching}
              />
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody className={`list ${isFetching && 'is-fetching'}`}>
          {result.data.map((shipmentRequest) => (
            <TableRow
              key={shipmentRequest.id}
              to={`/shipment-requests/${shipmentRequest.id}`}
            >
              <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                <TogglePosted
                  shipmentRequest={shipmentRequest}
                  key={shipmentRequest.active}
                />
              </TableData>
              <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                {shipmentRequest.pickup_address ? (
                  <React.Fragment>
                    <p className="mb-0">
                      {addressLabel(shipmentRequest.pickup_address)}
                    </p>
                    <p className="mb-0">
                      {shipmentRequest.pickup_address.radius} mile radius
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="mb-0 text-muted">Anywhere</p>
                  </React.Fragment>
                )}
              </TableData>
              <TableData
                className="px-3 px-xl-4"
                style={{ width: '1%', position: 'relative' }}
              >
                <div style={{ position: 'absolute', left: -12, top: 21 }}>
                  <Icon
                    type="arrow-right text-success"
                    style={{ fontSize: 25 }}
                  />
                </div>
                {shipmentRequest.delivery_address ? (
                  <React.Fragment>
                    <p className="mb-0">
                      {addressLabel(shipmentRequest.delivery_address)}
                    </p>
                    <p className="mb-0">
                      {shipmentRequest.delivery_address.radius} mile radius
                    </p>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <p className="mb-0 text-muted">Anywhere</p>
                  </React.Fragment>
                )}
              </TableData>
              <TableData className="px-3 px-xl-4" style={{ width: '1px' }}>
                <div>
                  {shipmentRequest.trucks
                    .slice(0, Math.floor(shipmentRequest.trucks.length / 2))
                    .map((truck) => (
                      <Badge
                        key={truck.value}
                        className="mr-1 badge-light badge-secondary"
                      >
                        {truck.label}
                      </Badge>
                    ))}
                </div>
                <div>
                  {shipmentRequest.trucks
                    .slice(
                      Math.floor(shipmentRequest.trucks.length / 2),
                      shipmentRequest.trucks.length
                    )
                    .map((truck) => (
                      <Badge
                        key={truck.value}
                        className="mr-1 badge-light badge-secondary"
                      >
                        {truck.label}
                      </Badge>
                    ))}
                </div>
              </TableData>
              <TableData
                className="px-3 px-xl-4 text-right"
                style={{ width: '1%' }}
              >
                {shipmentRequest.matches ? (
                  <Badge color="soft-primary" className="p-2">
                    <Icon type="package" className="mr-2" />
                    {`${shipmentRequest.matches} Load Match${
                      shipmentRequest.matches > 1 ? 'es' : ''
                    }`}
                  </Badge>
                ) : null}
              </TableData>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

const ShipmentRequestCards = ({ result, isFetching, queryState }) => {
  return (
    <Card className={`mx-n3 ${isFetching && 'is-fetching'}`}>
      <ListGroup flush>
        {result.total > 10 && (
          <ListGroupItem className="px-3">
            <div className="text-right">
              <TablePaginationBtns
                total={result.total}
                limit={10}
                skip={queryState.getInState('query.$skip', 0)}
                setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
              />
            </div>
          </ListGroupItem>
        )}
        {result.data.map((shipmentRequest) => {
          return (
            <ListGroupItem
              key={shipmentRequest.id}
              tag={Link}
              to={`/shipment-requests/${shipmentRequest.id}`}
              className="list-group-item-action px-3"
            >
              <div className="mb-4 bg-light rounded">
                <MapImage
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_KEY}
                  size="640x280"
                  className="mw-100 rounded"
                  alt="View of address"
                >
                  {shipmentRequest.pickup_address?.position && (
                    <MarkerImage
                      location={shipmentRequest.pickup_address.position}
                      color="green"
                    />
                  )}
                  {shipmentRequest.delivery_address?.position && (
                    <MarkerImage
                      location={shipmentRequest.delivery_address.position}
                      color="red"
                    />
                  )}
                  {/* {shipmentRequest.matches.map((match) => {
                    return (
                      <MarkerImage
                        location={match.pickup_address.position}
                        color="blue"
                      />
                    );
                  })} */}
                </MapImage>
              </div>
              <ListGroup className="list-group list-group-flush list-group-activity">
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="clipboard" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2 d-flex justify-content-between">
                      <div>
                        {shipmentRequest.trucks.map((truck) => (
                          <Badge
                            key={truck.value}
                            className="mr-1 badge-light badge-secondary"
                          >
                            {truck.label}
                          </Badge>
                        ))}
                      </div>
                      <div>
                        {shipmentRequest.matches ? (
                          <Badge color="soft-primary" className="p-2">
                            <Icon type="package" className="mr-2" />
                            {`${shipmentRequest.matches} Load Match${
                              shipmentRequest.matches > 1 ? 'es' : ''
                            }`}
                          </Badge>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="truck" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      {shipmentRequest.pickup_address ? (
                        <div>
                          <div className="font-weight-bold">
                            {shipmentRequest.pickup_address.city},
                            {shipmentRequest.pickup_address.state}
                          </div>
                          <div>
                            {shipmentRequest.pickup_address.radius} mile radius
                          </div>
                        </div>
                      ) : (
                        <div className="text-secondary">Anywhere</div>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="pt-3 pb-0">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="map-pin" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      {shipmentRequest.delivery_address ? (
                        <div>
                          <div className="font-weight-bold">
                            {shipmentRequest.delivery_address.city},
                            {shipmentRequest.pickup_address.state}
                          </div>
                          <div>
                            {shipmentRequest.delivery_address.radius} mile
                            radius
                          </div>
                        </div>
                      ) : (
                        <div className="text-secondary">Anywhere</div>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </ListGroupItem>
          );
        })}
        {result.total > 10 && (
          <ListGroupItem className="px-3">
            <div className="text-right">
              <TablePaginationBtns
                total={result.total}
                limit={10}
                skip={queryState.getInState('query.$skip', 0)}
                setSkip={($skip) => queryState.setInState('query.$skip', $skip)}
              />
            </div>
          </ListGroupItem>
        )}
      </ListGroup>
    </Card>
  );
};

const NoResults = () => {
  return (
    <div className="p-4 text-center">
      <p className="lead text-secondary">
        There are no trucks matching your query. Try a different search term or
        post another truck.
      </p>
      <Row className="mb-n3 justify-content-center">
        <Col sm="6" md="auto" className="mb-3 pr-md-0">
          <Button
            to="/shipment-request"
            color="primary"
            size="lg"
            block
            className="mr-3"
          >
            <Icon type="truck" className="mr-2" />
            Post Truck
          </Button>
        </Col>
        <Col sm="6" md="auto" className="mb-3">
          <Button to="/shipment-matches" color="primary" size="lg" block>
            <Icon type="clipboard" className="mr-2" />
            View Load Board
          </Button>
        </Col>
      </Row>
    </div>
  );
};

const TogglePosted = ({ shipmentRequest }) => {
  const [checked, setChecked] = useState(shipmentRequest.active);
  const app = useApp();

  const handleChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setChecked((checked) => !checked);
    app
      .service('api/shipment-requests')
      .update(shipmentRequest.id, {
        ...shipmentRequest,
        active: !checked
      })
      .then((result) => {
        app.service('api/shipment-requests').invalidateFind();
        app.service('api/shipment-requests').invalidateGet(result.id);
        if (result.active) {
          toast.success('Truck Posted');
        } else {
          toast.warning('Truck Un-Posted');
        }
      })
      .catch((error) => {
        setChecked((checked) => !checked);
        toast.errors(error);
      });
  };

  return (
    <div onClick={handleChange}>
      <CustomInput
        type="switch"
        checked={checked}
        // onChange={handleChange}
      />
    </div>
  );
};
