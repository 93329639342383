import { useEffect, useMemo, useState } from 'react';
import { useAppState } from 'src/lib/hooks';
import {
  Button,
  Card,
  Col,
  Icon,
  Link,
  Row,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  toast,
  useApp
} from 'straightline-ui';
import {
  NOTIFICATION_SOURCE,
  NOTIFICATION_TYPE
} from 'straightline-utils/constants';

export function ShipmentNotifications({ shipment }) {
  const {
    state: { user }
  } = useAppState();

  const role = user.role;

  const notificationSettings = role.notifications.find((notification) => {
    return notification.type === NOTIFICATION_TYPE.SHIPMENT;
  });

  const allNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.ALL
  );

  const assignedNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.ASSIGNED
  );

  const followingNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.FOLLOWING
  );

  if (!notificationSettings) {
    return (
      <InfoItem icon="bell-off" title="Notifications Disabled">
        <div className="">
          Notifications have been disabled for all loads. Don't miss out on
          timely updates for rate confirmations, payments, and more. Visit the
          <Link to="/settings/profile">Settings</Link> page to manage your
          preferences.
        </div>
      </InfoItem>
    );
  }

  if (allNotifications) {
    return (
      <InfoItem icon="bell" title="Notifications Enabled">
        <div className="">
          Notifications have been enabled for all loads. You will receive alerts
          for status changes, payments, etc. Visit the{' '}
          <Link to="/settings/profile">Settings</Link> page to manage your
          preferences.
        </div>
      </InfoItem>
    );
  }

  if (assignedNotifications && shipment.carrier_user_id === user.id) {
    return (
      <InfoItem icon="bell" title="Notifications Enabled">
        <div className="">
          You are receiving notifications for this load because it is assigned
          to you. Visit the <Link to="/settings/profile">Settings</Link> page to
          manage your preferences.
        </div>
      </InfoItem>
    );
  }

  if (followingNotifications) {
    return (
      <FollowItem
        service="api/shipment-users"
        role={role}
        data={{ shipment_id: shipment.id }}
        followText="You are receiving notifications for this load because you are following it. Disable notifications to mute future alerts. Re-enable
        them anytime."
        unfollowText="Follow this load to enable timely notifications for rate
        confirmations, payments, and more. You can disable notifications
        anytime."
      />
    );
  }

  return (
    <InfoItem icon="bell-off" title="Notifications Disabled">
      <div className="">
        You are not receiving notifications for this load because it is not
        assigned to you. Enable following loads by visiting the{' '}
        <Link to="/settings/profile">Settings</Link> page.
      </div>
    </InfoItem>
  );
}

export function ShipmentRequestNotifications({ shipmentRequest }) {
  const {
    state: { user }
  } = useAppState();

  const role = user.role;

  const notificationSettings = role.notifications.find((notification) => {
    return notification.type === NOTIFICATION_TYPE.SHIPMENT_REQUEST;
  });

  const allNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.ALL
  );

  const assignedNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.ASSIGNED
  );

  const followingNotifications = notificationSettings?.source.includes(
    NOTIFICATION_SOURCE.FOLLOWING
  );

  if (!notificationSettings) {
    return (
      <InfoItem icon="bell-off" title="Notifications Disabled">
        <div className="">
          Notifications have been disabled for all trucks. Don't miss out on
          alerts when shipments match your trucks. Visit the{' '}
          <Link to="/settings/profile">Settings</Link> page to manage your
          preferences.
        </div>
      </InfoItem>
    );
  }

  if (allNotifications) {
    return (
      <InfoItem icon="bell" title="Notifications Enabled">
        <div className="">
          Notifications have been enabled for all trucks. You will receive
          alerts anytime a new shipment matches this truck. Visit the{' '}
          <Link to="/settings/profile">Settings</Link> page to manage your
          preferences.
        </div>
      </InfoItem>
    );
  }

  if (assignedNotifications && shipmentRequest.carrier_user_id === user.id) {
    return (
      <InfoItem icon="bell" title="Notifications Enabled">
        <div className="">
          You are receiving notifications for this truck because it is assigned
          to you. Visit the <Link to="/settings/profile">Settings</Link> page to
          manage your preferences.
        </div>
      </InfoItem>
    );
  }

  if (followingNotifications) {
    return (
      <FollowItem
        service="api/shipment-request-users"
        role={role}
        data={{ shipment_request_id: shipmentRequest.id }}
        followText="You are receiving notifications for this truck because you are following it. Disable notifications to mute future alerts. Re-enable them anytime."
        unfollowText="Follow this truck to receive notifications whenever a new load matches this truck criteria. You can disable notifications anytime."
      />
    );
  }

  return (
    <InfoItem icon="bell-off" title="Notifications Disabled">
      <div className="">
        You are not receiving notifications for this truck because it is not
        assigned to you. Enable following trucks by visiting the{' '}
        <Link to="/settings/profile">Settings</Link>
        page.
      </div>
    </InfoItem>
  );
}

function InfoItem({ icon, title, children }) {
  return (
    <div>
      <Row className="justify-content-between align-items-center mb-3">
        <Col>
          <div className="h3 mb-0 d-flex align-items-center">
            <Icon type={icon} className="mr-3" />
            {title}
          </div>
        </Col>
        <Col xs="auto">
          <Link type="button" color="link" to="/settings/profile">
            View Settings
          </Link>
        </Col>
      </Row>
      {children}
    </div>
  );
}

function FollowItem({ service, role, data, followText, unfollowText }) {
  const app = useApp();
  const [isUpdating, setIsUpdating] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const { result, error, isError, isLoading, isFetching } = app
    .service(service)
    .useFindOne({
      query: {
        ...data,
        user_role_id: role.id
      }
    });

  useEffect(() => {
    setIsChecked(!!result);
  }, [result]);

  const inputId = useMemo(() => {
    const number = new Date().getMilliseconds() * Math.random();
    return `follow-${number}`;
  }, []);

  const isDisabled = isLoading || isFetching || isUpdating;

  const handleFollow = () => {
    setIsUpdating(true);
    setIsChecked(true);
    app
      .service(service)
      .create(data)
      .then(() => {
        app.service(service).invalidateFind();
        toast.success('Subscribed to notifications!');
      })
      .catch((error) => {
        toast.errors(error);
        setIsChecked(false);
      })
      .finally(() => setIsUpdating(false));
  };

  const handleUnFollow = (shipmentUserId) => {
    setIsUpdating(true);
    setIsChecked(false);
    app
      .service(service)
      .remove(shipmentUserId)
      .then(() => {
        app.service(service).invalidateFind();
        toast.success('Muted notifications!');
      })
      .catch((error) => {
        toast.errors(error);
        setIsChecked(true);
      })
      .finally(() => setIsUpdating(false));
  };

  if (isLoading) {
    return (
      <InfoItem icon="bell" title="Notifications Enabled">
        <div>
          <div
            className="bg-light rounded text-lighter mb-2"
            style={{ width: '95%', height: '20px' }}
          ></div>
          <div
            className="bg-light rounded text-lighter"
            style={{ width: '60%', height: '19px' }}
          ></div>
        </div>
      </InfoItem>
    );
  }

  if (isError) {
    return (
      <InfoItem icon="bell-off" title="Notifications Disabled">
        <div className="text-danger">
          {error.message || 'There was an error loading notification settings'}
        </div>
      </InfoItem>
    );
  }

  return (
    <div>
      <Row className="justify-content-between align-items-center mb-3">
        <Col>
          <div className="h3 mb-0 d-flex align-items-center">
            <Icon type={result ? 'bell' : 'bell-off'} className="mr-3" />
            Notifications {result ? 'Enabled' : 'Disabled'}
          </div>
        </Col>
        <Col xs="auto">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={isChecked}
              id={inputId}
              disabled={isUpdating}
              onChange={() => {
                if (isDisabled) {
                  return;
                }
                if (result) {
                  return handleUnFollow(result.id);
                }
                handleFollow();
              }}
            />
            <label className="custom-control-label" htmlFor={inputId} />
          </div>
        </Col>
      </Row>
      <div>{result ? <div>{followText}</div> : <div>{unfollowText}</div>}</div>
    </div>
  );
}

export function NotificationsPicker() {
  const app = useApp();
  const {
    state: { user },
    setInState
  } = useAppState();
  const notifications = user.roles[0].notifications;
  const [values, setValues] = useState(notifications);
  const [isUpdating, setIsUpdating] = useState(false);

  useEffect(() => {
    setValues(notifications);
  }, [notifications]);

  const handleUpdate = (notifications) => {
    setValues(notifications);
    setIsUpdating(true);
    app
      .service('api/user')
      .create({ notifications })
      .then((updatedUser) => {
        toast.success('Notifications Updated');
        setInState('user', updatedUser);
      })
      .catch((error) => {
        toast.errors(error);
        setValues(values);
      })
      .finally(() => {
        setIsUpdating(false);
      });
  };

  const handleChange = (type, source) => {
    const current = values.find((notification) => {
      return notification.type === type;
    });
    const validValues = values.filter((notification) => {
      return notification.source?.length > 0;
    });
    if (!current) {
      const newNotifs = [...validValues, { type, source: [source] }];
      return handleUpdate(newNotifs);
    }
    if (current.source.includes(source)) {
      const newNotifs = validValues.map((notification) => {
        if (notification.type !== type) {
          return notification;
        }
        return {
          ...notification,
          source: notification.source.filter(
            (src) => src !== source && src !== NOTIFICATION_SOURCE.ALL
          )
        };
      });
      return handleUpdate(newNotifs);
    }
    const newNotifs = validValues.map((notification) => {
      if (notification.type !== type) {
        return notification;
      }
      const sources = notification.source.filter(
        (src) => src !== source && src !== NOTIFICATION_SOURCE.ALL
      );
      return {
        ...notification,
        source: [...sources, source]
      };
    });
    return handleUpdate(newNotifs);
  };

  const handleAll = (type) => {
    const current = values.find((notification) => {
      return notification.type === type;
    });
    const validValues = values.filter((notification) => {
      return notification.source?.length > 0;
    });
    if (!current) {
      const newNotifs = [
        ...validValues,
        { type, source: [NOTIFICATION_SOURCE.ALL] }
      ];
      return handleUpdate(newNotifs);
    }
    const hasAll = current.source.includes(NOTIFICATION_SOURCE.ALL);
    if (hasAll) {
      const newNotifs = validValues.filter((notification) => {
        return notification.type !== type;
      });
      return handleUpdate(newNotifs);
    }
    const newNotifs = validValues.map((notification) => {
      if (notification.type !== type) {
        return notification;
      }
      return {
        ...notification,
        source: [NOTIFICATION_SOURCE.ALL]
      };
    });
    return handleUpdate(newNotifs);
  };

  const handleDisable = () => {
    if (!values.length) {
      return;
    }
    return handleUpdate([]);
  };

  const smallStyle = {
    fontSize: '0.65rem'
  };

  return (
    <Card>
      <div className="card-header h4 mb-0">
        <div className="font-weight-bold">Notifications</div>
        <div>
          <Button
            color="white"
            size="sm"
            onClick={handleDisable}
            disabled={isUpdating || !values.length}
          >
            Disable All
          </Button>
        </div>
      </div>
      <Table className="table mb-0">
        <TableHead>
          <TableRow>
            <TableHeader className="" style={smallStyle}>
              Type
            </TableHeader>
            <TableHeader className="text-center" style={smallStyle}>
              All
            </TableHeader>
            <TableHeader className="text-center" style={smallStyle}>
              Owned
            </TableHeader>
            <TableHeader className="text-center" style={smallStyle}>
              Following
            </TableHeader>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableData className="">
              <div className="font-weight-bold">Loads</div>
              <div className="small">
                Follow your loads from "Booked" to "Delivered" and see every
                detail and alert along the way.
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipments-all"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type === NOTIFICATION_TYPE.SHIPMENT &&
                        notification.source.includes(NOTIFICATION_SOURCE.ALL)
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() => handleAll(NOTIFICATION_TYPE.SHIPMENT)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipments-all"
                />
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipments-assigned"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type === NOTIFICATION_TYPE.SHIPMENT &&
                        notification.source.includes(
                          NOTIFICATION_SOURCE.ASSIGNED
                        )
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() =>
                    handleChange(
                      NOTIFICATION_TYPE.SHIPMENT,
                      NOTIFICATION_SOURCE.ASSIGNED
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipments-assigned"
                />
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipments-following"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type === NOTIFICATION_TYPE.SHIPMENT &&
                        notification.source.includes(
                          NOTIFICATION_SOURCE.FOLLOWING
                        )
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() =>
                    handleChange(
                      NOTIFICATION_TYPE.SHIPMENT,
                      NOTIFICATION_SOURCE.FOLLOWING
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipments-following"
                />
              </div>
            </TableData>
          </TableRow>
          <TableRow>
            <TableData className="">
              <div className="font-weight-bold">Trucks</div>
              <div className="small">
                Get notifications when new shipments match a truck. Act quickly
                to lock in the best rates.
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipment-quotes-all"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type ===
                          NOTIFICATION_TYPE.SHIPMENT_REQUEST &&
                        notification.source.includes(NOTIFICATION_SOURCE.ALL)
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() => handleAll(NOTIFICATION_TYPE.SHIPMENT_REQUEST)}
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipment-quotes-all"
                />
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipment-quotes-assigned"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type ===
                          NOTIFICATION_TYPE.SHIPMENT_REQUEST &&
                        notification.source.includes(
                          NOTIFICATION_SOURCE.ASSIGNED
                        )
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() =>
                    handleChange(
                      NOTIFICATION_TYPE.SHIPMENT_REQUEST,
                      NOTIFICATION_SOURCE.ASSIGNED
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipment-quotes-assigned"
                />
              </div>
            </TableData>
            <TableData className="text-center">
              <div className="custom-control custom-checkbox mr-n2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="shipment-quotes-following"
                  checked={
                    !!values.find((notification) => {
                      return (
                        notification.type ===
                          NOTIFICATION_TYPE.SHIPMENT_REQUEST &&
                        notification.source.includes(
                          NOTIFICATION_SOURCE.FOLLOWING
                        )
                      );
                    })
                  }
                  disabled={isUpdating}
                  onChange={() =>
                    handleChange(
                      NOTIFICATION_TYPE.SHIPMENT_REQUEST,
                      NOTIFICATION_SOURCE.FOLLOWING
                    )
                  }
                />
                <label
                  className="custom-control-label"
                  htmlFor="shipment-quotes-following"
                />
              </div>
            </TableData>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}
