import { cache } from 'src/feathers/hooks';

export default {
  before: {
    find: [cache]
  },
  after: {
    find: [cache]
  },
  error: {
    find: []
  }
};
