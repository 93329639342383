import React, { useState } from 'react';
import termsImg from 'src/images/terms.svg';
import { useAppState } from 'src/lib/hooks';
import { Button, Modal, ModalBody, toast, useApp } from 'straightline-ui';

export default function TermsModal() {
  const {
    state: { user },
    setInState
  } = useAppState();
  const app = useApp();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { tos } = user;

  const handleContinue = async () => {
    setIsSubmitting(true);
    try {
      // Accept the latest TOS and then refresh the user in state
      await app.service('api/tos-accept').create({});
      await app
        .service('api/user')
        .find()
        .then((result) => {
          setInState('user', result);
        });
    } catch (error) {
      toast.errors(error);
      // refresh the user for good measure
      await app
        .service('api/user')
        .find()
        .then((result) => {
          setInState('user', result);
        });
    }
    setIsSubmitting(false);
  };

  const isOpen = !tos || !tos.terms_of_service || !tos.terms_of_service.active;

  return (
    <Modal isOpen={isOpen} size="sm">
      {() => (
        <React.Fragment>
          <div className="p-4 d-flex justify-content-between align-items-center border-bottom">
            <h2 className="m-0">Terms of Service</h2>
          </div>
          <ModalBody>
            <div className="text-center mb-4">
              <img
                src={termsImg}
                style={{ maxWidth: '80%' }}
                alt="Terms of Service"
              />
            </div>
            <p className="text-secondary">
              Straightline has updated our{' '}
              <a href="/terms-of-service" target="_blank">
                Terms of Service
              </a>
              . By continuing, you expressly agree to these new terms.
            </p>
            <Button
              onClick={handleContinue}
              className={isSubmitting ? 'is-loading' : ''}
              color="primary"
              block
            >
              Continue
            </Button>
          </ModalBody>
        </React.Fragment>
      )}
    </Modal>
  );
}
