import React, { Component } from 'react';
import { Col, Container, Row } from 'straightline-ui';

export default class TermsOfService extends Component {
  render() {
    return (
      <Container>
        <Row className="justify-content-center">
          <Col sm="12" xl="8" className="my-5">
            <div className="header mt-md-3 text-center">
              <div className="header-body">
                <Row className="align-items-center">
                  <Col>
                    <h6 className="header-pretitle">Terms & Conditions</h6>
                    <h1 className="header-title">
                      Straightline - Carrier Agreement
                    </h1>
                  </Col>
                </Row>
              </div>
            </div>
            <p>
              <strong>1 .CARRIER REPRESENTS AND WARRANTS THAT IT:</strong>
            </p>
            <p>
              A. Is a Registered Motor Carrier of Property authorized to provide
              transportation of property under contracts with shippers and
              receivers and/or brokers of general commodities;
            </p>
            <p>
              B. Shall transport the property, under its own operating authority
              and subject to the terms of this Agreement;
            </p>
            <p>
              C. Makes the representations herein for the purpose of inducing
              Straightline to enter into this Agreement;
            </p>
            <p>
              D. Agrees that a Shipper&rsquo;s insertion of Straightline&rsquo;s
              name as the carrier on a bill of lading shall be for the
              Shipper&rsquo;s convenience only and shall not change
              Straightline&rsquo;s status as a property broker nor
              CARRIER&rsquo;s status as a motor carrier.
            </p>
            <p>
              E. Will not re-broker, assign or interline the shipments
              hereunder, without prior written consent of Straightline. If
              CARRIER breaches this provision, Straightline shall have the right
              of paying the monies it owes CARRIER directly to the delivering
              carrier, in lieu of payment to CARRIER. Upon Straightline&rsquo;s
              payment to delivering carrier, CARRIER shall not be released from
              any liability to Straightline under this Agreement. In addition to
              the indemnity obligation in Par 1.H CARRIER will be liable for
              consequential damages for violation of this Paragraph.
            </p>
            <p>
              F. Is in, and shall maintain compliance during the term of this
              Agreement, with all applicable federal, state and local laws
              relating to the provision of its services including, but not
              limited to: training of drivers, transportation of Hazardous
              Materials, (including the licensing and training of Haz Mat
              qualified drivers), as defined in 49 C.F.R. &sect;172.800,
              &sect;173, and &sect;397 et seq. to the extent that any shipments
              hereunder constitute Hazardous Materials; security regulations;
              owner/operator lease regulations; loading and securement of
              freight regulations; implementation and maintenance of driver
              safety regulations including, but not limited to, hiring,
              controlled substances, and hours of service regulations;
              sanitation, temperature, and contamination requirements for
              transporting food, perishable, and other products, qualification
              and licensing and training of drivers; implementation and
              maintenance of equipment safety regulations; maintenance and
              control of the means and method of transportation including, but
              not limited to, performance of its drivers; all applicable
              insurance laws and regulations including but not limited to
              workers compensation.
            </p>
            <p>
              G. CARRIER will notify Straightline immediately if its federal
              Operating Authority is revoked, suspended or rendered inactive for
              any reason; and/or if it is sold, or if there is a change in
              control of ownership, and/or any insurance required hereunder is
              threatened to be or is terminated, cancelled, suspended, or
              revoked for any reason.
            </p>
            <p>H.</p>
            <p>
              i. To the extent permissible under applicable federal and state
              law, and subject to the express monetary insurance limits in Par
              3.D as to CARRIER, and Straightline&rsquo;s monetary insurance
              limits for general liability or such other amounts as mutually
              agreed by the Parties in writing, CARRIER shall defend, indemnify
              and hold Straightline and its shipper customer harmless from any
              claims, actions or damages, arising out of its performance under
              this Agreement, including cargo loss and damage, theft, delay,
              damage to property, and personal injury or death, and Straightline
              shall defend, indemnify, and hold CARRIER harmless from any
              claims, actions, or damages, including cargo loss and damage,
              theft, delay, damage to property, personal injury or death,
              arising out of its performance hereunder. Neither Party shall be
              liable to the other for any claims, actions or damages due to the
              negligence or intentional act of the other Party, or the shipper.
              The obligation to defend shall include all costs of defense as
              they accrue.
            </p>
            <p>
              ii. Except for CARRIER&rsquo;s liability under Par 1.E, unless
              otherwise agreed in writing, and regardless of whether the Parties
              insurance as referred to in sub-par i) above, is valid or provides
              coverage, the Parties indemnity obligations shall not exceed the
              monetary insurance limits referred to in sub-par i).
            </p>
            <p>
              I. Does not have an &ldquo;Unsatisfactory&rdquo; safety rating
              issued by the Federal Motor Carrier Safety Administration (FMCSA),
              U.S. Department of Transportation, and will notify Straightline in
              writing immediately if its safety rating is changed to
              &ldquo;Unsatisfactory&rdquo; or &ldquo;Conditional&rdquo;.
            </p>
            <p>
              J. Authorizes Straightline to invoice CARRIER&rsquo;s freight
              charges to shipper, consignee, or third parties responsible for
              payment.
            </p>
            <p>
              K. Has investigated, monitors, and agrees to conduct business
              hereunder based on the credit-worthiness of Straightline and is
              granting Straightline credit terms accordingly.
            </p>
            <p>
              <strong>2. BROKER RESPONSIBILITIES: </strong>
            </p>
            <p>
              A. SHIPMENTS, BILLING &amp; RATES: Straightline agrees to solicit
              and obtain freight transportation business for CARRIER to the
              mutual benefit of CARRIER and Straightline, and shall offer
              CARRIER at least three (3) loads/shipments annually. Straightline
              shall inform CARRIER of (a) place of origin and destination of all
              shipments; and (b) if applicable, any special shipping
              instructions or special equipment requirements, of which
              Straightline has been timely notified. &nbsp;
            </p>
            <p>
              B. Straightlne agrees to conduct all billing services to shippers.
              CARRIER shall invoice Straightline for its (CARRIER&rsquo;s)
              charges, as mutually agreed in writing, by fax, or by electronic
              means, contained in Straightline&rsquo;s Load Confirmation
              incorporated herein by this reference. Additional rates for
              truckload or LTL shipments, or modifications or amendments of the
              above rates, or additional rates, may be established to meet
              changing market conditions, shipper requirements, Straightline
              requirements, and/or specific shipping schedules as mutually
              agreed upon, and shall be confirmed in writing (or by fax) by both
              Parties. Any such additional, modified, or amended rates, changes
              in rates shall automatically be incorporated herein by this
              reference.
            </p>
            <p>
              C. RATES: Additionally, any rates, which may be verbally agreed
              upon, shall be deemed confirmed in writing where CARRIER has
              billed the agreed rate and Straightline has paid it. All written
              confirmations of rates, including confirmations by billing and
              payment, shall be incorporated herein by this reference. Rates or
              charges, including but not limited to stop-offs, detention,
              loading or unloading, fuel surcharges, or other accessorial
              charges, released rates or values, or tariff rules or circulars,
              shall only be valid when specifically agreed to in a signed
              writing by the Parties.
            </p>
            <p>D. PAYMENT:</p>
            <p>
              i. The Parties agree that Straightline is the sole party
              responsible for payment of CARRIER's charges. Failure of
              Straightline to collect payment from its customer shall not
              exonerate Straightline of its obligation to pay CARRIER.
              Straightline agrees to pay CARRIER's invoice within 14 days of
              receipt of the bill of lading or proof of delivery, provided
              CARRIER is not in default under the terms of this Agreement. If
              Straightline has not paid CARRIER's invoice as agreed, and CARRIER
              has complied with the terms of this Agreement, CARRIER may seek
              payment from the Shipper or other party responsible for payment
              after giving Straightline 14 (business days) advance written
              notice. CARRIER shall not seek payment from Shipper if Shipper can
              prove payment to Straightline.
            </p>
            <p>
              ii. Payment and other disputes are subject to the terms of Par
              4.D, which provides in part that prevailing parties are entitled
              to recovery of costs, expenses and reasonable attorney fees.
            </p>
            <p>
              iii. Payment processing services for CARRIER on Straightline are
              provided by Stripe and are subject to the{' '}
              <a
                href="https://stripe.com/connect-account/legal"
                target="_blank"
                rel="noopener noreferrer"
              >
                Stripe Connected Account Agreement
              </a>
              , which includes the Stripe Terms of Service (collectively, the
              “Stripe Services Agreement”). By agreeing to this Agreement or
              continuing to operate as a CARRIER on Straightline, you agree to
              be bound by the Stripe Services Agreement, as the same may be
              modified by Stripe from time to time. As a condition of
              Straightline enabling payment processing services through Stripe,
              you agree to provide Straightline accurate and complete
              information about you and your business, and you authorize
              Straightline to share it and transaction information related to
              your use of the payment processing services provided by Stripe.
            </p>
            <p>
              E. BOND: Straightline shall maintain a surety bond /trust fund as
              agreed to in the amount of $75,000 and on file with the Federal
              Motor Carrier Safety Administration (FMCSA) in the form and amount
              not less than that required by that agency&rsquo;s regulations.
            </p>
            <p>
              F. Straightline will notify CARRIER immediately if its federal
              Operating Authority is revoked, suspended or rendered inactive for
              any reason; and/or if it is sold, or if there is a change in
              control of ownership, and/or any insurance required hereunder is
              threatened to be or is terminated, cancelled, suspended, or
              revoked for any reason.
            </p>
            <p>
              G. Brokers responsibility is limited to arranging for, but not
              actually performing, transportation of a shipper&rsquo;s freight.
            </p>
            <p>
              <strong>3. CARRIER RESPONSIBILITIES:</strong>
            </p>
            <p>
              A. EQUIPMENT: Subject to its representations and warranties in
              Paragraph 1 above, CARRIER agrees to provide the necessary
              equipment and qualified personnel for completion of the
              transportation services required for Straightline and/or its
              customers. CARRIER will not supply equipment that has been used to
              transport hazardous wastes, solid or liquid, regardless of whether
              they meet the definition in 40 C.F.R. &sect;261.1 et. seq. CARRIER
              agrees that all shipments will be transported and delivered with
              reasonable dispatch, or as otherwise agreed in writing.
            </p>
            <p>
              B. BILLS OF LADING: CARRIER shall issue a bill of lading in
              compliance with 49 U.S.C. &sect;80101 et seq., 49 C.F.R.
              &sect;373.101 (and any amendments thereto), for the property it
              receives for transportation under this Agreement. Unless otherwise
              agreed in writing, CARRIER shall become fully responsible/liable
              for the freight when it takes/receives possession thereof, and the
              trailer(s) is loaded, regardless of whether a bill of lading has
              been issued, and/or signed, and/or delivered to CARRIER, and which
              responsibility/liability shall continue until delivery of the
              shipment to the consignee and the consignee signs the bill of
              lading or delivery receipt. Any terms of the bill of lading
              (including but not limited to payment terms, released rates or
              released value) inconsistent with the terms of this Agreement
              shall be ineffective. Failure to issue a bill of lading, or sign a
              bill of lading acknowledging receipt of the cargo, by CARRIER,
              shall not affect the liability of CARRIER.
            </p>
            <p>C. LOSS &amp; DAMAGE CLAIMS:</p>
            <p>
              i. CARRIER shall comply with 49 C.F.R. &sect;370.1 et seq. and any
              amendments and/or any other applicable regulations adopted by the
              Federal Motor Carrier Safety Administration, U.S. Department of
              Transportation, or any applicable state regulatory agency, for
              processing all loss and damage claims and salvage and
            </p>
            <p>
              ii. CARRIER&rsquo;s liability for any cargo damage, loss, or theft
              from any cause shall be determined under the Carmack Amendment, 49
              U.S.C. &sect;14706; and
            </p>
            <p>
              iii. Special Damages: CARRIERs indemnification liability (Par 1.H)
              for freight loss and damage claims under this sub par C (ii) shall
              include legal fees which shall constitute special damages, the
              risk of which is expressly assumed by CARRIER, and which shall not
              be limited by any liability of CARRIER under sub par (ii) above.
            </p>
            <p>
              iv. Except as provided in Par 1.E above, neither Party shall be
              liable to the other for consequential damages without prior
              written notification of the risk of loss and its approximate
              financial amount, and agreement to assume such responsibility in
              writing.
            </p>
            <p>
              v. Notwithstanding the terms of 49 CFR 370.9,CARRIER shall pay,
              decline or make settlement offer in writing on all cargo loss or
              damage claims within _____ days of receipt of the claim. Failure
              of CARRIER to pay, decline or offer settlement within this _____
              day period shall be deemed admission by CARRIER of full liability
              for the amount claimed and a material breach of this Agreement. C
            </p>
            <p>
              vi. CARRIER&rsquo;s liability for cargo damage, loss, or theft
              from any cause for any one shipment, under sub par b) above, shall
              not exceed $500,000 unless CARRIER is notified by Straightline or
              Shipper of the increased value 3 business days prior to shipment
              pick up.
            </p>
            <p>
              D. INSURANCE: CARRIER shall furnish Straightline with
              Certificate(s) of Insurance, or insurance policies providing
              thirty (30) days advance written notice of cancellation or
              termination, and unless otherwise agreed, subject to the following
              minimum limits: General liability $1,000,000; motor vehicle
              (including hired and non-owned vehicles) $1,000,000 ($5,000,000 if
              transporting hazardous materials including environmental damages
              due to release or discharge of hazardous substances); cargo
              damage/loss, $100,000; workers&rsquo; compensation with limits
              required by law. Except for the higher coverage limits which may
              be specified above, the insurance policies shall comply with
              minimum requirements of the Federal Motor Carrier Safety
              Administration and any other applicable regulatory state agency.
              Nothing in this Agreement shall be construed to avoid CARRIERS
              liability due to any exclusion or deductible in any insurance
              policy.
            </p>
            <p>
              E. ASSIGNMENT OF RIGHTS: CARRIER automatically assigns to
              Straightline all its rights to collect freight charges from
              Shipper or any responsible third party on receipt of payment from
              Straightline.
            </p>
            <p>
              <strong>4. MISCELLANEOUS:</strong>
            </p>
            <p>
              A. INDEPENDENT CONTRACTOR: It is understood and agreed that the
              relationship between Straightline and CARRIER is that of
              independent contractor and that no employer/employee relationship
              exists, or is intended. Straightline has no control of any kind
              over CARRIER, including but not limited to routing of freight, and
              nothing contained herein shall be construed to be inconsistent
              with this provision.
            </p>
            <p>
              B. NON-EXCLUSIVE AGREEMENT: CARRIER and Straightline acknowledge
              and agree that this contract does not bind the respective Parties
              to exclusive services to each other. Either party may enter into
              similar agreements with other carriers, brokers, or freight
              forwarders.
            </p>
            <p>C. WAIVER OF PROVISIONS:</p>
            <p>
              i. Failure of either Party to enforce a breach or waiver of any
              provision or term of this Agreement shall not be deemed to
              constitute a waiver of any subsequent failure or breach, and shall
              not affect or limit the right of either Party to thereafter
              enforce such a term or provision.
            </p>
            <p>
              ii. This Agreement is for specified services pursuant to 49 U.S.C.
              &sect;14101(b). To the extent that terms and conditions herein are
              inconsistent with Part (b), Subtitle IV, of Title 49 U.S.C. (ICC
              Termination Act of 1995), the Parties expressly waive any or all
              rights and remedies they may have under the Act.
            </p>
            <p>
              D. DISPUTES: In the event of a dispute arising out of this
              Agreement, including but not limited to Federal or State statutory
              claims, the Party's sole recourse (except as provided below) shall
              be to arbitration. Proceedings shall be conducted under the rules
              of the (select one): ___Transportation Arbitration and Mediation
              PLLC (TAM), ___ American Arbitration Association (AAA), ___
              Transportation ADR Council, Inc. (ADR), ___ DRC (Fruit and
              Vegetable Dispute Resolution Corp) for fresh produce related
              claims, upon mutual agreement of the Parties, or if no agreement,
              then at Straightline&rsquo;s sole discretion. Arbitration
              proceedings shall be started within eighteen (18) months from the
              date of delivery or scheduled date of delivery of the freight,
              whichever is later. Upon agreement of the Parties, arbitration
              proceedings may be conducted outside of the administrative control
              of the TAM, AAA, ADR, or DRC. The decision of the arbitrators
              shall be binding and final and the award of the arbitrator may be
              entered as judgment in any court of competent jurisdiction. The
              rationale and reasoning of the decision of arbitrator(s) shall be
              fully explained in a written opinion. The prevailing party shall
              be entitled to recovery of costs, expenses and reasonable attorney
              fees as well as those incurred in any action for injunctive
              relief, or in the event further legal action is taken to enforce
              the award of arbitrators. Arbitration proceedings shall be
              conducted at the office of the AAA, ADR, DRC or TAM nearest
              Straightline headquarters or such other place as mutually agreed
              upon in writing or directed by the acting arbitration association.
              Provided, however, either Party may apply to a court of competent
              jurisdiction for injunctive relief. Unless preempted or controlled
              by federal transportation law and regulations, the laws of the
              State of ______shall be controlling notwithstanding applicable
              conflicts of laws rules. The arbitration provisions of this
              paragraph shall not apply to enforcement of the award of
              arbitration.
            </p>
            <p>
              i. (OPTIONAL):(BROKER INITIAL TDB; CARRIER INTIAL_________)
              Subject to the time limitation set forth in subpar A above, for
              disputes where the amount in controversy exceeds $_____________
              BROKER shall have the right, but not the obligation, to select
              litigation in order to resolve any disputes arising hereunder. In
              the event of litigation the prevailing Party shall be entitled to
              recover costs, expenses and reasonable attorney fees, including
              but not limited to any incurred on appeals.
            </p>
            <p>
              ii. (OPTIONAL)(BROKER INITIAL TDB; CARRIER INITIAL_________)
              Subject to the time limitation set forth in subpar A above, for
              disputes where the amount in controversy does not exceed
              $_____________ BROKER shall have the right, but not the
              obligation, to select litigation in small claims court order to
              resolve any disputes arising hereunder. The prevailing Party shall
              be entitled to recover costs, expenses and reasonable attorney
              fees, including but not limited to any incurred on appeals.
            </p>
            <p>
              iii. (IF i, AND OR ii ARE ADOPTED, THEN iii MUST BE INCLUDED)
              Venue, controlling law, and jurisdiction in any legal proceedings
              under subpars i or ii above shall be in the State of
              ________________.
            </p>
            <p>E. NO BACK SOLICITATION:</p>
            <p>
              i. Unless otherwise agreed in writing, CARRIER shall not knowingly
              solicit freight shipments for a period of ______ month(s)
              following termination of this agreement for any reason, from any
              shipper, consignor, consignee, or other customer of Straightline,
              when such shipments of shipper customers were first tendered to
              CARRIER by Straightline
            </p>
            <p>(OPTIONAL)</p>
            <p>
              ii. In the event of breach of this provision, Straightline shall
              be entitled, for a period of 12 months following delivery of the
              last shipment transported by CARRIER under this Agreement, to a
              commission of twenty percent (20%) of the gross transportation
              revenue (as evidenced by freight bills) received by CARRIER for
              the transportation of said freight as liquidated damages.
              Additionally, Straightline may seek injunctive relief and in the
              event it is successful, CARRIER shall be liable for all costs and
              expenses incurred by Straightline, including, but not limited to,
              reasonable attorney's fees.
            </p>
            <p>F. CONFIDENTIALITY:</p>
            <p>
              i. In addition to Confidential Information protected by law,
              statutory or otherwise, the Parties agree that all of their
              financial information and that of their customers, including but
              not limited to freight and brokerage rates, amounts received for
              brokerage services, amounts of freight charges collected, freight
              volume requirements, as well as personal customer information,
              customer shipping or other logistics requirements shared or
              learned between the Parties and their customers, shall be treated
              as Confidential, and shall not be disclosed or used for any reason
              without prior written consent.
            </p>
            <p>
              ii. In the event of violation of this Confidentiality paragraph,
              the Parties agree that the remedy at law, including monetary
              damages, may be inadequate and that the Parties shall be entitled,
              in addition to any other remedy they may have, to an injunction
              restraining the violating Party from further violation of this
              Agreement in which case the prevailing Party shall be liable for
              all costs and expenses incurred, including but not limited to
              reasonable attorney&rsquo;s fees.
            </p>
            <p>
              G. The limitations of liability for cargo loss and damage as well
              as other liabilities, arising out of the transportation of
              shipments, which originate outside the United States of America,
              may be subject to the laws of the country of origination.
            </p>
            <p>
              H. MODIFICATION OF AGREEMENT: This Agreement and Exhibit A et.seq.
              attached may not be amended, except by mutual written agreement,
              or the procedures set forth above (Pars 2.B and 2.C).
            </p>
            <p>I. NOTICES:</p>
            <p>
              i. All notices provided or required by this Agreement, shall be
              made in writing and delivered, return receipt requested, to the
              addresses shown herein with postage prepaid; or by confirmed
              (electronically acknowledged on paper) fax.
            </p>
            <p>
              ii. THE PARTIES shall promptly notify each other of any claim that
              is asserted against either of them by anyone arising out of the
              Parties performance of this Agreement.
            </p>
            <p>
              iii. Notices sent as required hereunder, to the addresses shown in
              this Agreement shall be deemed sent to the correct address, unless
              the Parties are notified in writing of any changes in address.
            </p>
            <p>
              J. CONTRACT TERM: The term of this Agreement shall be one year
              from the date hereof and thereafter it shall automatically be
              renewed for successive one (1) year periods, unless terminated,
              upon thirty (30) day's prior written notice, with or without
              cause, by either Party at any time, including the initial term. In
              the event of termination of this Agreement for any reason, the
              Parties shall be obligated to complete performance of any work in
              progress in accordance with the terms of this Agreement.
            </p>
            <p>
              K. SEVERANCE: SURVIVAL: In the event any of the terms of this
              Agreement are determined to be invalid or unenforceable, no other
              terms shall be affected and the unaffected terms shall remain
              valid and enforceable as written. The representations, rights and
              obligations of the parties hereunder shall survive termination of
              this Agreement for any reason.
            </p>
            <p>
              L. COUNTERPARTS: This Agreement may be executed in any number of
              counterparts each of which shall be deemed to be a duplicate
              original hereof.
            </p>
            <p>
              M. FAX CONSENT: The Parties to this Agreement are authorized to
              fax to each other at the numbers shown herein, (or otherwise
              modified in writing from time to time) shipment availabilities,
              equipment and rate promotions, or any advertisements of new
              services.
            </p>
            <p>
              N. ENTIRE AGREEMENT: Except for Exhibit A and its amendments, and
              unless otherwise agreed in writing, this Agreement contains the
              entire understanding of the Parties and supersedes all verbal or
              written prior agreements, arrangements, and understandings of the
              Parties relating to the subject matter stated herein. The Parties
              further intend that this Agreement constitutes the complete and
              exclusive statement of its terms, and that no extrinsic evidence
              may be introduced to reform this Agreement in any judicial or
              arbitration proceeding involving this Agreement.
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
}
