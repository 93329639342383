import { RATE_TYPE } from 'straightline-utils/constants';
import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    shipment_id: yup.string().id().label('Shipment ID').required(),
    carrier_rate_type: yup
      .string()
      .trim()
      .required()
      .oneOf(Object.values(RATE_TYPE))
      .label('Carrier Rate Type'),
    carrier_service_level: yup
      .string()
      .trim()
      .label('Service Level')
      .nullable(),
    carrier_email: yup.string().trim().email().label('Driver Email').nullable(),
    carrier_phone: yup.string().trim().phone().label('Driver Phone').required(),
    carrier_driver: yup.string().trim().label('Driver Name').required(),
    carrier_tracking_url: yup.string().trim().label('Tracking Link').nullable(),
    carrier_truck_number: yup.string().trim().label('Truck Number').nullable(),
    carrier_trailer_number: yup
      .string()
      .trim()
      .label('Trailer Number')
      .nullable()
  });
};
