import React from 'react';
import carrierPacket from 'src/images/Straightline-Carrier-Packet.pdf';
import { useAppState } from 'src/lib/hooks';
import {
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  FormSubmit,
  Label,
  Row,
  toast,
  useApp
} from 'straightline-ui';
import { yup } from 'straightline-utils/validation';

const schema = yup.object({
  text: yup.string().trim().required().label('Message')
});

export default function Support() {
  const app = useApp();
  const contactService = app.service('api/contact');
  const {
    state: { user }
  } = useAppState();

  const handleSubmit = (data, formik) => {
    contactService
      .create({
        subject: 'New App Contact',
        emails: [process.env.REACT_APP_SUPPORT_EMAIL],
        text: data.text,
        preTag: JSON.stringify(
          {
            user: { id: user.id, email: user.email, full_name: user.full_name }
          },
          null,
          2
        )
      })
      .then(() => {
        toast.success(`Message Sent!`);
        formik.resetForm();
      })
      .catch((err) => {
        toast.errors(err);
        if (err.errors) {
          formik.setErrors(err.errors);
        }
        formik.setSubmitting(false);
      });
  };

  return (
    <Container fluid>
      <div className="header pt-md-2">
        <div className="header-body">
          <h6 className="header-pretitle">Contact Us</h6>
          <h1 className="header-title">How can we help you?</h1>
        </div>
      </div>
      <Row>
        <Col lg="6">
          <Card>
            <CardBody>
              <h3 className="text-uppercase text-muted mb-3">Contact</h3>
              <p className="mb-4">
                Straightline has a team of logistics professionals ready to
                help. Call us any time. We’d love to talk to you.
              </p>
              <div className="mb-4">
                <p className="mb-1 font-weight-bold">Straightline Memphis</p>
                <p className="mb-1">3750 Hacks Cross Rd</p>
                <p className="mb-1">Suite 102-205</p>
                <p className="mb-1">Memphis, TN 38125</p>
                <p className="mb-1">(901) 295-8844</p>
                <p className="mb-0">ops@bookstraightline.com</p>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h3 className="text-uppercase text-muted mb-3">Carrier Packet</h3>
              <p>
                You can also download our Carrier Packet to get access to all of
                Straightline's DOT, insurance, and legal information.
              </p>
              <a
                href={carrierPacket}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Download Carrier Packet
              </a>
            </CardBody>
          </Card>
        </Col>
        <Col xl="5" lg="6">
          <Card>
            <CardBody>
              <h3 className="text-uppercase text-muted mb-3">Holler at us!</h3>
              <Formik
                initialValues={{ text: '' }}
                onSubmit={handleSubmit}
                validationSchema={schema}
                render={({ isSubmitting }) => {
                  return (
                    <Form>
                      <FormGroup>
                        <Label>Message*</Label>
                        <FormInput
                          name="text"
                          type="textarea"
                          placeholder="Report a bug, ask a question, or provide feedback"
                          rows="6"
                          disabled={isSubmitting}
                        />
                        <FormFeedback name="text" />
                      </FormGroup>
                      <FormSubmit>Submit</FormSubmit>
                    </Form>
                  );
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
