import React from 'react';
import { Container } from 'straightline-ui';

import LoadingIndicator from '../LoadingIndicator';
import SettingsHeader from '../SettingsHeader';

export default ({ activeTab }) => (
  <Container fluid>
    <SettingsHeader activeTab={activeTab} />
    <div className="mt-5">
      <LoadingIndicator />
    </div>
  </Container>
);
