import React, { Component } from 'react';
import feathers from 'src/feathers';
import {
  Alert,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Formik,
  FormInput,
  Icon,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Stripes,
  toast
} from 'straightline-ui';

export default class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.resetService = feathers.service('auth/reset-password');
    this.state = {
      showPassword: false,
      error: null,
      success: null,
      fetching: true,
      valid: false
    };
  }

  componentDidMount() {
    const { resetToken } = this.props.location.query;
    if (!resetToken) {
      return this.props.history.push('/login');
    }
    this.resetService
      .get(resetToken)
      .then(({ valid }) => this.setState({ valid, fetching: false }))
      .catch((error) => {
        toast.errors(error);
        this.props.history.push('/login');
      });
  }

  handleSubmit = (data, formik) => {
    const { resetToken } = this.props.location.query;
    const { password } = data;
    formik.setSubmitting(true);
    this.resetService
      .create({ password, password_reset_token: resetToken })
      .then(async (user) => {
        feathers
          .authenticate({
            strategy: 'local',
            email: user.email,
            password
          })
          .then((authResult) => {
            formik.setSubmitting(false);
            this.props.history.push('/');
          })
          .catch((error) => {
            formik.setSubmitting(false);
            this.props.history.push('/login');
          });
      })
      .catch((error) => {
        formik.setSubmitting(false);
        if (error.formik) {
          formik.setErrors(error.formik);
        }
        this.setState({ error, success: null });
      });
  };

  render() {
    const { error, success, fetching, valid, showPassword } = this.state;
    const { resetToken } = this.props.location.query;
    if (fetching) {
      return null;
    }
    if (!valid) {
      return (
        <div className="vh-100 d-flex align-items-center border-top-2 border-primary">
          <Container>
            <Row className="align-items-center">
              <Col sm="12" md={{ size: 5, offset: 4 }}>
                <h1 className="display-4 text-center mb-3 animated slideInDown">
                  Invalid or Expired Link
                </h1>
                <p className="text-muted text-center mb-5 animated slideInDown">
                  This link has either already been used or is expired. Password
                  links will expire after 7 days. Click the button to try again.
                </p>
                <Button
                  to="/forgot-password"
                  color="primary"
                  className="animated slideInDown"
                  block
                >
                  Reset Password
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    return (
      <div className="vh-100 d-flex align-items-center border-top-2 border-primary">
        <Stripes animation="fadeIn" />
        <Container>
          <Row className="justify-content-center">
            <Col md="8" lg="5">
              <Card className="py-5 p-sm-5">
                <CardBody>
                  <h1 className="display-4 text-center mb-3">Set Password</h1>
                  <p className="text-muted text-center mb-5">
                    Password must be 8+ characters and contain a lowercase
                    letter, uppercase letter, number, and special character.
                  </p>
                  {success ? (
                    <Alert color="success" className="animated bounceIn faster">
                      {success}
                    </Alert>
                  ) : (
                    <Formik
                      onSubmit={this.handleSubmit}
                      initialValues={{
                        password: '',
                        password_reset_token: resetToken
                      }}
                      validationSchema={this.resetService.schema}
                      render={({ isSubmitting }) => {
                        return (
                          <Form>
                            {error && (
                              <Alert
                                color="warning"
                                className="animated bounceIn faster"
                              >
                                <strong>Error:&nbsp;</strong>&nbsp;
                                {error.message || error}
                              </Alert>
                            )}
                            <FormGroup>
                              <Label>Password*</Label>
                              <InputGroup className="input-group-merge">
                                <FormInput
                                  type={showPassword ? 'text' : 'password'}
                                  name="password"
                                  className="form-control-appended"
                                  placeholder="Enter a new password"
                                  disabled={isSubmitting}
                                />
                                <InputGroupAddon addonType="append">
                                  <InputGroupText>
                                    <Icon
                                      type={showPassword ? 'eye-off' : 'eye'}
                                      className="pointer"
                                      onClick={() =>
                                        this.setState({
                                          showPassword: !showPassword
                                        })
                                      }
                                    />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <FormFeedback name="password" />
                              </InputGroup>
                            </FormGroup>
                            <Button
                              type="submit"
                              color="primary"
                              size="lg"
                              block
                              className={`mb-3 ${
                                isSubmitting ? 'is-loading' : ''
                              }`}
                              disabled={isSubmitting}
                            >
                              Set Password
                            </Button>
                          </Form>
                        );
                      }}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
