import React from 'react';
import RateConfirmation from 'src/components/RateConfirmation';
import {
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Row,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  useApp
} from 'straightline-ui';
import { formatMoney } from 'straightline-utils/accounting';
import { formatDate } from 'straightline-utils/time';

import { ShipmentDispatch } from './details';

const DownloadElement = React.lazy(() =>
  import('src/components/DownloadElement').catch(() => window.location.reload())
);

export default function RateCon({ shipment }) {
  if (!shipment.shipment_dispatch_id) {
    return <ShipmentDispatch shipment={shipment} />;
  }
  return (
    <Row>
      <Col xl="8">
        <div className="text-right mb-3">
          <React.Suspense
            fallback={
              <Button color="primary" className="mr-3">
                Download
              </Button>
            }
          >
            <DownloadElement
              elementId="rateConfirmation"
              options={{
                filename: `Straightline Load ${shipment.id} Rate Confirmation`
              }}
              color="primary"
              className="mr-3"
            >
              Download
            </DownloadElement>
          </React.Suspense>
        </div>
        <div className="card">
          <RateConfirmation shipment={shipment} id="rateConfirmation" />
        </div>
        <Transfers shipment={shipment} />
      </Col>
    </Row>
  );
}

function Transfers({ shipment }) {
  const app = useApp();

  const { result, isLoading, isError, error } = app
    .service('api/shipment-transfers')
    .useFind({ query: { shipment_id: shipment.id } });

  if (isLoading) {
    return null;
  }

  if (isError) {
    return (
      <Alert color="danger">
        {error.message || 'There was a error fetching payouts for this RateCon'}
      </Alert>
    );
  }

  const transfers = result.data;

  if (!transfers.length) {
    return null;
  }

  return (
    <Card className="p-5">
      <h2>Payments</h2>
      <div className="table-responsive">
        <Table className="mb-0">
          <TableHead>
            <TableRow>
              <TableHeader className="px-0 bg-transparent border-top-0">
                <span className="h6">Payment</span>
              </TableHeader>
              <TableHeader className="px-0 bg-transparent border-top-0">
                <span className="h6">Date</span>
              </TableHeader>
              <TableHeader className="px-0 bg-transparent border-top-0">
                <span className="h6">Status</span>
              </TableHeader>
              <TableHeader className="px-0 bg-transparent border-top-0">
                <span className="h6">Notes</span>
              </TableHeader>
              <TableHeader className="px-0 bg-transparent border-top-0 text-right">
                <span className="h6">Amount</span>
              </TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {transfers.map((transfer) => (
              <TableRow key={transfer.id}>
                <TableData className="px-0">
                  {transfer.type.charAt(0).toUpperCase() +
                    transfer.type.slice(1)}
                </TableData>
                <TableData className="px-0">
                  {formatDate(transfer.created_at, 'monthDayYear')}
                </TableData>
                <TableData className="px-0">
                  {transfer.reversed ? (
                    <React.Fragment>
                      <Badge color="danger">Reversed</Badge>
                      <span className="text-muted ml-3">
                        {formatDate(transfer.updated_at, 'monthDayYear')}
                      </span>
                    </React.Fragment>
                  ) : (
                    <Badge color="success">Paid</Badge>
                  )}
                </TableData>
                <TableData className="px-0" style={{ maxWidth: 100 }}>
                  {transfer.notes || 'NA'}
                </TableData>
                <TableData className="px-0 text-right">
                  <span className={transfer.reversed ? 'text-muted' : ''}>
                    {formatMoney(transfer.amount)}
                  </span>
                </TableData>
              </TableRow>
            ))}
            <TableRow>
              <TableData className="px-0 border-top border-top-2">
                <strong>Total paid</strong>
              </TableData>
              <TableData
                colSpan={4}
                className="px-0 text-right border-top border-top-2"
              >
                <span className="h3">
                  {formatMoney(
                    transfers.reduce((total, transfer) => {
                      return transfer.reversed
                        ? total
                        : total + transfer.amount;
                    }, 0)
                  )}
                </span>
              </TableData>
              <TableData className="px-0 text-right border-top border-top-2" />
            </TableRow>
          </TableBody>
        </Table>
      </div>
    </Card>
  );
}
