// import styles from './style';
import './style.scss';

import React from 'react';
import { GoogleMap as Gmap, withGoogleMap } from 'react-google-maps';
import { WithScripts } from 'straightline-ui';

class MapBase extends React.Component {
  componentDidMount() {
    this.setCenter(this.props.center);
  }

  componentWillReceiveProps(nextProps) {
    const nextCenter = nextProps.center;
    const currCenter = this.props.center;

    if (JSON.stringify(nextCenter) !== JSON.stringify(currCenter)) {
      this.setCenter(nextCenter);
    }
  }

  setCenter = (center) => {
    if (!center) {
      return;
    }

    if (Array.isArray(center)) {
      if (center.length === 1) {
        this.map.setCenter(center[0]);
        this.map.panTo(center[0]);
        return;
      }

      const bounds = new window.google.maps.LatLngBounds();
      center.forEach((latLng) => {
        bounds.extend(latLng);
      });

      this.map.setCenter(bounds.getCenter());
      // this.map.panTo(bounds.getCenter());
      this.map.panToBounds(bounds);

      if (!this.props.zoom) {
        // auto zoom
        this.map.fitBounds(bounds);
      }

      return;
    }

    this.map.setCenter(center);
    this.map.panTo(center);
  };

  render() {
    // Do not pass center because it is handled progromatically
    // to make for better panning animation when center changes
    const { defaultOptions, center, ...rest } = this.props;
    return (
      <Gmap
        ref={(map) => {
          if (map) {
            this.map = map;
            this.map.setCenter = (center) => {
              map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED.setCenter(
                center
              );
            };
          }
        }}
        defaultOptions={{
          zoom: 19,
          mapTypeId: 'roadmap',
          tilt: 0,
          mapTypeControl: false,
          rotateControl: false,
          streetViewControl: false,
          ...defaultOptions
        }}
        {...rest}
      />
    );
  }
}

const GoogleMap = withGoogleMap(MapBase);

export default class Map extends React.Component {
  state = { error: null };

  // Google Maps Javascript API will throw on unsupported browsers
  componentDidCatch(error) {
    this.setState({ error });
  }

  render() {
    const {
      containerClassName,
      containerStyle,
      mapClassName,
      mapStyle,
      wrapperClassName,
      wrapperStyle,
      ...rest
    } = this.props;

    const { error } = this.state;

    if (error) {
      return (
        <WrapperElement className={wrapperClassName} style={wrapperStyle}>
          <div className="text-center mt-5 mb-5">
            {error.message || 'There was an error rendering the map'}
          </div>
        </WrapperElement>
      );
    }

    return (
      <WithScripts
        path={process.env.REACT_APP_GOOGLE_MAPS_URL}
        loader={
          <WrapperElement className={wrapperClassName} style={wrapperStyle}>
            <ContainerElement
              className={containerClassName}
              style={containerStyle}
            />
          </WrapperElement>
        }
        render={() => {
          return (
            <WrapperElement className={wrapperClassName} style={wrapperStyle}>
              <GoogleMap
                containerElement={
                  <ContainerElement
                    className={containerClassName}
                    style={containerStyle}
                  />
                }
                mapElement={
                  <div
                    className={`map ${mapClassName ? mapClassName : ''}`}
                    style={mapStyle}
                    // {...rest}
                  />
                }
                {...rest}
              />
            </WrapperElement>
          );
        }}
      />
    );
  }
}

const WrapperElement = (props) => {
  const { className, ...rest } = props;
  return (
    <div className={`map-wrapper ${className ? className : ''}`} {...rest} />
  );
};

const ContainerElement = (props) => {
  const { className, ...rest } = props;
  return (
    <div className={`map-container ${className ? className : ''}`} {...rest} />
  );
};
