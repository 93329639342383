import { yup } from 'straightline-utils/validation';

export default (app) => {
  return yup.object({
    email: yup.string().trim().email().required().label('Email'),
    phone: yup.string().trim().phone().required().label('Phone'),
    first_name: yup.string().trim().required().label('First name'),
    last_name: yup.string().trim().required().label('Last name'),
    carrier_name: yup.string().trim().required().label('Business name'),
    mc_number: yup
      .string()
      .trim()
      .numbersOnly()
      .required()
      .min(6)
      .max(8)
      .label('MC number'),
    dot_number: yup
      .string()
      .trim()
      .numbersOnly()
      .required()
      .min(4)
      .max(8)
      .label('DOT number')
  });
};
