import debounce from 'lodash/debounce';
import React, { useCallback, useState } from 'react';
import LoadingIndicator from 'src/components/LoadingIndicator';
import NavTab from 'src/components/NavTab';
import PaginationBtns from 'src/components/PaginationBtns';
import ShipmentBanner from 'src/components/ShipmentBanner';
import StatusBadge from 'src/components/StatusBadge';
import logisticsImg from 'src/images/logistics.svg';
import {
  Button,
  Card,
  Col,
  Container,
  Icon,
  InputSelect,
  Link,
  ListGroup,
  ListGroupItem,
  Nav,
  Row,
  ScreenSize,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  TableSearch,
  useApp,
  useUrlState
} from 'straightline-ui';
import { formatMoney, roundNumberToLabel } from 'straightline-utils/accounting';
import { STATUSES } from 'straightline-utils/constants';
import { formatDate } from 'straightline-utils/time';

export default function Shipments() {
  const app = useApp();

  const { getInState, setInState, delInState } = useUrlState();
  const query = getInState('query', { $sort: { updated_at: -1 } });
  const $sort = getInState('query.$sort', { updated_at: -1 });
  const $search = getInState('query.$search', '');
  const status_type = getInState('query.status_type', 'all');
  const [text, setText] = useState($search);

  const {
    result: shipments,
    isLoading,
    isError,
    isFetching
  } = app
    .service('api/shipments')
    .useFind({ query }, { keepPreviousData: true });

  // eslint-disable-next-line
  const debouncedHandleChange = useCallback(
    debounce((value) => {
      const val = value && value.trim ? value.trim() : value;
      if (val) {
        setInState('query.$search', val);
      } else {
        delInState('query.$search');
      }
    }, 300),
    []
  );

  const handleSearchChange = ({ target: { value } }) => {
    setText(value);
    debouncedHandleChange(value);
  };

  const handleSearchClear = async () => {
    delInState('query.$search');
  };

  const handleSort = ({ name, value }) => {
    setInState('query.$sort', { [name]: value });
  };

  const handleTabChange = async (status) => {
    if (status === 'all') {
      return delInState('query.status_type');
    }

    return setInState('query.status_type', status);
  };

  if (isLoading || isError) {
    return (
      <div className="d-flex vh-100 align-items-center">
        <LoadingIndicator delay={250} />
      </div>
    );
  }

  const shipmentStatuses = [STATUSES].sort((a, b) =>
    a.order > b.order ? 1 : -1
  );

  if (!getInState('query') && shipments.total === 0) {
    return <NoShipments />;
  }

  return (
    <Container fluid className="pb-5">
      <div className="header pt-md-2">
        <div className="header-body">
          <Row className="align-items-center">
            <Col>
              <h6 className="header-pretitle">Overview</h6>
              <h1 className="header-title">My Loads</h1>
            </Col>
            <Col className="col-auto">
              <Button to="/shipment-request" color="primary" className="mr-2">
                <Icon type="truck" className="mr-2" />
                Post Truck
              </Button>
              <Button to="/shipment-matches" color="primary">
                <Icon type="clipboard" className="mr-2" />
                Load Board
              </Button>
            </Col>
          </Row>
          <ScreenSize sm md lg xl>
            {() => (
              <Row className="align-items-center">
                <Col>
                  <Nav tabs className="nav-overflow header-tabs">
                    <NavTab
                      tab="all"
                      onClick={handleTabChange}
                      activeTab={status_type}
                    >
                      All Loads
                    </NavTab>
                    {shipmentStatuses.map((status) => (
                      <NavTab
                        key={status.value}
                        tab={status.value}
                        onClick={handleTabChange}
                        activeTab={status_type}
                      >
                        {status.label}
                      </NavTab>
                    ))}
                  </Nav>
                </Col>
              </Row>
            )}
          </ScreenSize>
          <ScreenSize xs>
            {() => (
              <div className="mt-4">
                <InputSelect
                  value={status_type}
                  onChange={({ target }) => handleTabChange(target.value)}
                  options={[{ label: 'All Loads', value: 'all' }].concat(
                    shipmentStatuses
                  )}
                />
              </div>
            )}
          </ScreenSize>
        </div>
      </div>
      <div className={isFetching ? 'is-fetching' : ''}>
        <ScreenSize sm md lg xl>
          {() => (
            <ShipmentTable
              shipments={shipments.data}
              text={text}
              sort={handleSort}
              $sort={$sort}
              handleSearchChange={handleSearchChange}
              handleSearchClear={handleSearchClear}
            />
          )}
        </ScreenSize>
        <ScreenSize xs>
          {() => <ShipmentCards shipments={shipments.data} />}
        </ScreenSize>
      </div>
      <div className="text-right">
        <PaginationBtns
          total={shipments.total}
          skip={getInState('query.$skip', 0)}
          setSkip={($skip) => setInState('query.$skip', $skip)}
        />
      </div>
    </Container>
  );
}

const NoShipments = () => {
  return (
    <Container fluid className="pb-5">
      <div className="header pt-md-2">
        <div className="header-body">
          <Row className="align-items-center">
            <Col>
              <h6 className="header-pretitle">Overview</h6>
              <h1 className="header-title">My Loads</h1>
            </Col>
            <Col className="col-auto">
              <Button to="/shipment-request" color="primary" className="mr-2">
                <Icon type="truck" className="mr-2" />
                Post Truck
              </Button>
              <Button to="/shipment-matches" color="primary">
                <Icon type="clipboard" className="mr-2" />
                Load Board
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <Row className="justify-content-center pt-6">
        <Col lg="9">
          <Row className="align-items-center">
            <Col lg="6" className="text-center mb-4 mb-lg-0">
              <img
                src={logisticsImg}
                alt="Get Started"
                className="img-fluid mb-4 mb-xl-0"
              />
            </Col>
            <Col lg="6">
              <h1>Find Loads</h1>
              <p className="mb-4 text-muted lead">
                You haven't booked any loads yet. Use the Load Board to search
                for loads near you or Post a Truck to be notified when loads
                meet your search criteria.
              </p>
              <Row className="mb-n3">
                <Col xs="6" className="mb-3 pr-0">
                  <Button to="/shipment-request" color="primary" block>
                    <Icon type="truck" className="mr-2" />
                    Post Truck
                  </Button>
                </Col>
                <Col xs="6" className="mb-3">
                  <Button to="/shipment-matches" color="primary" block>
                    <Icon type="clipboard" className="mr-2" />
                    Load Board
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const ShipmentTable = ({
  shipments,
  text,
  handleSearchChange,
  handleSearchClear
}) => {
  return (
    <Card>
      <TableSearch
        value={text}
        onChange={handleSearchChange}
        onClear={handleSearchClear}
        disabled={!text}
        placeholder="Search by shipper, city, or reference number"
      />
      <div className="table-responsive">
        <Table className="table table-nowrap card-table table-hover">
          <TableHead>
            <TableRow>
              <TableHeader className="py-3 px-3 px-xl-4">Status</TableHeader>
              <TableHeader className="py-3 px-3 px-xl-4">Details</TableHeader>
              <TableHeader className="py-3 px-3 px-xl-4">Pickup</TableHeader>
              <TableHeader className="py-3 px-3 px-xl-4">Delivery</TableHeader>
              <TableHeader className="py-3 px-3 px-xl-4">Service</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody className="list">
            {shipments.map((shipment) => (
              <TableRow key={shipment.id} to={`/shipments/${shipment.id}`}>
                <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                  <StatusBadge status={shipment.status} />
                </TableData>
                <TableData className="px-3 px-xl-4">
                  <div className="d-flex justify-content-between">
                    <div className="mr-3">
                      <div>
                        <span className="text-muted">SL#: </span>
                        {shipment.id}
                      </div>
                      <div>
                        <span className="text-muted">PICKUP#: </span>
                        {shipment.reference_number || (
                          <span className="text-muted">NA</span>
                        )}
                      </div>
                    </div>
                    <div className="text-right">
                      <div className="text-success">
                        {formatMoney(shipment.rate.price.carrier_price)}
                      </div>
                      <div>{shipment.shipper.name}</div>
                    </div>
                  </div>
                  {/* <Icon type="dot" className="mx-1 text-muted" /> */}
                </TableData>
                <TableData className="px-3 px-xl-4" style={{ width: '1%' }}>
                  <div>
                    {shipment.pickup_address.city},{' '}
                    {shipment.pickup_address.state}
                  </div>
                  <div>{formatDate(shipment.pickup_date, 'LLL d, yyyy')}</div>
                </TableData>
                <TableData
                  className="px-3 px-xl-4"
                  style={{ width: '1%', position: 'relative' }}
                >
                  <div style={{ position: 'absolute', left: -12, top: 21 }}>
                    <Icon
                      type="arrow-right text-success"
                      style={{ fontSize: 25 }}
                    />
                  </div>
                  <div>
                    {shipment.delivery_address.city},{' '}
                    {shipment.delivery_address.state}
                  </div>
                  <div>{formatDate(shipment.due_date, 'LLL d, yyyy')}</div>
                </TableData>
                <TableData className="px-3 px-xl-4">
                  <div>{shipment.rate.service_level.name}</div>
                  <div>
                    {shipment.truck.label}
                    <Icon type="dot" className="text-muted mx-1" />
                    {roundNumberToLabel(shipment.load_weight)} lbs
                    <Icon type="dot" className="text-muted mx-1" />
                    {shipment.travel.miles} mi
                    {/* <Icon type="dot" className="text-muted mx-1" />
                  {formatMoney(shipment.cost_per_mile)}/mi */}
                  </div>
                </TableData>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};

const ShipmentCards = ({ shipments }) => {
  return (
    <Card className="mx-n3">
      <ListGroup className="list-group-flush">
        {shipments.map((shipment) => {
          return (
            <ListGroupItem
              key={shipment.id}
              tag={Link}
              to={`/shipments/${shipment.id}`}
              className="list-group-item-action px-3"
            >
              <ShipmentBanner shipment={shipment} />
              <ListGroup className="list-group list-group-flush list-group-activity">
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="clipboard" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div className="font-weight-bold">
                        {shipment.shipper.name}
                      </div>
                      <div className="mb-3 small text-primary mt-n1 font-weight-bold">
                        {shipment.rate.service_level.name}
                      </div>
                      <StatusBadge status={shipment.status} />
                      <div>
                        <span className="text-secondary">SL#: </span>
                        {shipment.id}
                      </div>
                      {shipment.reference_number && (
                        <div>
                          <span className="text-secondary">PICKUP#: </span>
                          {shipment.reference_number}
                        </div>
                      )}
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="py-3">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="truck" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div className="font-weight-bold">
                        {`${shipment.pickup_address.city}, ${shipment.pickup_address.state}`}
                      </div>
                      <div>
                        {formatDate(shipment.pickup_date, 'LLL d, yyyy')}
                      </div>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem className="pt-3 pb-0">
                  <Row>
                    <Col xs="auto">
                      <div className="avatar avatar-sm">
                        <div className="avatar-title font-size-lg rounded-circle bg-light text-dark">
                          <Icon type="map-pin" />
                        </div>
                      </div>
                    </Col>
                    <Col className="ml-n2">
                      <div className="font-weight-bold">
                        {`${shipment.delivery_address.city}, ${shipment.delivery_address.state}`}
                      </div>
                      <div>{formatDate(shipment.due_date, 'LLL d, yyyy')}</div>
                    </Col>
                  </Row>
                </ListGroupItem>
              </ListGroup>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    </Card>
  );
};
